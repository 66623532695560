import { combineReducers } from 'redux';

import { sessionReducer as session } from './sessionReducer';
import { speechToTextReducer as speechToText } from './speechToTextReducer';
import { profileReducer as profile } from './profileReducer';
import { publishReducer as publish } from './publishReducer';
import { paginationReducer as pagination } from './paginationReducer';
import { navReducer } from './navReducer';
import { breadCrumbReducer } from './breadCrumbReducer';
import { notificationReducer as notification } from './notificationReducer';
import { snapshotSettingsReducer as snapshotSettings } from './snapshotSettingsReducer';
import { liveManagerReducer as liveManager } from './liveManagerReducer';
import { searchReducer as search } from './searchReducer';
import { playerReducer as player } from './playerReducer';
import { appsReducer as apps } from './appsReducer';
import { otpReducer as otp } from './otpReducer';
import { channelReducer as channel } from './channelReducer';
import { iponlyChannelReducer as iponlyChannels } from './iponlyChannelReducer';
import { inspectScreenReducer } from './inspectScreenReducer';
import { interactTimelineReducer } from './interactTimelineReducer';
import { interactElementsReducer } from './interactElementsReducer';
import { tracksReducer } from './tracksReducer';
import { globalReducer } from './globalReducer';
import { accountFeaturesReducer as accountFeatures } from './accountFeaturesReducer';
import { liveChatReducer } from './liveChatReducer';
import { pollReducer } from './pollReducer';
import { scenarioReducer } from './scenarioReducer';
import { uploadingReducer } from './uploadingReducer';
import { viewEngagementReducer } from './viewEngagementReducer';
import { withReduxStateSync } from 'redux-state-sync';

const reducer = combineReducers({
	publish,
	pagination,
	speechToText,
	session,
	profile,
	navReducer,
	breadCrumbReducer,
	notification,
	snapshotSettings,
	liveManager,
	search,
	player,
	apps,
	otp,
	channel,
	iponlyChannels,
	inspectScreenReducer,
	interactTimelineReducer,
	interactElementsReducer,
	tracksReducer,
	accountFeatures,
	liveChatReducer,
	globalReducer,
	pollReducer,
	scenarioReducer,
	uploadingReducer,
	viewEngagementReducer,
});

export const rootReducer = withReduxStateSync(reducer);
