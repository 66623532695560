import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './../InteractEditorTopPanel/InteractElementProperties/InteractElementProperties.scss';
import trashcan from './../../../assets/images/trashcan.svg';
import link_arrow from './../../../assets/images/link_arrow.svg';
import { updateInteractTimelineItem } from '../../../actions/interactTimelineActions';
import {
	interactActionsId,
	openUrlOptions,
	playPauseOptions,
	interactActionList,
} from '../../../constants/interactConstant';
import _ from 'underscore';
import Radio from '@material-ui/core/Radio';
import TimeInput from '../../../reusable/TimeInput/TimeInput';
import { showNotification } from '../../../actions/notificationActions';
import { sendUpdateInteractItems } from '../../../actions/interactTimelineActions';
import {
	getTimelineTimeFromSeconds,
	getFormattedTimeWithMilliseconds,
	getSecondsFromTimelineTime,
	getTimelineTimeFromTimeInput,
} from '../../../services/timeStampService';

class InteractButtonActions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openUrl: '',
		};
	}

	componentWillUnmount() {}

	componentDidMount = () => {};

	componentDidUpdate(prevProps) {
		if (prevProps.parentKey != this.props.parentKey) {
			this.props.items
				.map((x) => {
					return x.id;
				})
				.indexOf(this.props.item.id);
		}
	}

	getActionsTitleSection = (actionKey) => {
		let textTitle = '';
		let action = _.find(interactActionList, (actionObj) => {
			return actionObj.id === actionKey;
		});
		textTitle = action.name;
		return (
			<>
				<div className="actionTitle">
					<label> {textTitle} </label>
					<img
						src={trashcan}
						onClick={() => {
							this.deleteAction(actionKey);
						}}
					/>
				</div>
			</>
		);
	};

	renderLinkArrowI = () => {
		return (
			<>
				<div className="linkArrow">
					<img src={link_arrow} />
				</div>
			</>
		);
	};

	deleteAction = (actionKey) => {
		const { item, parentKey } = this.props;
		let newItem = {
			...item,
		};
		delete newItem.settings[parentKey].action.default[actionKey];
		this.props.updateInteractTimelineItem(newItem);
	};
	// openurl changes start
	handleOpenUrChange = (e, target) => {
		const { item, actionKey, parentKey, items } = this.props;

		let newItems = [...items];
		newItems
			.map((x) => {
				return x.id;
			})
			.indexOf(item.id);
		let newItem = {
			...item,
		};
		if (target) {
			newItem.settings[parentKey].action.default[actionKey].target = e.target.value;
			//newItems[itemIndex].settings[parentKey].action.default[actionKey].target = e.target.value
		} else {
			newItem.settings[parentKey].action.default[actionKey].value = e.target.value;
			//newItems[itemIndex].settings[parentKey].action.default[actionKey].value = e.target.value
		}
		newItem.settings = JSON.parse(JSON.stringify(newItem.settings));
		this.props.updateInteractTimelineItem(newItem);
		//this.props.sendUpdateInteractItems(newItems)
	};

	renderOpenUrl = () => {
		const { fieldObj, t, actionKey, item } = this.props;
		let targetValue = fieldObj.default[actionKey].target;

		let openUrlPossibleValues = _.find(interactActionList, (values) => {
			return values.id === interactActionsId.openUrl;
		});
		if (!openUrlPossibleValues) {
			openUrlPossibleValues = {
				values: [],
			};
		}

		this.props.items
			.map((x) => {
				return x.id;
			})
			.indexOf(item.id);

		return (
			<>
				<div className="actionsSection">
					{this.getActionsTitleSection(actionKey)}
					{this.renderLinkArrowI()}
					<div className="openUrlAction actionOptions">
						<input
							type="text"
							value={fieldObj.default.openUrl.value}
							onChange={(e) => this.handleOpenUrChange(e)}
						/>
						{/* <input type='text' value={this.props.items[itemIndex].settings[parentKey].action.default.openUrl.value } onChange={(e) => this.handleOpenUrChange(e)} /> */}
						<div className="openUrlActionOptions">
							{openUrlPossibleValues.values.map((possibleValue) => (
								<>
									<Radio
										className={
											targetValue === possibleValue.value
												? 'actionsCheckedRadioButton'
												: 'actionsUnCheckedRadioButton'
										}
										checked={targetValue === possibleValue.value}
										onChange={(e) => this.handleOpenUrChange(e, 'target')}
										value={possibleValue.value}
										name="actionsRadioButton"
									/>
									<label className="inlineLabel">
										{' '}
										{possibleValue.id === openUrlOptions.replacePage
											? t('REPLACE')
											: t('NEWTAB')}{' '}
									</label>
								</>
							))}
						</div>
					</div>
				</div>
			</>
		);
	};

	// openurl changes end

	// playpause changes start
	handlePlayPauseChange = (e) => {
		const { item, actionKey, parentKey } = this.props;
		let newItem = {
			...item,
		};
		newItem.settings[parentKey].action.default[actionKey].value = e.target.value;
		this.props.updateInteractTimelineItem(newItem);
	};

	renderPlayPause = () => {
		const { fieldObj, t, actionKey } = this.props;
		let targetValue = fieldObj.default[actionKey].value;
		let playPausePossibleValues = _.find(interactActionList, (values) => {
			return values.id === interactActionsId.playPause;
		});
		if (!playPausePossibleValues) {
			playPausePossibleValues = {
				values: [],
			};
		}
		return (
			<>
				<div className="actionsSection">
					{this.getActionsTitleSection(actionKey)}
					{this.renderLinkArrowI()}
					<div className="playPauseOptions actionOptions">
						{playPausePossibleValues.values.map((possibleValue) => (
							<>
								<Radio
									className={
										targetValue === possibleValue.value
											? 'actionsCheckedRadioButton'
											: 'actionsUnCheckedRadioButton'
									}
									checked={targetValue === possibleValue.value}
									onChange={(e) => this.handlePlayPauseChange(e)}
									value={possibleValue.value}
									name="actionsRadioButton"
								/>
								<label className="inlineLabel">
									{' '}
									{possibleValue.id === playPauseOptions.play ? t('PLAY') : t('PAUSE')}{' '}
								</label>
							</>
						))}
					</div>
				</div>
			</>
		);
	};
	// playpause changes end

	//Jumptotime changes start
	handleJumptoTimeChange = (value) => {
		const { item, actionKey, mediaDetails, t, parentKey } = this.props;
		let newItem = {
			...item,
		};
		let newTime = getTimelineTimeFromTimeInput(value);
		let positionToMove = getSecondsFromTimelineTime(newTime);
		if (positionToMove > mediaDetails.duration) {
			this.props.showNotification(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_TIME_IS_OUT_OF_RANGE'));
			return;
		}
		newItem.settings[parentKey].action.default[actionKey].value = positionToMove;
		this.props.updateInteractTimelineItem(newItem);
	};

	renderJumptoTime = () => {
		const { fieldObj, actionKey } = this.props;
		let targetValue = fieldObj.default[actionKey].value;
		let newTime = getTimelineTimeFromSeconds(targetValue);
		let formattedTime = getFormattedTimeWithMilliseconds(newTime, 'HH:mm:ss:SSS');

		return (
			<>
				<div className="actionsSection">
					{this.getActionsTitleSection(actionKey)}
					{this.renderLinkArrowI()}
					<div className="actionOptions">
						<TimeInput max={12} value={formattedTime} onChange={this.handleJumptoTimeChange} />
					</div>
				</div>
			</>
		);
	};
	handleChange = (e) => {
		const { item, actionKey, parentKey } = this.props;
		let newItem = {
			...item,
		};
		newItem.settings[parentKey].action.default[actionKey].value = e.target.value;
		this.props.updateInteractTimelineItem(newItem);
	};

	handleChangeFadeOutAnimation = (e) => {
		const { item, actionKey, parentKey } = this.props;
		let newItem = {
			...item,
		};

		newItem.settings[parentKey].action.default[actionKey].value = e.target.checked;
		newItem.settings[parentKey].action.default[actionKey].itemId = newItem.settings[parentKey].gid.default;
		this.props.updateInteractTimelineItem(newItem);
	};

	renderTextField = () => {
		const { fieldObj, actionKey } = this.props;
		let targetValue = fieldObj.default[actionKey].value;
		return (
			<>
				<div className="actionsSection">
					{this.getActionsTitleSection(actionKey)}
					{this.renderLinkArrowI()}
					<div className="actionOptions">
						<input
							className="fullWidth"
							type="text"
							value={targetValue}
							onChange={(e) => {
								this.handleChange(e);
							}}
						/>
					</div>
				</div>
			</>
		);
	};

	renderFadeOutAnimation = () => {
		const { fieldObj, t, actionKey } = this.props;
		let targetValue = fieldObj.default[actionKey].value;

		/* let checkboxValue
        if (targetValue){
            checkboxValue = 'checked'
        } else {
            checkboxValue = ''
        } */

		return (
			<>
				<div className="actionsSection">
					{this.getActionsTitleSection(actionKey)}
					{this.renderLinkArrowI()}
					<div className="actionOptions fadeOutWrapper">
						<label className="fullWidth" for="fadeOutAnim">
							{' '}
							{t('ENABLE_FADEOUT')}{' '}
						</label>
						<input
							/* className='fullWidth' */ id="fadeOutAnim"
							type="checkbox"
							checked={targetValue}
							onChange={(e) => {
								this.handleChangeFadeOutAnimation(e);
							}}
						/>
					</div>
				</div>
			</>
		);
	};

	renderRelevantAction = () => {
		const { actionKey } = this.props;
		switch (actionKey) {
			case interactActionsId.openUrl:
				return this.renderOpenUrl();
			case interactActionsId.fadeOut:
				return this.renderFadeOutAnimation();
			case interactActionsId.playPause:
				return this.renderPlayPause();
			case interactActionsId.jumpToTime:
				return this.renderJumptoTime();
			case interactActionsId.customEvent:
			case interactActionsId.loadVideo:
			case interactActionsId.videoToPlaylist:
				return this.renderTextField();
			default:
				break;
		}
	};

	render() {
		return <>{this.renderRelevantAction()}</>;
	}
}

const mapStateToProps = ({ session, inspectScreenReducer, interactTimelineReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	mediaDetails: inspectScreenReducer.mediaOriginalDetails,
	items: interactTimelineReducer.items,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
});

const mapDispatchToProps = (dispatch) => ({
	updateInteractTimelineItem: (item) => dispatch(updateInteractTimelineItem(item)),
	showNotification: (message) => dispatch(showNotification(message)),
	sendUpdateInteractItems: (items) => dispatch(sendUpdateInteractItems(items)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InteractButtonActions);
