import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

import { getAllQnAs, getQuestions } from '../../../../actions/liveChatActions';

import { QuestionAndAnswerTemplate } from './QuestionAnsAnswerTemplate';

interface DataProps {
	defaultAccountId: string;
	mediaId: string;
	refresh?: boolean;
	callback?: () => void;
}
interface ReturnDataProps {
	qnas?: any[];
	questions?: QuestionAndAnswerTemplate.Question[];
}

export const useFetchQnAData = ({ defaultAccountId, mediaId, refresh }: DataProps): ReturnDataProps => {
	const dispatch = useDispatch() as any;
	const [qnas, setQnas] = useState<any[]>([]);
	const [questions, setQuestions] = useState<any[]>([]);
	const questionsIntervalId = useRef<any | undefined>(undefined);

	const fetchQnas = useCallback(() => {
		dispatch(getAllQnAs({ accountId: defaultAccountId, mediaId })).then((response: any) => {
			if (response) {
				setQnas(response);
			}

			if (response?.[0]?.id) {
				dispatch(
					getQuestions({
						accountId: defaultAccountId,
						mediaId,
						qnaId: response?.[0]?.id,
					})
				).then((questions: any[]) => {
					if (questions) {
						const sortedQuestion = _.sortBy(questions ?? [], 'votes');
						setQuestions(sortedQuestion);
					}
				});
			}
		});
	}, [defaultAccountId, dispatch, mediaId]);

	useEffect(() => {
		fetchQnas();
	}, [fetchQnas, refresh]);

	useEffect(() => {
		if (qnas?.[0]?.id) {
			if (questionsIntervalId.current) {
				clearInterval(questionsIntervalId.current);
			}
			questionsIntervalId.current = setInterval(() => fetchQnas(), 10000);
		} else {
			if (questionsIntervalId.current) {
				clearInterval(questionsIntervalId.current);
			}
		}
	}, [defaultAccountId, dispatch, fetchQnas, mediaId, qnas]);

	return {
		qnas,
		questions,
	};
};
