import React, { Component } from 'react'

import './Tooltip.scss'

export default class Tooltip extends Component {

    state = {
        displayTooltip: false
    };

    hideTooltip = _ => this.setState({ displayTooltip: false });

    showTooltip = _ => this.setState({ displayTooltip: true });

    toggleTooltip = () => {
        const { displayTooltip } = this.state;
        this.setState({
            displayTooltip: !displayTooltip
        })
    };

    render() {

        const { message, position, children, clickEvent } = this.props;

        return (
            <span className='tooltip' onClick={() => clickEvent ? this.toggleTooltip() : ''} onMouseLeave={() =>  !clickEvent ? this.hideTooltip() : '' }>
                {this.state.displayTooltip &&
                    <div className={`tooltip-bubble tooltip-${position}`}>
                        <div className='tooltip-message'>{message}</div>
                    </div>}
                <span className='tooltip-trigger' onMouseOver={()=> !clickEvent ? this.showTooltip() : ''}>{children}</span>
            </span>
        )
    }
}