import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { IconsList } from './IconsList';

class IconsStore extends Component {
	renderIcons = (_) => {
		const { iconName, width, height, color, className, style } = this.props;
		switch (iconName) {
			case IconsList.TRASHCAN:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 35.41 44.83"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<g id="H7CZZr.tif">
									<path
										className="cls-1"
										d="M30,44.83H5.38l-2-31.44L0,13.22V9.41a9.84,9.84,0,0,1,1.2-.15h33a9.84,9.84,0,0,1,1.2.15v3.81L32,13.4C31.35,23.92,30.69,34.37,30,44.83ZM15.9,13.44l.56,27.22H19c.19-9.12.37-18.16.56-27.22Zm-8.39,0L9.22,40.68h2.47l-.56-27.24ZM23.72,40.67h2.47c.57-9.1,1.13-18.13,1.7-27.19H24.28C24.09,22.58,23.91,31.6,23.72,40.67Z"
									/>
									<path className="cls-1" d="M20.84,0V3H32.22V7h-29V3h11.2l.14-3Z" />
								</g>
							</g>
						</g>
					</svg>
				);
			case IconsList.TRASHCANBORDER:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 40.63 40.63"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<rect className="cls-1" x="1" y="1" width="38.63" height="38.63" rx="5.65" />
								<path
									className="cls-2"
									d="M34,40.63H6.65A6.65,6.65,0,0,1,0,34V6.65A6.65,6.65,0,0,1,6.65,0H34a6.65,6.65,0,0,1,6.65,6.65V34A6.65,6.65,0,0,1,34,40.63ZM6.65,2A4.65,4.65,0,0,0,2,6.65V34a4.65,4.65,0,0,0,4.65,4.65H34A4.65,4.65,0,0,0,38.63,34V6.65A4.65,4.65,0,0,0,34,2Z"
								/>
								<g id="H7CZZr.tif">
									<path
										className="cls-2"
										d="M27.4,33.2H13.23L12.09,15.13l-2-.1V12.84a5.43,5.43,0,0,1,.69-.09h19a5.43,5.43,0,0,1,.69.09V15l-2,.1C28.16,21.18,27.78,27.19,27.4,33.2ZM19.28,15.15l.32,15.66H21l.32-15.66Zm-4.83,0,1,15.66h1.43l-.33-15.66Zm9.32,15.66H25.2l1-15.64H24.09Z"
									/>
									<path className="cls-2" d="M22.12,7.43V9.16h6.54v2.3H12V9.16h6.44l.08-1.73Z" />
								</g>
							</g>
						</g>
					</svg>
				);
			case IconsList.CLOSE:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 26 26"
					>
						<defs>
							<style>{`.a{fill:#f2f5f7;}.b{fill:#444a58;fill-rule:evenodd;}`}</style>
						</defs>
						<g transform="translate(-926 -140)">
							<rect className="a" width="26" height="26" rx="4" transform="translate(926 140)" />
							<path
								className="b"
								d="M401.43,170.486l-2.527,2.527-2.531-2.529a1.446,1.446,0,1,0-2.045,2.046l2.529,2.529-2.527,2.527a1.448,1.448,0,0,0,2.047,2.048l2.528-2.527,2.528,2.528a1.446,1.446,0,1,0,2.046-2.045l-2.528-2.53,2.527-2.527a1.447,1.447,0,0,0-2.047-2.047Z"
								transform="translate(540.098 -22.059)"
							/>
						</g>
					</svg>
				);
			case IconsList.ADD_TO_CATALOG:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="15.681"
						height="14"
						viewBox="0 0 15.681 14"
					>
						<defs />
						<g transform="translate(-575.183 -1379.076)">
							<path
								className="a"
								d="M589.682,1390.373h-1.589v1.59a.8.8,0,0,1-1.59,0v-1.59h-1.589a.794.794,0,1,1,0-1.589H586.5v-1.59a.8.8,0,0,1,1.59,0v1.59h1.589a.794.794,0,0,1,0,1.589Z"
								transform="translate(0.388 0.318)"
							/>
							<path
								className="a"
								d="M583.463,1389.9a1.841,1.841,0,0,1,1.839-1.839h.546v-.546a1.828,1.828,0,0,1,2.612-1.661v-3.457a1.66,1.66,0,0,0-1.651-1.659l-3.822-.008-1.266-1.267a1.416,1.416,0,0,0-.942-.384h-3.942a1.66,1.66,0,0,0-1.653,1.659v8.3h0a1.667,1.667,0,0,0,1.665,1.659h6.8A1.8,1.8,0,0,1,583.463,1389.9Z"
								transform="translate(0 0)"
							/>
						</g>
					</svg>
				);
			case IconsList.ADD_TO_PLAYLIST:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="15.9"
						height="13.676"
						viewBox="0 0 15.9 13.676"
					>
						<defs />
						<g transform="translate(-558.882 -1379.076)">
							<g transform="translate(558.882 1379.076)">
								<g transform="translate(4.387 0)">
									<path
										className="a"
										d="M572.039,1379.076h-7.824a1.034,1.034,0,0,0-1.029,1.021v.415a1.03,1.03,0,0,0,1.029,1.029h7.824a1.03,1.03,0,0,0,1.03-1.029v-.416A1.033,1.033,0,0,0,572.039,1379.076Z"
										transform="translate(-563.186 -1379.076)"
									/>
									<g transform="translate(0 4.668)">
										<path
											className="a"
											d="M569.507,1383.656h-5.292a1.034,1.034,0,0,0-1.029,1.022v.415a1.03,1.03,0,0,0,1.029,1.028h5.292a1.03,1.03,0,0,0,1.03-1.028v-.416A1.034,1.034,0,0,0,569.507,1383.656Z"
											transform="translate(-563.186 -1383.656)"
										/>
									</g>
									<g transform="translate(0 9.338)">
										<path
											className="a"
											d="M566.352,1388.237h-2.136a1.034,1.034,0,0,0-1.029,1.022v.415a1.03,1.03,0,0,0,1.029,1.029h2.136a1.03,1.03,0,0,0,1.03-1.029v-.416A1.033,1.033,0,0,0,566.352,1388.237Z"
											transform="translate(-563.186 -1388.237)"
										/>
									</g>
								</g>
								<circle className="a" cx="1.233" cy="1.233" r="1.233" />
								<circle className="a" cx="1.233" cy="1.233" r="1.233" transform="translate(0 4.669)" />
								<circle className="a" cx="1.233" cy="1.233" r="1.233" transform="translate(0 9.338)" />
							</g>
							<path
								className="b"
								d="M573.822,1390.281h-1.552v1.553a.776.776,0,0,1-1.552,0v-1.553h-1.553a.776.776,0,0,1,0-1.553h1.553v-1.553a.776.776,0,0,1,1.552,0v1.553h1.552a.776.776,0,1,1,0,1.553Z"
								transform="translate(0.183 0.142)"
							/>
						</g>
					</svg>
				);
			case IconsList.INSPECT:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11">
						<defs></defs>
						<g transform="translate(0 -2.5)">
							<path className="a" d="M10.5,8A2.5,2.5,0,1,1,8,5.5,2.5,2.5,0,0,1,10.5,8Z" />
							<path
								className="b"
								d="M0,8S3,2.5,8,2.5,16,8,16,8s-3,5.5-8,5.5S0,8,0,8Zm8,3.5A3.5,3.5,0,1,0,4.5,8,3.5,3.5,0,0,0,8,11.5Z"
							/>
						</g>
					</svg>
				);
			case IconsList.COPY:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 31.5 29.18"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M20.16,22.83a5,5,0,0,0,5-5V7.56h-2.5V17.84a2.5,2.5,0,0,1-2.5,2.49H7.56v2.5Z"
								/>
								<path
									className="cls-1"
									d="M5.06,20.33H5a2.5,2.5,0,0,1-2.5-2.49V5A2.5,2.5,0,0,1,5,2.5H20.16A2.5,2.5,0,0,1,22.66,5v.06h2.5V5a5,5,0,0,0-5-5H5A5,5,0,0,0,0,5V17.84a5,5,0,0,0,5,5h.06Z"
								/>
								<path
									className="cls-1"
									d="M26.51,29.18H11.34a5,5,0,0,1-5-5V11.34a5,5,0,0,1,5-5H26.51a5,5,0,0,1,5,5V24.18A5,5,0,0,1,26.51,29.18ZM11.34,8.84a2.5,2.5,0,0,0-2.5,2.5V24.18a2.5,2.5,0,0,0,2.5,2.5H26.51A2.5,2.5,0,0,0,29,24.18V11.34a2.5,2.5,0,0,0-2.49-2.5Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.PLAY:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 13.34 15.4"
					>
						<defs />{' '}
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<polygon className="cls-1" points="13.34 7.7 0 0 0 15.4 13.34 7.7" />
							</g>
						</g>
					</svg>
				);
			case IconsList.GRAB:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 11.2 8.57"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<g id="Group_6817" data-name="Group 6817">
									<path
										id="Path_8693"
										data-name="Path 8693"
										className="cls-1"
										d="M10.46,1.48H.74A.74.74,0,0,1,.74,0h9.72a.74.74,0,0,1,0,1.48Z"
									/>
									<path
										id="Path_8694"
										data-name="Path 8694"
										className="cls-1"
										d="M10.46,5H.74a.74.74,0,0,1,0-1.48h9.72a.74.74,0,0,1,0,1.48Z"
									/>
									<path
										id="Path_8695"
										data-name="Path 8695"
										className="cls-1"
										d="M10.46,8.57H.74a.74.74,0,1,1,0-1.48h9.72a.74.74,0,0,1,.74.73.75.75,0,0,1-.74.75Z"
									/>
								</g>
							</g>
						</g>
					</svg>
				);
			case IconsList.DRAG_ICON:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 10 30"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<g id="Group_7300" data-name="Group 7300">
									<circle
										id="Ellipse_1507"
										data-name="Ellipse 1507"
										className="cls-1"
										cx="1.5"
										cy="19.5"
										r="1.5"
									/>
								</g>
								<g id="Group_7307" data-name="Group 7307">
									<circle
										id="Ellipse_1507-2"
										data-name="Ellipse 1507-2"
										className="cls-1"
										cx="1.5"
										cy="28.5"
										r="1.5"
									/>
								</g>
								<g id="Group_7305" data-name="Group 7305">
									<circle
										id="Ellipse_1507-3"
										data-name="Ellipse 1507-3"
										className="cls-1"
										cx="8.5"
										cy="19.5"
										r="1.5"
									/>
								</g>
								<g id="Group_7306" data-name="Group 7306">
									<circle
										id="Ellipse_1507-4"
										data-name="Ellipse 1507-4"
										className="cls-1"
										cx="8.5"
										cy="28.5"
										r="1.5"
									/>
								</g>
								<g id="Group_7301" data-name="Group 7301">
									<circle
										id="Ellipse_1507-5"
										data-name="Ellipse 1507-5"
										className="cls-1"
										cx="1.5"
										cy="10.5"
										r="1.5"
									/>
								</g>
								<g id="Group_7304" data-name="Group 7304">
									<circle
										id="Ellipse_1507-6"
										data-name="Ellipse 1507-6"
										className="cls-1"
										cx="8.5"
										cy="10.5"
										r="1.5"
									/>
								</g>
								<g id="Group_7302" data-name="Group 7302">
									<circle
										id="Ellipse_1507-7"
										data-name="Ellipse 1507-7"
										className="cls-1"
										cx="1.5"
										cy="1.5"
										r="1.5"
									/>
								</g>
								<g id="Group_7303" data-name="Group 7303">
									<circle
										id="Ellipse_1507-8"
										data-name="Ellipse 1507-8"
										className="cls-1"
										cx="8.5"
										cy="1.5"
										r="1.5"
									/>
								</g>
							</g>
						</g>
					</svg>
				);
			case IconsList.RIGHT_ARROW:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 5.8 10.18"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M.5,10.18A.47.47,0,0,1,.15,10a.48.48,0,0,1,0-.7L4.38,5.09.15.85a.48.48,0,0,1,0-.7.48.48,0,0,1,.7,0l5,4.94L.85,10A.47.47,0,0,1,.5,10.18Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.INTERACT_JUMP_TO_TIME:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 91.18 91.18"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M45.59,0A45.58,45.58,0,0,0,31.24,2.31a2.25,2.25,0,1,0,1.42,4.27A41.08,41.08,0,1,1,10.47,24.25c.45-.75.94-1.5,1.45-2.22a2.25,2.25,0,0,0-3.68-2.58c-.57.8-1.11,1.63-1.61,2.46A45.59,45.59,0,1,0,45.59,0Z"
								/>
								<path
									className="cls-1"
									d="M15.88,16.29a2.28,2.28,0,0,0,1.54-.61,41.58,41.58,0,0,1,5.2-4.16A2.25,2.25,0,0,0,20.1,7.79a44.85,44.85,0,0,0-5.77,4.61,2.25,2.25,0,0,0,1.55,3.89Z"
								/>
								<path
									className="cls-1"
									d="M65.28,31.22a2.24,2.24,0,0,0-3.15-.49L50.28,39.38a7.76,7.76,0,0,0-9.53.11l-18-15.38a1.5,1.5,0,1,0-1.95,2.28l18,15.38a7.71,7.71,0,0,0-1,3.82,7.8,7.8,0,1,0,15.59,0A7.9,7.9,0,0,0,52.94,43l11.85-8.65A2.25,2.25,0,0,0,65.28,31.22Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.INTERACT_PRODUCT:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 16 15"
					>
						<defs>
							<style>{`.a{fill:#fff;fill-rule:evenodd;}`}</style>
						</defs>
						<g transform="translate(0 -1)">
							<path
								className="a"
								d="M10.243,1.071a.5.5,0,0,1,.686.172l3,5a.5.5,0,1,1-.858.514l-3-5a.5.5,0,0,1,.172-.686Zm-4.486,0a.5.5,0,0,0-.686.172l-3,5a.5.5,0,0,0,.858.514l3-5a.5.5,0,0,0-.172-.686Z"
							/>
							<path
								className="a"
								d="M1,7V8H15V7ZM.5,6a.5.5,0,0,0-.5.5v2A.5.5,0,0,0,.5,9h15a.5.5,0,0,0,.5-.5v-2a.5.5,0,0,0-.5-.5Z"
							/>
							<path
								className="a"
								d="M14,9H2v5a1,1,0,0,0,1,1H13a1,1,0,0,0,1-1ZM2,8A1,1,0,0,0,1,9v5a2,2,0,0,0,2,2H13a2,2,0,0,0,2-2V9a1,1,0,0,0-1-1Z"
							/>
							<path
								className="a"
								d="M4,10a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-1,0v-3A.5.5,0,0,1,4,10Zm2,0a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-1,0v-3A.5.5,0,0,1,6,10Zm2,0a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-1,0v-3A.5.5,0,0,1,8,10Zm2,0a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-1,0v-3A.5.5,0,0,1,10,10Zm2,0a.5.5,0,0,1,.5.5v3a.5.5,0,0,1-1,0v-3A.5.5,0,0,1,12,10Z"
							/>
						</g>
					</svg>
				);
			case IconsList.INTERACT_BUTTON:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 103 67.09"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M63.79,40.41H22.45a18,18,0,0,1,0-35.91h8.94a2.25,2.25,0,0,0,0-4.5H22.45a22.46,22.46,0,0,0,0,44.91H63.79a2.25,2.25,0,1,0,0-4.5Z"
								/>
								<path
									className="cls-1"
									d="M102.68,52.47,94,39.28l.08-.06A22.45,22.45,0,0,0,79.15,0H40.72a2.25,2.25,0,0,0,0,4.5H79.15a17.94,17.94,0,0,1,12.34,31L80.7,19.1a1.94,1.94,0,0,0-3.56,1l-.91,20.33H72.62a2.25,2.25,0,0,0,0,4.5H76L75.36,60a1.94,1.94,0,0,0,2.46,2l7.5-2.06,1.59,5.76a2,2,0,0,0,.91,1.18,1.94,1.94,0,0,0,1,.25A1.81,1.81,0,0,0,89.3,67l5-1.38a1.93,1.93,0,0,0,1.36-2.39l-1.59-5.77,7.51-2.06a2,2,0,0,0,1.3-1.19A1.93,1.93,0,0,0,102.68,52.47ZM91.16,54.25a2,2,0,0,0-1.36,2.39l1.59,5.77-1.25.35L88.55,57a1.92,1.92,0,0,0-1.87-1.43,1.81,1.81,0,0,0-.52.07l-6.8,1.87,1.39-31.25L98,52.38Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.INTERACT_CUSTOM_IMAGE:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 103.5 89.47"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M71.07,44.2a2.09,2.09,0,0,0-2.72,1.16L66,51.29A2.1,2.1,0,0,0,67.1,54a2.21,2.21,0,0,0,.79.15,2.11,2.11,0,0,0,1.94-1.31l2.39-5.94A2.08,2.08,0,0,0,71.07,44.2Z"
								/>
								<path
									className="cls-1"
									d="M102.87,36.39l-4.72-4.56a2.09,2.09,0,0,0-2.91,3l3.22,3.1L59.59,78.18l8-19.7a2.09,2.09,0,0,0-3.88-1.57l-8,19.71v-58a2.1,2.1,0,0,0-2.09-2.09H28.94L32.77,7l20.1,8.12h0l23.79,9.61-6,15a2.08,2.08,0,0,0,1.15,2.72,2.17,2.17,0,0,0,.79.15,2.07,2.07,0,0,0,1.93-1.31l6.85-16.92a2.1,2.1,0,0,0-1.16-2.73L57.29,12.4l7.1-7.35L81.92,22a2.09,2.09,0,0,0,2.91-3L65.8.59a2.09,2.09,0,0,0-3,.05L53.11,10.71,32.4,2.35A2.08,2.08,0,0,0,29.68,3.5l-5.25,13H2.09A2.1,2.1,0,0,0,0,18.58v68.8a2.1,2.1,0,0,0,2.09,2.09H53.87l.13,0h.06l.16,0h0l.06,0,.1,0,0,0,0,0,.09,0,0,0h0a.6.6,0,0,0,.15-.09h0l0,0,.12-.09,0,0,0,0,.1-.09,0,0,0,0,47.78-49.47A2.09,2.09,0,0,0,102.87,36.39ZM4.18,48.87,15.43,37.62,29.5,51.69l-9.6,9.59L13,54.41a2.09,2.09,0,0,0-3,0L4.18,60.29ZM51.53,85.28H43.9l-7.1-7.1a2.09,2.09,0,1,0-2.95,3L38,85.28H4.18V66.21l7.37-7.37,6.87,6.88h0l2.33,2.33a2.09,2.09,0,0,0,3-3l-.85-.85L39.68,47.43,51.53,59.28Zm0-31.91L41.16,43a2.09,2.09,0,0,0-3,0l-5.74,5.74L16.91,33.18a2.09,2.09,0,0,0-3,0L4.18,43V20.67H51.53Z"
								/>
								<path
									className="cls-1"
									d="M90.89,30.63a2.07,2.07,0,0,0,1.45.59,2.09,2.09,0,0,0,1.45-3.6l-4.6-4.44a2.09,2.09,0,1,0-2.91,3Z"
								/>
								<path
									className="cls-1"
									d="M28,69.37a2.09,2.09,0,1,0-2.95,3l4.52,4.53a2.09,2.09,0,0,0,3-3Z"
								/>
								<path
									className="cls-1"
									d="M35.43,37a5.57,5.57,0,1,0-5.56-5.57A5.58,5.58,0,0,0,35.43,37Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.INTERACT_CHAPTER:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="13.698" height="14" viewBox="0 0 13.698 14">
						<defs />
						<g transform="translate(-504.151 -112.002)">
							<path d="M504.535,115.854v.011l5.9,2.691.006,0a1.326,1.326,0,0,0,1.12,0l.006,0,5.9-2.691v-.011a.535.535,0,0,0-.005-1.028l-5.89-2.7h-.011a1.322,1.322,0,0,0-1.118,0h-.011l-5.89,2.7a.535.535,0,0,0-.005,1.028Z" />
							<path
								className="a"
								d="M517.46,118.487l-1.153-.529c-1.808.828-4.277,1.954-4.306,1.963a2.694,2.694,0,0,1-2,0c-.028-.009-2.494-1.134-4.3-1.962l-1.153.529a.534.534,0,0,0-.005,1.027v.012l5.9,2.691.006,0a1.326,1.326,0,0,0,1.12,0l.006,0,5.9-2.691v-.012a.534.534,0,0,0-.005-1.027Z"
							/>
							<path
								className="a"
								d="M517.46,122.15l-1.153-.528c-1.808.827-4.277,1.953-4.306,1.962a2.694,2.694,0,0,1-2,0c-.028-.009-2.494-1.133-4.3-1.961l-1.153.528a.535.535,0,0,0-.005,1.028v.011l5.9,2.691.006,0a1.326,1.326,0,0,0,1.12,0l.006,0,5.9-2.691v-.011a.535.535,0,0,0-.005-1.028Z"
							/>
						</g>
					</svg>
				);
			case IconsList.INTERACT_ELEMENTS:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 26.18 25.76"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<circle className="cls-1" cx="5.49" cy="5.49" r="5.49" />
								<circle className="cls-1" cx="20.69" cy="5.49" r="5.49" />
								<circle className="cls-1" cx="5.49" cy="20.27" r="5.49" />
								<circle className="cls-1" cx="20.69" cy="20.27" r="5.49" />
							</g>
						</g>
					</svg>
				);
			case IconsList.INTERACT_CLOSE_DARK_MODE:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 26 26"
					>
						<defs>
							<style>{`.b{fill:#ffffff;fill-rule:evenodd;}`}</style>
						</defs>
						<g transform="translate(-926 -140)">
							<path
								className="b"
								d="M401.43,170.486l-2.527,2.527-2.531-2.529a1.446,1.446,0,1,0-2.045,2.046l2.529,2.529-2.527,2.527a1.448,1.448,0,0,0,2.047,2.048l2.528-2.527,2.528,2.528a1.446,1.446,0,1,0,2.046-2.045l-2.528-2.53,2.527-2.527a1.447,1.447,0,0,0-2.047-2.047Z"
								transform="translate(540.098 -22.059)"
							/>
						</g>
					</svg>
				);
			case IconsList.PEN:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 21.65 22.89"
					>
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<rect
									id="Rectangle_8570"
									data-name="Rectangle 8570"
									x="0.22"
									y="8.95"
									width="21"
									height="5.27"
									transform="translate(-5.06 11.61) rotate(-47.31)"
								/>
								<rect
									id="Rectangle_8571"
									data-name="Rectangle 8571"
									x="17.88"
									y="-0.05"
									width="2.18"
									height="5.27"
									transform="translate(4.21 14.78) rotate(-47.31)"
								/>
								<path id="Path_9419" data-name="Path 9419" d="M1.28,17.92,0,22.89l5.12-1.43Z" />
							</g>
						</g>
					</svg>
				);
			case IconsList.PENCIL:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
						<defs>
							<style>{`.a{fill:#fff;stroke:#dedede;}.b{fill:#dedede;fill-rule:evenodd;}.c{stroke:none;}.d{fill:none;}`}</style>
						</defs>
						<g className="a">
							<rect className="c" width="26" height="26" rx="2" />
							<rect className="d" x="0.5" y="0.5" width="25" height="25" rx="1.5" />
						</g>
						<path
							className="b"
							d="M120.3,840.411l-7.227,7.309-1,4.6a1.386,1.386,0,0,0,1.65,1.65l4.6-1,7.237-7.321a1.388,1.388,0,0,0-.006-1.956l-3.284-3.284A1.387,1.387,0,0,0,120.3,840.411Zm-5.491,12.053-1.234-1.233.63-2.893,3.5,3.5Z"
							transform="translate(-105.042 -833.999)"
						/>
					</svg>
				);
			case IconsList.SETTINGS:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 22.794 23.329"
					>
						{' '}
						<defs />
						<path
							className="a"
							d="M132.754,401.994l1.643-.886a1.043,1.043,0,0,0,.427-1.428l-1.791-3.229a1.072,1.072,0,0,0-1.448-.42l-1.648.891a1.9,1.9,0,0,1-1.785-.128c-.225-.145-.455-.278-.685-.4a1.845,1.845,0,0,1-.975-1.488v-1.846A1.061,1.061,0,0,0,125.427,392h-3.734a1.061,1.061,0,0,0-1.063,1.056v1.853a1.843,1.843,0,0,1-.975,1.481c-.183.1-.367.205-.545.315a1.9,1.9,0,0,1-1.79.088l-1.265-.711a1.07,1.07,0,0,0-1.455.385l-1.86,3.194a1.042,1.042,0,0,0,.395,1.435l1.26.718a1.82,1.82,0,0,1,.82,1.568c0,.133-.005.262,0,.4a1.837,1.837,0,0,1-.856,1.55l-1.641.893a1.042,1.042,0,0,0-.425,1.428l1.79,3.229a1.07,1.07,0,0,0,1.448.42l1.655-.891a1.878,1.878,0,0,1,1.778.128,7.758,7.758,0,0,0,.692.4,1.86,1.86,0,0,1,.975,1.488v1.848a1.06,1.06,0,0,0,1.063,1.055h3.734a1.06,1.06,0,0,0,1.065-1.055V412.42a1.825,1.825,0,0,1,.975-1.481c.183-.1.367-.2.543-.315a1.9,1.9,0,0,1,1.791-.087l1.258.712a1.072,1.072,0,0,0,1.455-.385l1.86-3.194a1.042,1.042,0,0,0-.4-1.435l-1.263-.718a1.848,1.848,0,0,1-.817-1.568c.007-.133.007-.268.007-.4A1.828,1.828,0,0,1,132.754,401.994Zm-9.2,5.777a4.106,4.106,0,1,1,4.166-4.1A4.128,4.128,0,0,1,123.554,407.771Z"
							transform="translate(-112.161 -392.001)"
						/>
					</svg>
				);
			case IconsList.INTERACT:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 34.16 37.88"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M32.56,20.19c-2-4.55-4-8.56-7-7.61a3.82,3.82,0,0,0-1.33.74,4.44,4.44,0,0,0-1.15-1.23,3.23,3.23,0,0,0-2.86-.41,3.8,3.8,0,0,0-1.27.71,4.12,4.12,0,0,0-.47-.53,3.47,3.47,0,0,0-2.75-.92,3.89,3.89,0,0,0-2.22,1l-.41-.83a7,7,0,0,0,1.22-3.95,7.15,7.15,0,1,0-8.07,7.08c1.16,2.45,2.78,5.89,4,8.51A13.66,13.66,0,0,0,6.81,22c-1.85-.15-3.21.27-4,1.25a3,3,0,0,0-.65,2.58c.25,1.16,1.1,2.62,5.54,4.72s8.5,6.85,8.54,6.89a1.25,1.25,0,1,0,1.91-1.61c-.18-.21-4.44-5.21-9.39-7.54-2.48-1.18-4-2.26-4.17-3.06a.56.56,0,0,1,.15-.4c.14-.14.59-.45,1.92-.34a12.54,12.54,0,0,1,5.31,1.85,1.35,1.35,0,0,0,1.61-.12,1.33,1.33,0,0,0,.33-1.59c-1.8-3.84-6.07-12.94-6.8-14.41a5.6,5.6,0,0,1-.68-2.28c0-.7.26-.93.56-1.07a.59.59,0,0,1,.51-.05A5.34,5.34,0,0,1,9.57,9.54c.87,1.9,4.82,10,5,10.36a1.25,1.25,0,1,0,2.24-1.1l-2.17-4.48a1.06,1.06,0,0,0,.15-.15,1.69,1.69,0,0,1,1.16-.74,1,1,0,0,1,.8.24c.9.85,2.08,3.86,2.61,5.56a1.25,1.25,0,0,0,1.2.88,1.19,1.19,0,0,0,.37-.06,1.25,1.25,0,0,0,.82-1.57,34.2,34.2,0,0,0-1.51-3.87h0a1.26,1.26,0,0,1,.72-.54.74.74,0,0,1,.69.09c.61.42,1.81,2.83,2.53,4.81a1.26,1.26,0,0,0,1.18.82,1.09,1.09,0,0,0,.43-.08,1.24,1.24,0,0,0,.74-1.6c-.18-.48-.54-1.44-1-2.47a.33.33,0,0,0,.06-.1,1.19,1.19,0,0,1,.72-.56c.43-.13,1.53.49,4,6.2a17.37,17.37,0,0,1,1.34,8.08,1.26,1.26,0,0,0,1.09,1.4h.15a1.26,1.26,0,0,0,1.24-1.1A19.65,19.65,0,0,0,32.56,20.19ZM8.29,4.46a3.06,3.06,0,0,0-2.46.16A3.48,3.48,0,0,0,3.89,8a8,8,0,0,0,.83,3.13,4.64,4.64,0,1,1,7.07-3.94,4.8,4.8,0,0,1-.12,1C11,6.7,9.84,5,8.29,4.46Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.UPLOAD:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 27.53 27.15"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M15.26,2.74V20.36a1.5,1.5,0,0,1-3,0V2.74a1.5,1.5,0,0,1,3,0Z"
								/>
								<path
									className="cls-1"
									d="M21.52,7.48a1.48,1.48,0,0,1-.42,1A1.5,1.5,0,0,1,19,8.56l-5.22-5-5.21,5A1.5,1.5,0,0,1,6.47,6.39l6.26-6a1.49,1.49,0,0,1,2.07,0l6.25,6A1.51,1.51,0,0,1,21.52,7.48Z"
								/>
								<path
									className="cls-1"
									d="M24.79,18.38v3.31a2.73,2.73,0,0,1-2.73,2.73H5.47a2.73,2.73,0,0,1-2.73-2.73V18.38H0v3.31a5.47,5.47,0,0,0,5.47,5.46H22.06a5.47,5.47,0,0,0,5.47-5.46V18.38Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.PREVIEW:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 52.14 27.63"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M51.66,12.22C49.81,9.46,42.74.63,27.63,0l-1.5,0H24.51V0C9.41.63,2.33,9.46.48,12.22a2.87,2.87,0,0,0,0,3.19c1.9,2.85,9.38,12.17,25.58,12.22h.14c16.08-.08,23.54-9.37,25.45-12.22A2.85,2.85,0,0,0,51.66,12.22ZM26.13,23.13a9.32,9.32,0,1,1,9.32-9.31A9.32,9.32,0,0,1,26.13,23.13ZM3,13.82C4.5,11.65,9.32,5.74,19.12,3.71a12.28,12.28,0,0,0,0,20.21C9.32,21.89,4.5,16,3,13.82ZM33.18,23.9a12.28,12.28,0,0,0,0-20.16c9.68,2.06,14.47,7.92,15.92,10.08C47.66,16,42.87,21.84,33.18,23.9Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.IMAGE:
				return (
					<svg
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="11.717"
						viewBox="0 0 14 11.717"
					>
						<defs />
						<path
							className="a"
							d="M68.542,1289.14H57.458A1.459,1.459,0,0,0,56,1290.6v8.8a1.458,1.458,0,0,0,1.458,1.458H68.542A1.458,1.458,0,0,0,70,1299.4v-8.8A1.459,1.459,0,0,0,68.542,1289.14Zm-1.212,9.243h-8.6a.536.536,0,0,1-.367-.926l3.152-4.178a.536.536,0,0,1,.733,0l1.794,2.335a.535.535,0,0,0,.744-.011l.367-.367a.534.534,0,0,1,.754,0l1.8,2.235A.535.535,0,0,1,67.33,1298.383Z"
							transform="translate(-56 -1289.14)"
						/>
					</svg>
				);
			case IconsList.DASHBOARD:
				return (
					<svg
						style={{
							fill: color,
						}}
						width="20"
						height="20"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 38.13 32.36"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path d="M34.74,0H3.39A3.39,3.39,0,0,0,0,3.39V7.64A3.39,3.39,0,0,0,3.39,11H34.74a3.39,3.39,0,0,0,3.39-3.39V3.39A3.39,3.39,0,0,0,34.74,0Zm.89,7.64a.89.89,0,0,1-.89.89H3.39a.89.89,0,0,1-.89-.89V3.39a.89.89,0,0,1,.89-.89H34.74a.89.89,0,0,1,.89.89Z" />
								<path d="M9,14.61H3.39A3.39,3.39,0,0,0,0,18V29a3.39,3.39,0,0,0,3.39,3.39H9A3.39,3.39,0,0,0,12.39,29V18A3.39,3.39,0,0,0,9,14.61ZM9.89,29a.89.89,0,0,1-.89.89H3.39A.89.89,0,0,1,2.5,29V18a.89.89,0,0,1,.89-.89H9a.89.89,0,0,1,.89.89Z" />
								<path d="M34.74,14.61H20.14A3.39,3.39,0,0,0,16.75,18V29a3.39,3.39,0,0,0,3.39,3.39h14.6A3.39,3.39,0,0,0,38.13,29V18A3.39,3.39,0,0,0,34.74,14.61ZM35.63,29a.89.89,0,0,1-.89.89H20.14a.89.89,0,0,1-.89-.89V18a.89.89,0,0,1,.89-.89h14.6a.89.89,0,0,1,.89.89Z" />
							</g>
						</g>
					</svg>
				);
			case IconsList.MAIN_MENU_ANALYTICS:
				return (
					<svg
						style={{
							fill: color,
						}}
						width="20"
						height="20"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 38.13 32.36"
					>
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path d="M5.91,12.48H3.59A3.59,3.59,0,0,0,0,16.07V28.8a3.59,3.59,0,0,0,3.59,3.59H5.91A3.59,3.59,0,0,0,9.5,28.8V16.07A3.59,3.59,0,0,0,5.91,12.48ZM7,28.8a1.09,1.09,0,0,1-1.09,1.09H3.59A1.09,1.09,0,0,1,2.5,28.8V16.07A1.09,1.09,0,0,1,3.59,15H5.91A1.09,1.09,0,0,1,7,16.07Z" />
								<path d="M20.24,6.51H17.92a3.59,3.59,0,0,0-3.59,3.59V28.8a3.59,3.59,0,0,0,3.59,3.59h2.32a3.59,3.59,0,0,0,3.59-3.59V10.1A3.59,3.59,0,0,0,20.24,6.51ZM21.33,28.8a1.09,1.09,0,0,1-1.09,1.09H17.92a1.09,1.09,0,0,1-1.09-1.09V10.1A1.09,1.09,0,0,1,17.92,9h2.32a1.09,1.09,0,0,1,1.09,1.09Z" />
								<path d="M34.58,0H32.26a3.59,3.59,0,0,0-3.59,3.59V28.8a3.59,3.59,0,0,0,3.59,3.59h2.32a3.6,3.6,0,0,0,3.59-3.59V3.59A3.6,3.6,0,0,0,34.58,0Zm1.09,28.8a1.09,1.09,0,0,1-1.09,1.09H32.26a1.09,1.09,0,0,1-1.09-1.09V3.59A1.09,1.09,0,0,1,32.26,2.5h2.32a1.09,1.09,0,0,1,1.09,1.09Z" />
							</g>
						</g>
					</svg>
				);
			case IconsList.BREADCRUMB_FOLDER:
				return (
					<svg
						style={{
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="19.429"
						height="17"
						viewBox="0 0 19.429 17"
					>
						<defs />
						<path
							d="M92.757,1363.118l-5.594-.012-1.853-1.854a2.087,2.087,0,0,0-1.379-.562H78.164a2.431,2.431,0,0,0-2.419,2.428v12.143h0a2.438,2.438,0,0,0,2.437,2.428H92.737a2.426,2.426,0,0,0,2.437-2.428v-9.714A2.431,2.431,0,0,0,92.757,1363.118Zm-3.569,6.208-2.943,2.836a1.061,1.061,0,0,1-1.571,0l-2.945-2.836a.911.911,0,0,1,.676-1.522h6.109A.911.911,0,0,1,89.188,1369.326Z"
							transform="translate(-75.745 -1360.69)"
						/>
					</svg>
				);
			case IconsList.BREADCRUMB_MENU_FOLDER:
				return (
					<svg
						style={{
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="12.25"
						viewBox="0 0 14 12.25"
					>
						<defs />
						<path
							d="M12.26,2.625,8.23,2.616,6.895,1.28A1.5,1.5,0,0,0,5.9.875H1.745A1.75,1.75,0,0,0,0,2.625v8.75H0a1.757,1.757,0,0,0,1.756,1.75H12.245A1.748,1.748,0,0,0,14,11.375v-7A1.75,1.75,0,0,0,12.26,2.625Z"
							transform="translate(-0.002 -0.875)"
						/>
					</svg>
				);
			case IconsList.BREADCRUMB_MENU_MAIN:
				return (
					<svg
						style={{
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="13.767"
						height="11.827"
						viewBox="0 0 13.767 11.827"
					>
						<defs />
						<g transform="translate(-1537.018 -355.424)">
							<path
								d="M1552.012,364.961h-8.6a.449.449,0,0,0,0,.9h8.6a.449.449,0,1,0,0-.9Z"
								transform="translate(-3.812 -6.113)"
							/>
							<path
								className="a"
								d="M1549.259,356.578h-7.31v-.026a1.129,1.129,0,0,0-1.128-1.128h-2.675a1.129,1.129,0,0,0-1.128,1.128v9.173a1.528,1.528,0,0,0,1.526,1.526h10.715a1.527,1.527,0,0,0,1.526-1.526V358.1A1.527,1.527,0,0,0,1549.259,356.578Zm.628,9.147a.629.629,0,0,1-.628.628h-10.715a.629.629,0,0,1-.628-.628v-9.173a.23.23,0,0,1,.23-.23h2.675a.23.23,0,0,1,.23.23v.924h8.207a.628.628,0,0,1,.628.628Z"
								transform="translate(0)"
							/>
						</g>
					</svg>
				);
			case IconsList.BREADCRUMB_MENU_MEDIA:
				return (
					<svg
						style={{
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="14"
						viewBox="0 0 14 14"
					>
						<defs />
						<path d="M0,.875A.875.875,0,0,1,.875,0h12.25A.875.875,0,0,1,14,.875v12.25a.875.875,0,0,1-.875.875H.875A.875.875,0,0,1,0,13.125Zm3.5,0h7v5.25h-7Zm7,7h-7v5.25h7ZM.875.875h1.75v1.75H.875ZM2.625,3.5H.875V5.25h1.75ZM.875,6.125h1.75v1.75H.875ZM2.625,8.75H.875V10.5h1.75ZM.875,11.375h1.75v1.75H.875ZM13.125.875h-1.75v1.75h1.75ZM11.375,3.5h1.75V5.25h-1.75Zm1.75,2.625h-1.75v1.75h1.75ZM11.375,8.75h1.75V10.5h-1.75Zm1.75,2.625h-1.75v1.75h1.75Z" />
					</svg>
				);
			case IconsList.EDITOR:
				return (
					<svg
						style={{
							fill: color,
						}}
						width="20"
						height="20"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 37.75 33.13"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M33.91,0H3.84A3.84,3.84,0,0,0,0,3.84V29.29a3.85,3.85,0,0,0,3.84,3.84H33.91a3.85,3.85,0,0,0,3.84-3.84V3.84A3.85,3.85,0,0,0,33.91,0Zm0,2.5a1.34,1.34,0,0,1,1.34,1.34v4H30.17V2.5ZM30.17,18h5.08v4.5H30.17Zm0-2.83v-4.5h5.08v4.5ZM27.67,2.5V15.15H10.07V2.5ZM2.5,15.15v-4.5H7.57v4.5ZM7.57,18v4.5H2.5V18ZM3.84,2.5H7.57V7.83H2.5v-4A1.34,1.34,0,0,1,3.84,2.5Zm0,28.13A1.34,1.34,0,0,1,2.5,29.29v-4H7.57v5.33Zm6.23,0V18h17.6V30.63Zm23.84,0H30.17V25.3h5.08v4A1.34,1.34,0,0,1,33.91,30.63Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.LEFT_ARROW:
				return (
					<svg
						viewBox="0 0 21.86 15.51"
						style={{
							fill: color,
						}}
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M20.36,6.25H5L8.56,2.54A1.5,1.5,0,0,0,8.51.42a1.48,1.48,0,0,0-1-.42A1.54,1.54,0,0,0,6.39.46l-6,6.26a1.49,1.49,0,0,0,0,2.07l6,6.25A1.5,1.5,0,1,0,8.56,13L5,9.25H20.36a1.5,1.5,0,0,0,0-3Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.CARD_IMAGE:
				return (
					<svg
						style={{
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="16.5"
						height="12.375"
						viewBox="0 0 16.5 12.375"
					>
						<defs />
						<path
							className="a"
							d="M14.953,3.031H1.547a.516.516,0,0,0-.516.516v9.281a.516.516,0,0,0,.516.516H14.953a.516.516,0,0,0,.516-.516V3.547A.516.516,0,0,0,14.953,3.031ZM1.547,2A1.547,1.547,0,0,0,0,3.547v9.281a1.547,1.547,0,0,0,1.547,1.547H14.953A1.547,1.547,0,0,0,16.5,12.828V3.547A1.547,1.547,0,0,0,14.953,2Z"
							transform="translate(0 -2)"
						/>
						<path
							className="b"
							d="M10.95,7.651a.516.516,0,0,1,.6-.1l3.9,2.008v3.609H1V12.141L3.731,9.713a.516.516,0,0,1,.65-.064l2.743,1.828L10.95,7.652Z"
							transform="translate(0.031 -1.828)"
						/>
						<path
							className="a"
							d="M4.549,7.094A1.547,1.547,0,1,0,3,5.547,1.547,1.547,0,0,0,4.549,7.094Z"
							transform="translate(0.094 -1.937)"
						/>
					</svg>
				);
			case IconsList.BOOK:
				return (
					<svg
						style={{
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="15.153"
						height="12.418"
						viewBox="0 0 15.153 12.418"
					>
						<defs />
						<g transform="translate(0 -0.889)">
							<path
								className="a"
								d="M3.044,1.062C4.558.759,6.55.719,7.912,2.079a.474.474,0,0,1,.138.335V12.832a.474.474,0,0,1-.809.335A3.945,3.945,0,0,0,3.955,12.3a10.167,10.167,0,0,0-3.269.959A.474.474,0,0,1,0,12.832V2.415a.474.474,0,0,1,.261-.423l.212.423L.261,1.991h0l0,0,.012-.006q.1-.051.208-.1a12.272,12.272,0,0,1,2.557-.823ZM.947,2.718V12.1a10.969,10.969,0,0,1,2.911-.746,5.5,5.5,0,0,1,3.245.53V2.621a4.493,4.493,0,0,0-3.873-.629,11.325,11.325,0,0,0-2.282.725Z"
							/>
							<path
								className="a"
								d="M12.506,1.062C10.993.759,9,.719,7.638,2.079a.474.474,0,0,0-.138.335V12.832a.474.474,0,0,0,.809.335A3.945,3.945,0,0,1,11.6,12.3a10.167,10.167,0,0,1,3.269.959.474.474,0,0,0,.686-.423V2.415a.474.474,0,0,0-.261-.423l-.212.423.212-.423h0l0,0-.012-.006-.045-.022q-.373-.174-.757-.322a12.274,12.274,0,0,0-1.963-.577Zm2.1,1.655V12.1a10.969,10.969,0,0,0-2.911-.746,5.5,5.5,0,0,0-3.245.53V2.621a4.493,4.493,0,0,1,3.873-.629,11.323,11.323,0,0,1,2.282.725Z"
								transform="translate(-0.397)"
							/>
						</g>
					</svg>
				);
			case IconsList.PLUS_SQUARE:
				return (
					<svg
						style={{
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="12.417"
						height="12.417"
						viewBox="0 0 12.417 12.417"
					>
						<defs />
						<path
							className="a"
							d="M6.992,3.5a.388.388,0,0,1,.388.388v3.1a.388.388,0,0,1-.388.388h-3.1a.388.388,0,1,1,0-.776H6.6V3.888A.388.388,0,0,1,6.992,3.5Z"
							transform="translate(-0.784 -0.784)"
						/>
						<path
							className="a"
							d="M7.5,7.888A.388.388,0,0,1,7.888,7.5h3.1a.388.388,0,1,1,0,.776H8.276v2.716a.388.388,0,1,1-.776,0Z"
							transform="translate(-1.679 -1.679)"
						/>
						<path
							className="a"
							d="M10.865.776H1.552a.776.776,0,0,0-.776.776v9.313a.776.776,0,0,0,.776.776h9.313a.776.776,0,0,0,.776-.776V1.552A.776.776,0,0,0,10.865.776ZM1.552,0A1.552,1.552,0,0,0,0,1.552v9.313a1.552,1.552,0,0,0,1.552,1.552h9.313a1.552,1.552,0,0,0,1.552-1.552V1.552A1.552,1.552,0,0,0,10.865,0Z"
						/>
					</svg>
				);
			case IconsList.REFRESH:
				return (
					<svg
						style={{
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="12.001"
						height="13.999"
						viewBox="0 0 12.001 13.999"
					>
						<defs />
						<g transform="translate(-1.999 0.001)">
							<path
								className="a"
								d="M12.83,6.706a5,5,0,0,0-7.1-3.16.5.5,0,1,1-.454-.892A6,6,0,1,1,2.545,5.5a.5.5,0,0,1,.91.417,5,5,0,1,0,9.375.789Z"
							/>
							<path
								className="a"
								d="M7.854.146a.5.5,0,0,0-.708,0l-2.5,2.5a.5.5,0,0,0,0,.708l2.5,2.5a.5.5,0,0,0,.708-.708L5.707,3,7.854.854a.5.5,0,0,0,0-.708Z"
							/>
						</g>
					</svg>
				);
			case IconsList.PLUS_NEW:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="13.261"
						height="13.261"
						viewBox="0 0 13.261 13.261"
					>
						<path
							className="ab"
							d="M176.667,168h-4.072A4.6,4.6,0,0,0,168,172.6v4.072a4.6,4.6,0,0,0,4.595,4.594h4.072a4.6,4.6,0,0,0,4.594-4.594V172.6A4.6,4.6,0,0,0,176.667,168Zm.576,7.571h-1.672v1.672a.94.94,0,0,1-1.88,0v-1.672h-1.672a.94.94,0,1,1,0-1.88h1.672v-1.672a.94.94,0,1,1,1.88,0v1.672h1.672a.94.94,0,0,1,0,1.88Z"
							transform="translate(-168 -168.002)"
						/>
					</svg>
				);
			case IconsList.CHAT_LEFT_DOTS:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="23.794" height="22.742" viewBox="0 0 23.794 22.742">
						<defs />
						<path
							className="a"
							d="M20.82,1.487H2.974A1.487,1.487,0,0,0,1.487,2.974V20.2L4.461,17.23a2.974,2.974,0,0,1,2.1-.871H20.82a1.487,1.487,0,0,0,1.487-1.487V2.974A1.487,1.487,0,0,0,20.82,1.487ZM2.974,0A2.974,2.974,0,0,0,0,2.974V22a.744.744,0,0,0,1.27.525l4.243-4.243a1.487,1.487,0,0,1,1.051-.436H20.82a2.974,2.974,0,0,0,2.974-2.974V2.974A2.974,2.974,0,0,0,20.82,0Z"
						/>
						<path
							className="b"
							d="M5.974,6.487A1.487,1.487,0,1,1,4.487,5,1.487,1.487,0,0,1,5.974,6.487Zm5.948,0A1.487,1.487,0,1,1,10.436,5,1.487,1.487,0,0,1,11.923,6.487Zm5.948,0A1.487,1.487,0,1,1,16.384,5,1.487,1.487,0,0,1,17.871,6.487Z"
							transform="translate(1.461 2.436)"
						/>
					</svg>
				);
			case IconsList.BROADCASTER_ICON:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="17.337" height="23.116" viewBox="0 0 17.337 23.116">
						<defs>
							<style>{`.a{fill:#fff;fill-rule:evenodd;}`}</style>
						</defs>
						<g transform="translate(-2)">
							<path
								className="a"
								d="M16.447,1.445H4.889A1.445,1.445,0,0,0,3.445,2.889V20.226a1.445,1.445,0,0,0,1.445,1.445H16.447a1.445,1.445,0,0,0,1.445-1.445V2.889A1.445,1.445,0,0,0,16.447,1.445ZM4.889,0A2.889,2.889,0,0,0,2,2.889V20.226a2.889,2.889,0,0,0,2.889,2.889H16.447a2.889,2.889,0,0,0,2.889-2.889V2.889A2.889,2.889,0,0,0,16.447,0Z"
							/>
							<path
								className="a"
								d="M8.445,14.889A1.445,1.445,0,1,0,7,13.445,1.445,1.445,0,0,0,8.445,14.889Z"
								transform="translate(2.224 5.337)"
							/>
						</g>
					</svg>
				);
			case IconsList.BROADCASTER_QR_CODE:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="13.939" height="13.939" viewBox="0 0 13.939 13.939">
						<defs>
							<style>{`.a,.b{fill:#fff;}.a{fill - rule:evenodd;}`}</style>
						</defs>
						<path
							className="a"
							d="M1.307.871a.436.436,0,0,0-.436.436V3.92A.436.436,0,0,1,0,3.92V1.307A1.307,1.307,0,0,1,1.307,0H3.92a.436.436,0,0,1,0,.871ZM9.583.436A.436.436,0,0,1,10.019,0h2.614a1.307,1.307,0,0,1,1.307,1.307V3.92a.436.436,0,0,1-.871,0V1.307a.436.436,0,0,0-.436-.436H10.019A.436.436,0,0,1,9.583.436ZM.436,9.583a.436.436,0,0,1,.436.436v2.614a.436.436,0,0,0,.436.436H3.92a.436.436,0,0,1,0,.871H1.307A1.307,1.307,0,0,1,0,12.633V10.019a.436.436,0,0,1,.436-.436Zm13.068,0a.436.436,0,0,1,.436.436v2.614a1.307,1.307,0,0,1-1.307,1.307H10.019a.436.436,0,0,1,0-.871h2.614a.436.436,0,0,0,.436-.436V10.019A.436.436,0,0,1,13.5,9.583Z"
							transform="translate(0 0)"
						/>
						<path
							className="b"
							d="M3,4.436a.436.436,0,1,1,.871,0v6.1a.436.436,0,1,1-.871,0Zm1.742,0a.436.436,0,1,1,.871,0v6.1a.436.436,0,0,1-.871,0Zm1.742,0a.436.436,0,1,1,.871,0v6.1a.436.436,0,0,1-.871,0Zm1.742,0A.436.436,0,0,1,8.663,4h.871a.436.436,0,0,1,.436.436v6.1a.436.436,0,0,1-.436.436H8.663a.436.436,0,0,1-.436-.436Zm2.614,0a.436.436,0,1,1,.871,0v6.1a.436.436,0,0,1-.871,0Z"
							transform="translate(-0.386 -0.515)"
						/>
					</svg>
				);
			case IconsList.BROADCASTER_ABOUT:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="13.939" height="13.939" viewBox="0 0 13.939 13.939">
						<defs>
							<style>{`.a,.b{fill:#fff;}.b{fill - rule:evenodd;}`}</style>
						</defs>
						<g transform="translate(-0.001 -0.001)">
							<path
								className="a"
								d="M7,10.1a.871.871,0,1,1,.871.871A.871.871,0,0,1,7,10.1Zm.085-5.232a.789.789,0,1,1,1.568,0l-.3,3.056a.481.481,0,0,1-.958,0Z"
								transform="translate(-0.901 -0.514)"
							/>
							<path
								className="b"
								d="M8.951,2.19l-.8-.823L8.771.759l.542.555.775-.01a2.518,2.518,0,0,1,2.548,2.548l-.009.775.554.542a2.518,2.518,0,0,1,0,3.6l-.555.542.01.775a2.518,2.518,0,0,1-2.548,2.548l-.775-.009-.542.554a2.518,2.518,0,0,1-3.6,0l-.542-.555-.775.01A2.518,2.518,0,0,1,1.3,10.089l.009-.775L.759,8.771a2.518,2.518,0,0,1,0-3.6l.555-.542L1.3,3.852A2.518,2.518,0,0,1,3.852,1.3l.775.009L5.169.759a2.518,2.518,0,0,1,3.6,0l-.623.608a1.647,1.647,0,0,0-2.356,0l-.8.823-1.15-.014A1.647,1.647,0,0,0,2.176,3.842L2.19,4.99l-.823.8a1.647,1.647,0,0,0,0,2.356l.823.8L2.176,10.1a1.647,1.647,0,0,0,1.666,1.665l1.148-.014.8.823a1.647,1.647,0,0,0,2.356,0l.8-.823,1.15.014A1.647,1.647,0,0,0,11.765,10.1l-.014-1.148.823-.8a1.647,1.647,0,0,0,0-2.356l-.823-.8.014-1.15A1.647,1.647,0,0,0,10.1,2.176L8.951,2.19Z"
							/>
						</g>
					</svg>
				);
			case IconsList.PEOPLE:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="18.667" height="14" viewBox="0 0 18.667 14">
						<defs />
						<path
							className="a"
							d="M17.5,16a1.143,1.143,0,0,0,1.167-1.167c0-1.167-1.167-4.667-5.833-4.667S7,13.667,7,14.833A1.143,1.143,0,0,0,8.167,16ZM8.172,14.9v0Zm.02-.065h9.282l.016,0,.009,0a3.589,3.589,0,0,0-.887-2.007,4.669,4.669,0,0,0-3.78-1.489,4.676,4.676,0,0,0-3.78,1.489,3.584,3.584,0,0,0-.887,2.007l.026,0Zm9.3.065v0ZM12.833,7.833A2.333,2.333,0,1,0,10.5,5.5,2.333,2.333,0,0,0,12.833,7.833Zm3.5-2.333a3.5,3.5,0,1,1-3.5-3.5,3.5,3.5,0,0,1,3.5,3.5ZM8.092,10.493a6.86,6.86,0,0,0-1.435-.288q-.411-.039-.824-.038C1.167,10.167,0,13.667,0,14.833A1.031,1.031,0,0,0,1.167,16H6.085a2.611,2.611,0,0,1-.252-1.167,5.354,5.354,0,0,1,1.272-3.388A6.173,6.173,0,0,1,8.092,10.493Zm-2.352.84a4.64,4.64,0,0,0-3.687,1.489,3.568,3.568,0,0,0-.887,2.011h3.5A6.418,6.418,0,0,1,5.74,11.333ZM1.75,6.083a3.5,3.5,0,1,1,3.5,3.5A3.5,3.5,0,0,1,1.75,6.083ZM5.25,3.75A2.333,2.333,0,1,0,7.583,6.083,2.333,2.333,0,0,0,5.25,3.75Z"
							transform="translate(0 -2)"
						/>
					</svg>
				);
			case IconsList.DOTS:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.78 9.72">
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<circle className="cls-1" cx="4.86" cy="4.86" r="4.86" />
								<circle className="cls-1" cx="22.39" cy="4.86" r="4.86" />
								<circle className="cls-1" cx="39.92" cy="4.86" r="4.86" />
							</g>
						</g>
					</svg>
				);
			case IconsList.EMOJI_SMILE:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
						<defs />
						<path className="a" d="M8,15A7,7,0,1,0,1,8a7,7,0,0,0,7,7Zm0,1A8,8,0,1,0,0,8,8,8,0,0,0,8,16Z" />
						<path
							className="a"
							d="M4.285,9.567a.5.5,0,0,1,.683.183,3.5,3.5,0,0,0,6.064,0,.5.5,0,1,1,.866.5,4.5,4.5,0,0,1-7.8,0,.5.5,0,0,1,.183-.683Z"
						/>
						<path
							className="b"
							d="M7,6.5C7,7.328,6.552,8,6,8S5,7.328,5,6.5,5.448,5,6,5,7,5.672,7,6.5Zm4,0c0,.828-.448,1.5-1,1.5S9,7.328,9,6.5,9.448,5,10,5,11,5.672,11,6.5Z"
						/>
					</svg>
				);
			case IconsList.SEND_MESSAGE:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="13.728"
						height="13.728"
						viewBox="0 0 13.728 13.728"
					>
						<defs />
						<path
							className="a"
							d="M14.082,2.182a.5.5,0,0,1,.1.557L8.528,15.467a.5.5,0,0,1-.917-.007L5.57,10.694.8,8.652A.5.5,0,0,1,.8,7.736L13.525,2.079a.5.5,0,0,1,.556.1Z"
							transform="translate(-0.501 -2.036)"
						/>
					</svg>
				);
			case IconsList.PIN:
				return (
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 193.826 193.826">
						<path
							d="M191.495,55.511L137.449,1.465c-1.951-1.953-5.119-1.953-7.07,0l-0.229,0.229c-3.314,3.313-5.14,7.72-5.14,12.406
	c0,3.019,0.767,5.916,2.192,8.485l-56.55,48.533c-4.328-3.868-9.852-5.985-15.703-5.985c-6.305,0-12.232,2.455-16.689,6.913
	l-0.339,0.339c-1.953,1.952-1.953,5.118,0,7.07l32.378,32.378l-31.534,31.533c-0.631,0.649-15.557,16.03-25.37,28.27
	c-9.345,11.653-11.193,13.788-11.289,13.898c-1.735,1.976-1.639,4.956,0.218,6.822c0.973,0.977,2.256,1.471,3.543,1.471
	c1.173,0,2.349-0.41,3.295-1.237c0.083-0.072,2.169-1.885,13.898-11.289c12.238-9.813,27.619-24.74,28.318-25.421l31.483-31.483
	l30.644,30.644c0.976,0.977,2.256,1.465,3.535,1.465s2.56-0.488,3.535-1.465l0.339-0.339c4.458-4.457,6.913-10.385,6.913-16.689
	c0-5.851-2.118-11.375-5.985-15.703l48.533-56.55c2.569,1.425,5.466,2.192,8.485,2.192c4.687,0,9.093-1.825,12.406-5.14l0.229-0.229
	C193.448,60.629,193.448,57.463,191.495,55.511z"
						/>
					</svg>
				);
			case IconsList.X:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.5 19.5">
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M.45.45a1.56,1.56,0,0,1,2.19,0L19.05,16.86a1.55,1.55,0,0,1-2.19,2.19L.45,2.64A1.56,1.56,0,0,1,.45.45Z"
								/>
								<path
									className="cls-1"
									d="M19.05.45a1.56,1.56,0,0,1,0,2.19L2.64,19.05A1.55,1.55,0,0,1,.45,16.86L16.86.45A1.56,1.56,0,0,1,19.05.45Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.PLAYLISTS:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="11.869"
						height="11.078"
						viewBox="0 0 11.869 11.078"
					>
						<g transform="translate(0 -10.667)">
							<g transform="translate(0 13.832)">
								<rect width="9.495" height="1.583" />
							</g>
							<g transform="translate(0 10.667)">
								<g transform="translate(0 0)">
									<rect width="9.495" height="1.583" />
								</g>
							</g>
							<g transform="translate(0 16.997)">
								<rect width="6.33" height="1.583" />
							</g>
							<g transform="translate(7.913 16.997)">
								<path
									d="M213.333,181.333v4.748l3.956-2.374Z"
									transform="translate(-213.333 -181.333)"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.OPEN_IN_NEW_WINDOW:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="15.251"
						height="15.251"
						viewBox="0 0 15.251 15.251"
					>
						<defs />
						<g transform="translate(-649 -100)">
							<path
								className="a"
								d="M1.605,0A1.605,1.605,0,0,0,0,1.605v9.632a1.605,1.605,0,0,0,1.605,1.605h9.632a1.605,1.605,0,0,0,1.605-1.605V1.605A1.605,1.605,0,0,0,11.237,0ZM5.217,4.013H8.428a.4.4,0,0,1,.4.4V7.625a.4.4,0,0,1-.8,0V5.383L4.7,8.712a.4.4,0,0,1-.568-.568L7.459,4.816H5.217a.4.4,0,0,1,0-.8Z"
								transform="translate(651.408 100)"
							/>
							<path
								className="a"
								d="M-71.262,109.894a1.589,1.589,0,0,1-.526-.1,1.6,1.6,0,0,1-1.08-1.51V98.657a1.6,1.6,0,0,0-1.605,1.605v9.632a1.605,1.605,0,0,0,1.605,1.605h9.632a1.606,1.606,0,0,0,1.605-1.605Z"
								transform="translate(723.473 3.751)"
							/>
						</g>
					</svg>
				);
			case IconsList.EXPANDED:
				return (
					<svg
						className={className + ' ' + iconName}
						style={{
							width: width,
							height: height,
							fill: color,
							...style,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 7.37 4.45"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M3.69,4.45a.51.51,0,0,1-.37-.16L.13.84a.51.51,0,0,1,0-.71.51.51,0,0,1,.71,0L3.69,3.22,6.5.16a.51.51,0,0,1,.71,0,.51.51,0,0,1,0,.71L4.05,4.29A.5.5,0,0,1,3.69,4.45Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.EXPANDED_WHITE:
				return (
					<svg
						className={className + ' ' + iconName}
						style={{
							width: width,
							height: height,
							fill: '#FFFFFF',
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 7.37 4.45"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M3.69,4.45a.51.51,0,0,1-.37-.16L.13.84a.51.51,0,0,1,0-.71.51.51,0,0,1,.71,0L3.69,3.22,6.5.16a.51.51,0,0,1,.71,0,.51.51,0,0,1,0,.71L4.05,4.29A.5.5,0,0,1,3.69,4.45Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.NOT_EXPANDED:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 4.45 7.37"
					>
						<defs />
						<g id="Layer_2" data-name="Layer 2">
							<g id="Layer_1-2" data-name="Layer 1">
								<path
									className="cls-1"
									d="M4.45,3.68a.51.51,0,0,1-.16.37L.84,7.24a.51.51,0,0,1-.71,0,.51.51,0,0,1,0-.71L3.22,3.68.16.87a.51.51,0,0,1,0-.71.51.51,0,0,1,.71,0L4.29,3.32A.5.5,0,0,1,4.45,3.68Z"
								/>
							</g>
						</g>
					</svg>
				);
			case IconsList.GRID:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="14"
						viewBox="0 0 14 14"
					>
						<defs />
						<path
							className="a"
							d="M1,2.5A1.5,1.5,0,0,1,2.5,1h3A1.5,1.5,0,0,1,7,2.5v3A1.5,1.5,0,0,1,5.5,7h-3A1.5,1.5,0,0,1,1,5.5ZM2.5,2a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3A.5.5,0,0,0,6,5.5v-3A.5.5,0,0,0,5.5,2ZM9,2.5A1.5,1.5,0,0,1,10.5,1h3A1.5,1.5,0,0,1,15,2.5v3A1.5,1.5,0,0,1,13.5,7h-3A1.5,1.5,0,0,1,9,5.5ZM10.5,2a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5ZM1,10.5A1.5,1.5,0,0,1,2.5,9h3A1.5,1.5,0,0,1,7,10.5v3A1.5,1.5,0,0,1,5.5,15h-3A1.5,1.5,0,0,1,1,13.5ZM2.5,10a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5Zm6.5.5A1.5,1.5,0,0,1,10.5,9h3A1.5,1.5,0,0,1,15,10.5v3A1.5,1.5,0,0,1,13.5,15h-3A1.5,1.5,0,0,1,9,13.5Zm1.5-.5a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5Z"
							transform="translate(-1 -1)"
						/>
					</svg>
				);
			case IconsList.LIST:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="10"
						viewBox="0 0 14 10"
					>
						<defs />
						<path
							className="a"
							d="M5,11.5a.5.5,0,0,1,.5-.5h9a.5.5,0,0,1,0,1h-9A.5.5,0,0,1,5,11.5Zm0-4A.5.5,0,0,1,5.5,7h9a.5.5,0,0,1,0,1h-9A.5.5,0,0,1,5,7.5Zm0-4A.5.5,0,0,1,5.5,3h9a.5.5,0,0,1,0,1h-9A.5.5,0,0,1,5,3.5Zm-3,1a1,1,0,1,0-1-1A1,1,0,0,0,2,4.5Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,2,8.5Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,2,12.5Z"
							transform="translate(-1 -2.5)"
						/>
					</svg>
				);
			case IconsList.CHECKBOX:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="14"
						viewBox="0 0 14 14"
					>
						<defs />
						<path
							className="a"
							d="M12.25.875H1.75a.875.875,0,0,0-.875.875v10.5a.875.875,0,0,0,.875.875h10.5a.875.875,0,0,0,.875-.875V1.75A.875.875,0,0,0,12.25.875ZM1.75,0A1.75,1.75,0,0,0,0,1.75v10.5A1.75,1.75,0,0,0,1.75,14h10.5A1.75,1.75,0,0,0,14,12.25V1.75A1.75,1.75,0,0,0,12.25,0Z"
						/>
					</svg>
				);
			case IconsList.CHECKBOX_FILL:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						xmlns="http://www.w3.org/2000/svg"
						width="14"
						height="14"
						viewBox="0 0 14 14"
					>
						<defs />
						<path
							className="a"
							d="M1.75,0A1.75,1.75,0,0,0,0,1.75v10.5A1.75,1.75,0,0,0,1.75,14h10.5A1.75,1.75,0,0,0,14,12.25V1.75A1.75,1.75,0,0,0,12.25,0Zm8.776,4.349a.656.656,0,0,0-.945.019L6.542,8.24,4.711,6.408a.656.656,0,0,0-.927.927L6.1,9.651a.656.656,0,0,0,.944-.018l3.493-4.366a.656.656,0,0,0-.009-.919Z"
						/>
					</svg>
				);
			case IconsList.CHECKBOX_WHITE_FILL:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						version="1.1"
						id="Layer_1"
						x="0px"
						y="0px"
						viewBox="0 0 15.2 15.2"
					>
						<g>
							<path
								className="st0"
								d="M7.6,15.2L7.6,15.2C3.4,15.2,0,11.8,0,7.6v0C0,3.4,3.4,0,7.6,0h0c4.2,0,7.6,3.4,7.6,7.6v0
		            C15.2,11.8,11.8,15.2,7.6,15.2z"
							/>
							<path
								id="check-square-fill"
								className="st1"
								d="M1.9,0C0.9,0,0,0.9,0,1.9c0,0,0,0,0,0v11.4c0,1,0.9,1.9,1.9,1.9h11.4
		            c1,0,1.9-0.9,1.9-1.9V1.9c0-1-0.9-1.9-1.9-1.9H1.9z M11.4,4.7c-0.3-0.3-0.7-0.3-1,0c0,0,0,0,0,0L7.1,9l-2-2c-0.3-0.3-0.7-0.3-1,0
		            c-0.3,0.3-0.3,0.7,0,1l2.5,2.5c0.3,0.3,0.7,0.3,1,0c0,0,0,0,0,0l3.8-4.7C11.7,5.4,11.7,5,11.4,4.7L11.4,4.7z"
							/>
						</g>
					</svg>
				);
			case IconsList.SMARTFILTER:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						version="1.1"
						id="Layer_1"
						x="0px"
						y="0px"
						viewBox="0 0 98.3 81.9"
					>
						<g>
							<path
								className="st0"
								d="M97.3,75.6L83,61.3c0,0,0,0,0,0c-0.2-0.2-0.3-0.2-0.5-0.3c3-4,4.8-8.9,4.8-14.3c0,0,0,0,0,0
		            c0-13.3-10.8-24.1-24.1-24.1c-13.3,0-24.1,10.8-24.1,24.1c0,13.3,10.8,24.1,24.1,24.1c5.4,0,10.3-1.8,14.3-4.8
		            c0.1,0.2,0.2,0.4,0.4,0.5L92,80.8c1.4,1.4,3.8,1.4,5.2,0C98.7,79.4,98.7,77,97.3,75.6z M63.2,67.1c-11.3,0-20.4-9.1-20.4-20.4v0
		            c0-11.3,9.1-20.4,20.4-20.4s20.4,9.1,20.4,20.4S74.4,67.1,63.2,67.1z"
							/>
							<path
								className="st0"
								d="M39.7,63.4c-0.3-0.2-0.6-0.6-0.9-0.9l-0.8-0.9L27.9,65V38.1c0-0.6-0.2-1.2-0.6-1.7L5.1,11.7V5.1h55.8v6.6
		            L55,18.2c2.3-0.6,4.7-0.9,7.1-0.9c0.2,0,0.4,0,0.5,0l2.7-3c0.4-0.5,0.6-1.1,0.6-1.7V2.5C66,1.1,64.9,0,63.5,0H2.5
		            C1.1,0,0,1.1,0,2.5v10.2c0,0.6,0.2,1.2,0.6,1.7L22.8,39v29.5c0,1.4,1.1,2.5,2.5,2.5c0.3,0,0.5,0,0.8-0.1l15.2-5.1
		            c0.2-0.1,0.4-0.2,0.5-0.2C41,64.7,40.4,64.2,39.7,63.4z"
							/>
						</g>
					</svg>
				);
			case IconsList.SORT:
				return (
					<svg
						className={iconName}
						style={{
							width: width,
							height: height,
							fill: color,
						}}
						version="1.1"
						id="Layer_1"
						xmlns="http://www.w3.org/2000/svg"
						x="0px"
						y="0px"
						viewBox="0 0 40 23.3"
					>
						<path
							id="filter-left"
							className="st0"
							d="M0,21.7C0,20.7,0.7,20,1.7,20h10c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7h-10
	                C0.7,23.3,0,22.6,0,21.7z M0,11.7C0,10.7,0.7,10,1.7,10H25c0.9,0,1.7,0.7,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7H1.7C0.7,13.3,0,12.6,0,11.7
	                z M0,1.7C0,0.7,0.7,0,1.7,0h36.7C39.3,0,40,0.7,40,1.7s-0.7,1.7-1.7,1.7H1.7C0.7,3.3,0,2.6,0,1.7z"
						/>
					</svg>
				);
			case IconsList.LIBRARY_SEARCH:
				return (
					<svg xmlns="http://www.w3.org/2000/svg" width="11.678" height="11.677" viewBox="0 0 11.678 11.677">
						<defs>
							<style>{'.sip {fill - rule: evenodd;}'}</style>
						</defs>
						<g transform="translate(0 0)">
							<path
								className="sip"
								d="M10.363,10.363a.73.73,0,0,1,1.033,0l2.81,2.81a.73.73,0,0,1-1.032,1.033l-2.81-2.81a.73.73,0,0,1,0-1.033Z"
								transform="translate(-2.742 -2.742)"
							/>
							<path
								className="sip"
								d="M4.744,8.758A4.014,4.014,0,1,0,.73,4.744,4.014,4.014,0,0,0,4.744,8.758ZM9.488,4.744A4.744,4.744,0,1,1,4.744,0,4.744,4.744,0,0,1,9.488,4.744Z"
								transform="translate(0 0)"
							/>
						</g>
					</svg>
				);
			case IconsList.PLUS_CIRCLE_FILL:
				return (
					<svg
						style={{ fill: color }}
						xmlns="http://www.w3.org/2000/svg"
						width="20.5"
						height="20.5"
						viewBox="0 0 20.5 20.5"
					>
						<path
							id="plus-circle-fill"
							d="M20.5,10.25A10.25,10.25,0,1,1,10.25,0,10.25,10.25,0,0,1,20.5,10.25ZM10.891,5.125a.641.641,0,0,0-1.281,0V9.609H5.125a.641.641,0,0,0,0,1.281H9.609v4.484a.641.641,0,1,0,1.281,0V10.891h4.484a.641.641,0,1,0,0-1.281H10.891Z"
							fill-rule="evenodd"
							fill={color ?? '#fff'}
						/>
					</svg>
				);

			case IconsList.POLL_FILL:
				return (
					<svg
						style={{ fill: color }}
						xmlns="http://www.w3.org/2000/svg"
						width="21.239"
						height="21.239"
						viewBox="0 0 21.239 21.239"
					>
						<path
							id="Path_9855"
							data-name="Path 9855"
							d="M455.738,745.119H438.945a2.224,2.224,0,0,0-2.223,2.224v16.791a2.223,2.223,0,0,0,2.223,2.223h16.793a2.223,2.223,0,0,0,2.223-2.223V747.343A2.224,2.224,0,0,0,455.738,745.119Zm-11.431,16.688a1.011,1.011,0,0,1-1.011,1.011h-2.023a1.011,1.011,0,0,1-1.011-1.011v-3.034a1.012,1.012,0,0,1,1.011-1.011H443.3a1.012,1.012,0,0,1,1.011,1.011Zm5.056,0a1.01,1.01,0,0,1-1.011,1.011H446.33a1.011,1.011,0,0,1-1.012-1.011v-7.08a1.012,1.012,0,0,1,1.012-1.011h2.022a1.011,1.011,0,0,1,1.011,1.011Zm5.058,0a1.011,1.011,0,0,1-1.011,1.011h-2.023a1.011,1.011,0,0,1-1.011-1.011V749.671a1.012,1.012,0,0,1,1.011-1.012h2.023a1.012,1.012,0,0,1,1.011,1.012Z"
							transform="translate(-436.722 -745.119)"
							fill={color ?? '#fff'}
						/>
					</svg>
				);

			case IconsList.POLL_BLUE:
				return (
					<svg
						style={{ fill: color }}
						xmlns="http://www.w3.org/2000/svg"
						width="15"
						height="15"
						viewBox="0 0 15 15"
					>
						<path
							id="Path_9855"
							data-name="Path 9855"
							d="M450.152,745.119h-11.86a1.571,1.571,0,0,0-1.57,1.571v11.859a1.57,1.57,0,0,0,1.57,1.57h11.86a1.57,1.57,0,0,0,1.57-1.57V746.69A1.571,1.571,0,0,0,450.152,745.119Zm-8.073,11.786a.714.714,0,0,1-.714.714h-1.429a.714.714,0,0,1-.714-.714v-2.143a.714.714,0,0,1,.714-.714h1.429a.714.714,0,0,1,.714.714Zm3.571,0a.713.713,0,0,1-.714.714h-1.428a.714.714,0,0,1-.715-.714v-5a.715.715,0,0,1,.715-.714h1.428a.714.714,0,0,1,.714.714Zm3.572,0a.714.714,0,0,1-.714.714h-1.429a.714.714,0,0,1-.714-.714v-8.571a.715.715,0,0,1,.714-.715h1.429a.715.715,0,0,1,.714.715Z"
							transform="translate(-436.722 -745.119)"
							fill={color ?? '#0c306c'}
						/>
					</svg>
				);

			case IconsList.THREE_DOTS:
				return (
					<svg
						style={{ fill: color }}
						xmlns="http://www.w3.org/2000/svg"
						width="13"
						height="3"
						viewBox="0 0 13 3"
					>
						<path
							id="three-dots"
							d="M3,9.5A1.5,1.5,0,1,1,4.5,8,1.5,1.5,0,0,1,3,9.5Zm5,0A1.5,1.5,0,1,1,9.5,8,1.5,1.5,0,0,1,8,9.5Zm5,0A1.5,1.5,0,1,1,14.5,8,1.5,1.5,0,0,1,13,9.5Z"
							transform="translate(-1.5 -6.5)"
							fill={color ?? '#1a273e'}
							fill-rule="evenodd"
						/>
					</svg>
				);
		}
	};

	render() {
		return <>{this.renderIcons()}</>;
	}
}

IconsStore.defaultProps = {
	width: '15px',
	height: '15px',
	fill: '#000000',
};

const mapStateToProps = ({ navReducer }) => ({
	open: navReducer.open,
});

export default compose(connect(mapStateToProps, null), withTranslation())(IconsStore);
