class FeaturesService {
	IsEnabled = (permissions, FeatureName) => {
		let allowAccess = false;
		permissions = permissions && permissions.length > 0 ? JSON.parse(permissions) : [];

		permissions.forEach((permission) => {
			if (permission.name === FeatureName && permission.accessLevel == '2') {
				allowAccess = true;
			}
		});
		return allowAccess;
	};
}

export default new FeaturesService();
