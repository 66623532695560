import { readApiEndpointV1, writeApiEndpointV1 } from '../utils/config';
import BackendClient from './backendClient';

export const _createPoll = (accountId, mediaId, body) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/polls`, body);

export const _updatePoll = (accountId, mediaId, pollId, body) =>
	BackendClient.put(
		`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/polls/${pollId}`,
		body,
		false,
		false
	);

export const _getPolls = (accountId, mediaId) =>
	BackendClient.get(`${readApiEndpointV1}chat/public/accounts/${accountId}/medias/${mediaId}/polls/`);

export const _getPollResult = (accountId, mediaId, pollId) =>
	BackendClient.get(`${readApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/polls/${pollId}/result`);

export const _publishPoll = (accountId, mediaId, pollId) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/polls/${pollId}/publish`);

export const _unpublishPoll = (accountId, mediaId, pollId) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/polls/${pollId}/unpublish`);

export const _closePoll = (accountId, mediaId, pollId) =>
	BackendClient.post(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/polls/${pollId}/close`);

export const _deletePoll = (accountId, mediaId, pollId) =>
	BackendClient.delete(`${writeApiEndpointV1}chat/accounts/${accountId}/medias/${mediaId}/polls/${pollId}`);
