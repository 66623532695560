const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, 'g'));

const convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16);

const getAlphafloat = (a, alpha) => {
	if (typeof a !== 'undefined') {
		return a / 255;
	}
	if (typeof alpha != 'number' || alpha < 0 || alpha > 1) {
		return 1;
	}
	return alpha;
};

export const hexToRGBA = (hex, alpha) => {
	let hexValue = hex === 'transparent' ? '#00000000' : hex;
	if (!isValidHex(hexValue)) {
		hexValue = '#126cfc';
	}
	const chunkSize = Math.floor((hexValue.length - 1) / 3);
	const hexArr = getChunksFromString(hexValue.slice(1), chunkSize);
	const [r, g, b, a] = hexArr.map(convertHexUnitTo256);

	return {
		r,
		g,
		b,
		a: getAlphafloat(a, alpha),
	};
};

export const rGBAToHex = (r, g, b, a) => {
	const aValue = a * 255;

	if (r > 255 || g > 255 || b > 255 || aValue > 255) {
		throw 'Invalid color component';
	}

	return (256 + r).toString(16).substr(1) + (((1 << 24) + (g << 16)) | (b << 8) | aValue).toString(16).substr(1);
};
