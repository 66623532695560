import { LocalStorageService } from '../services/localStorageService';
import {
	transcribeStart,
	transcribeProgress,
	transcribeSave,
	transcribeSaveSRT,
	addTranslationDetails,
	getExternalUrlTrascription,
	deleteTranscript,
} from '../services/mediaService';

export const PLAY_BUTTON_PLAY = 'PLAY_BUTTON_PLAY';
export const PLAY_BUTTON_PAUSE = 'PLAY_BUTTON_PAUSE';
export const SET_PLAYER_POSITION = 'SET_PLAYER_POSITION';
export const SET_RAW_TRANSCRIPTION_TEXT = 'SET_RAW_TRANSCRIPTION_TEXT';
export const SET_APP_MODE = 'SET_APP_MODE';
export const SET_IS_MOUNTED_SUB_UPDATED = 'SET_IS_MOUNTED_SUB_UPDATED';
export const SET_SUBTITLE_IS_UPDATING = 'SET_SUBTITLE_IS_UPDATING';
export const SET_CLICKED_POSITION = 'SET_CLICKED_POSITION';
export const TRANSCRIPTION_START_REQUEST = 'TRANSCRIPTION_START_REQUEST';
export const TRANSCRIPTION_START_REQUEST_SUCCESS = 'TRANSCRIPTION_START_REQUEST_SUCCESS';
export const TRANSCRIPTION_START_REQUEST_FAILURE = 'TRANSCRIPTION_START_REQUEST_FAILURE';
export const TRANSCRIPTION_SAVE_REQUEST = 'TRANSCRIPTION_SAVE_REQUEST';
export const TRANSCRIPTION_SAVE_SRT_REQUEST = 'TRANSCRIPTION_SAVE_SRT_REQUEST';
export const TRANSCRIPTION_SAVE_REQUEST_SUCCESS = 'TRANSCRIPTION_SAVE_REQUEST_SUCCESS';
export const TRANSCRIPTION_SAVE_REQUEST_FAILURE = 'TRANSCRIPTION_SAVE_REQUEST_FAILURE';
export const TRANSCRIPTION_LOAD_REQUEST_FAILURE = 'TRANSCRIPTION_LOAD_REQUEST_FAILURE';
export const TRANSCRIPTION_LOAD_REQUEST_SUCCESS = 'TRANSCRIPTION_LOAD_REQUEST_SUCCESS';
export const RESET_SPEECH_TO_TEXT_REDUCER = 'RESET_SPEECH_TO_TEXT_REDUCER';
export const TOGGLE_SHOW_PREVIEW = 'TOGGLE_SHOW_PREVIEW';

export const ADD_TRANSLATION_DETAILS_REQUEST_SUCCESS = 'ADD_TRANSLATION_DETAILS_REQUEST_SUCCESS';
export const ADD_TRANSLATION_DETAILS_REQUEST_FAILURE = 'ADD_TRANSLATION_DETAILS_REQUEST_FAILURE';
export const ADD_TRANSLATION_DETAILS_REQUEST = 'ADD_TRANSLATION_DETAILS_REQUEST';

export const playButtonPlay = () => ({
	type: PLAY_BUTTON_PLAY,
});
export const resetSpeechToTextReducer = () => ({
	type: RESET_SPEECH_TO_TEXT_REDUCER,
});

export const playButtonPause = () => ({
	type: PLAY_BUTTON_PAUSE,
});

export const setPlayerPosition = (position) => ({
	type: SET_PLAYER_POSITION,
	position,
});

export const setClickedPosition = (clickedPosition) => ({
	type: SET_CLICKED_POSITION,
	clickedPosition,
});

export const setRawTranscriptionText = (rawTranscribedText) => ({
	type: SET_RAW_TRANSCRIPTION_TEXT,
	rawTranscribedText,
});

export const setAppMode = (mode) => ({
	type: SET_APP_MODE,
	mode,
});

export const setIsMountedSubtitleUpdated = (mountedSubUpdate) => ({
	type: SET_IS_MOUNTED_SUB_UPDATED,
	mountedSubUpdate,
});

export const setSubtitleIsUpdating = (subtitleIsUpdating) => ({
	type: SET_SUBTITLE_IS_UPDATING,
	subtitleIsUpdating,
});

export const getTranscriptioStartRequest = () => ({
	type: TRANSCRIPTION_START_REQUEST,
});

export const getTranscriptioStartRequestSuccess = () => ({
	type: TRANSCRIPTION_START_REQUEST_SUCCESS,
});

export const getTranscriptioStartRequestFailure = () => ({
	type: TRANSCRIPTION_START_REQUEST_FAILURE,
});

export const getTranscriptioSaveRequest = () => ({
	type: TRANSCRIPTION_SAVE_REQUEST,
});

export const getTranscriptionSaveSRTRequest = () => ({
	type: TRANSCRIPTION_SAVE_SRT_REQUEST,
});

export const getTranscriptioSaveRequestSuccess = () => ({
	type: TRANSCRIPTION_SAVE_REQUEST_SUCCESS,
});

export const getTranscriptioSaveRequestFailure = () => ({
	type: TRANSCRIPTION_SAVE_REQUEST_FAILURE,
});

export const getTranscriptionLoadRequestFailure = (error) => ({
	type: TRANSCRIPTION_LOAD_REQUEST_FAILURE,
	error,
});

export const getTranscriptionLoadRequestSuccess = (data) => ({
	type: TRANSCRIPTION_LOAD_REQUEST_SUCCESS,
	data,
});

export const addTranslationDetailsRequestSuccess = (data) => ({
	type: ADD_TRANSLATION_DETAILS_REQUEST_SUCCESS,
	data,
});

export const addTranslationDetailsRequestFailure = (data) => ({
	type: ADD_TRANSLATION_DETAILS_REQUEST_FAILURE,
	data,
});

export const addTranslationDetailsRequest = () => ({
	type: ADD_TRANSLATION_DETAILS_REQUEST,
});

export const toggleShowPreview = (showPreview) => ({
	type: TOGGLE_SHOW_PREVIEW,
	showPreview,
});

export const startTranscription =
	({ accountId, mediaId, language, translationLanguages }) =>
	(dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		dispatch(getTranscriptioStartRequest());
		return transcribeStart(defaultAccountId, mediaId, language, translationLanguages)
			.then((data) => {
				dispatch(getTranscriptioStartRequestSuccess());
				return data;
			})
			.catch((error) => {
				dispatch(getTranscriptioStartRequestFailure());
				return error;
			});
	};

export const saveTranscription = (accountId, mediaId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	const accessToken = getState().session.accessToken || LocalStorageService.getAccessToken();
	dispatch(getTranscriptioSaveRequest());
	return transcribeSave(defaultAccountId, mediaId, accessToken, body)
		.then((data) => {
			dispatch(getTranscriptioSaveRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(getTranscriptioSaveRequestFailure());
			return error;
		});
};

export const saveSrt = (accountId, mediaId, body, language, fileName) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	const accessToken = getState().session.accessToken || LocalStorageService.getAccessToken();
	dispatch(getTranscriptionSaveSRTRequest());
	return transcribeSaveSRT(defaultAccountId, mediaId, accessToken, body, language, fileName);
};

export const loadTranscription = (accountId, mediaId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	const accessToken = getState().session.accessToken || LocalStorageService.getAccessToken();
	if (mediaId === '376e61ff-00090330-245cce3d-Rw9I-copy-xhWD-copy') {
		return getExternalUrlTrascription()
			.then((data) => {
				dispatch(getTranscriptionLoadRequestSuccess(data));
				return data;
			})
			.catch((error) => {
				dispatch(getTranscriptionLoadRequestFailure(error.response));
				return error;
			});
	} else {
		return transcribeProgress(defaultAccountId, mediaId, accessToken)
			.then((data) => {
				dispatch(getTranscriptionLoadRequestSuccess(data));
				return data;
			})
			.catch((error) => {
				dispatch(getTranscriptionLoadRequestFailure(error.response));
				return error;
			});
	}
};

export const addTranslationDetailsToDatabase = (accountId, mediaId, language, charCount) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	const accessToken = getState().session.accessToken || LocalStorageService.getAccessToken();

	return addTranslationDetails(defaultAccountId, mediaId, accessToken, language, charCount)
		.then((data) => {
			dispatch(addTranslationDetailsRequestSuccess(data));
			return data;
		})
		.catch((error) => {
			dispatch(addTranslationDetailsRequestFailure(error.response));
			return error;
		});
};

export const getAccountId = (getState) => {
	if (getState) {
		return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

export const deleteTranscription = (accountId, mediaId, callback) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);

	return deleteTranscript(defaultAccountId, mediaId).then((data) => {
		//delete endpoint alway 202 and empty payload
		if (data === '') {
			dispatch(resetSpeechToTextReducer());
			// call speech2Text screen to clear transcription data inside state
			callback && callback();
		}
	});
};
