import { getApps } from './../services/appsService';

export const SEND_APPS_GET_REQUEST = 'SEND_APPS_GET_REQUEST';
export const SEND_APPS_GET_SUCCESS = 'SEND_APPS_GET_SUCCESS';
export const SEND_APPS_GET_FAILURE = 'SEND_APPS_GET_FAILURE';
export const SET_APPS = 'SET_APPS';
export const TOGGLE_FULLSCREEN_MODE = 'TOGGLE_FULLSCREEN_MODE';
export const SET_SELECTED_APP = 'SET_SELECTED_APP';

const sendAppsGetRequest = (_) => ({
	type: SEND_APPS_GET_REQUEST,
});

const sendAppsGetSuccess = (data) => ({
	type: SEND_APPS_GET_SUCCESS,
	data,
});

const sendAppsGetFailure = (_) => ({
	type: SEND_APPS_GET_FAILURE,
});

const setApps = (apps) => ({
	type: SET_APPS,
	apps,
});

export const setSelectedApp = (selectedApp) => ({
	type: SET_SELECTED_APP,
	selectedApp: selectedApp,
});

export const toggleAppFullScreenMode = (isFullscreen) => ({
	type: TOGGLE_FULLSCREEN_MODE,
	isFullscreen: isFullscreen,
});

export const loadApps = (accountId) => (dispatch) => {
	const defaultAccountId = accountId;
	dispatch(sendAppsGetRequest());
	return getApps(defaultAccountId)
		.then((data) => {
			dispatch(sendAppsGetSuccess());
			dispatch(setApps(Array.from(data) ?? []));
			return data;
		})
		.catch((error) => {
			dispatch(sendAppsGetFailure());
			return error;
		});
};
