import React from 'react';
import Slider from '@material-ui/core/Slider';
import { Tooltip, Typography } from '@material-ui/core';

const TooltipInfo = ({ children, open, value }: any) => {
	return (
		<Tooltip
			open={open}
			enterTouchDelay={0}
			placement="top"
			title={
				<Typography variant="body1" color="inherit">
					{`${value}px`}
				</Typography>
			}
			arrow
			color="primary"
		>
			{children}
		</Tooltip>
	);
};

const CustomSlider = (props: any) => {
	return <Slider {...props} ValueLabelComponent={TooltipInfo} />;
};

export default CustomSlider;
