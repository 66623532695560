// Identity
export const identityConfigurations = {
	oidc: true,
	clientId: process.env.REACT_APP_IDENTITY_CLIENT_ID,
	scope: process.env.REACT_APP_IDENTITY_SCOPE,
	issuer: process.env.REACT_APP_IDENTITY_CLIENT_ISSUER,
	redirectUri: process.env.REACT_APP_IDENTITY_REDIRECT_URL,
	loginUrl: process.env.REACT_APP_IDENTITY_AUTHORIZATION_ENDPOINT,
	logoutUrl: process.env.REACT_APP_IDENTITY_END_SESSION_ENDPOINT + '?id_token_hint=',
	baseUrl: process.env.REACT_APP_IDENTITY_BASE_URL,
};

export const publicURL = process.env.PUBLIC_URL;

// Account & Authorization
export const readAccountApiEndpointV2 = process.env.REACT_APP_READ_ACCOUNT_API_ENDPOINT_V2;
export const writeAccountApiEndpointV2 = process.env.REACT_APP_WRITE_ACCOUNT_API_ENDPOINT_V2;

// Player
export const GoBrainUrl = process.env.REACT_APP_GOBRAIN_URL;
export const play2ConfigBaseUrl = process.env.REACT_APP_PLAY2_CONFIG_BASE_URL;
export const qplayerLoaderUrl = process.env.REACT_APP_QPLAYER_LOADER;
export const qplayerDomain = process.env.REACT_APP_QPLAYER_PROD_DOMAIN;

// Library APIs
//export const readApiEndpointV1 ="https://api.qbrick.com/api/v1/" //process.env.REACT_APP_READ_API_ENDPOINT_V1
export const readApiEndpointV1 = process.env.REACT_APP_READ_API_ENDPOINT_V1;
export const readApiEndpointV2 = process.env.REACT_APP_READ_API_ENDPOINT_V2;
export const writeApiEndpointV1 = process.env.REACT_APP_WRITE_API_ENDPOINT_V1;
export const writeApiEndpointV2 = process.env.REACT_APP_WRITE_API_ENDPOINT_V2;

// CDN API Endpoint
export const cdnApiEndpointV1 = process.env.REACT_APP_CDN_API_ENDPOINT_V1;

export const readPublicApiEndpointV1 = process.env.REACT_APP_PUBLIC_API_ENDPOINT_V1;
//export const transcribeEndpointV1 = 'https://speech-to-text-adzq564ela-lz.a.run.app/'
//export const transcribeEndpointV1 = 'https://speechtotext.qbrick.com/'  //test on new kubernates cluster
export const transcribeEndpointV1 = process.env.REACT_APP_SPEECH_TO_TEXT_ENDPOINT;
export const interactAssetLibraryEndpointV1 = process.env.REACT_APP_INTERACT_ASSET_LIBRARY_ENDPOINT;
//export const transcribeEndpointV1 ='http://127.0.0.1:8080/'
export const otpDeepLinkEndpoint = process.env.REACT_APP_OTP_DEEPLINK_ENDPOINT;

// Other - workflow, ingest
//export const workflowEndpoint = "http://localhost:62591/api/v1/workflow/" //process.env.REACT_APP_WORKFLOW_ENDPOINT
export const workflowEndpoint = process.env.REACT_APP_WORKFLOW_ENDPOINT;
export const ingestEndpointV1 = process.env.REACT_APP_INGEST_ENDPOINT_V1;
export const cdnAdminEndpointV1 = process.env.REACT_APP_CDN_ADMIN_ENDPOINT_V1;
export const cdnWorkflowEndpointV1 = process.env.REACT_APP_CDN_WORKFLOW_ENDPOINT_V1;
export const uiBaseUrl = process.env.REACT_APP_UI_BASE_URL;
export const PlayerBaseUrl = process.env.REACT_APP_EMBED_PLAYER_BASE_URL;
export const adminPortalUrl = process.env.REACT_APP_ADMIN_PORTAL_URL;
export const encoderEndpointV1 = process.env.REACT_APP_ENCODER_ENDPOINT_V1;

//IP-ONly APIs
export const ipOnlyEndpointV1 = process.env.REACT_APP_IP_ONLY_API_ENDPOINT_V1;
export const ipOnlyEndpointV2 = process.env.REACT_APP_IP_ONLY_API_ENDPOINT_V2;
export const ipOnlyWorkflowEndpointV1 = process.env.REACT_APP_IP_ONLY_WORKFLOW_ENDPOINT_V1;
// Social sharing client IDs
export const facebookClientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
export const twitterClientId = process.env.REACT_APP_TWITTER_CLIENT_ID;
export const youtubeClientId = process.env.REACT_APP_YOUTUBE_CLIENT_ID;
export const linkedinClientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
export const socialSharingRedirectUrl = process.env.REACT_APP_SOCIAL_SHARING_REDIRECT_URL;
export const twitterAuthenticateUrl = process.env.REACT_APP_TWITTER_AUTHENTICE_URL;
export const twitterAuthorizationUrl = process.env.REACT_APP_TWITTER_AUTHORIZATION_URL;
export const facebookAuthorizationUrl = process.env.REACT_APP_FACEBOOK_AUTHORIZATION_URL;
export const googleAuthorizationUrl = process.env.REACT_APP_GOOGLE_AUTHORIZATION_URL;
export const linkedinAuthorizationUrl = process.env.REACT_APP_LINKEDIN_AUTHORIZATION_URL;
export const googleAuthorizationScope = process.env.REACT_APP_GOOGLE_AUTHORIZATION_SCOPE;
export const facebookAuthorizationScope = process.env.REACT_APP_FACEBOOK_AUTHORIZATION_SCOPE;
export const linkedinAuthorizationScope = process.env.REACT_APP_LINKEDIN_AUTHORIZATION_SCOPE;

export const supportUrl = process.env.REACT_APP_SUPPORT_URL;
export const qbrickUrl = process.env.REACT_APP_QBRICK_URL;
export const googleAnalyticsTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

//Analytics
export const analyticsEndpointV1 = process.env.REACT_APP_ANALYTICS_ENDPOINT_V1;

//websocket
export const chatWebSocketUrl = process.env.REACT_APP_CHAT_WEBSOCKET_URL;
export const notificationServiceUrl = process.env.REACT_APP_NOTIFICATION_WEBSOCKET_URL;

//third parties api resource
export const pexelsApiKey = process.env.REACT_APP_PEXELS_API_KEY;
export const unsplashAccessKey = process.env.REACT_APP_UNSPLASH_ACCESS_KEY;
export const unsplashAppName = process.env.REACT_APP_UNSPLASH_APP_NAME;
