import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import './SideBar.scss';

const baseClassName = 'scenario-chart-side-bar';

class SideBar extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { header, children, style } = this.props;
		return (
			<div
				className={baseClassName}
				style={style}
			>
				{header}
				<div className={`${baseClassName}__content`}>{children}</div>
				<div />
			</div>
		);
	}
}

const mapStateToProps = ({ session }) => ({
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = (_) => ({});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation()
)(SideBar);
