import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@material-ui/core';

import { checkPermissionGrantedFor } from '../../../../services/componentReusableService';
import { AdobeExpressUtils } from '../../AdobeExpress/utils';
import useAEEditor from '../../../../hooks/useAEEditor';
import { updateAdobeExpressEditorModal } from '../../../../actions/sessionActions';

interface AdobeExpressForClassComponentProps {
	mediaId: string;
	mediaName: string;
	className?: string;
	assetId: string;
	onClick: () => {};
}

// this component is a wrapper to use useUploadingVideoByUrl, useUploadingImageByUrl hook inside inpect page - which is class component for now
// will update after finish inpect refactor
const AdobeExpressForClassComponent = ({
	mediaId,
	mediaName,
	assetId,
	onClick,
	className,
}: AdobeExpressForClassComponentProps) => {
	const ccEverywhere = useSelector((state: any) => state.publish.ccEverywhere);
	const { userPermissions } = useSelector((state) => (state as any).session);
	const { t: translator } = useTranslation();
	const dispatch = useDispatch();

	const { uploadToPlatformCallback } = useAEEditor({
		afterJobFunc: () => {
			dispatch(updateAdobeExpressEditorModal(false));
		},
	});

	return (
		checkPermissionGrantedFor(userPermissions, 'adobeExpress') && (
			<MenuItem
				className={className}
				onClick={() => {
					dispatch(updateAdobeExpressEditorModal(true));
					const projectId = AdobeExpressUtils.getAdobeExpressProjectIdFromMediaId(mediaId);
					let replaceMediaContent = { mediaId, assetId };
					projectId &&
						AdobeExpressUtils.editDesign({
							ccEverywhere,
							projectId,
							actionCallback: uploadToPlatformCallback,
							mediaName,
							replaceMediaContent,
						});
					onClick?.();
				}}
			>
				{translator('COMMON_OPEN_AND_REPLACE_AE_PROJECT')}
			</MenuItem>
		)
	);
};

export default AdobeExpressForClassComponent;
