import { createContext } from 'react';

interface SearchContextProps {
	searchInputValue: string;
	setSearchInputValue: (_value: string) => void;
}

export const SearchContext = createContext<SearchContextProps>({
	searchInputValue: '',
	setSearchInputValue: (_value) => {},
});
