import { readAccountApiEndpointV2, writeAccountApiEndpointV2 } from '../utils/config';
import BackendClient from './backendClient';

export const _getPermission = (accountId, userId) =>
	BackendClient.get(`${readAccountApiEndpointV2}accounts/${accountId}/users/${userId}/features`);
export const _addPermission = (accountId, userId, data) =>
	BackendClient.post(
		`${writeAccountApiEndpointV2}accounts/${accountId}/users/${userId}/features`,
		data,
		false,
		false
	);
export const _updatePermission = (accountId, userId, data) =>
	BackendClient.put(`${writeAccountApiEndpointV2}accounts/${accountId}/users/${userId}/features`, data, false, false);
export const _deletePermission = (accountId, userId, featureId) =>
	BackendClient.delete(`${writeAccountApiEndpointV2}accounts/${accountId}/users/${userId}/features/${featureId}`);

export const _getFeatures = (accountId) =>
	BackendClient.get(`${readAccountApiEndpointV2}@me/accounts/${accountId}/features`);
