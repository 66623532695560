export const DRAWER_OPEN = "DRAWER_OPEN";
export const DRAWER_CLOSE = "DRAWER_CLOSE";
export const SHOW_MAIN_NAV_TEXTS = "SHOW_MAIN_NAV_TEXTS";
export const HIDE_MAIN_NAV_TEXTS = "HIDE_MAIN_NAV_TEXTS";
export const UPDATE_SELECTED_KEY = "UPDATE_SELECTED_KEY";
export const UPDATE_SUB_NAV_SELECTED_KEY = "UPDATE_SUB_NAV_SELECTED_KEY";
export const UPDATE_CURRENT_COMPONENT_KEY = "UPDATE_CURRENT_COMPONENT_KEY";

export const openDrawer = _ => ({
    type: DRAWER_OPEN
});

export const closeDrawer = _ => ({
    type: DRAWER_CLOSE
});

export const showMainNavTexts = _ => ({
    type: SHOW_MAIN_NAV_TEXTS
});

export const hideMainNavTexts = _ => ({
    type: HIDE_MAIN_NAV_TEXTS
});

export const updateSelectedKey = (key) => ({
    type: UPDATE_SELECTED_KEY,
    key
});

export const updateSubSelectedKey = (parent, key) => ({
    type: UPDATE_SUB_NAV_SELECTED_KEY,
    parent,
    key
});

export const updateCurentComponentKey = (key) => ({
    type: UPDATE_CURRENT_COMPONENT_KEY,
    key
});
