import { sendMediaScreenChanged } from './publishActions';

import { hideSelectedSubPropertyKey } from './interactElementsActions';

export const CREATE_INTERACT_TIMELINE_ITEM = 'CREATE_INTERACT_TIMELINE_ITEM';
export const UPDATE_INTERACT_TIMELINE_ITEM = 'UPDATE_INTERACT_TIMELINE_ITEM';
export const REMOVE_INTERACT_TIMELINE_ITEM = 'REMOVE_INTERACT_TIMELINE_ITEM';
export const SET_SELECTED_INTERACT_TIMELINE_ITEM =
	'SET_SELECTED_INTERACT_TIMELINE_ITEM';
export const CREATE_INTERACT_TIMELINE_GROUP = 'CREATE_INTERACT_TIMELINE_GROUP';
export const UPDATE_INTERACT_TIMELINE_GROUP = 'UPDATE_INTERACT_TIMELINE_GROUP';
export const REMOVE_INTERACT_TIMELINE_GROUP = 'REMOVE_INTERACT_TIMELINE_GROUP';
export const SET_SELECTED_INTERACT_TIMELINE_GROUP =
	'SET_SELECTED_INTERACT_TIMELINE_GROUP';
export const REMOVE_SELECTED_INTERACT_TIMELINE_ITEM =
	'REMOVE_SELECTED_INTERACT_TIMELINE_ITEM';
export const SET_INTERACT_SCREEN_MODIFIED = 'SET_INTERACT_SCREEN_MODIFIED';
export const SET_INTERACT_SCREEN_UNMODIFIED = 'SET_INTERACT_SCREEN_UNMODIFIED';
export const UPDATE_INTERACT_ITEMS = 'UPDATE_INTERACT_ITEMS';
export const SET_LIVE_QUEUE_ITEM = 'SET_LIVE_QUEUE_ITEM';

export const CLEAR_INTERACT_STORE = 'CLEAR_INTERACT_STORE';
export const SET_PLAYER_POSITION = 'SET_PLAYER_POSITION';
export const START_INTERACT_MEDIA_LOOP = 'START_INTERACT_MEDIA_LOOP';
export const STOP_INTERACT_MEDIA_LOOP = 'STOP_INTERACT_MEDIA_LOOP';
export const START_FETCH_INTERACT_ELEMENTS = 'START_FETCH_INTERACT_ELEMENTS';
export const STOP_FETCH_INTERACT_ELEMENTS = 'STOP_FETCH_INTERACT_ELEMENTS';
export const SHOW_TRIM_TOOL = 'SHOW_TRIM_TOOL';
export const HIDE_TRIM_TOOL = 'HIDE_TRIM_TOOL';
export const REFRESH_INTERACT_PLAYER = 'REFRESH_INTERACT_PLAYER';
export const DISABLE_VIDEO_APPLY_BUTTON = 'DISABLE_VIDEO_APPLY_BUTTON';
export const ENABLE_VIDEO_APPLY_BUTTON = 'ENABLE_VIDEO_APPLY_BUTTON';
export const SET_QUEUE_OPERATION = 'SET_QUEUE_OPERATION';
export const RESET_QUEUE_OPERATION = 'RESET_QUEUE_OPERATION';

export const refreshInteractPlayer = () => ({
	type: REFRESH_INTERACT_PLAYER,
});

export const disableVideoApplyButton = () => ({
	type: DISABLE_VIDEO_APPLY_BUTTON,
});

export const enableVideoApplyButton = () => ({
	type: ENABLE_VIDEO_APPLY_BUTTON,
});

export const showTrimTool = () => ({
	type: SHOW_TRIM_TOOL,
});

export const hideTrimTool = () => ({
	type: HIDE_TRIM_TOOL,
});

export const startFetchInteractItems = () => ({
	type: START_FETCH_INTERACT_ELEMENTS,
});

export const stopFetchInteractItems = () => ({
	type: STOP_FETCH_INTERACT_ELEMENTS,
});

export const setPlayerPosition = (datetime) => ({
	type: SET_PLAYER_POSITION,
	datetime: datetime,
});

export const startInteractMediaLoop = () => ({
	type: START_INTERACT_MEDIA_LOOP,
});

export const stopInteractMediaLoop = () => ({
	type: STOP_INTERACT_MEDIA_LOOP,
});

export const sendInteractScreenModified = () => ({
	type: SET_INTERACT_SCREEN_MODIFIED,
});

export const setInteractScreenModified = () => (dispatch) => {
	dispatch(sendInteractScreenModified());
	dispatch(sendMediaScreenChanged());
};

export const setInteractScreenUnModified = () => ({
	type: SET_INTERACT_SCREEN_UNMODIFIED,
});

export const sendCreateInteractTimelineItem = (item) => ({
	type: CREATE_INTERACT_TIMELINE_ITEM,
	item,
});

export const createInteractTimelineItem =
	(item, setScreenUnModified) => (dispatch) => {
		if (!setScreenUnModified) {
			dispatch(setInteractScreenModified());
		}
		dispatch(sendCreateInteractTimelineItem(item));
	};

export const sendUpdateInteractTimelineItem = (item) => ({
	type: UPDATE_INTERACT_TIMELINE_ITEM,
	item,
});

export const sendUpdateInteractItems = (items) => ({
	type: UPDATE_INTERACT_ITEMS,
	items,
});

export const updateInteractTimelineItem =
	(item, setScreenUnModified) => (dispatch) => {
		if (!setScreenUnModified) {
			dispatch(setInteractScreenModified());
		}
		dispatch(sendUpdateInteractTimelineItem(item));
	};

export const sendRemoveInteractTimelineItem = (item) => ({
	type: REMOVE_INTERACT_TIMELINE_ITEM,
	item,
});

export const removeInteractTimelineItem =
	(item, setScreenUnModified) => (dispatch) => {
		if (!setScreenUnModified) {
			dispatch(setInteractScreenModified());
		}
		dispatch(sendRemoveInteractTimelineItem(item));
	};

export const sendSetSelectedInteractTimelineItem = (items) => ({
	type: SET_SELECTED_INTERACT_TIMELINE_ITEM,
	items,
});

export const sendRemoveSelectedInteractTimelineItem = (item) => ({
	type: REMOVE_SELECTED_INTERACT_TIMELINE_ITEM,
	item,
});

export const setSelectedInteractTimelineItem = (items) => (dispatch) => {
	dispatch(sendSetSelectedInteractTimelineItem(items));
	dispatch(hideSelectedSubPropertyKey());
};

export const removeSelectedInteractTimelineItem = (item) => (dispatch) => {
	dispatch(sendRemoveSelectedInteractTimelineItem(item));
	dispatch(hideSelectedSubPropertyKey());
};

export const createInteractTimelineGroup = (group) => ({
	type: CREATE_INTERACT_TIMELINE_GROUP,
	group,
});

export const updateInteractTimelineGroup = (group) => ({
	type: UPDATE_INTERACT_TIMELINE_GROUP,
	group,
});

export const removeInteractTimelineGroup = (group) => ({
	type: REMOVE_INTERACT_TIMELINE_GROUP,
	group,
});

export const setSelectedInteractTimelineGroup = (group) => ({
	type: SET_SELECTED_INTERACT_TIMELINE_GROUP,
	group,
});

export const setLiveQueueItem = (item) => ({
	type: SET_LIVE_QUEUE_ITEM,
	item,
});

export const setQueueOperation = () => ({
	type: SET_QUEUE_OPERATION,
});

export const resetQueueOperation = () => ({
	type: RESET_QUEUE_OPERATION,
});

export const clearInteractStore = () => ({
	type: CLEAR_INTERACT_STORE,
});
