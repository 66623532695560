import {
	featurePermissionsList,
	ACCESS_LEVEL,
} from './../constants/featurePermissionsConstant';
import { imageRelTagList } from '../constants/mediaConstants';
import _ from 'underscore';

export const hideNavArea = (_) => {
	let navArea = document.getElementById('navDrawer');
	if (navArea) {
		navArea.style.display = 'none';
	}
};

export const showNavArea = (_) => {
	let navArea = document.getElementById('navDrawer');
	if (navArea) {
		navArea.style.display = 'block';
	}
};
export const getValuesInArrayFromObject = (obj) => {
	let values = [];
	values = Object.keys(obj).map((e) => obj[e]);
	return values;
};

const parsePermissionToJson = (rawUserPermission) => {
	return rawUserPermission && rawUserPermission.length > 0
		? JSON.parse(rawUserPermission)
		: [];
};

export const checkPermissionGrantedFor = (userPermissions, permissionName) => {
	const parsedUserPermission = parsePermissionToJson(userPermissions);

	for (const permission of parsedUserPermission) {
		const { featureId, accessLevel } = permission;

		if (
			featureId === featurePermissionsList[permissionName].featureId &&
			accessLevel === ACCESS_LEVEL.READ_WRITE
		) {
			return true;
		}
	}

	return false;
};

export const checkForAccessToChannels = (uPermissions) =>
	checkPermissionGrantedFor(uPermissions, 'channels');

export const getPostCoverResourceId = (media) => {
	let tag = imageRelTagList.POSTCOVER;
	if (media && media.asset && media.asset.resources) {
		let resources = media.asset.resources.filter(
			(eachImageResource) =>
				eachImageResource.type === 'image' &&
				eachImageResource.renditions
		);
		let resourcesWithRelTag = resources.filter(
			(eachImageResource) =>
				eachImageResource.rel && eachImageResource.rel.length > 0
		);
		let resourcesWithTag = [];
		if (resourcesWithRelTag.length > 0) {
			resourcesWithRelTag.map((resource) => {
				if (resource.rel.indexOf(tag) != -1) {
					resourcesWithTag.push(resource);
				}
			});
		}
		if (resourcesWithTag.length > 0) {
			return resourcesWithTag[0].id;
		}
	}
	return '';
};

export const getTrackIdFromMediaId = (tracks, mediaId) => {
	let trackDetails = _.find(tracks, function (track) {
		return (
			track.sequences &&
			track.sequences[0].custom &&
			track.sequences[0].custom.mediaId === mediaId
		);
	});
	if (trackDetails) {
		return trackDetails.id;
	}
	return '';
};

export const getUrlParameters = (search) => {
	const hashes = search.slice(search.indexOf('?') + 1).split('&');
	const params = {};
	hashes.map((hash) => {
		const [key, val] = hash.split('=');
		params[key] = decodeURIComponent(val);
	});
	return params;
};

export const getTextWithEmojiUnicode = (str) => {
	return str.replace(
		/\p{Emoji}/gu,
		(m) => `&#x${m.codePointAt(0).toString(16)}`
	);
};
