import * as actions from '../actions/otpActions';

const initialState = {
	accessToken: null,
	url: null,
};
export const otpReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SEND_OTP_GET_SUCCESS:
			return {
				...state,
				accessToken: action.data,
			};
		case actions.SEND_OTP_GET_FAILURE:
			return {
				...state,
			};
		case actions.SEND_OTP_POST_SUCCESS:
			return {
				...state,
				url: action.data,
			};
		case actions.SEND_OTP_POST_FAILURE:
			return {
				...state,
			};
		case actions.SEND_OTP_DELETE_SUCCESS:
			return {
				...state,
			};
		case actions.SEND_OTP_DELETE_FAILURE:
			return {
				...state,
			};
		default:
			return state;
	}
};
