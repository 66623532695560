import React from 'react';
import Technology from './components/Technology';

const AnalyticsProtocolScreen = () => {
	return (
		<Technology
			dimension="proto"
			titleSec1="ANALYTICS_TITLE_CDN_PROTOCOL"
			titleSec2="ANALYTICS_TITLE_CDN_VOLUME_PER_PROTOCOL"
			column="Protocol"
		/>
	);
};

export default AnalyticsProtocolScreen;
