import * as actions from '../actions/snapshotSettingsActions';
import { getTimelineStartDate } from './../services/timeStampService';

const initialState = {
	snapshotPlayStarted: false,
	snapshotPlayPaused: false,
	selectedSnapShotStartTime: getTimelineStartDate(),
	selectedSnapShotEndTime: getTimelineStartDate(),
	lastSetSnapShotPlayTime: Date.now(),
};

export const snapshotSettingsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.RESET_SNAPSHOT_SETTINGS:
			return {
				...state,
				...initialState,
			};
		case actions.PLAY_SNAPSHOT_MEDIA:
			return {
				...state,
				lastSetSnapShotPlayTime: Date.now(),
				snapshotPlayStarted: true,
				snapshotPlayPaused: false,
			};
		case actions.PAUSE_SNAPSHOT_MEDIA:
			return {
				...state,
				lastSetSnapShotPlayTime: Date.now(),
				snapshotPlayStarted: true,
				snapshotPlayPaused: true,
			};
		case actions.STOP_SNAPSHOT_MEDIA:
			return {
				...state,
				snapshotPlayStarted: false,
				snapshotPlayPaused: false,
			};
		case actions.SET_SELECTED_SNAPSHOT_START_TIME:
			return {
				...state,
				selectedSnapShotStartTime: action.datetime,
			};
		case actions.SET_SELECTED_SNAPSHOT_END_TIME:
			return {
				...state,
				selectedSnapShotEndTime: action.datetime,
			};
		default:
			return state;
	}
};
