import React, { Component } from 'react';
import './LoginScreen.scss';

export default class IframeLogin extends Component {

    render() {
        return (
            <iframe className="iframelogin" title="login" src={this.props.src} ref={res => this.frameObj = res} onLoad={this.props.frameLoaded}/>
        );
    }
}