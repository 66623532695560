import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './FileTable.scss';
import arrow_up from '../../../../../../assets/images/arrow_up.svg';
import arrow_down from '../../../../../../assets/images/arrow_down.svg';
import { tableSortTypes } from './../../../../../../constants/mediaConstants';

class FileTable extends Component {
	shouldComponentUpdate(nextProps) {
		const { tableData, selectedHlsValue } = this.props;

		const itemsUpdated =
			JSON.stringify(tableData) !== JSON.stringify(nextProps.tableData) ||
			selectedHlsValue !== nextProps.selectedHlsValue;
		const shouldUpdate = itemsUpdated;

		return shouldUpdate;
	}
	renderTable = () => {
		const { tableType } = this.props;
		return (
			<>
				<div className="scrollable-table">
					<table className={tableType + ' ' + 'filesTable'}>
						{this.renderTableHeader()}
						<tbody>{this.renderTableData()}</tbody>
					</table>
				</div>
			</>
		);
	};

	renderTableHeader = () => {
		const { tableHeaderData, handleSort, tableType } = this.props;
		return (
			<tr>
				{tableHeaderData &&
					tableHeaderData.map((header, i) => (
						<th key={i} attr={header.id === 'more' ? 'more' : ''}>
							{' '}
							{header.text}
							<div className={header.sort ? 'sortIcon' : 'hide'} onClick={() => handleSort(tableType)}>
								<img src={header.sort == tableSortTypes.DESC ? arrow_up : arrow_down} />
							</div>
						</th>
					))}
				<th> </th>
			</tr>
		);
	};

	renderTableData = () => {
		const { tableHeaderData, tableData, selectedHlsValue } = this.props;

		return (
			tableData &&
			tableData.map((data, i) => (
				<tr key={i}>
					{' '}
					{tableHeaderData &&
						tableHeaderData.map((header, i) => (
							<td key={i}> {this.props.getCells(header.id, data, selectedHlsValue)}</td>
						))}
				</tr>
			))
		);
	};

	render() {
		return <>{this.renderTable()}</>;
	}
}

const mapStateToProps = ({ session, publish }) => ({
	loading: publish.loading,
	username: session.username,
	isLoggedIn: session.isLoggedIn,
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(FileTable);
