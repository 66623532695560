export const ADD_NEW_PATH_TO_LOCATION_ARRAY = 'ADD_NEW_PATH_TO_LOCATION_ARRAY';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const CLEAR_LOCATION_ARRAY = 'CLEAR_LOCATION_ARRAY';
export const UPDATE_LOCATION_ARRAY = 'UPDATE_LOCATION_ARRAY';
export const UPDATE_SCREEN_NAME = 'UPDATE_SCREEN_NAME';

export const addNewPathToBreadCrumbLocationArray = (location) => ({
	type: ADD_NEW_PATH_TO_LOCATION_ARRAY,
	location,
});

export const updateBreadCrumbLocationArray = (key, newArray) => ({
	type: UPDATE_LOCATION_ARRAY,
	key,
	newArray,
});

export const clearBreadCrumbLocationArray = (_) => ({
	type: CLEAR_LOCATION_ARRAY,
});

export const updateScreenName = (name) => ({
	type: UPDATE_SCREEN_NAME,
	name,
});
