import React, { PureComponent } from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { withStyles } from '@material-ui/core/styles';

import playIcon from '../../assets/images/play.svg';
import stopIcon from '../../assets/images/stop.svg';
import ConfirmationDialog from '../../reusable/ConfirmationDialog/ConfirmationDialog';
import StopLiveEventConfirmationDialog from './StopLiveDialog/StopLiveEventConfirmationDialog';
import { createSnapshot, startLiveMedia, stopLiveMedia } from './../../actions/workflowActions';
import { showNotification } from '../../actions/notificationActions';
import {
	setLiveEventStatus,
	setTimelineMarkerPosition,
	setLiveEventStartTime,
	setLiveEventStopTime,
	checkIfLinkExists,
	showLiveLoading,
	hideLiveLoading,
} from '../../actions/liveManagerActions';
import { getCurrentDateTimeInISOFormat, getDifferenceBetweenTwodates } from '../../services/timeStampService';
import {
	getMediaQueryDetails,
	saveInspectScreenDetails,
	createNewRestrictionRule,
	applyRestrictionRuleOnMedia,
} from '../../actions/publishActions';
import { liveEventStatusList } from '../../constants/liveEventStatus';
import { v3WorkflowTypes, workflowTypes } from './../../constants/workflowTypes';
import { odSelectionList } from './../../constants/liveManagerConstants';
import { generateUUID } from '../../utils/commonUtil';
import { FLOWS, mediaNames, messageTypes } from '../../constants/mediaConstants';
import { getDetails } from '../../services/mediaDisplayService';
import { getPostCoverResourceId } from '../../services/componentReusableService';
import { generateRestrictionId } from '../../services/stringHelperService';
import { isRequestFail } from '../../services/publishScreenHelperService';
import { showMessage } from '../../actions/globalActions';

import './LiveManagerTopPanel.scss';

const styles = () => ({
	disabled: {
		opacity: '0.5',
	},
});

class LiveManagerTopPanel extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showConfirmationDialog: false,
			showStopLiveConfirmationDialog: false,
			confirmationDialogTitle: '',
			confirmationDialogMessage: '',
			liveEventStartTime: '',
			liveEventStopTime: '',
			selectedTimelineItem: '',
			streamCheck: false,
		};
		this.maxCountPlaylist = 10;
		this.retryCount = 0;
	}

	componentDidMount = () => {
		window.addEventListener('beforeunload', this.handleBeforeWindowUnload);

		if (this.props.onliveManagerTopPanelLoaded) {
			this.props.onliveManagerTopPanelLoaded(this);
		}
		this.updateLiveEventStatus();
	};

	componentDidUpdate(prevProps) {
		const { timelineItems, selectedItem } = this.props;
		if (selectedItem !== prevProps.selectedItem) {
			let item = '';
			item = _.find(timelineItems ? timelineItems : this.props.timelineItems, (item) => {
				return item.id === selectedItem;
			});
			if (item) {
				this.setState({
					selectedTimelineItem: item,
				});
			}
		}
	}

	componentWillUnmount = () => {
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
		window.removeEventListener('beforeunload', this.handleBeforeWindowUnload);
	};

	handleBeforeWindowUnload = (event) => {
		if (this.props.liveLoading) {
			event.preventDefault();
			var confirmationMessage = 'prompt-before-unload';

			(event || window.event).returnValue = confirmationMessage;
			return confirmationMessage;
		}
	};

	onPlayerCreated = () => {};

	updateLiveEventStatus = () => {
		const { mediaDetails } = this.props;

		if (
			mediaDetails.custom &&
			mediaDetails.custom.livemanager &&
			mediaDetails.custom.livemanager.start &&
			mediaDetails.custom.livemanager.stop
		) {
			this.setState(
				{
					liveEventStopTime: mediaDetails.custom.livemanager.stop,
					liveEventStartTime: mediaDetails.custom.livemanager.start,
				},
				() => {
					this.props.setLiveEventStartTime(mediaDetails.custom.livemanager.start);
					this.props.setLiveEventStopTime(mediaDetails.custom.livemanager.stop);
					this.props.setLiveEventStatus(liveEventStatusList.STOPPED);
				}
			);
		}
		this.showStartStatusOnPlaylist(mediaDetails);
	};

	showStartStatusOnPlaylist = (mediaDetails) => {
		if (
			mediaDetails.custom &&
			mediaDetails.custom.livemanager &&
			mediaDetails.custom.livemanager.start &&
			!mediaDetails.custom.livemanager.stop
		) {
			let playlistFilePath = this.getPlaylistFilePath(mediaDetails);
			if (playlistFilePath) {
				this.props.showLiveLoading();
				this.retryCount = 0;
				this.checkIfLiveMediaIsCreated(playlistFilePath, mediaDetails.custom.livemanager.start);
			} else {
				this.props.hideLiveLoading();
				this.props.showMessage(this.props.t('STREAM_LINK_NOT_FOUND'), messageTypes.error);
			}
			// this.setLiveEventStatusStart(mediaDetails.custom.livemanager.start)
		}
	};

	setLiveEventStatusStart = (time) => {
		this.setState(
			{
				liveEventStartTime: time,
			},
			() => {
				this.props.setLiveEventStartTime(time);
				this.props.setLiveEventStatus(liveEventStatusList.STARTED);
				this.props.hideLiveLoading();
			}
		);
	};

	setLiveEventStatusStop = (time, callback) => {
		this.setState(
			{
				liveEventStopTime: time,
			},
			() => {
				if (callback) {
					callback();
				}
				this.props.setLiveEventStopTime(time);
			}
		);
	};

	handleStartClick = () => {
		const { t } = this.props;
		this.setState(() => ({
			showConfirmationDialog: true,
			confirmationDialogTitle: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_START_LIVE_EVENT'),
			confirmationDialogMessage: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_MSG_TO_START_LIVE'),
		}));
	};

	handleDialogCallback = (e) => {
		this.setState(() => ({ showConfirmationDialog: false }));
		if (e.ok) {
			this._startLiveVideo();
		}
	};

	handleStopLiveDialogCallback = (e) => {
		this.setState(() => ({ showStopLiveConfirmationDialog: false }));
		if (e.ok && e.selectedSnapShotForOd) {
			this._stopLiveMedia(e.selectedSnapShotForOd);
		}
	};

	_startLiveVideo = () => {
		const { mediaId, defaultAccountId, t } = this.props;
		let body = {
			workflow: {
				id: workflowTypes.START_LIVE_EVENT.ID,
				scope: workflowTypes.START_LIVE_EVENT.SCOPE,
			},
			input: {
				mediaId: this.props.mediaId,
			},
		};
		this.props.showLiveLoading();
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((mediaDetails) => {
			if (
				mediaDetails.custom &&
				mediaDetails.custom.livemanager &&
				mediaDetails.custom.livemanager.start &&
				!mediaDetails.custom.livemanager.stop
			) {
				this.showStartStatusOnPlaylist(mediaDetails);
				return;
			}
			this.props.showMessage(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_MSG_LIVE_MEDIA_CREATING'),
				messageTypes.info
			);
			this.props.startLiveMedia(this.props.defaultAccountId, body).then((data) => {
				if ((data && data.Message) || (data && data.message)) {
					this.props.showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_START_LIVE'),
						messageTypes.error
					);
					this.props.hideLiveLoading();
					return;
				} else {
					let timeOfStart = getCurrentDateTimeInISOFormat();
					this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
						if (media && media.custom && media.custom.livemanager && media.custom.livemanager.start) {
							timeOfStart = media.custom.livemanager.start;
							let playlistFilePath = this.getPlaylistFilePath(media);
							if (playlistFilePath) {
								this.retryCount = 0;
								this.checkIfLiveMediaIsCreated(playlistFilePath, timeOfStart);
							} else {
								this.props.hideLiveLoading();
								this.props.showMessage(
									t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_STREAM_LINK_NOT_FOUND'),
									messageTypes.error
								);
							}
						} else {
							this.props.hideLiveLoading();
						}
					}); //getMediaDetails
				} //else
			}); //start live
		}); //getMediadetails to check start time
	}; //start live start function

	checkIfLiveMediaIsCreated = (playlistFilePath, timeOfStart) => {
		const { t } = this.props;
		this.setState({
			streamCheck: true,
		});
		this.props.checkIfLinkExists(playlistFilePath).then((res) => {
			if (res) {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_LIVE_EVENT_STARTED'),
					messageTypes.info
				);
				this.setLiveEventStatusStart(timeOfStart);
				this.setState({
					streamCheck: false,
				});
			} else {
				setTimeout(() => {
					if (this.retryCount <= this.maxCountPlaylist) {
						this.checkIfLiveMediaIsCreated(playlistFilePath, timeOfStart);
						this.retryCount = this.retryCount + 1;
					} else {
						this.props.hideLiveLoading();
						this.props.showMessage(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_STREAM_LINK_NOT_FOUND'),
							messageTypes.error
						);
						this.setState({
							streamCheck: false,
						});
					}
				}, 500);
			}
		});
	};

	getPlaylistFilePath = (media) => {
		let playlistFile = '';
		let indexObj = _.find(media.asset.resources, (resource) => {
			return resource.type === 'index';
		});
		if (indexObj && indexObj.renditions && indexObj.renditions.length > 0) {
			let renditions = indexObj.renditions;
			for (let i = 0; i < renditions.length; i++) {
				if (renditions[i].links && !playlistFile) {
					for (let j = 0; j < renditions[i].links.length; j++) {
						if (renditions[i].links[j].href && renditions[i].links[j].href.match('playlist.m3u8')) {
							playlistFile = renditions[i].links[j].href;
							break;
						}
					}
				} else {
					break;
				}
			}
		} //indexObj for
		return playlistFile;
	};

	_stopLiveMedia = (selectedSnapShotIdForOd) => {
		const { mediaId, defaultAccountId, t } = this.props;

		let snapShotId = '';
		let fullStreamId = '';
		if (selectedSnapShotIdForOd && selectedSnapShotIdForOd !== odSelectionList.FULLSTREAM) {
			snapShotId = selectedSnapShotIdForOd;
		}

		this.props.showMessage(
			t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_MSG_LIVE_MEDIA_STOPPING'),
			messageTypes.info
		);
		this.props.showLiveLoading();
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (!media) {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_MEDIA_NOT_FOUND_POST_COVER'),
					messageTypes.error
				);
			}

			let mediaDetails = getDetails(media, false);
			const SaveAsOnDemandFromData =
				mediaDetails.custom &&
				mediaDetails.custom.livemanager &&
				mediaDetails.custom.livemanager.SaveAsOnDemand;
			const shouldSaveFullStream =
				(SaveAsOnDemandFromData ?? this.props.SaveAsOnDemand) ||
				(selectedSnapShotIdForOd && selectedSnapShotIdForOd === odSelectionList.FULLSTREAM);

			if (shouldSaveFullStream) {
				fullStreamId = generateUUID();
			}

			let postCoverResourceId = '';
			if (media) {
				postCoverResourceId = getPostCoverResourceId(media);
			}
			let body = {
				workflow: {
					id: workflowTypes.STOP_LIVE_EVENT_WITHOUT_RESTRICTION.ID,
					scope: workflowTypes.STOP_LIVE_EVENT_WITHOUT_RESTRICTION.SCOPE,
				},
				input: {
					mediaId: mediaId,
				},
			};
			if (postCoverResourceId) {
				body.input['coverResourceId'] = postCoverResourceId;
			}
			if (selectedSnapShotIdForOd && selectedSnapShotIdForOd !== odSelectionList.POSTCOVER) {
				body.input['snapshotMediaId'] =
					selectedSnapShotIdForOd === odSelectionList.FULLSTREAM ? fullStreamId : snapShotId;
			}

			this.props.stopLiveMedia(defaultAccountId, body).then((data) => {
				const { t } = this.props;
				if ((data && data.Message) || (data && data.message)) {
					this.props.hideLiveLoading();
					this.props.showMessage(t('FAILED_TO_STOP_LIVE'), messageTypes.error);
				} else {
					this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
						let timeOfStop = getCurrentDateTimeInISOFormat();
						if (media && media.id) {
							let mediaDetails = getDetails(media);
							if (
								mediaDetails &&
								mediaDetails.custom &&
								mediaDetails.custom.livemanager &&
								mediaDetails.custom.livemanager.stop
							) {
								timeOfStop = mediaDetails.custom.livemanager.stop;
							}
						}
						this.setLiveEventStatusStop(timeOfStop, () => {
							if (shouldSaveFullStream) {
								this._saveAsOnDemand({
									accountId: defaultAccountId,
									item: { id: fullStreamId },
									timeOfStop: timeOfStop,
									callback: () => {
										if (
											selectedSnapShotIdForOd &&
											selectedSnapShotIdForOd !== odSelectionList.POSTCOVER
										) {
											this.createRedirectRestrictionRuleForLiveMedia(
												selectedSnapShotIdForOd === odSelectionList.FULLSTREAM
													? fullStreamId
													: snapShotId
											);
										} else {
											this.setStopLiveInfo();
										}
									},
								});
							} else if (
								selectedSnapShotIdForOd &&
								selectedSnapShotIdForOd !== odSelectionList.POSTCOVER
							) {
								this.createRedirectRestrictionRuleForLiveMedia(
									selectedSnapShotIdForOd === odSelectionList.FULLSTREAM ? fullStreamId : snapShotId
								);
							} else {
								this.setStopLiveInfo();
							}
						}); //settinglive event as stop
					}); // //getMediaQueryDetails
				} //else
			}); //stopLiveMEdia
		}); //getMediaQueryDetails
	};

	setStopLiveInfo = () => {
		const { setLiveEventStatus, hideLiveLoading, showMessage, t } = this.props;
		setLiveEventStatus(liveEventStatusList.STOPPED);
		hideLiveLoading();
		showMessage(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_LIVE_EVENT_STOPPED'), messageTypes.info);
	};

	_saveAsOnDemand = ({ accountId, item, extractVod, callback, timeOfStop }) => {
		const { mediaDetails, mediaId, t, liveEventStartTime, qbrickStandardFlows, defaultEmails } = this.props;
		const snapshotEnd = getDifferenceBetweenTwodates(timeOfStop, liveEventStartTime);
		const qbrickStandardFlow = qbrickStandardFlows.find((f) => f.id === FLOWS.qbrickStandard);
		const flowId = qbrickStandardFlow?.id ? { flowId: FLOWS.qbrickStandard } : {};
		const body = {
			workflow: {
				id: qbrickStandardFlow?.id ? v3WorkflowTypes.CREATE_SNAPSHOT.ID : workflowTypes.CREATE_SNAPSHOT.ID,
			},
			input: {
				...flowId,
				mediaId: mediaId,
				snapshotMediaId: item.id,
				snapshotTitle: mediaDetails ? mediaDetails.title : item.content,
				snapshotStart: '00:00:00.000',
				snapshotEnd: snapshotEnd,
				emailRecipients: defaultEmails,
			},
		};

		this.props.createSnapshot(accountId, extractVod, body).then((data) => {
			if ((data && data.Message) || (data && data.message) || !data) {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_SAVE_ON_DEMAND'),
					messageTypes.error
				);
			} else {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_MEDIA_CREATED_AS') +
						' ' +
						body.input.snapshotTitle,
					messageTypes.info
				);
			}
			callback?.();
		});
	};

	createRedirectRestrictionRuleForLiveMedia = (redirectId) => {
		const { mediaId, defaultAccountId, t } = this.props;
		// Create new redirect restriction and apply it on the new media
		const restrictionId = generateRestrictionId();
		const redirectUrl = `https://video.qbrick.com/api/v1/public/accounts/${defaultAccountId}/medias/${redirectId}`;
		const redirectRestrictionBody = {
			name: 'Redirect',
			id: restrictionId,
			rules: [{ type: 'Redirect', url: redirectUrl }],
		};

		this.props.createNewRestrictionRule(defaultAccountId, redirectRestrictionBody).then((data) => {
			if (isRequestFail(this, data)) {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_RESTRICTION_FAILED_CREATE_RESTRICTION_RULE'),
					messageTypes.error
				);
				this.setStopLiveInfo();
				return;
			}

			// Add new redirect restriction into live media
			this.props
				.applyRestrictionRuleOnMedia(defaultAccountId, 'medias', mediaId, { id: restrictionId })
				.then((data) => {
					if (isRequestFail(this, data)) {
						this.props.showMessage(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_RESTRICTION_FAILED_ASSIGN_RESTRICTION_RULE'),
							messageTypes.error
						);
						this.setStopLiveInfo();
						return;
					}

					this.props.showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_RESTRICTION_ASSIGN_RESTRICTION_RULE_SUCCESSFULLY'),
						messageTypes.info
					);
					this.setStopLiveInfo();
				});
		});
	};

	handleStopClick = () => {
		this.setState(() => ({
			showStopLiveConfirmationDialog: true,
		}));
	};

	renderLeftPanelContainer = () => {
		const { classes, liveEventStatus, isStreamPublished, t } = this.props;
		if (liveEventStatus === liveEventStatusList.STOPPED) {
			return null;
		}
		return (
			<div className="leftPanelActionButtons">
				<Button
					className={
						liveEventStatus === liveEventStatusList.STARTED
							? 'defaultActionBtn deleteUserBtn LivePlayerStopButton'
							: 'defaultActionBtn LivePlayerStartButton'
					}
					onClick={
						liveEventStatus === liveEventStatusList.STARTED ? this.handleStopClick : this.handleStartClick
					}
					disabled={liveEventStatus === liveEventStatusList.STOPPED || !isStreamPublished}
					classes={{ disabled: classes.disabled }}
				>
					{liveEventStatus === liveEventStatusList.STARTED ? (
						<>
							<img src={stopIcon} /> {t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_STOP_LIVE')}
						</>
					) : (
						<>
							<img src={playIcon} /> {t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_GO_LIVE')}
						</>
					)}
				</Button>
			</div>
		);
	};

	render() {
		const { liveEventStatus, mediaId, liveLoading, t } = this.props;
		const { confirmationDialogTitle, confirmationDialogMessage } = this.state;

		return (
			<div className="LiveManagerLeftPanelWrapper" id="LiveManagerLeftPanelWrapper">
				<div id="leftPanelActionContainer">{this.renderLeftPanelContainer()}</div>

				{this.state.showConfirmationDialog && (
					<ConfirmationDialog
						openDialog={this.state.showConfirmationDialog}
						title={confirmationDialogTitle}
						message={confirmationDialogMessage}
						callback={this.handleDialogCallback}
					/>
				)}
				{this.state.showStopLiveConfirmationDialog && (
					<StopLiveEventConfirmationDialog
						t={t}
						mediaId={mediaId}
						openDialog={this.state.showStopLiveConfirmationDialog}
						callback={this.handleStopLiveDialogCallback}
					/>
				)}
				{liveLoading && (
					<LoadingOverlay
						className="loadingStreamOverlay"
						active={true}
						spinner={<ScaleLoader color={'#ffffff'} />}
						text={
							liveEventStatus === liveEventStatusList.STARTED
								? t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_MSG_LIVE_MEDIA_STOPPING')
								: this.state.streamCheck && liveEventStatus === liveEventStatusList.INITIAL
								? t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_STREAM_CHECK_MSG')
								: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_MSG_LIVE_MEDIA_CREATING')
						}
					></LoadingOverlay>
				)}
			</div>
		);
	}
}

LiveManagerTopPanel.defaultProps = {};

const mapStateToProps = ({ session, snapshotSettings, liveManager, channel, publish }) => ({
	defaultAccountId: session.defaultAccountId,
	snapshotPlayStarted: snapshotSettings.snapshotPlayStarted,
	defaultDate: liveManager.defaultDate,
	duration: liveManager.duration,
	liveStreamPositionTime: liveManager.liveStreamPositionTime,
	liveEventStatus: liveManager.liveEventStatus,
	SaveAsOnDemand: liveManager.SaveAsOnDemand,
	timelineMarkerPosition: liveManager.timelineMarkerPosition,
	channels: channel.channels,
	timelineItems: liveManager.items,
	isPlayerAtLivePosition: liveManager.isPlayerAtLivePosition,
	selectedItem: liveManager.selectedItem,
	liveLoading: liveManager.liveLoading,
	liveEventStartTime: liveManager.liveEventStartTime,
	qbrickStandardFlows: publish.qbrickStandardFlows,
});

const mapDispatchToProps = (dispatch) => ({
	startLiveMedia: (accountId, body) => dispatch(startLiveMedia(accountId, body)),
	stopLiveMedia: (accountId, body) => dispatch(stopLiveMedia(accountId, body)),
	createSnapshot: (accountId, mediaId, inpoint, outpoint, extractVod) =>
		dispatch(createSnapshot(accountId, mediaId, inpoint, outpoint, extractVod)),
	showNotification: (message) => dispatch(showNotification(message)),
	setLiveEventStatus: (status) => dispatch(setLiveEventStatus(status)),
	setTimelineMarkerPosition: (datetime) => dispatch(setTimelineMarkerPosition(datetime)),
	setLiveEventStartTime: (datetime) => dispatch(setLiveEventStartTime(datetime)),
	setLiveEventStopTime: (datetime) => dispatch(setLiveEventStopTime(datetime)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	saveInspectScreenDetails: (accountId, mediaId, mediaName, body) =>
		dispatch(saveInspectScreenDetails(accountId, mediaId, mediaName, body)),
	checkIfLinkExists: (path) => dispatch(checkIfLinkExists(path)),
	showLiveLoading: () => dispatch(showLiveLoading()),
	hideLiveLoading: () => dispatch(hideLiveLoading()),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	applyRestrictionRuleOnMedia: (accountId, mediaName, mediaId, body) =>
		dispatch(applyRestrictionRuleOnMedia(accountId, mediaName, mediaId, body)),
	createNewRestrictionRule: (accountId, restrictionId, body) =>
		dispatch(createNewRestrictionRule(accountId, restrictionId, body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LiveManagerTopPanel));
