export const socialMediaTypes = {
    facebook: 'Facebook',
    youtube: 'YouTube',
    twitter: 'Twitter',
    linkedln: 'LinkedIn'
};

export const sharedPostType = {
    facebook: 'FacebookPage',
    youtube: 'YouTube',
    twitter: 'Twitter',
    linkedln: 'LinkedIn'
};

export const sharedPostTypeStatus = {
    failed: 'Failed',
    shared: 'Shared',
    deleted: 'Deleted'
};

export const socialMediaPlatformIds = {
    facebook: 'Facebook01',
    linkedIn: 'LinkedIn01',
    youtube: 'YouTube01',
    twitter: 'Twitter01'
};

export const socialMediaPlatforms = {
    facebook: 'facebook',
    linkedIn: 'linkedin',
    youtube: 'youtube',
    twitter: 'twitter'
};