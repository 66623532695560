import * as actions from '../actions/liveChatActions';
import { LocalStorageService } from '../services/localStorageService';
import _ from 'underscore';

const initialState = {
	liveManagerChat: LocalStorageService.getChatSessionDetails()
		? JSON.parse(LocalStorageService.getChatSessionDetails())
		: '',
	replyMessageDetails: '',
	mediaMessages: [],
	blockedMessages: [],
	blockedUsers: [],
	scrollChatViewBottom: '',
	pinedMessageId: '',
	savedChatSettings: '',
};

export const liveChatReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SAVE_CHAT_SETTING: {
			return {
				...state,
				savedChatSettings: action.settings,
			};
		}
		case actions.SCROLL_CHAT_BOTTOM: {
			return {
				...state,
				scrollChatViewBottom: action.id,
			};
		}
		case actions.SEND_BLOCK_MESSAGE: {
			return {
				...state,
				mediaMessages: state.mediaMessages.filter((message) => {
					return message.id !== action.id;
				}),
			};
		}

		case actions.SAVE_BLOCKED_USERS: {
			return {
				...state,
				blockedUsers: action.blockedUsers,
			};
		}
		case actions.SAVE_UNBLOCKED_USERS: {
			return {
				...state,
				blockedUsers: state.blockedUsers.filter((x) => action.unblockedUsers.indexOf(x) < 0),
			};
		}
		case actions.SET_CHAT_SESSION_DETAILS:
			return {
				...state,
				liveManagerChat: action.sessionDetails,
			};
		case actions.SET_REPLY_MESSAGE:
			return {
				...state,
				replyMessageDetails: action.messageDetails,
			};
		case actions.SAVE_MEDIA_MESSAGES: {
			let newMessages = [...state.mediaMessages, ...action.messages];
			newMessages = _.uniq(newMessages, (x) => x.id);
			return {
				...state,
				mediaMessages: newMessages,
			};
		}
		case actions.CLEAR_LIVE_CHAT_DETAILS:
			return {
				...initialState,
			};
		case actions.SET_PINED_MESSAGE_ID:
			return {
				...state,
				pinedMessageId: action.messageId,
			};
		case actions.UNSET_PINED_MESSAGE_ID:
			return {
				...state,
				pinedMessageId: '',
			};
		default:
			return state;
	}
};
