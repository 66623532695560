import { LocalStorageService } from '../services/localStorageService';
import * as MediaService from './../services/mediaService';
import { clearLiveChatDetails } from './liveChatActions';

export const CREATE_TIMELINE_ITEM = 'CREATE_TIMELINE_ITEM';
export const UPDATE_TIMELINE_ITEM = 'UPDATE_TIMELINE_ITEM';
export const REMOVE_TIMELINE_ITEM = 'REMOVE_TIMELINE_ITEM';
export const SET_SELECTED_TIMELINE_ITEM = 'SET_SELECTED_TIMELINE_ITEM';
export const CREATE_TIMELINE_GROUP = 'CREATE_TIMELINE_GROUP';
export const UPDATE_TIMELINE_GROUP = 'UPDATE_TIMELINE_GROUP';
export const REMOVE_TIMELINE_GROUP = 'REMOVE_TIMELINE_GROUP';
export const SET_SELECTED_TIMELINE_GROUP = 'SET_SELECTED_TIMELINE_GROUP';
export const CLEAR_LIVE_MANAGER_STORE = 'CLEAR_LIVE_MANAGER_STORE';
export const TOGGLE_MEDIA_PROPERTIES = 'TOGGLE_MEDIA_PROPERTIES';
export const SET_DURATION_OF_LIVE_MEDIA = 'SET_DURATION_OF_LIVE_MEDIA';
export const SET_LIVESTREAM_POSITION = 'SET_LIVESTREAM_POSITION';
export const SET_LIVE_EVENT_STATUS = 'SET_LIVE_EVENT_STATUS';
export const SET_TIMELINE_MARKER_POSITION = 'SET_TIMELINE_MARKER_POSITION';
export const SET_LIVEPLAYER_POSITION = 'SET_LIVEPLAYER_POSITION';
export const SET_LIVE_EVENT_START_TIME = 'SET_LIVE_EVENT_START_TIME';
export const SET_LIVE_EVENT_STOP_TIME = 'SET_LIVE_EVENT_STOP_TIME';
export const SET_PLAYER_STREAM_AS_LIVE = 'SET_PLAYER_STREAM_AS_LIVE';
export const SET_PLAYER_STREAM_AS_NOT_LIVE = 'SET_PLAYER_STREAM_AS_NOT_LIVE';
export const SET_LIVE_SETTINGS_ID = 'SET_LIVE_SETTINGS_ID';
export const SET_SAVE_ONDEMAND_STATUS = 'SET_SAVE_ONDEMAND_STATUS';
export const SET_LIVE_INTERACT_QUEUE_ITEMS = 'SET_LIVE_INTERACT_QUEUE_ITEMS';
export const SHOW_LIVE_LOADING = 'SHOW_LIVE_LOADING';
export const HIDE_LIVE_LOADING = 'HIDE_LIVE_LOADING';
export const UPDATE_LIVE_CHANNEL = 'UPDATE_LIVE_CHANNEL';

export const getAccountId = (getState) => {
	if (getState) {
		return (
			getState().session.defaultAccountId ||
			LocalStorageService.getDefaultAccountId()
		);
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

export const checkIfLinkExists = (path) => () => {
	return MediaService.fetchLink(path)
		.then((data) => {
			return data;
		})
		.catch(() => {
			return false;
		});
};

export const updateLiveChannel = (liveChannel) => ({
	type: UPDATE_LIVE_CHANNEL,
	liveChannel,
});

export const createTimelineItem = (item) => ({
	type: CREATE_TIMELINE_ITEM,
	item,
});

export const updateTimelineItem = (item) => ({
	type: UPDATE_TIMELINE_ITEM,
	item,
});

export const removeTimelineItem = (item) => ({
	type: REMOVE_TIMELINE_ITEM,
	item,
});

export const setSelectedTimelineItem = (item) => ({
	type: SET_SELECTED_TIMELINE_ITEM,
	item,
});

export const createTimelineGroup = (group) => ({
	type: CREATE_TIMELINE_GROUP,
	group,
});

export const updateTimelineGroup = (group) => ({
	type: UPDATE_TIMELINE_GROUP,
	group,
});

export const removeTimelineGroup = (group) => ({
	type: REMOVE_TIMELINE_GROUP,
	group,
});

export const setSelectedTimelineGroup = (group) => ({
	type: SET_SELECTED_TIMELINE_GROUP,
	group,
});

export const setTimelineMarkerPosition = (markerPosition) => ({
	type: SET_TIMELINE_MARKER_POSITION,
	markerPosition,
});

export const clearLiveManagerScreenStore = (_) => ({
	type: CLEAR_LIVE_MANAGER_STORE,
});

export const clearLiveManagerStore = (_) => (dispatch) => {
	dispatch(clearLiveManagerScreenStore());
	dispatch(clearLiveChatDetails());
};

export const toggleMediaProperties = (toggle) => ({
	type: TOGGLE_MEDIA_PROPERTIES,
	toggle,
});

export const setDurationOfLiveMedia = (duration) => ({
	type: SET_DURATION_OF_LIVE_MEDIA,
	duration: duration,
});

export const setLivePlayerPosition = (datetime, startPlaying) => ({
	type: SET_LIVEPLAYER_POSITION,
	datetime: datetime,
	startPlaying: startPlaying,
});

export const setLiveStreamPosition = (datetime) => ({
	type: SET_LIVESTREAM_POSITION,
	datetime: datetime,
});

export const setLiveEventStatus = (status) => ({
	type: SET_LIVE_EVENT_STATUS,
	status: status,
});

export const setLiveEventStartTime = (datetime) => ({
	type: SET_LIVE_EVENT_START_TIME,
	datetime: datetime,
});

export const setLiveEventStopTime = (datetime) => ({
	type: SET_LIVE_EVENT_STOP_TIME,
	datetime: datetime,
});

export const setPlayerStreamAsLive = () => ({
	type: SET_PLAYER_STREAM_AS_LIVE,
});

export const setPlayerStreamAsNotLive = () => ({
	type: SET_PLAYER_STREAM_AS_NOT_LIVE,
});

export const setLiveSettingsId = (id) => ({
	type: SET_LIVE_SETTINGS_ID,
	id: id,
});

export const setSaveAsOnDemandOption = (boolStatus) => ({
	type: SET_SAVE_ONDEMAND_STATUS,
	boolStatus: boolStatus,
});

export const showLiveLoading = () => ({
	type: SHOW_LIVE_LOADING,
});

export const hideLiveLoading = () => ({
	type: HIDE_LIVE_LOADING,
});

export const removeChannelFromLiveMedia =
	(defaultAccountId, assetId, channelId) => (dispatch, getState) => {
		const accountId = defaultAccountId || getAccountId(getState);
		return MediaService._removeChannelFromLiveMedia(
			accountId,
			assetId,
			channelId
		)
			.then((data) => data)
			.catch((error) => error);
	};

export const addChannelToLiveMedia =
	(defaultAccountId, mediaId, assetId, channelId) => (dispatch, getState) => {
		const accountId = defaultAccountId || getAccountId(getState);
		return MediaService._addChannelToLiveMedia(
			accountId,
			mediaId,
			assetId,
			channelId
		)
			.then((data) => {
				if (data) {
					dispatch(updateLiveChannel({ id: channelId }));
				}
				return data;
			})
			.catch((error) => error);
	};
