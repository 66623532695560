import * as actions from '../actions/paginationActions';
import { paginationHandlers } from '../constants/paginationConstant';
import { paginationDataLimitPerScreens } from '../constants/mediaConstants';
const initialState = {
	firstPageFlag: true,
	lastPageFlag: false,
	dataPerPage: paginationDataLimitPerScreens.default_screens,
	totalItemsCount: 0,
	totalCatalogsCount: 0,
	totalAllMediasCount: 0,
	totalPlaylistsCount: 0,
	totalScenariosCount: 0,
	currentOffset: 0,
	currentCatalogOffset: 0,
	currentMediaOffset: 0,
	currentPlaylistOffset: 0,
	catalogOffset: [],
	mediaOffset: [],
	lastBlockDataSetIndex: 0,
	firstBlockDataSetIndex: 0,
	firstPageHandler: true,
	nextPageHandler: true,
	previousPageHandler: true,
	lastPageHandler: true,
	paginatedMedia: {},
	cachedMediaCount: 0,
	lastPaginationAction: paginationHandlers.FIRST,
	displayedMedia: '',
	displayedMediaCountStack: [],
};

export const paginationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SET_FIRST_PAGE_HANDLER:
			return {
				...state,
				firstPageHandler: action.firstPageHandler,
			};
		case actions.SET_NEXT_PAGE_HANDLER:
			return {
				...state,
				nextPageHandler: action.nextPageHandler,
			};
		case actions.SET_PREVIOUS_PAGE_HANDLER:
			return {
				...state,
				previousPageHandler: action.previousPageHandler,
			};
		case actions.SET_LAST_PAGE_HANDLER:
			return {
				...state,
				lastPageHandler: action.lastPageHandler,
			};
		case actions.SET_LAST_BLOCK_DATASET_INDEX:
			return {
				...state,
				lastBlockDataSetIndex: action.lastBlockDataSetIndex,
			};
		case actions.SET_FIRST_BLOCK_DATASET_INDEX:
			return {
				...state,
				firstBlockDataSetIndex: action.firstBlockDataSetIndex,
			};
		case actions.SET_DATA_PER_PAGE:
			return {
				...state,
				dataPerPage: Number(action.dataPerPage),
			};
		case actions.SET_LAST_PAGE_FLAG:
			return {
				...state,
				lastPageFlag: action.lastPageFlag,
			};
		case actions.SET_FIRST_PAGE_FLAG:
			return {
				...state,
				firstPageFlag: action.firstPageFlag,
			};
		case actions.SET_TOTAL_ITEMS_COUNT:
			return {
				...state,
				totalItemsCount: action.totalItemsCount,
			};
		case actions.SET_TOTAL_CATALOGS_COUNT:
			return {
				...state,
				totalCatalogsCount: Number(action.totalCatalogsCount),
			};
		case actions.SET_TOTAL_PLAYLISTS_COUNT:
			return {
				...state,
				totalPlaylistsCount: Number(action.totalPlaylistsCount),
			};
		case actions.SET_TOTAL_ALL_MEDIAS_COUNT:
			return {
				...state,
				totalAllMediasCount: Number(action.totalAllMediasCount),
			};
		case actions.SET_CURRENT_MEDIA_OFFSET:
			return {
				...state,
				currentMediaOffset: Number(action.currentMediaOffset),
			};
		case actions.SET_CURRENT_CATALOG_OFFSET:
			return {
				...state,
				currentCatalogOffset: Number(action.currentCatalogOffset),
			};
		case actions.SET_CURRENT_PLAYLIST_OFFSET: {
			return {
				...state,
				currentPlaylistOffset: Number(action.currentPlaylistOffset),
			};
		}
		case actions.SET_CATALOG_OFFSET:
			return {
				...state,
				catalogOffset: action.catalogOffset,
			};
		case actions.SET_MEDIA_OFFSET:
			return {
				...state,
				mediaOffset: action.mediaOffset,
			};
		case actions.SET_CURRENT_OFFSET:
			return {
				...state,
				currentOffset: Number(action.currentOffset),
			};
		case actions.PUSH_DISPLAYED_MEDIA_COUNT_TO_STACK: {
			let _items = state.displayedMediaCountStack;
			_items.push(action.object);
			return {
				...state,
				displayedMediaCountStack: _items,
			};
		}
		case actions.POP_DISPLAYED_MEDIA_COUNT_FROM_STACK: {
			let _items = state.displayedMediaCountStack;
			_items.pop();
			return {
				...state,
				displayedMediaCountStack: _items,
			};
		}
		case actions.CLEAR_DISPLAYED_MEDIA_COUNT_FROM_STACK: {
			return {
				...state,
				displayedMediaCountStack: [],
			};
		}
		case actions.CLEAR_PAGINATION_STORE:
			return {
				...state,
				firstPageFlag: true,
				lastPageFlag: false,
				dataPerPage: paginationDataLimitPerScreens.default_screens,
				totalItemsCount: 0,
				totalCatalogsCount: 0,
				totalAllMediasCount: 0,
				currentOffset: 0,
				currentCatalogOffset: 0,
				currentMediaOffset: 0,
				catalogOffset: [],
				mediaOffset: [],
				lastBlockDataSetIndex: 0,
				firstBlockDataSetIndex: 0,
				firstPageHandler: true,
				nextPageHandler: true,
				previousPageHandler: true,
				lastPageHandler: true,
				lastPaginationAction: paginationHandlers.FIRST,
				displayedMedia: '',
				displayedMediaCountStack: [],
			};

		case actions.SUCCESS_CACHE_PAGINATED_DATA:
			return {
				...state,
				paginatedMedia: action.data,
				loading: false,
			};

		case actions.SUCCESS_CACHE_PAGE_COUNT_DATA:
			return {
				...state,
				cachedMediaCount: action.data,
				loading: false,
			};
		case actions.SET_LAST_PAGINATION_ACTION:
			return {
				...state,
				lastPaginationAction: action.action,
			};
		case actions.SET_DISPLAYED_MEDIA:
			return {
				...state,
				displayedMedia: action.media,
			};
		case actions.SET_TOTAL_SCENARIOS_COUNT:
			return {
				...state,
				totalScenariosCount: action.count,
			};
		default:
			return state;
	}
};
