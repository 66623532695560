import React, { useCallback, useState } from 'react';
import { Button, FormControl, FormControlLabel, IconButton, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { saveMediaChatSettings, setChatSessionDetails } from '../../../actions/liveChatActions';
import { setOpenChatSettings, setViewEngagementLoading } from '../../../actions/viewEngagementActions';
import { showMessage } from '../../../actions/globalActions';

import { ViewerEngagementTemplate } from './ViewerEngagementTemplate';
import { isChatProfileEmpty } from './utils';

const ViewerEngagementAdminProfileSettings: React.FC<
	ViewerEngagementTemplate.ViewerEngagementAdminProfileSettingsProps
> = ({ mediaId, settings, onSaveSettings }) => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();

	const { defaultAccountId } = useSelector((state: any) => state.session);

	const [adminChatName, setAdminChatName] = useState<string>(
		!settings?.adminProfile?.chatName || isChatProfileEmpty(settings?.adminProfile)
			? ''
			: settings?.adminProfile?.chatName
	);

	const onCloseSettingClick = useCallback(() => {
		dispatch(setOpenChatSettings(false));
	}, []);

	const onAdminChatNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => setAdminChatName(e.target.value),
		[]
	);

	const onSaveMediaChatSettings = useCallback(() => {
		dispatch(setViewEngagementLoading(true));

		const chatSettings = {
			...(settings ?? {}),
			adminProfile: { ...(settings?.adminProfile ?? {}), chatName: adminChatName },
			dataCollection: {
				...(settings?.dataCollection ?? {}),
				requireUsername: false,
			},
		};

		dispatch(saveMediaChatSettings(defaultAccountId, mediaId, chatSettings))
			.then(() => {
				dispatch(setChatSessionDetails(mediaId));
				setTimeout(() => {
					dispatch(setOpenChatSettings(false));
					onSaveSettings?.();
				}, 2000);
			})
			.catch((error: any) => {
				dispatch(
					showMessage(
						`${t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_SETTINGS_CANT_SAVE_MESSAGE')} ${error}`
					)
				);
				dispatch(setOpenChatSettings(false));
				dispatch(setViewEngagementLoading(false));
			});
	}, [dispatch, settings, adminChatName, defaultAccountId, mediaId, onSaveSettings, t]);

	return (
		<div className={`${ViewerEngagementTemplate.baseClassName}-profile-settings__container`}>
			<div className={`${ViewerEngagementTemplate.baseClassName}-settings__header`}>
				<h3>
					{!settings?.adminProfile?.chatName || isChatProfileEmpty(settings?.adminProfile)
						? t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_SETTINGS_ADMIN_PROFILE_LABEL')
						: t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_SETTINGS_ADMIN_PROFILE_EDIT_LABEL')}
				</h3>
				<IconButton onClick={onCloseSettingClick}>
					<Close />
				</IconButton>
			</div>

			<div className={`${ViewerEngagementTemplate.baseClassName}-settings__panel`}>
				<FormControl fullWidth>
					<FormControlLabel
						label={
							<span>
								{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_SETTINGS_ADMIN_NAME_LABEL')}
								<b>*</b>
							</span>
						}
						labelPlacement="top"
						control={
							<TextField
								variant={'outlined'}
								value={adminChatName}
								placeholder={t(
									'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHAT_SETTINGS_ADMIN_NAME_LABEL'
								)}
								onChange={onAdminChatNameChange}
							/>
						}
					/>
				</FormControl>
			</div>

			{/* <div
				className={`${ViewerEngagementTemplate.baseClassName}-settings__header`}
				onClick={() => togglePanel('data')}
			>
				<span>Data Collection</span>
				{expanded.includes('data') ? <ExpandLess /> : <ExpandMore />}
			</div> */}

			{/* {expanded.includes('data') && (
				<div className={`${ViewerEngagementTemplate.baseClassName}-settings__panel`}>
					<div className={`${ViewerEngagementTemplate.baseClassName}-settings__chat-access`}>
						<label>{t('CHAT_SETTINGS_USER_ACCESS_LABEL')}</label>
						<FormControl fullWidth>
							<FormControlLabel
								control={
									<Checkbox
										value={'username'}
										checked={requireUsername}
										onChange={onUserChatAccessChange}
									/>
								}
								label={t('CHAT_SETTINGS_USER_NAME_LABEL')}
							/>
						</FormControl>
					</div>
				</div>
			)} */}

			<div
				className={`${ViewerEngagementTemplate.baseClassName}-settings__panel ${ViewerEngagementTemplate.baseClassName}-settings__flex-panel`}
			>
				<Button
					variant="contained"
					color="primary"
					className={`defaultActionBtn`}
					disabled={!adminChatName || adminChatName === settings?.adminProfile?.chatName}
					onClick={onSaveMediaChatSettings}
				>
					{!settings?.adminProfile?.chatName || isChatProfileEmpty(settings?.adminProfile)
						? t('COMMON_SAVE')
						: t('COMMON_UPDATE')}
				</Button>
			</div>
		</div>
	);
};

export default ViewerEngagementAdminProfileSettings;
