import * as actions from '../actions/scenarioActions';

const initialState = {
	scenes: [],
	scenario: {},
	originScenario: {},
	editingScene: undefined,
	editingSceneDuration: null,
	activeLayout: undefined,
	uploadedMediaId: '',
	sceneLoading: false,
	updateWidgetWaitingDictionary: {},
	updateSceneWaitingDictionary: {},
	timelineChanged: true,
	sceneWithMetadata: [],
	uploadingScenes: [],
	sceneMediaIdsToRefresh: [],
};

export const scenarioReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SET_SAVE_SCENARIO:
			return {
				...state,
				shouldTriggerSave: action.shouldTriggerSave,
			};
		case actions.SET_ORIGIN_SCENARIO:
			return {
				...state,
				originScenario: action.originScenario,
			};
		case actions.CREATE_SCENARIO:
			return {
				...state,
				scenario: action.scenario,
			};
		case actions.UPDATE_SCENARIO:
			return {
				...state,
				scenario: action.scenario,
			};
		case actions.ADD_SCENES_METADATA:
			return {
				...state,
				sceneWithMetadata: action.sceneMetadata,
			};
		case actions.UPDATE_SCENES:
			return {
				...state,
				scenes: action.scenes.filter(Boolean),
			};
		case actions.REFRESH_SCENE_MEDIA:
			return {
				...state,
				sceneMediaIdsToRefresh: action.sceneMediaIdsToRefresh,
			};
		case actions.UPDATE_UPLOADING_SCENES:
			return {
				...state,
				uploadingScenes: action.uploadingScenes.filter(Boolean),
			};
		case actions.UPDATE_EDITING_SCENE: {
			const remainScenes = (state.scenes ?? []).filter(Boolean).filter((s) => s.id !== action.editingScene?.id);
			return {
				...state,
				editingScene: action.editingScene,
				scenes: [...remainScenes, action.editingScene],
			};
		}
		case actions.UPDATE_EDITING_SCENE_DURATION:
			return {
				...state,
				editingSceneDuration: {
					start: action.duration.start ?? state.editingSceneDuration.start,
					end: action.duration.end ?? state.editingSceneDuration.end,
				},
			};
		case actions.UPDATE_SCENARIO_ACTIVE_LAYOUT:
			return {
				...state,
				activeLayout: action.activeLayout,
			};
		case actions.SET_UPLOADED_VIDEO_OF_SCENE_WIDGET:
			return {
				...state,
				uploadedMediaId: action.mediaId,
			};
		case actions.SET_SCENE_LOADING:
			return {
				...state,
				sceneLoading: action.sceneLoading,
			};
		case actions.SET_IS_DATA_CHANGE:
			return {
				...state,
				isDataChanged: action.isDataChanged,
			};
		case actions.SET_IS_TIMELINE_CHANGE:
			return {
				...state,
				timelineChanged: action.timelineChanged,
			};
		case actions.ADD_WIDGET_TO_UPDATING_API_DICS: {
			const widgets = action.payload;

			const widgetsDictionary = widgets.reduce((dictionary, { widgetId, ...rest }) => {
				const stateDictionary = state.updateWidgetWaitingDictionary;

				let widgetToUpdate = {
					widgetId,
					...rest,
				};

				if (stateDictionary[widgetId]) {
					const oldWidget = stateDictionary[widgetId];

					widgetToUpdate = {
						...widgetToUpdate,
						body: {
							...oldWidget.body,
							...widgetToUpdate.body,
						},
					};
				}

				return {
					...dictionary,
					[widgetId]: widgetToUpdate,
				};
			}, []);

			return {
				...state,
				updateWidgetWaitingDictionary: {
					...state.updateWidgetWaitingDictionary,
					...widgetsDictionary,
				},
			};
		}

		case actions.EMPTY_WIDGET_UPDATING_API_DICS: {
			return {
				...state,
				updateWidgetWaitingDictionary: {},
			};
		}

		case actions.ADD_SCENE_TO_UPDATING_API_DICS: {
			const scenes = action.payload;

			const scenesDictionary = scenes.reduce((dictionary, { sceneId, ...rest }) => {
				const stateDictionary = state.updateSceneWaitingDictionary;

				let sceneToUpdate = {
					sceneId,
					...rest,
				};

				if (stateDictionary[sceneId]) {
					const oldScene = stateDictionary[sceneId];

					sceneToUpdate = {
						...sceneToUpdate,
						body: {
							...oldScene.body,
							...sceneToUpdate.body,
						},
					};
				}

				return {
					...dictionary,
					[sceneId]: sceneToUpdate,
				};
			}, []);

			return {
				...state,
				updateSceneWaitingDictionary: {
					...state.updateSceneWaitingDictionary,
					...scenesDictionary,
				},
			};
		}
		case actions.EMPTY_SCENE_WIDGET_UPDATING_API_DICS: {
			return { ...state, updateSceneWaitingDictionary: {} };
		}
		default:
			return state;
	}
};
