import React from 'react';
import Technology from './components/Technology';

const AnalyticsDeviceTypesScreen = () => {
	return (
		<Technology
			dimension="deviceType"
			titleSec1="ANALYTICS_TITLE_CDN_DEVICE_TYPE"
			titleSec2="ANALYTICS_TITLE_CDN_VOLUME_PER_DEVICE_TYPE"
			column="Device Type"
		/>
	);
};

export default AnalyticsDeviceTypesScreen;
