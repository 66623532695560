import { createApi } from 'unsplash-js';
import { createClient } from 'pexels';
import { mediaTypes } from '../constants/mediaConstants';
import { pexelsApiKey, unsplashAccessKey, unsplashAppName } from '../utils/config';

const unsplashApi = createApi({
	accessKey: unsplashAccessKey as string,
});

const pexcelApi = createClient(pexelsApiKey as string);

const getUnsplashImages = (searchKeyword: string, page: number, perPage: number) => {
	return unsplashApi.search.getPhotos({
		query: searchKeyword || 'Group of people working',
		page,
		perPage,
		color: 'green',
		orientation: 'portrait',
	});
};

const getPexelImages = (searchKeyword: string, page: number, perPage: number) => {
	return pexcelApi.photos.search({
		query: searchKeyword || 'Group of people working',
		per_page: perPage,
		page: page,
	});
};

const getPexelVideos = (searchKeyword: string, page: number, perPage: number) => {
	return pexcelApi.videos.search({
		query: searchKeyword || 'Group of people working',
		per_page: perPage,
		page: page,
	});
};

const standardlizeUnsplashImage = (images: any[]) => {
	return images.map(
		({
			id,
			urls: { thumb, regular },
			description,
			links: { download_location },
			user: {
				name,
				links: { html },
			},
		}) => {
			const fileType = regular.match(/(^(?!.*&fm=)|(?<=&fm=)).*?((?=&)|$)/)?.[0];
			return {
				id,
				thumbnail: thumb,
				download: regular,
				description,
				type: mediaTypes.image,
				fileType: fileType?.replaceAll('jpeg', 'jpg') ?? 'jpg',
				artist: {
					name,
					url: `${html}?utm_source=${unsplashAppName}&utm_medium=referral `,
				},
				trackingUrl: download_location,
			};
		}
	);
};

const standardlizePexelsImage = (images: any[]) => {
	return images.map(({ id, alt, src: { original, medium }, photographer, photographer_url }) => {
		const fileType = original.split('.')?.pop();
		return {
			id,
			thumbnail: medium,
			download: original,
			description: alt,
			type: mediaTypes.image,
			fileType: fileType?.replaceAll('jpeg', 'jpg') ?? 'jpg',
			artist: {
				name: photographer,
				url: photographer_url,
			},
		};
	});
};

const standardlizePexelsVideo = (videos: any[]) => {
	return videos.map(({ id, image, duration, video_pictures, video_files, user }) => {
		const downloadUrl = video_files?.find(({ quality }: any) => quality === 'hd') ?? video_files?.[0];
		return {
			id,
			title: undefined,
			created: undefined,
			duration,
			type: mediaTypes.video,
			hits: undefined,
			thumbnail: image,
			previewImages: video_pictures,
			download: downloadUrl?.link,
			artist: {
				name: user.name,
				url: user.url,
			},
		};
	});
};

const getImages = (searchKeyword: string, page: number, rowsPerPage: number, provider: string) => {
	if (provider === 'Pexels') {
		return new Promise((resolve) => {
			getPexelImages(searchKeyword, page, rowsPerPage).then((response: any) => {
				resolve({ result: standardlizePexelsImage(response.photos), count: response.total_results });
			});
		});
	}

	return new Promise((resolve) => {
		getUnsplashImages(searchKeyword, page, rowsPerPage).then((response: any) => {
			resolve({ result: standardlizeUnsplashImage(response.response.results), count: response.response.total });
		});
	});
};

const getVideos = (searchKeyword: string, page: number, rowsPerPage: number) => {
	return new Promise((resolve) => {
		getPexelVideos(searchKeyword, page, rowsPerPage).then((response: any) => {
			resolve({ result: standardlizePexelsVideo(response.videos), count: response.total_results });
		});
	});
};

export const getStock = (mediaType: string, searchKeyword: string, page: number, rowsPerPage: number, type: string) => {
	if (mediaTypes.image === mediaType) {
		return getImages(searchKeyword, page + 1, rowsPerPage, type);
	}

	return getVideos(searchKeyword, page + 1, rowsPerPage);
};

export const sendDownloadRequestToUnsplash = (downloadLocationUrl: string) => {
	unsplashApi.photos.trackDownload({ downloadLocation: downloadLocationUrl });
};
