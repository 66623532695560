import { FormControl, FormControlLabel, FormHelperText } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import TimeInput from '../../../../../../../../../../../reusable/TimeInput/TimeInput';
import arrow_down from '../../../../../../../../../../../assets/images/arrow-90deg-down.svg';

interface JumpToTimeActionProps {
	metadata?: { time?: string };
	selectedEvent: string;
	handleJumpToTimeMetaDataChange: (_value: string, _selectedEvent: string, _preventSubmit?: boolean) => {};
}

const JumpToTimeAction = ({ metadata, selectedEvent, handleJumpToTimeMetaDataChange }: JumpToTimeActionProps) => {
	const { t: translator } = useTranslation();

	return (
		<>
			<FormControlLabel
				className={'scenario-chart__control'}
				label={<img src={arrow_down} />}
				labelPlacement="start"
				control={
					<FormControl>
						<TimeInput
							value={metadata?.time || '00:00:00:000'}
							onChange={(e: string) => {
								handleJumpToTimeMetaDataChange(e, selectedEvent, true);
							}}
							onSubmit={(e: string) => {
								handleJumpToTimeMetaDataChange(e, selectedEvent);
							}}
						/>
						<FormHelperText>
							{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_ENTER_TIME_HELPER_TEXT')}
						</FormHelperText>
					</FormControl>
				}
			/>
		</>
	);
};

export default JumpToTimeAction;
