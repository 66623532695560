import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { showNotification } from '../../../actions/notificationActions';
import { liveEventStatusList } from '../../../constants/liveEventStatus';
import {
	addChannelToLiveMedia,
	removeChannelFromLiveMedia,
	setSaveAsOnDemandOption,
} from '../../../actions/liveManagerActions';
import { loadChannels } from '../../../actions/channelActions';
import { showMessage } from '../../../actions/globalActions';
import LiveManagerSettingsList from './../LiveManagerSettingsList';
import { IconsList } from '../../../reusable/IconsStore/IconsList';
import LiveCoversUpload from './LiveCoversUpload';
import { streamSettingsId } from '../../../constants/liveManagerConstants';
import { imageRelTagList, mediaNames, messageTypes } from '../../../constants/mediaConstants';
import { getMediaQueryDetails, saveInspectScreenDetails } from '../../../actions/publishActions';
import { getDetails } from '../../../services/mediaDisplayService';
import { isRequestFail } from '../../../services/inspectScreenHelperService';

import './LiveManagerStreamSettings.scss';

const styles = () => ({
	colorSwitchBase: {
		color: '#565E74',
		'&$colorChecked': {
			color: '#A2E2A7',
			'& + $colorBar': {
				backgroundColor: '#A2E2A7',
			},
		},
	},
});

class LiveManagerStreamSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedMediaItems: [],
			SaveAsOnDemand: this.props.SaveAsOnDemandFromReducer,
			disabledGeneralSetting: true,
		};

		this.settingsOptionList = [
			{
				id: streamSettingsId.GENERAL,
				title: this.props.t('COMMON_GENERAL_SETTINGS'),
				iconName: IconsList.SETTINGS,
			},
			{
				id: streamSettingsId.THUMBNAIL,
				title: this.props.t('COMMON_THUMBNAIL'),
				iconName: IconsList.IMAGE,
			},
			{
				id: imageRelTagList.PRECOVER,
				title: this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_PRE_COVER'),
				iconName: IconsList.IMAGE,
			},
			{
				id: imageRelTagList.POSTCOVER,
				title: this.props.t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_POST_COVER'),
				iconName: IconsList.IMAGE,
			},
		];
	}

	componentDidUpdate(prevProps) {
		if (prevProps.SaveAsOnDemand !== this.props.SaveAsOnDemand) {
			this.setState({ SaveAsOnDemand: this.props.SaveAsOnDemand });
		}
	}

	componentDidMount() {
		const { defaultAccountId, mediaId } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			let body = getDetails(media, false);
			let SaveAsOnDemand = this.props.SaveAsOnDemand;

			if (
				body &&
				body.hasOwnProperty('custom') &&
				body.custom &&
				body.custom.hasOwnProperty('livemanager') &&
				body.custom.livemanager &&
				body.custom.livemanager.hasOwnProperty('SaveAsOnDemand')
			) {
				SaveAsOnDemand = body.custom.livemanager.SaveAsOnDemand;
			}

			if (SaveAsOnDemand !== this.state.SaveAsOnDemand) {
				this.setState({ SaveAsOnDemand: SaveAsOnDemand, disabledGeneralSetting: false });
			}
		});
	}

	handleChangeSaveAsOnDemand = () => {
		const { defaultAccountId, mediaId, showNotification, t } = this.props;
		const { SaveAsOnDemand } = this.state;

		this.setState({ disabledGeneralSetting: true }, () => {
			this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
				let mediaDetail = getDetails(media, false);
				if (mediaDetail.hasOwnProperty('custom') && mediaDetail.custom) {
					if (mediaDetail.custom.hasOwnProperty('livemanager') && mediaDetail.custom.livemanager) {
						mediaDetail.custom.livemanager = {
							...(mediaDetail.custom?.livemanager ?? {}),
							SaveAsOnDemand: !SaveAsOnDemand,
						};
					} else {
						mediaDetail.custom = {
							...(mediaDetail.custom ?? {}),
							livemanager: { SaveAsOnDemand: !SaveAsOnDemand },
						};
					}
				} else {
					mediaDetail = {
						...mediaDetail,
						custom: { livemanager: { SaveAsOnDemand: !SaveAsOnDemand } },
					};
				}

				const body = {
					id: mediaDetail.id,
					asset: {
						id: mediaDetail.asset.id,
					},
					catalog: mediaDetail.catalog
						? {
								id: mediaDetail.catalog.id,
						  }
						: undefined,
					metadata: mediaDetail.metadata,
					tags: mediaDetail.tags,
					rel: mediaDetail.rel,
					custom: mediaDetail.custom,
				};

				this.props.saveInspectScreenDetails(defaultAccountId, mediaId, mediaNames.medias, body).then((data) => {
					if (isRequestFail(this, data)) {
						showNotification(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_SAVE_MEDIA_DETAILS')
						);
						this.props.setSaveAsOnDemandOption(SaveAsOnDemand);
						this.setState({ SaveAsOnDemand: SaveAsOnDemand, disabledGeneralSetting: false });
						return;
					}
					this.props.setSaveAsOnDemandOption(!SaveAsOnDemand);
					this.setState({ SaveAsOnDemand: !SaveAsOnDemand, disabledGeneralSetting: false });
				});
			});
		});
	};

	handleSelectChannelChange = (event) => {
		const {
			removeChannelFromLiveMedia,
			defaultAccountId,
			mediaOriginalDetails,
			liveChannel,
			showMessage,
			t,
			liveEventStatus,
			channels,
		} = this.props;
		if (liveEventStatus !== liveEventStatusList.INITIAL) {
			return;
		}

		if ((channels ?? []).filter((channel) => channel.id === event.target.value).length === 0) {
			return;
		}

		const channelId = event.target.value;
		const assetId = mediaOriginalDetails.asset?.id;

		this.setState({ disabledGeneralSetting: true }, () => {
			if (liveChannel) {
				removeChannelFromLiveMedia(defaultAccountId, assetId, liveChannel.id)
					.then((data) => {
						if (!data) {
							showMessage(
								t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHANGE_LIVE_CHANNEL_FAIL'),
								messageTypes.error
							);
							this.setState({ disabledGeneralSetting: false });
							return;
						}
						this.addChannelToLiveMedia(channelId);
					})
					.catch(() => {
						this.setState({ disabledGeneralSetting: false });
						showMessage(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHANGE_LIVE_CHANNEL_FAIL'),
							messageTypes.error
						);
					});
			} else {
				this.addChannelToLiveMedia(channelId);
			}
		});
	};

	addChannelToLiveMedia = (channelId) => {
		const { addChannelToLiveMedia, defaultAccountId, mediaOriginalDetails, showMessage, t } = this.props;
		const assetId = mediaOriginalDetails.asset?.id;

		addChannelToLiveMedia(defaultAccountId, mediaOriginalDetails.id, assetId, channelId)
			.then((data) => {
				if (!data) {
					showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHANGE_LIVE_CHANNEL_FAIL'),
						messageTypes.error
					);
					this.setState({ disabledGeneralSetting: false });
					return;
				}
				showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHANGE_LIVE_CHANNEL_SUCCESS'),
					messageTypes.success
				);
				this.setState({ disabledGeneralSetting: false });
			})
			.catch(() => {
				this.setState({ disabledGeneralSetting: false });
				showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_CHANGE_LIVE_CHANNEL_FAIL'),
					messageTypes.error
				);
			});
	};

	renderGeneralSettings = () => {
		const { liveEventStatus, t, liveChannel, channels } = this.props;
		const { SaveAsOnDemand, disabledGeneralSetting } = this.state;
		const channelsToRender = (channels ?? []).filter(({ id }) => !id.includes('legacy'));

		return (
			<>
				<div className={'generalSubField'}>
					<label className="subFieldTitle">
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_SAVE_AS_ON_DEMAND')}
					</label>
					<div className="subFieldDesc switchOption">
						<label className="switchButtonLabel">
							{SaveAsOnDemand ? t('COMMON_YES').toLowerCase() : t('COMMON_NO').toLowerCase()}{' '}
						</label>
						<div
							className={SaveAsOnDemand ? 'switchButton checked' : 'switchButton unchecked'}
							attr={
								liveEventStatus === liveEventStatusList.STOPPED || disabledGeneralSetting
									? 'disabled'
									: ''
							}
							onClick={this.handleChangeSaveAsOnDemand}
						/>
					</div>
				</div>
				<div className={'generalSubField'}>
					<label className="subFieldTitle" htmlFor="live-channel-select">
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_SELECT_LIVE_CHANNEL')}
					</label>
					<div className="subFieldDesc subFieldSelect">
						<select
							id="live-channel-select"
							onChange={this.handleSelectChannelChange}
							disabled={
								liveEventStatus !== liveEventStatusList.INITIAL ||
								disabledGeneralSetting ||
								!channelsToRender ||
								channelsToRender.length === 0
							}
						>
							{(!channelsToRender || channelsToRender.length === 0) && (
								<option value={'0'}>-Select-</option>
							)}
							{channelsToRender &&
								channelsToRender.length > 0 &&
								[...channelsToRender].map((channel) => (
									<option
										selected={liveChannel?.id === channel.id}
										key={channel.id}
										value={channel.id}
									>
										{channel.metadata && channel.metadata.title
											? channel.metadata.title
											: channel.id}
									</option>
								))}
						</select>
					</div>
				</div>
			</>
		);
	};

	renderUploadButton = (coverName) => {
		const { mediaId, t, isVisible } = this.props;
		return <LiveCoversUpload mediaId={mediaId} coverName={coverName} t={t} isVisible={isVisible} />;
	};

	renderThumbnailSettings = () => {
		return (
			<div className="generalSubField">
				<div className="uploadImageContainer">{this.renderUploadButton(imageRelTagList.THUMBNAIL)}</div>
			</div>
		);
	};

	renderPrecoverSettings = () => {
		return (
			<div className="generalSubField">
				<div className="uploadImageContainer">{this.renderUploadButton(imageRelTagList.PRECOVER)}</div>
			</div>
		);
	};

	renderPostcoverSettings = () => {
		return (
			<div className="generalSubField">
				<div className="uploadImageContainer">{this.renderUploadButton(imageRelTagList.POSTCOVER)}</div>
			</div>
		);
	};

	renderList = (item) => {
		switch (item.id) {
			case streamSettingsId.GENERAL:
				return this.renderGeneralSettings();
			case streamSettingsId.THUMBNAIL:
				return this.renderThumbnailSettings();
			case streamSettingsId.PRECOVER:
				return this.renderPrecoverSettings();
			case streamSettingsId.POSTCOVER:
				return this.renderPostcoverSettings();
			default:
				return null;
		}
	};

	render() {
		return (
			<div className="LiveManagerStreamSettingsWrapper">
				{this.settingsOptionList.map((item, index) => (
					<LiveManagerSettingsList title={item.title} iconName={item.iconName} key={index}>
						{this.renderList(item)}
					</LiveManagerSettingsList>
				))}
			</div>
		);
	}
}

const mapStateToProps = ({ session, notification, liveManager, inspectScreenReducer, channel }) => ({
	defaultAccountId: session.defaultAccountId,
	message: notification.message,
	open: notification.showNotification,
	liveEventStatus: liveManager.liveEventStatus,
	mediaOriginalDetails: inspectScreenReducer.mediaOriginalDetails,
	SaveAsOnDemand: liveManager.SaveAsOnDemand,
	liveChannel: liveManager.liveChannel,
	channels: channel.channels,
});

const mapDispatchToProps = (dispatch) => ({
	setSaveAsOnDemandOption: (boolStatus) => dispatch(setSaveAsOnDemandOption(boolStatus)),
	showNotification: (message) => dispatch(showNotification(message)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	saveInspectScreenDetails: (accountId, mediaId, mediaName, body) =>
		dispatch(saveInspectScreenDetails(accountId, mediaId, mediaName, body)),
	loadChannels: (accountId) => dispatch(loadChannels(accountId)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
	removeChannelFromLiveMedia: (accountId, assetId, channelId) =>
		dispatch(removeChannelFromLiveMedia(accountId, assetId, channelId)),
	addChannelToLiveMedia: (accountId, mediaId, assetId, channelId) =>
		dispatch(addChannelToLiveMedia(accountId, mediaId, assetId, channelId)),
});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation()
)(withStyles(styles)(LiveManagerStreamSettings));
