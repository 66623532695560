import BackendClient from './backendClient';

import { readAccountApiEndpointV2, writeAccountApiEndpointV2 } from '../utils/config';

export const validateToken = (accessToken) =>
	BackendClient.get(
		`${readAccountApiEndpointV2}accessToken/validate/${accessToken}?serviceId=USER_ACCOUNTS&accessLevel=2`,
		true
	);

export const loadUserInfo = (userId) => BackendClient.get(`${readAccountApiEndpointV2}@me?id=${userId}`);

export const loadUserAccounts = (userId) =>
	BackendClient.get(`${readAccountApiEndpointV2}@me/accounts/?userId=${userId}`);

export const loadUserPermissions = (accountId) =>
	BackendClient.get(`${readAccountApiEndpointV2}@me/accounts/${accountId}/features`, false);

export const resetPassword = (userID) =>
	BackendClient.post(`${writeAccountApiEndpointV2}@me/resetPassword`, { Login: userID }, false);

export const changePassword = (oldPassword, newPassword) =>
	BackendClient.post(
		`${writeAccountApiEndpointV2}@me/changePassword`,
		{
			OldPassword: oldPassword,
			NewPassword: newPassword,
		},
		false
	);

export const listAllAccountsPerCustomers = (customerId) =>
	BackendClient.get(`${readAccountApiEndpointV2}customers/${customerId}/accounts`);

export const listAllUsersPerAccounts = (accountId) =>
	BackendClient.get(`${readAccountApiEndpointV2}accounts/${accountId}/users`);

export const viewUserDetails = (accountId, userId) =>
	BackendClient.get(`${readAccountApiEndpointV2}accounts/${accountId}/users/${userId}/accounts`);

export const deleteUser = (accountId, userId) =>
	BackendClient.delete(`${writeAccountApiEndpointV2}accounts/${accountId}/users/${userId}`);

export const createUser = (email, ip) =>
	BackendClient.post(
		`${writeAccountApiEndpointV2}users?allowExisting=true`,
		{
			ipRestrictions: ip,
			login: email,
		},
		false
	);

export const updateUser = (userId, data) =>
	BackendClient.put(`${writeAccountApiEndpointV2}users/${userId}`, data, false, false);

export const addUserToAccount = (accountId, data) =>
	BackendClient.post(`${writeAccountApiEndpointV2}accounts/${accountId}/users`, data, false, false);

export const revokeToken = (accessToken) =>
	BackendClient.get(`${readAccountApiEndpointV2}accessToken/revoke/${accessToken}`, true);

export const getCustomMetadata = (accountId) =>
	BackendClient.get(`${readAccountApiEndpointV2}accounts/${accountId}/customMetadata`);

export const removeCustomMetadata = (accountId, nameInApi) =>
	BackendClient.delete(`${writeAccountApiEndpointV2}accounts/${accountId}/customMetadata/${nameInApi}`);

export const updateCustomMetadata = (accountId, data) =>
	BackendClient.put(`${writeAccountApiEndpointV2}accounts/${accountId}/customMetadata`, data, false, false);

export const createCustomMetadata = (accountId, data) =>
	BackendClient.post(`${writeAccountApiEndpointV2}accounts/${accountId}/customMetadata`, data, false, false);

export const _createAccountSettings = (accountId, data) =>
	BackendClient.post(`${readAccountApiEndpointV2}accounts/${accountId}/settings`, data, false, false);

export const _getAccountSettings = (accountId) =>
	BackendClient.get(`${readAccountApiEndpointV2}accounts/${accountId}/settings`);

export const _updateAccountSettings = (accountId, data) =>
	BackendClient.put(`${writeAccountApiEndpointV2}accounts/${accountId}/settings`, data, false, false);
