import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { showNotification } from './../../../../../actions/notificationActions';
import { PlayerBaseUrl } from '../../../../../utils/config';

import './PlayerIframe.scss';
import { setRefreshMediasIds } from '../../../../../actions/publishActions';

class PlayerIframe extends Component {
	state = {
		link: '',
	};

	componentDidMount() {
		this.setLink();
	}

	componentDidUpdate(prevProps) {
		const { playerConfigurationId, refreshMediasIds, mediaDetails, setRefreshMediasIds } = this.props;
		if (playerConfigurationId !== prevProps.playerConfigurationId) {
			this.setLink();
		}

		const shouldReinitPlayer =
			refreshMediasIds.find((r) => r === mediaDetails?.id) &&
			((mediaDetails?.restrictions ?? []).length > 0 || (prevProps.mediaDetails?.restrictions ?? []).length > 0);
		if (shouldReinitPlayer) {
			const newRefreshMediasIds = refreshMediasIds.filter((s) => s !== mediaDetails?.id);
			setRefreshMediasIds(newRefreshMediasIds);
			this.setLink(Math.random());
		}
	}

	setLink = (triggerPoint) => {
		const { defaultAccountId, mediaDetails, playerConfigurationId } = this.props;
		let link =
			playerConfigurationId === 'shoppingcompanion' || playerConfigurationId.includes('-sc')
				? `https://play2.qbrick.com/shopping-companion/preview.html?accountid=${defaultAccountId}&configid=${playerConfigurationId}&mediaid=${mediaDetails.id}&autoplay=false&ignoreAnalytics=true`
				: `${PlayerBaseUrl}play2/embed/qbrick-player?accountId=${defaultAccountId}&mediaId=${mediaDetails.id}&configId=${playerConfigurationId}&pageStyling=adaptive&autoplay=false&ignoreAnalytics=true&triggerPoint=${triggerPoint}`;

		this.setState({
			link: link,
		});
	};

	render() {
		const { link } = this.state;
		return (
			<>
				<div id="playerIframeWrapper">
					<iframe
						id="playerIframe"
						src={link}
						className="shoppingPlayer"
						allowFullScreen={true}
						frameBorder="0"
						border="0"
					/>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, publish }) => ({
	defaultAccountId: session.defaultAccountId,
	refreshMediasIds: publish.refreshMediasIds,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	setRefreshMediasIds: (ids) => dispatch(setRefreshMediasIds(ids)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(PlayerIframe);
