export const IconsList = {
    TRASHCAN: 'trashcan',
    TRASHCANBORDER: 'trashcanBorder',
    CLOSE: 'close',
    ADD_TO_CATALOG: 'addToCatalog',
    ADD_TO_PLAYLIST: 'addToPlaylist',
    INSPECT: 'inspect',
    COPY: 'copy',
    PLAY: 'play',
    GRAB: 'grab',
    DRAG_ICON: 'drag_icon',
    RIGHT_ARROW: 'rightArrow',
    INTERACT_ELEMENTS: 'interact_elements',
    INTERACT_CUSTOM_IMAGE: 'interact_custom_image',
    INTERACT_JUMP_TO_TIME: 'interact_jump_to_time',
    INTERACT_PRODUCT: 'interact_product',
    INTERACT_BUTTON: 'interact_button',
    INTERACT_CHAPTER: 'interact_chapter',
    INTERACT_CLOSE_DARK_MODE: 'interact_close_dark_mode',
    PEN: 'pen',
    PENCIL: 'pencil',
    SETTINGS: 'settings',
    INTERACT: 'interact',
    UPLOAD: 'upload',
    PREVIEW: 'preview',
    IMAGE: 'image',
    DASHBOARD: 'dashboard',
    MAIN_MENU_ANALYTICS: 'main_menu_analytics',
    BREADCRUMB_FOLDER: 'breadcrumb_folder',
    BREADCRUMB_MENU_FOLDER: 'breadcrumb_menu_folder',
    BREADCRUMB_MENU_MAIN: 'breadcrumb_menu_main',
    BREADCRUMB_MENU_MEDIA: 'breadcrumb_menu_media',
    EDITOR: 'editor',
    LEFT_ARROW: 'left_arrow',
    CARD_IMAGE: 'card_image',
    BOOk: 'book',
    PLUS_SQUARE: 'plus_square',
    REFRESH: 'refresh',
    PLUS_NEW: 'plus_new',
    CHAT_LEFT_DOTS: 'chat_left_dots',
    BROADCASTER_ICON: 'broadcaster_icon',
    BROADCASTER_QR_CODE: 'broadcaster_qr_code',
    BROADCASTER_ABOUT: 'broadcaster_about',
    PEOPLE: 'people',
    DOTS: 'dots',
    EMOJI_SMILE: 'emoji_smile',
    SEND_MESSAGE: 'sendMessage',
    PIN: 'pin',
    X: 'x',
    PLAYLISTS: 'playlists',
    OPEN_IN_NEW_WINDOW: 'open_in_new_window',
    EXPANDED: 'expanded',
    EXPANDED_WHITE: 'expanded_white',
    NOT_EXPANDED: 'not_expanded',
    GRID: 'grid',
    LIST: 'list',
    CHECKBOX: 'checkbox',
    CHECKBOX_FILL: 'checkbox_fill',
    CHECKBOX_WHITE_FILL: 'checkbox_white_fill',
    SMARTFILTER: 'smart_filter',
    SORT: 'sort',
    LIBRARY_SEARCH: 'library_search',
    PLUS_CIRCLE_FILL: 'plus_circle_fill',
    POLL_FILL: 'poll_fill',
    POLL_BLUE: 'poll_blue',
    THREE_DOTS: 'three_dots'
};

export const IconsColor = {
    CLOSEICONLIGHTGREY: '#707070'
};