import * as actions from "../actions/globalActions"
import { messageTypes } from "../constants/mediaConstants"

const initialState = {
    stackMessage: {
        message: '',
        type: messageTypes.info
    },
    stackAlert: {
        message: '',
        type: messageTypes.error
    }
};

export const globalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SHOW_STACK_MESSAGE:
            return {
                ...state,
                stackMessage: {
                    message: action.message,
                    type: action.msg_type,
                    time: Math.random()
                }
            };
        case actions.SHOW_ALERT:
            return {
                ...state,
                stackAlert: {
                    message: action.message,
                    type: action.msg_type ?? messageTypes.error
                }
            };
        default:
            return state
    }
};

