import React from 'react';

import { joinClassNames } from '../../services/elementHelperService';

import './ColorHub.scss';

export type ColorTypes = 'default | inactive | error | info';

interface ColorHubProps {
	component: React.ReactElement<any>;
	/**
	 * @default default
	 */
	color?: ColorTypes;

	htmlColor?: string;
}

const ColorHub = ({ component, color, htmlColor }: ColorHubProps): React.ReactElement<any> =>
	React.isValidElement(component)
		? React.cloneElement(component as React.ReactElement<any>, {
				className: joinClassNames('q-color', color ? `q-color--${color}` : ''),
				style: htmlColor ? { color: htmlColor, fill: htmlColor } : {},
		  })
		: component;

export default ColorHub;
