/* eslint-disable no-use-before-define */
import React from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import { languageSelector } from '../../../../constants/subtitleLanguageSelector';

import './LanguagePool.scss';
import { useTranslation } from 'react-i18next';

interface LanguageType {
	value: string;
	name: string;
}

interface LanguagePoolProps {
	languages?: LanguageType[];
	onLanguagesChange?: (_languages: LanguageType[]) => void;
}

export const Label = ({ children, ...rest }: any) => (
	<label className="language-pool-label" {...rest}>
		{children}
	</label>
);

const InputWrapper = ({ children, ...rest }: any) => (
	<div className="language-pool-input-wrapper" {...rest}>
		{children}
	</div>
);

const TagWrapper = ({ children, ...rest }: any) => (
	<div className="language-pool-tag-wrapper" {...rest}>
		{children}
	</div>
);

const Tag = ({ label, onDelete, ...rest }: any) => (
	<div className="language-pool-tag" {...rest}>
		<span>{label}</span>
		<CloseIcon onClick={onDelete} />
	</div>
);

const Listbox = ({ children, ...rest }: any) => (
	<ul className="language-pool-listbox" {...rest}>
		{children}
	</ul>
);

const LanguagePool = ({ languages = languageSelector, onLanguagesChange }: LanguagePoolProps) => {
	const { t } = useTranslation();
	const {
		getRootProps,
		getInputProps,
		getTagProps,
		getListboxProps,
		getOptionProps,
		groupedOptions,
		value,
		focused,
		setAnchorEl,
	} = useAutocomplete({
		id: 'language-pool',
		defaultValue: [],
		multiple: true,
		options: languages,
		getOptionLabel: (option) => option.name,
		onChange: (_event, value, _reason, _details) => {
			onLanguagesChange?.(value);
		},
	});

	return (
		<div className="language-pool-wrapper">
			<div {...getRootProps()}>
				<p className="sstlanguageSelectorText">{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_TRANSLATE_TO')}</p>
				<InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
					{value.length > 0 && (
						<TagWrapper>
							{value.map((option: LanguageType, index: number) => (
								<Tag key={index} label={option.name} {...getTagProps({ index })} />
							))}
						</TagWrapper>
					)}
					<input
						{...getInputProps()}
						placeholder={t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_S2T_TRANSLATION_LANGUAGE_PLACEHOLDER')}
						disabled={languages.length === value.length || value.length === 5}
					/>
				</InputWrapper>
			</div>
			{groupedOptions.length > 0 ? (
				<Listbox {...getListboxProps()}>
					{groupedOptions.map((option, index) => (
						<li key={index} {...getOptionProps({ option, index })}>
							<span>{option.name}</span>
							<CheckIcon fontSize="small" />
						</li>
					))}
				</Listbox>
			) : null}
		</div>
	);
};

export default LanguagePool;
