import timelineMediaIcon from './../assets/images/video.svg';
import interact_elements from '../assets/images/interact_elements.svg'
import chapter_icon from '../assets/images/chapter.svg'
import { IconsList } from "../reusable/IconsStore/IconsList"
import { LocalStorageService } from "../services/localStorageService"

export const interactActionsId = {
    openUrl: 'openUrl',
    playPause: 'playPause',
    customEvent: 'customEvent',
    jumpToTime: 'jumpToTime',
    loadVideo: 'loadVideo',
    videoToPlaylist: 'videoToPlaylist',
    fadeOut: 'fadeOut'
};

export const interactAnimationsList = [
    {
        name: "No animation",
        value: "fadein-0s"
    },
    {
        name: "Fade in 1s",
        value: "fadein-1s"
    },
    {
        name: "Fade in 2s",
        value: "fadein-2s"
    },
    {
        name: "Fade in 3s",
        value: "fadein-3s"
    }
];

export const interactActionList = [
    {
        name: "Go to url",
        id: interactActionsId.openUrl,
        values: [
            {
                name: "Replace",
                id: "replacePage",
                value: "_self"
            },
            {
                name: "New tab",
                id: "newTab",
                value: "_blank"
            }
        ],
        default: {
            value: "http://www.google.com",
            target: "_blank"
        }
    },
    {
        name: "Jump to time",
        id: interactActionsId.jumpToTime,
        default: {
            value: 0.207,
        }
    },
    {
        name: "Load another video",
        id: interactActionsId.loadVideo,
        default: {
            value: "https://video.qbrick.com/api/v1/public/accounts/" + LocalStorageService.getDefaultAccountId() + "/medias/" + "{mediaId}",
        }
    },
    {
        name: "Add video to playlist",
        id: interactActionsId.videoToPlaylist,
        default: {
            value: "https://video.qbrick.com/api/v1/accounts/" + LocalStorageService.getDefaultAccountId() + "/medias/" + "{mediaId}",
        }
    },
    {
        name: "Play / Pause video",
        id: interactActionsId.playPause,
        values: [
            {
                name: "Play",
                id: "play",
                value: "play"
            },
            {
                name: "Pause",
                id: "pause",
                value: "pause"
            }
        ],
        default: {
            value: "play",
        }
    },
    {
        name: "Custom event",
        id: interactActionsId.customEvent,
        default: {
            value: "some string",
        }
    },
    {
        name: "Fade out animation",
        id: interactActionsId.fadeOut,
        default: {
            value: false,
            itemId: "button"
        }
    },
];

export const interactWidgetsListKeys = {
    jumpToTime: '121212121',
    interactButton: '123456789TEXTBUTTON',
    interactCustomImage: 'dynamicButton12345',
    interactProduct: 'dynamicProduct',
    interactChapter: 'chapter'
};

export const interactWidgetsList = [
    {
        id: interactWidgetsListKeys.jumpToTime,
        img: IconsList.INTERACT_JUMP_TO_TIME
    },
    {
        id: interactWidgetsListKeys.interactButton,
        img: IconsList.INTERACT_BUTTON
    },
    {
        id: interactWidgetsListKeys.interactCustomImage,
        img: IconsList.INTERACT_CUSTOM_IMAGE
    },
    {
        id: interactWidgetsListKeys.interactProduct,
        img: IconsList.INTERACT_PRODUCT
    },
    {
        id: interactWidgetsListKeys.interactChapter,
        img: IconsList.INTERACT_CHAPTER
    }
];

export const interactTimelineItemTypes = {
    mediaItem: 'mediaItem',
    interactItem: 'interactItem',
    chapter: 'chapter',
    video: 'video'
};

export const interactTimelineGroupTypes = {
    mediaGroup: 'mediaGroup',
    interactGroup: 'interactGroup',
    buttonGroup: 'button',
    chapterGroup: 'chapter'
};

export const interactTimelineIcons = {
    mediaIcon: timelineMediaIcon,
    buttonsIcon: interact_elements,
    chaptersIcon: chapter_icon
};

export const tracksSequencesTypes = {
    customs: 'customs',
    chapters: 'chapters',
    videos: 'videos'
};

export const sequenceTypes = {
    custom: 'custom',
    chapter: 'chapter',
    video: 'video'
};

export const buttonImageType = {
    cover: 'cover',
    hover: 'hover'
};

export const openUrlOptions = {
    replacePage: 'replacePage',
    newTab: 'newTab'
};

export const playPauseOptions = {
    play: 'play',
    pause: 'pause'
};

export const resizePositions = {
    topleft: 'topleft',
    topright: 'topright',
    bottomleft: 'bottomleft',
    bottomright: 'bottomright'
};

export const interactSettingsType = {
    boolean: 'boolean',
    select: 'select',
    coordinate: 'coordinate',
    xCoordinate: 'coordinate.x',
    yCoordinate: 'coordinate.y',
    widthCoordinate: 'coordinate.width',
    heightCoordinate: 'coordinate.height',
    radiusCoordinate: 'coordinate.radius',
    metaData: 'meta',
    image: 'image',
    price: 'price',
    description: 'description',
    hideVolumeCounter: 'hideVolumeCounter',
    actions: 'actions',
    color: 'color',
    number: 'number',
    string: 'string',
    timestamp: 'timestamp',
    uuid: 'uuid',
    font: 'font',
    variantName1: 'variant.name1',
    variantName2: 'variant.name2',
    variantValues1: 'variant.values1',
    variantValues2: 'variant.values2',
    variantCombinations: 'variant.combinations'


};

export const defaultImageUrl = 'https://7cf813f32fa741bfdfb521ba7cab7f30-httpcache0-90006-cachedown0.dna.ip-only.net/90006-cachedown0/assets/c6/c60873b9-00090006/uploadimage.png';

export const interactMimeType = 'image/svg+xml';