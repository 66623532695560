import React from 'react';
import Technology from './components/Technology';

const AnalyticsBrowsersScreen = () => {
	return (
		<Technology
			dimension="browser"
			titleSec1="ANALYTICS_TITLE_CDN_BROWSERS"
			titleSec2="ANALYTICS_TITLE_CDN_VOLUME_PER_BROWSERS"
			column="Browsers"
		/>
	);
};

export default AnalyticsBrowsersScreen;
