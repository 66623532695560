import timelineLiveIcon from './../assets/images/spinner_fade.svg';
import timelineMediaAndSnapshotIcon from './../assets/images/video.svg';

export const snapshotTypes = {
	INIT_SNAPSHOT: {
		CSS: 'snapshot-initial',
		TITLE: 'Snapshot',
		ICON: timelineMediaAndSnapshotIcon,
	},
	SNAPSHOT: {
		CSS: 'snapshot',
		TITLE: 'Snapshot',
		ICON: timelineMediaAndSnapshotIcon,
	},
	MEDIA: {
		CSS: 'media',
		TITLE: 'Media',
		ICON: timelineMediaAndSnapshotIcon,
	},
	LIVE: {
		CSS: 'live',
		TITLE: 'Live',
		ICON: timelineLiveIcon,
	},
};
