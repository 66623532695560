import * as actions from '../actions/appsActions';

const initialState = {
	apps: [],
	appFullScreenMode: false,
	selectedApp: null,
};
export const appsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SET_APPS:
			return {
				...state,
				apps: [...action.apps],
			};
		case actions.TOGGLE_FULLSCREEN_MODE:
			return {
				...state,
				appFullScreenMode: action.isFullscreen,
			};
		case actions.SET_SELECTED_APP:
			return {
				...state,
				selectedApp: action.selectedApp,
			};
		default:
			return state;
	}
};
