import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'underscore';
import LoadingOverlay from 'react-loading-overlay';
import ScaleLoader from 'react-spinners/ScaleLoader';
import Button from '@material-ui/core/Button';

import { getMediaQueryDetails } from '../../actions/publishActions';
import { isRequestFail } from '../../services/inspectScreenHelperService';
import { showNotification } from '../../actions/notificationActions';
import { mediaNames, messageTypes } from '../../constants/mediaConstants';
import { generateId } from '../../services/stringHelperService';
import { getTimelineTimeFromSeconds, getFormattedTimeWithMilliseconds } from '../../services/timeStampService';
import {
	isItemUpdated,
	getBodyOfSequence,
	getBodyOfTrack,
	getTrackSequenceName,
	getTrackOfInteractItem,
} from '../../services/interactScreenHelperService';
import {
	setInteractScreenModified,
	setInteractScreenUnModified,
	updateInteractTimelineItem,
	refreshInteractPlayer,
	setSelectedInteractTimelineItem,
	removeInteractTimelineItem,
	removeSelectedInteractTimelineItem,
} from '../../actions/interactTimelineActions';
import {
	createNewResource,
	deleteExistingResource,
	updateSelectedElementProperties,
	uploadFileUsingSmilWorkflow,
} from '../../actions/interactElementsActions';
import { createTrack, createSequence, deleteSequence, updateSequence } from '../../actions/tracksActions';
import { interactMimeType, tracksSequencesTypes, sequenceTypes } from '../../constants/interactConstant';
import { deleteTrackFromMedia } from '../../actions/inspectScreenActions';

class InteractSaveProject extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isSavingProject: false,
		};
	}

	componentDidMount = () => {
		if (this.props.onRef) {
			this.props.onRef(this);
		}
	};

	checkIfProductIdIsEmpty = () => {
		const { interactItems } = this.props;

		let result = false;

		interactItems.map((item) => {
			if (item.hasOwnProperty('templateId') && item.templateId === 'dynamicProduct') {
				for (const [key] of Object.entries(item.settings)) {
					if (key !== 'general' && key !== 'template') {
						if (item.settings[key].prodId.default === '') {
							result = true;
						}
					}
				}
			}
		});

		return result;
	};

	handleSaveProject = () => {
		const { mediaId, t, defaultAccountId, interactItems, elementPropertiesError } = this.props;

		if (this.state.isSavingProject) {
			return;
		}

		if (this.checkIfProductIdIsEmpty()) {
			this.props.showMessage(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_PRODUCT_ID_IS_REQUIRED'),
				messageTypes.error
			);
			return;
		}

		if (elementPropertiesError) {
			this.props.showMessage(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_ELEMENT_ERROR'),
				messageTypes.error
			);
			return;
		}

		this.setState({ isSavingProject: true }, () => {
			this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
				if (isRequestFail(this, media)) {
					this.props.showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_FAILED_FETCH_MEDIA_DETAILS'),
						messageTypes.error
					);
					this.setState({ isSavingProject: false });
					return;
				}
				let tracks = media.tracks;
				if ((!tracks || tracks.length === 0) && interactItems.length > 1) {
					//create a track with a sequence if no track exists in server and interact element is found
					this.createTracksWithSequence(media);
					return;
				}
				let serverInteractTrack = [];
				let chapterTrackFound = false;
				let customTrackFound = false;
				let videoTrackFound = false;
				tracks.map((track) => {
					if (track && track.sequences && track.sequences.length > 0) {
						if (
							(track &&
								track.sequences &&
								track.sequences.length > 0 &&
								track.sequences[0].type === sequenceTypes.custom &&
								track.sequences[0].custom &&
								track.sequences[0].custom.mimeType === interactMimeType &&
								track.sequences[0].custom.interactiveWidget) ||
							track.name === sequenceTypes.chapter ||
							track.name === sequenceTypes.video
						) {
							if (track.name === sequenceTypes.chapter && !chapterTrackFound) {
								chapterTrackFound = true;
								serverInteractTrack.push(track);
							} else if (track.name === sequenceTypes.video && !videoTrackFound) {
								videoTrackFound = true;
								serverInteractTrack.push(track);
							} else if (
								track.sequences[0].custom &&
								track.sequences[0].custom.mimeType === interactMimeType &&
								!customTrackFound
							) {
								customTrackFound = true;
								serverInteractTrack.push(track);
							}
						}
					}
				});

				if (serverInteractTrack.length === 0) {
					//create a track with a sequence if no interact track exists in server and interact element is found (applies if there are other tracks) track with a sequence if no track exists in server and interact element is found
					this.createTracksWithSequence(media);
				} else if (
					serverInteractTrack.length > 0 &&
					((interactItems.length === 1 && interactItems[0].id === mediaId) || interactItems.length == 0)
				) {
					//if there is an interact track in server but no interact items in UI, delete the track
					this.props.showMessage(t('COMMON_WAIT_TO_SAVE_CHANGE'), messageTypes.info);
					serverInteractTrack.map((track, index) => {
						this.deleteTrack(track.id);
						if (index === serverInteractTrack.length - 1) {
							this.setState({ isSavingProject: false });
						}
					});
				} else if (serverInteractTrack.length > 0 && interactItems.length > 0) {
					let itemTypeArray = []; //create track if track is not there but item is there
					let chapterItemFound = _.find(interactItems, (item) => {
						return item.itemType === sequenceTypes.chapter;
					});
					/*let videoItemFound = _.find(interactItems, (item) => {
                        return item.itemType === sequenceTypes.video
                    })*/
					let customItemFound = _.find(interactItems, (item) => {
						return item.itemType === sequenceTypes.custom && item.mimeType == interactMimeType;
					});
					if (!chapterTrackFound && chapterItemFound) {
						itemTypeArray.push(sequenceTypes.chapter);
					} /*else if (chapterTrackFound && !chapterItemFound) {
                        serverInteractTrack.map(track => {
                            if (track.name == sequenceTypes.chapter) {
                                this.deleteTrack(track.id) //to test
                            }
                        })
                    } */

					/*if (!videoTrackFound && videoItemFound) {
                        itemTypeArray.push(sequenceTypes.video)
                    }*/ // commenting for now as planning to trim video on the spot

					if (!customTrackFound && customItemFound) {
						itemTypeArray.push(sequenceTypes.custom);
					} /*else if (customTrackFound && !customItemFound) {
                        serverInteractTrack.map(track => {
                            if (track.name == sequenceTypes.custom) {
                                this.deleteTrack(track.id) //to test
                            }
                        })
                    }*/
					if (itemTypeArray.length > 0) {
						this.createTracksWithSequence(media, itemTypeArray);
					} else {
						this.updateInteractSequencesInTrack(media, serverInteractTrack);
					}
				}
				this.props.setInteractScreenUnModified();
			});
		});
	};

	deleteTrack = (trackId) => {
		//deletes track with sequences
		const { defaultAccountId, mediaId, t } = this.props;
		this.props.deleteTrackFromMedia(defaultAccountId, mediaId, trackId).then(() => {
			this.props.showMessage(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_DELETED_INTERACT_ITEMS'),
				messageTypes.info
			); //deletes entire track with sequences
		});
	};

	createTracksWithSequence = (media, typeList) => {
		const { defaultAccountId, mediaId, interactItems, t } = this.props;
		this.props.showMessage(t('COMMON_WAIT_TO_SAVE_CHANGE'), messageTypes.info);

		var itemTypeList = [];
		if (!typeList || typeList.length === 0) {
			interactItems.map((item) => {
				if (item.id !== mediaId && item.itemType && itemTypeList.indexOf(item.itemType) == -1) {
					itemTypeList.push(item.itemType);
				}
			});
		} else {
			itemTypeList = typeList;
		}
		itemTypeList.map((type) => {
			let trackBody = getBodyOfTrack(type);
			let trackId = trackBody.id;
			this.props.createTrack(defaultAccountId, mediaId, trackBody).then((res) => {
				if (typeof res === 'string') {
					// let count = 0
					let itemList = [];
					interactItems.map((item) => {
						if (item.id !== mediaId && item.itemType == type) {
							itemList.push(item);
						}
					});
					this.createSequence(media, itemList, trackId, type);
				}
			});
		});
	};

	getItemsNewlyAdded = (clientItemArray, serverItemsArray) => {
		let addedItems = [];
		clientItemArray.map((item) => {
			let foundItem = _.find(serverItemsArray, (serverItem) => {
				return serverItem.id === item.id;
			});
			if (!foundItem) {
				addedItems.push(item);
			}
		});
		return addedItems;
	};

	getItemsRemoved = (clientItemArray, serverItemsArray) => {
		let removedItems = [];
		serverItemsArray.map((item) => {
			let foundItem = _.find(clientItemArray, (clientItem) => {
				return clientItem.id === item.id;
			});
			if (!foundItem) {
				removedItems.push(item);
			}
		});
		return removedItems;
	};

	getItemsUpdated = (clientItemArray, serverItemsArray) => {
		let updatedItems = [];
		clientItemArray.map((item) => {
			let foundItem = _.find(serverItemsArray, (serverItem) => {
				return serverItem.id === item.id;
			});
			if (foundItem && isItemUpdated(item, foundItem)) {
				updatedItems.push(item);
			}
		});
		return updatedItems;
	};

	updateInteractSequencesInTrack = (media, serverTrack) => {
		const { interactItems, mediaId, t } = this.props;
		serverTrack.map((track) => {
			let serverItemsArray = [];
			track.sequences.map((sequence) => {
				if (
					(sequence.type === track.name &&
						sequence.type !== sequenceTypes.video &&
						sequence.custom &&
						sequence.custom.mimeType === interactMimeType &&
						sequence.custom.interactiveWidget) ||
					sequence.type == sequenceTypes.chapter
					// || (sequence.type == sequenceTypes.video) //commenting for now
				) {
					serverItemsArray.push(sequence);
				}
			});
			let clientItemArray = [];
			interactItems.map((item) => {
				if (item.id !== mediaId && item.itemType == track.name && item.itemType !== sequenceTypes.video) {
					clientItemArray.push(item);
				}
			});
			let itemsAdded = this.getItemsNewlyAdded(clientItemArray, serverItemsArray);
			let itemsRemoved = this.getItemsRemoved(clientItemArray, serverItemsArray);
			let itemsUpdated = this.getItemsUpdated(clientItemArray, serverItemsArray);
			let changes = false;
			if (itemsAdded.length > 0) {
				changes = true;
				this.createSequence(media, itemsAdded, track.id, track.name);
			}
			if (itemsRemoved.length > 0) {
				changes = true;
				this.deleteSequence(media, itemsRemoved, track, track.name);
			}
			if (itemsUpdated.length > 0) {
				changes = true;
				this.updateSequence(media, itemsUpdated, track.id, track.name);
			}
			if (changes) {
				this.props.showMessage(t('COMMON_WAIT_TO_SAVE_CHANGE'), messageTypes.info);
			} else {
				this.props.setInteractScreenUnModified();
			}
		});
	};

	applyTrim = (item) => {
		const { defaultAccountId, mediaId, t } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (isRequestFail(this, media)) {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_FAILED_FETCH_MEDIA_DETAILS'),
					messageTypes.error
				);
				return;
			}
			let videoTrack = getTrackOfInteractItem(media, item.id);
			if (videoTrack && videoTrack.sequences) {
				this.createVideoSequence(media, item, videoTrack.id, 'update');
			} else {
				let trackBody = getBodyOfTrack(sequenceTypes.video);
				this.props.createTrack(defaultAccountId, mediaId, trackBody).then((res) => {
					if (typeof res === 'string') {
						this.createVideoSequence(media, item, trackBody.id, 'create');
					}
				});
			}
		});
	};

	removeTrim = (item) => {
		const { defaultAccountId, mediaId, t } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (isRequestFail(this, media)) {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_FAILED_FETCH_MEDIA_DETAILS'),
					messageTypes.error
				);
				return;
			}
			let track = getTrackOfInteractItem(media, item.id);
			if (track) {
				this.deleteVideoSequence(media, item, track, () => {
					let customBody = getBodyOfSequence(item);
					if (!customBody) {
						this.props.showMessage(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_SAVE_INVALID_VALUES'),
							messageTypes.error
						);
						return;
					}
					customBody['resource'] = { id: generateId() };
					customBody.start = undefined;
					customBody.end = undefined;

					this.props.createNewResource(defaultAccountId, media.asset.id, customBody.resource).then((res) => {
						if (res && media && media.asset && media.asset.resources) {
							const resources = media.asset.resources;
							const videoResource = _.find(resources, (resource) => {
								return resource.type === 'video';
							});

							if (videoResource && videoResource.renditions instanceof Array) {
								this.generateSequenceSmilFile(media, customBody, () => {
									// Update item resource
									item['resource'] = customBody.resource;
								});
							}
						}
					});
				});
			} else {
				this.props.removeInteractTimelineItem(item, true);
				this.props.removeSelectedInteractTimelineItem(item.id);
			}
		});
	};

	cancelTrim = (item) => {
		const { defaultAccountId, mediaId, t } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (isRequestFail(this, media)) {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_FAILED_FETCH_MEDIA_DETAILS'),
					messageTypes.error
				);
				return;
			}
			let itemFound = false;
			media.tracks &&
				media.tracks.map((track) => {
					if (track && track.sequences && track.name === sequenceTypes.video && track.sequences.length > 0) {
						track.sequences.map((sequence) => {
							if (sequence.id === item.id) {
								item.start =
									!sequence.start && sequence.start != 0
										? null
										: getTimelineTimeFromSeconds(sequence.start);
								item.end =
									!sequence.end && sequence.end != 0
										? null
										: getTimelineTimeFromSeconds(sequence.end);
								this.props.updateInteractTimelineItem(item, true);
								itemFound = true;
							}
						});
					}
				});
			if (!itemFound) {
				this.props.removeInteractTimelineItem(item, true);
			}
		});
	};

	prepareActionForProductItem = (item) => {
		let resItem = item;
		let settings = resItem.settings;

		settings &&
			Object.keys(settings).forEach((eachKey) => {
				if (eachKey !== 'template' && eachKey !== 'general') {
					let tempValue;
					let priceCurrency = settings[eachKey].price.default.slice(-3);

					let variationComboArray =
						settings[eachKey].variantCombinations.default === undefined
							? '[]'
							: settings[eachKey].variantCombinations.default.map((elem) => ({
									comboName: elem.comboName,
									comboId: elem.comboId,
									comboImg: elem.comboImg === '' ? settings[eachKey].imageUrl.default : elem.comboImg,
									comboPrice:
										elem.comboPrice === ''
											? settings[eachKey].price.default
											: elem.comboPrice + ' ' + priceCurrency,
							  }));

					tempValue =
						settings[eachKey].prodName.default +
						'=)' +
						settings[eachKey].prodId.default +
						'=)' +
						settings[eachKey].prodPage.default +
						'=)' +
						settings[eachKey].price.default +
						'=)' +
						settings[eachKey].prodDescription.default +
						'=)' +
						'(=' +
						settings[eachKey].hideVolumeCounter.default +
						'=)' +
						settings[eachKey].imageUrl.default +
						'=)' +
						(settings[eachKey].variantName1.default === undefined
							? ''
							: settings[eachKey].variantName1.default) +
						'=)' +
						(settings[eachKey].variantName2.default === undefined
							? ''
							: settings[eachKey].variantName2.default) +
						'=)' +
						(settings[eachKey].variantValues1.default === undefined
							? ''
							: settings[eachKey].variantValues1.default.join()) +
						'=)' +
						(settings[eachKey].variantValues2.default === undefined
							? ''
							: settings[eachKey].variantValues2.default.join()) +
						'=)' +
						(settings[eachKey].variantCombinations.default === undefined
							? '[]'
							: JSON.stringify(variationComboArray));

					let tempActionObj = { customEvent: { value: tempValue } };

					settings[eachKey].action.default = tempActionObj;
				}
			});

		return resItem;
	};

	generateSequenceSmilFile = (media, customBody, callback) => {
		const { t, defaultAccountId } = this.props;
		this.props
			.uploadFileUsingSmilWorkflow(defaultAccountId, media.asset.id, {
				resourceId: customBody.resource.id,
				inPoint: customBody.start,
				outPoint: customBody.end,
			})
			.then((res) => {
				if (!res) {
					this.props.showMessage(
						t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_TRIMMING_VIDEO_FAILURE'),
						messageTypes.error
					);
					return;
				}
				callback?.(res);
			})
			.catch(() =>
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_TRIMMING_VIDEO_FAILURE'),
					messageTypes.error
				)
			);
	};

	deleteExistingResource = (media, resourceId, callback) => {
		const { defaultAccountId } = this.props;
		if (!resourceId) {
			callback('no index file found. create a new resource');
			return;
		}
		this.props.deleteExistingResource(defaultAccountId, media.asset.id, resourceId).then((res) => {
			if (res) {
				callback?.(res);
			} else {
				this.props.showMessage('Failed to delete existing resource', messageTypes.error);
				callback?.();
			}
		});
	};

	createVideoSequence = (media, item, trackId, operationType) => {
		const { mediaId, defaultAccountId, t } = this.props;
		let customBody = getBodyOfSequence(item);
		if (!customBody) {
			this.props.showMessage(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_SAVE_INVALID_VALUES'),
				messageTypes.error
			);
			return;
		}
		customBody['resource'] = { id: generateId() };
		let indexResource = _.find(media.asset.resources, (resource) => {
			return resource.type === 'index';
		});
		this.props.showMessage(
			t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_CREATE_VIDEO_MSG'),
			messageTypes.info
		);
		this.deleteExistingResource(
			media,
			item.resource ? item.resource.id : indexResource ? indexResource.id : '',
			() => {
				if (1) {
					// allowing to create a new video sequence
					this.props.createNewResource(defaultAccountId, media.asset.id, customBody.resource).then((res) => {
						if (res) {
							if (media && media.asset && media.asset.resources) {
								let resources = media.asset.resources;
								let videoResource = _.find(resources, (resource) => {
									return resource.type === 'video';
								});
								if (videoResource && videoResource.renditions instanceof Array) {
									this.props.showMessage(
										t(
											'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_CREATE_VIDEO_INPROGRESS'
										),
										messageTypes.info
									);
									const customBodyForSmilFile = {
										...customBody,
										start: item.start
											? getFormattedTimeWithMilliseconds(item.start, 'HH:mm:ss.SSS')
											: undefined,
										end: item.end
											? getFormattedTimeWithMilliseconds(item.end, 'HH:mm:ss.SSS')
											: undefined,
									};
									this.generateSequenceSmilFile(media, customBodyForSmilFile, () => {
										if (operationType === 'create') {
											this.props
												.createSequence(
													defaultAccountId,
													mediaId,
													trackId,
													getTrackSequenceName(item.itemType),
													customBody
												)
												.then((res) => {
													if (typeof res === 'string') {
														item['resource'] = customBody.resource;
														this.props.updateInteractTimelineItem(item, true);
														this.props.showMessage(
															t(
																'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_TRIMMING_VIDEO_SUCCESS'
															),
															messageTypes.info
														);
														this.props.showMessage(
															t(
																'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_LOADING_NEW_MEDIA'
															),
															messageTypes.info
														);
														this.props.refreshInteractPlayer();
													} else {
														this.props.showMessage(
															t(
																'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_TRIMMING_VIDEO_FAILURE'
															),
															messageTypes.error
														);
													}
												});
										} else if (operationType === 'update') {
											this.props
												.updateSequence(
													defaultAccountId,
													mediaId,
													trackId,
													getTrackSequenceName(item.itemType),
													item.id,
													customBody
												)
												.then((res) => {
													if (typeof res === 'string') {
														item['resource'] = customBody.resource;
														this.props.updateInteractTimelineItem(item, true);
														this.props.showMessage(
															t(
																'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_TRIMMING_VIDEO_SUCCESS'
															),
															messageTypes.info
														);
														this.props.showMessage(
															t(
																'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_LOADING_NEW_MEDIA'
															),
															messageTypes.info
														);
														this.props.refreshInteractPlayer();
													} else {
														this.props.showMessage(
															t(
																'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_TRIMMING_VIDEO_FAILURE'
															),
															messageTypes.error
														);
													}
												});
										} //else if
									}); //generatesmil file
								} //if video resource
							} //media asset resources
						}
					}); //create new resource
				}
			}
		); //delete existing resource
	};

	deleteVideoSequence = (media, item, track, callback) => {
		const { defaultAccountId, mediaId, t } = this.props;
		let videoItem = _.find(track.sequences, (sequence) => {
			return sequence.id === item.id;
		});
		//let indexResource = _.find(media.asset.resources, (resource) => { return resource.type === 'index' })
		this.props
			.deleteExistingResource(
				defaultAccountId,
				media.asset.id,
				item.resource ? item.resource.id : videoItem && videoItem.resource ? videoItem.resource.id : ''
			)
			.then(() => {
				if (1) {
					// to check if index resource is required here or not
					this.props
						.deleteSequence(defaultAccountId, mediaId, track.id, tracksSequencesTypes.videos, item.id)
						.then((res) => {
							if (typeof res === 'string') {
								this.props.showMessage(
									t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_VIDEO_DELETION_SUCCESS'),
									messageTypes.info
								);
								this.props.removeInteractTimelineItem(item, 'true'); //true is for setting screen unmodified
								this.props.removeSelectedInteractTimelineItem(item.id);
								this.deleteTrack(track.id);
								callback?.();
							} else {
								this.props.showMessage(
									t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_TRIMMING_VIDEO_FAILURE'),
									messageTypes.error
								);
								this.props.setSelectedInteractTimelineItem([item.id]);
							}
						});
				}
			});
	};

	createSequence = (media, itemList, trackId, type) => {
		const { defaultAccountId, mediaId, t } = this.props;
		let count = 0;

		itemList.map((item, index) => {
			let customBody = getBodyOfSequence(
				item.templateName === 'Product' ? this.prepareActionForProductItem(item) : item
			);
			if (!customBody) {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_SAVE_INVALID_VALUES'),
					messageTypes.error
				);
				return;
			}

			this.props
				.createSequence(defaultAccountId, mediaId, trackId, getTrackSequenceName(type), customBody)
				.then((res) => {
					if (typeof res === 'string') {
						count = count + 1;
						if (count === itemList.length) {
							this.props.showMessage(
								t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_CREATED_INTERACT_ITEMS'),
								messageTypes.info
							);
						}
					} else {
						this.props.showMessage(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_SAVE') + ' ' + item.templateName,
							messageTypes.error
						);
						this.props.setInteractScreenModified();
					}

					if (index === itemList.length - 1) {
						this.setState({ isSavingProject: false });
					}
				});
		});
	};

	deleteSequence = (media, itemList, track, type) => {
		const { defaultAccountId, mediaId, t, interactItems } = this.props;
		let count = 0;
		itemList.map((item, index) => {
			this.props
				.deleteSequence(defaultAccountId, mediaId, track.id, getTrackSequenceName(type), item.id)
				.then((res) => {
					if (typeof res === 'string') {
						count = count + 1;
						if (count === itemList.length) {
							this.props.showMessage(
								t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_DELETED_INTERACT_ITEMS'),
								messageTypes.info
							);
							let itemTypeFound = _.find(interactItems, (item) => {
								return item.itemType == track.name;
							});
							if (!itemTypeFound) {
								this.deleteTrack(track.id);
							} //not required as already handled when save project is called
						}
					} else {
						this.props.showMessage(
							t('COMMON_DELETION_FAILED') + ' ' + item.templateName,
							messageTypes.error
						);
						this.props.setInteractScreenModified();
					}

					if (index === itemList.length - 1) {
						this.setState({ isSavingProject: false });
					}
				});
		});
	};

	updateSequence = (media, itemList, trackId, type) => {
		const { defaultAccountId, mediaId, t } = this.props;
		let count = 0;
		/*if (type === sequenceTypes.video && itemList && itemList.length > 0) {
            //this.updateVideoSequence(media, itemList[0], trackId)
            this.createVideoSequence(media, itemList[0], trackId, 'update') //ideally we are creating a video sequence
            return;
        }*/ //commenting for now
		itemList.map((item, index) => {
			let customBody = getBodyOfSequence(
				item.templateName === 'Product' ? this.prepareActionForProductItem(item) : item
			);
			if (!customBody) {
				this.setState({ isSavingProject: false });
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_SAVE_INVALID_VALUES'),
					messageTypes.error
				);
				return;
			}
			this.props
				.updateSequence(defaultAccountId, mediaId, trackId, getTrackSequenceName(type), item.id, customBody)
				.then((res) => {
					if (typeof res === 'string') {
						count = count + 1;
						if (count === itemList.length) {
							this.props.showMessage(
								t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_UPDATED_INTERACT_ITEMS'),
								messageTypes.info
							);
						}
					} else {
						this.props.showMessage(
							t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_FAILED_TO_SAVE') + ' ' + item.templateName,
							messageTypes.error
						);
						this.props.setInteractScreenModified();
					}
					if (index === itemList.length - 1) {
						this.setState({ isSavingProject: false });
					}
				});
		});
	};

	render() {
		const { t, isInteractScreenModified, noUI } = this.props;
		if (noUI) {
			return null;
		}
		return (
			<>
				<Button
					disabled={this.state.isSavingProject ? true : !isInteractScreenModified}
					variant="contained"
					className="defaultActionBtn saveProjectButton"
					onClick={this.handleSaveProject}
				>
					{t('COMMON_SAVE_PROJECT')}
				</Button>
				{this.state.isSavingProject && (
					<LoadingOverlay
						className="loadingStreamOverlay"
						active={true}
						spinner={<ScaleLoader color={'#ffffff'} />}
						text={'Saving project...'}
					/>
				)}
			</>
		);
	}
}

const mapStateToProps = ({ session, interactTimelineReducer, interactElementsReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	isInteractScreenModified: interactTimelineReducer.isInteractScreenModified,
	refreshInteractPlayer: interactTimelineReducer.refreshInteractPlayer,
	elementPropertiesError: interactElementsReducer.elementPropertiesError,
});

const mapDispatchToProps = (dispatch) => ({
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	showNotification: (message) => dispatch(showNotification(message)),
	setInteractScreenUnModified: () => dispatch(setInteractScreenUnModified()),
	setInteractScreenModified: () => dispatch(setInteractScreenModified()),
	createTrack: (accId, mediaId, body) => dispatch(createTrack(accId, mediaId, body)),
	deleteTrackFromMedia: (accId, mediaId, trackId) => dispatch(deleteTrackFromMedia(accId, mediaId, trackId)),
	createSequence: (accId, mediaId, trackId, sequenceType, body) =>
		dispatch(createSequence(accId, mediaId, trackId, sequenceType, body)),
	deleteSequence: (accId, mediaId, trackId, sequenceType, sequenceId) =>
		dispatch(deleteSequence(accId, mediaId, trackId, sequenceType, sequenceId)),
	updateSequence: (accId, mediaId, trackId, sequenceType, sequenceId, body) =>
		dispatch(updateSequence(accId, mediaId, trackId, sequenceType, sequenceId, body)),
	createNewResource: (accId, assetId, resource) => dispatch(createNewResource(accId, assetId, resource)),
	deleteExistingResource: (accId, assetId, resourceId) =>
		dispatch(deleteExistingResource(accId, assetId, resourceId)),
	uploadFileUsingSmilWorkflow: (defaultAccountId, assetId, inPoint, outPoint) =>
		dispatch(uploadFileUsingSmilWorkflow(defaultAccountId, assetId, inPoint, outPoint)),
	updateInteractTimelineItem: (item, screenUnmodified) =>
		dispatch(updateInteractTimelineItem(item, screenUnmodified)),
	refreshInteractPlayer: (item) => dispatch(refreshInteractPlayer(item)),
	setSelectedInteractTimelineItem: (item) => dispatch(setSelectedInteractTimelineItem(item)),
	removeInteractTimelineItem: (item, screenUnmodified) =>
		dispatch(removeInteractTimelineItem(item, screenUnmodified)),
	removeSelectedInteractTimelineItem: (item) => dispatch(removeSelectedInteractTimelineItem(item)),
	updateSelectedElementProperties: () => dispatch(updateSelectedElementProperties()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InteractSaveProject);
