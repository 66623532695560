import React from 'react';
import { getBezierPath, EdgeText } from 'reactflow';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useEdgeInfo } from '../../../hooks/screens/scenario/scenarioGraph/useEdgeInfo';
import './SceneEdge.scss';

const foreignObjectSize = 40;

const SceneEdge = ({
	id,
	sourceX,
	sourceY,
	targetX,
	targetY,
	sourcePosition,
	targetPosition,
	style = {},
	data,
	markerEnd,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const info = useEdgeInfo(data);

	const [path, labelX, labelY] = getBezierPath({
		sourceX,
		sourceY,
		sourcePosition,
		targetX,
		targetY,
		targetPosition,
	});

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<path id={id} style={style} className="react-flow__edge-path" d={path} markerEnd={markerEnd} />

			{info.length === 1 ? (
				<EdgeText x={labelX} y={labelY} label={info[0]} />
			) : (
				<foreignObject
					width={foreignObjectSize}
					height={foreignObjectSize}
					x={labelX - foreignObjectSize / 2}
					y={labelY - foreignObjectSize / 2}
				>
					<div>
						<Button class="edge-button" onClick={handleClick}>
							{`${info.length}+`}
						</Button>
						<Menu
							class="connection-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							{info.map((connectionInfo, idx) => {
								return (
									<MenuItem key={`${idx}$-{connectionInfo}`} className="connection-item">
										{connectionInfo}
									</MenuItem>
								);
							})}
						</Menu>
					</div>
				</foreignObject>
			)}
		</>
	);
};

export default SceneEdge;
