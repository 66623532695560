import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ChatContainerTemplate } from './ChatContainerTemplate';
import { showMessage } from '../../../../actions/globalActions';
import { setViewEngagementLoading } from '../../../../actions/viewEngagementActions';
import { messageTypes } from '../../../../constants/mediaConstants';
import { getBlockedUsersList, getMessages } from '../../../../actions/liveChatActions';
import _ from 'underscore';

interface DataProps {
	defaultAccountId: string;
	mediaId: string;
	shouldUpdateData?: boolean;
	callback?: () => void;
	setMessages: (_mess: ChatContainerTemplate.Message[]) => void;
}

interface ReturnDataProps {
	error?: any;
	mediaMessages: ChatContainerTemplate.Message[];
	pinnedMessage?: ChatContainerTemplate.Message;
	blockedUserIds: string[];
}

export const useFetchChatData = ({
	mediaId,
	defaultAccountId,
	shouldUpdateData,
	callback,
	setMessages,
}: DataProps): ReturnDataProps => {
	const dispatch = useDispatch() as any;
	const [mediaMessages, setMediaMessage] = useState<ChatContainerTemplate.Message[]>([]);
	const [pinnedMessage, setPinnedMessage] = useState<ChatContainerTemplate.Message | undefined>(undefined);
	const [blockedUserIds, setBlockedUserIds] = useState<string[]>([]);

	useEffect(() => {
		if (!shouldUpdateData) {
			return;
		}

		dispatch(getMessages(defaultAccountId, mediaId))
			.then((data: any) => {
				if (!data) {
					dispatch(showMessage("Can't fetch Chat", messageTypes.error));
					dispatch(setViewEngagementLoading(false));
					return;
				}

				setMediaMessage(data);
				setMessages(data);
				setPinnedMessage(_.find(data, (msg) => msg.message.pinned));
			})
			.catch((error: any) => {
				dispatch(showMessage(`Can't fetch Chat. ${error}`, messageTypes.error));
			});

		dispatch(getBlockedUsersList(defaultAccountId, mediaId))
			.then((data: any) => {
				let userIds: string[] = [];

				if (data && data.map) {
					data.map((user: any) => {
						userIds.push(user.id);
					});
				}

				setBlockedUserIds(userIds);
			})
			.catch((error: any) => {
				dispatch(showMessage(`Can't fetch blocked Users. ${error}`, messageTypes.error));
			});

		setTimeout(() => {
			callback?.();
		}, 1000);
	}, [mediaId, defaultAccountId, shouldUpdateData]);

	return { mediaMessages, blockedUserIds, pinnedMessage };
};
