export const v1WorkflowTypes = {
	CREATE_LEGACY_LIVE_EVENT_FROM_CHANNEL: {
		ID: 'v1-create-legacy-live-media-from-channel',
		NAME: 'Create legacy live media from channel',
		SCOPE: 'global',
	},
	CREATE_LIVE_EVENT_FROM_CHANNEL: {
		ID: 'v1-create-live-media-from-channel',
		NAME: 'Create live media from channel',
		SCOPE: 'global',
	},
	START_LIVE_EVENT: {
		ID: 'v1-start-live-media',
		NAME: 'Start live media',
		SCOPE: 'global',
	},
	STOP_LIVE_EVENT: {
		ID: 'v1-stop-live-media',
		NAME: 'Stop live media',
		SCOPE: 'global',
	},
	STOP_LIVE_EVENT_WITHOUT_RESTRICTION: {
		ID: 'v1-stop-live-media-without-restrictions',
		NAME: 'Stop live media without restriction',
		SCOPE: 'global',
	},
	CREATE_SNAPSHOT: {
		ID: 'v1-create-snapshot',
		NAME: 'Create snapshot',
		SCOPE: 'global',
	},
	CREATE_IPONLY_CHANNEL: {
		ID: 'v1-create-channel',
		NAME: 'Create iponly channel',
		SCOPE: 'global',
	},
	DELETE_IPONLY_CHANNEL: {
		ID: 'v1-delete-channel',
		NAME: 'Delete iponly channel',
		SCOPE: 'global',
	},
	SEND_EMAIL_NOTIFICATION: {
		ID: 'v1-test-email',
		NAME: 'Send email notifications',
		SCOPE: 'global',
	},
	ADD_SUBTITLE: {
		ID: 'v1-add-subtitle',
		NAME: 'Add subtitle',
		SCOPE: 'global',
	},
};

export const workflowTypes = {
	CREATE_LEGACY_LIVE_EVENT_FROM_CHANNEL: {
		ID: 'v2-create-legacy-live-media-from-channel',
		NAME: 'Create legacy live media from channel',
		SCOPE: 'global',
	},
	CREATE_LIVE_EVENT_FROM_CHANNEL: {
		ID: 'v1-create-live-media-from-channel',
		NAME: 'Create live media from channel',
		SCOPE: 'global',
	},
	START_LIVE_EVENT: {
		ID: 'v2-start-live-media',
		NAME: 'Start live media',
		SCOPE: 'global',
	},
	STOP_LIVE_EVENT: {
		ID: 'v1-stop-live-media',
		NAME: 'Stop live media',
		SCOPE: 'global',
	},
	STOP_LIVE_EVENT_WITHOUT_RESTRICTION: {
		ID: 'v1-stop-live-media-without-restrictions',
		NAME: 'Stop live media without restriction',
		SCOPE: 'global',
	},
	CREATE_SNAPSHOT: {
		ID: 'v2-create-snapshot',
		NAME: 'Create snapshot',
		SCOPE: 'global',
	},
	CREATE_IPONLY_CHANNEL: {
		ID: 'v2-create-channel',
		NAME: 'Create iponly channel',
		SCOPE: 'global',
	},
	DELETE_IPONLY_CHANNEL: {
		ID: 'v2-delete-channel',
		NAME: 'Delete iponly channel',
		SCOPE: 'global',
	},
	SEND_EMAIL_NOTIFICATION: {
		ID: 'v1-test-email',
		NAME: 'Send email notifications',
		SCOPE: 'global',
	},
	ADD_SUBTITLE: {
		ID: 'v1-add-subtitle',
		NAME: 'Add subtitle',
		SCOPE: 'global',
	},
};

export const v3WorkflowTypes = {
	CREATE_SNAPSHOT: {
		ID: 'v3-create-snapshot',
		NAME: 'Create snapshot',
		SCOPE: 'global',
	},
};
