import { encoderEndpointV1 } from '../utils/config';
import BackendClient from './backendClient';

export const loadEncoderFlows = (accountId) => BackendClient.get(`${encoderEndpointV1}accounts/${accountId}/flows`);

export const getEncoderUploadId = ({ accountId, flowId, callbackUrl, mediaId, mediaTitle, mediaDescription }) => {
	return BackendClient.post(`${encoderEndpointV1}accounts/${accountId}/upload`, {
		flow_id: flowId,
		callback_url: callbackUrl,
		media_id: mediaId,
		media_title: mediaTitle,
		media_description: mediaDescription,
	});
};

export const uploadMediaToEncoderService = ({ accountId, uploadId, mediaUrl }) => {
	return BackendClient.post(`${encoderEndpointV1}accounts/${accountId}/ingest/${uploadId}`, {
		uri: mediaUrl,
	});
};

export const _getEncoderJobs = (accountId) =>
	BackendClient.get(`${encoderEndpointV1}accounts/${accountId}/jobs?status=IN_PROGRESS,SUCCESS&limit=50`, false);

export const _getEncoderJobsByStatus = (accountId, status) =>
	BackendClient.get(`${encoderEndpointV1}accounts/${accountId}/jobs?status=${status}&limit=50`, false);
