export const PLAY_SNAPSHOT_MEDIA = "PLAY_SNAPSHOT_MEDIA";
export const STOP_SNAPSHOT_MEDIA = "STOP_SNAPSHOT_MEDIA";
export const SET_SELECTED_SNAPSHOT_START_TIME = "SET_SELECTED_SNAPSHOT_START_TIME";
export const SET_SELECTED_SNAPSHOT_END_TIME = "SET_SELECTED_SNAPSHOT_END_TIME";
export const RESET_SNAPSHOT_SETTINGS = "RESET_SNAPSHOT_SETTINGS";
export const PAUSE_SNAPSHOT_MEDIA = "PAUSE_SNAPSHOT_MEDIA";

export const playSnapShotMedia = _ => ({
    type: PLAY_SNAPSHOT_MEDIA
});

export const pauseSnapShotMedia = _ => ({
    type: PAUSE_SNAPSHOT_MEDIA
});

export const stopSnapShotMedia = _ => ({
    type: STOP_SNAPSHOT_MEDIA
});

export const setSelectedSnapShotStartTime = (datetime) => ({
    type: SET_SELECTED_SNAPSHOT_START_TIME,
    datetime: datetime,
});

export const setSelectedSnapShotEndTime = (datetime) => ({
    type: SET_SELECTED_SNAPSHOT_END_TIME,
    datetime: datetime,
});

export const resetSnapShotSettings = () => ({
    type: RESET_SNAPSHOT_SETTINGS
});


