import React, { useCallback, useEffect } from 'react';
import { PLAYER_IFRAME_ID } from '../../../constants/scenarioConstant';
import { qplayerDomain } from '../../../utils/config';

const ScenarioEditorPlayerIframe = (props) => {
	const pause = useCallback(() => {
		const playerIframe = document.getElementById(PLAYER_IFRAME_ID);
		if (playerIframe && playerIframe.contentWindow) {
			playerIframe.contentWindow.postMessage({ type: 'pause' }, '*');
		}
	}, []);

	const play = useCallback(() => {
		const playerIframe = document.getElementById(PLAYER_IFRAME_ID);
		if (playerIframe && playerIframe.contentWindow) {
			playerIframe.contentWindow.postMessage({ type: 'play' }, '*');
		}
	}, []);

	const forward = useCallback(() => {
		const playerIframe = document.getElementById(PLAYER_IFRAME_ID);
		if (playerIframe && playerIframe.contentWindow) {
			playerIframe.contentWindow.postMessage({ type: 'forward' }, '*');
		}
	}, []);

	const reward = useCallback(() => {
		const playerIframe = document.getElementById(PLAYER_IFRAME_ID);
		if (playerIframe && playerIframe.contentWindow) {
			playerIframe.contentWindow.postMessage({ type: 'reward' }, '*');
		}
	}, []);

	const mute = useCallback(() => {
		const playerIframe = document.getElementById(PLAYER_IFRAME_ID);
		if (playerIframe && playerIframe.contentWindow) {
			playerIframe.contentWindow.postMessage({ type: 'mute' }, '*');
		}
	}, []);

	const unMute = useCallback(() => {
		const playerIframe = document.getElementById(PLAYER_IFRAME_ID);
		if (playerIframe && playerIframe.contentWindow) {
			playerIframe.contentWindow.postMessage({ type: 'unMute' }, '*');
		}
	}, []);

	const movePlayerToPosition = useCallback((pos, message = '') => {
		const playerIframe = document.getElementById(PLAYER_IFRAME_ID);
		if (playerIframe && playerIframe.contentWindow) {
			playerIframe.contentWindow.postMessage(
				{ type: 'move-position-from-editor', position: pos, message: message },
				'*'
			);
		}
	}, []);

	useEffect(() => {
		props.movePlayerToPosition?.(movePlayerToPosition);
		props.pause?.(pause);
		props.play?.(play);
		props.forward?.(forward);
		props.reward?.(reward);
		props.mute?.(mute);
		props.unMute?.(unMute);
	}, [
		props.pause,
		props.play,
		props.forward,
		props.reward,
		props.movePlayerToPosition,
		props.mute,
		props.unMute,
		movePlayerToPosition,
		pause,
		play,
		forward,
		reward,
		mute,
		unMute,
	]);

	return (
		<iframe
			id={PLAYER_IFRAME_ID}
			src={`${qplayerDomain}?accountId=${props.accountId}&scenarioId=${props.scenarioId}&configId=Enterprise&showBorder=true&fromSceneEditor=true`}
			frameBorder="0"
			border="0"
			height="100%"
			width="100%"
			style={{ position: 'absolute', top: '0', left: '0', pointerEvents: 'none' }}
			allow="autoplay"
		/>
	);
};

export default ScenarioEditorPlayerIframe;
