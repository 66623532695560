import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import QueryString from 'query-string';

export class OauthClient extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	componentWillUnmount() {
		window.removeEventListener('message', this.receiveMessage, false);
		if (this.popup && !this.popup.closed) {
			this.popup.close();
		}
	}

	messageHandler = (event) => {
		const { state } = this.props;
		if (event.origin === window.location.origin) {
			const params = QueryString.parse(event.data.searchParam);
			if (params.error) {
				if (params.state !== state) {
					this.popup && this.popup.close();
					return;
				}
				this.props.onFailure(params.error);
				this.popup && this.popup.close();
			} else if (params.code) {
				if (params.state !== state) {
					this.popup && this.popup.close();
					return;
				}
				this.props.onSuccess({ code: params.code });
				this.popup && this.popup.close();
			}
		}
	};

	generatetUrl = (_) => {
		const { state, scope, clientId, redirectUri, authorizeUrl } = this.props;
		const _scope = scope ? `&scope=${encodeURI(scope)}` : '';
		return `${authorizeUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${_scope}&state=${state}`;
	};

	handleClick = (e) => {
		if (e) {
			e.preventDefault();
		}
		const options = `width=${this.props.popupWidth},height=${this.props.popupHeight}`;
		const url = this.generatetUrl();
		this.popup = window.open(url, '_blank', options);
		window.removeEventListener('message', this.messageHandler, false);
		window.addEventListener('message', this.messageHandler, false);
	};

	render() {
		const { disabled, renderElement } = this.props;

		if (renderElement) {
			return renderElement({ onClick: this.handleClick, disabled });
		}

		return <button type="button" disabled={disabled} onClick={this.handleClick}></button>;
	}
}

OauthClient.propTypes = {
	authorizeUrl: PropTypes.string.isRequired,
	clientId: PropTypes.string.isRequired,
	redirectUri: PropTypes.string.isRequired,
	scope: PropTypes.string,
	state: PropTypes.string,
	onFailure: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	renderElement: PropTypes.func,
	className: PropTypes.string,
	popupWidth: PropTypes.string,
	popupHeight: PropTypes.string,
};

OauthClient.defaultProps = {
	disabled: false,
	popupWidth: 550,
	popupHeight: 550,
	state: 'undefined',
};
