import * as actions from '../actions/interactTimelineActions';
import { getTimelineStartDate } from './../services/timeStampService';
import { sequenceTypes } from '../constants/interactConstant';
import _ from 'underscore';

const initialState = {
	items: [],
	groups: [],
	selectedInteractItems: [],
	isInteractScreenModified: false,
	playerPositionToSet: getTimelineStartDate(),
	lastSetPlayerPositionTime: '',
	interacMediaLoopStarted: false,
	liveQueueItem: '',
	fetchInteractItemsCmd: false,
	trimTool: false,
	refreshInteractPlayer: '',
	disableVideoApplyButton: false,
	queueOperationInProgress: false,
};
export const interactTimelineReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.CLEAR_INTERACT_STORE:
			return {
				...initialState,
			};
		case actions.SET_SELECTED_INTERACT_TIMELINE_ITEM: {
			let videoItem = '';
			if (action.items && action.items.length > 0) {
				videoItem = _.find(state.items, (item) => {
					return item.id === action.items[0] && item.itemType === sequenceTypes.video;
				});
			}
			return {
				...state,
				selectedInteractItems: action.items,
				trimTool: videoItem ? true : false,
			};
		}

		case actions.REMOVE_SELECTED_INTERACT_TIMELINE_ITEM:
			if (action.item) {
				state.selectedInteractItems; //Todo remove specific selected item
			}
			return {
				...state,
				selectedInteractItems: [],
				trimTool: false,
			};

		case actions.SET_INTERACT_SCREEN_MODIFIED:
			return {
				...state,
				isInteractScreenModified: true,
			};

		case actions.SET_INTERACT_SCREEN_UNMODIFIED:
			return {
				...state,
				isInteractScreenModified: false,
			};

		case actions.CREATE_INTERACT_TIMELINE_ITEM: {
			let _items = state.items && state.items.concat(action.item);
			return {
				...state,
				items: _items,
				disableVideoApplyButton: action.item.itemType === sequenceTypes.video ? false : true,
			};
		}

		case actions.UPDATE_INTERACT_TIMELINE_ITEM:
			return {
				...state,
				// isInteractScreenModified: true,
				items: state.items.map((item) =>
					item.id === action.item.id
						? {
								...item,
								className: action.item.className,
								content: action.item.content,
								start: action.item.start,
								end: action.item.end,
								index: action.item.index,
						  }
						: item
				),
				disableVideoApplyButton: action.item.itemType === sequenceTypes.video ? false : true,
			};
		case actions.UPDATE_INTERACT_ITEMS:
			return {
				...state,
				// isInteractScreenModified: true,
				items: action.items,
			};
		case actions.REMOVE_INTERACT_TIMELINE_ITEM: {
			/*let isgroupHavingOtherItems = false
            isgroupHavingOtherItems = _.find((state.items), (item) => { return (item.group === action.item.group && item.id !== action.item.id)})
            let newGroup = state.groups
            if (!isgroupHavingOtherItems) {
                newGroup =  state.groups.filter(group => { return group.id !== action.item.group })
            }*/ //added for future - in case we have to remove group
			return {
				...state,
				items: state.items.filter((item) => {
					return item.id !== action.item.id;
				}),
				disableVideoApplyButton: action.item.itemType === sequenceTypes.video ? false : true,
				//groups: !isgroupHavingOtherItems ? newGroup : state.groups //added for group removal
			};
		}

		case actions.CREATE_INTERACT_TIMELINE_GROUP: {
			let groups = state.groups && state.groups.concat(action.group);
			return {
				...state,
				groups: groups,
			};
		}
		case actions.UPDATE_INTERACT_TIMELINE_GROUP:
			return {
				...state,
				groups: state.groups.map((group) =>
					group.id === action.group.id
						? {
								...group,
								content: action.group.content,
						  }
						: group
				),
			};
		case actions.REMOVE_INTERACT_TIMELINE_GROUP:
			return {
				...state,
				groups: state.groups.filter((group) => {
					return group.id !== action.group.id;
				}),
			};
		case actions.SET_LIVE_QUEUE_ITEM:
			return {
				...state,
				liveQueueItem: action.item,
			};
		case actions.SET_PLAYER_POSITION: {
			return {
				...state,
				playerPositionToSet: action.datetime,
				lastSetPlayerPositionTime: Date.now(),
			};
		}
		case actions.START_INTERACT_MEDIA_LOOP: {
			return {
				...state,
				interacMediaLoopStarted: true,
			};
		}
		case actions.STOP_INTERACT_MEDIA_LOOP: {
			return {
				...state,
				interacMediaLoopStarted: false,
			};
		}
		case actions.START_FETCH_INTERACT_ELEMENTS: {
			return {
				...state,
				items: [],
				fetchInteractItemsCmd: true,
			};
		}
		case actions.STOP_FETCH_INTERACT_ELEMENTS: {
			return {
				...state,
				fetchInteractItemsCmd: false,
			};
		}
		case actions.SHOW_TRIM_TOOL: {
			return {
				...state,
				trimTool: true,
			};
		}
		case actions.HIDE_TRIM_TOOL: {
			return {
				...state,
				trimTool: false,
			};
		}
		case actions.REFRESH_INTERACT_PLAYER: {
			return {
				...state,
				refreshInteractPlayer: Math.random(),
			};
		}
		case actions.DISABLE_VIDEO_APPLY_BUTTON: {
			return {
				...state,
				disableVideoApplyButton: true,
			};
		}
		case actions.ENABLE_VIDEO_APPLY_BUTTON: {
			return {
				...state,
				disableVideoApplyButton: false,
			};
		}
		case actions.SET_QUEUE_OPERATION: {
			return {
				...state,
				queueOperationInProgress: true,
			};
		}
		case actions.RESET_QUEUE_OPERATION: {
			return {
				...state,
				queueOperationInProgress: false,
			};
		}
		default:
			return state;
	}
};
