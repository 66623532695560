import * as mediaChatService from '../services/mediaChatService';
import { LocalStorageService } from '../services/localStorageService';
import { localStorageKeys } from '../constants/localStorageKeys';
import _ from 'underscore';
import { showMessage } from '../actions/globalActions';
import { messageTypes } from '../constants/mediaConstants';

export const SET_REPLY_MESSAGE = 'SET_REPLY_MESSAGE';
export const SET_CHAT_SESSION_DETAILS = 'SET_CHAT_SESSION_DETAILS';
export const SAVE_MEDIA_MESSAGES = 'SAVE_MEDIA_MESSAGES';
export const CLEAR_LIVE_CHAT_DETAILS = 'CLEAR_LIVE_CHAT_DETAILS';
export const SET_BLOCK_MESSAGE = 'SET_BLOCK_MESSAGE';
export const SEND_BLOCK_MESSAGE = 'SEND_BLOCK_MESSAGE';
export const SCROLL_CHAT_BOTTOM = 'SCROLL_CHAT_BOTTOM';
export const SAVE_BLOCKED_USERS = 'SAVE_BLOCKED_USERS';
export const SAVE_UNBLOCKED_USERS = 'SAVE_UNBLOCKED_USERS';
export const SET_PINED_MESSAGE_ID = 'SET_PINED_MESSAGE_ID';
export const UNSET_PINED_MESSAGE_ID = 'UNSET_PINED_MESSAGE_ID';
export const SAVE_CHAT_SETTING = 'SAVE_CHAT_SETTING';

export const getAccountId = (getState) => {
	if (getState) {
		return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

const setChatSession = (sessionDetails) => ({
	type: SET_CHAT_SESSION_DETAILS,
	sessionDetails,
});

export const setReplyMessage = (messageDetails) => ({
	type: SET_REPLY_MESSAGE,
	messageDetails,
});

export const sendBlockMessage = (id) => ({
	type: SEND_BLOCK_MESSAGE,
	id,
});

export const saveMediaMessages = (messages) => ({
	type: SAVE_MEDIA_MESSAGES,
	messages,
});

export const saveBlockedUsers = (blockedUsers) => ({
	type: SAVE_BLOCKED_USERS,
	blockedUsers,
});

export const saveUnblockedUsers = (unblockedUsers) => ({
	type: SAVE_UNBLOCKED_USERS,
	unblockedUsers,
});

export const clearLiveChatDetails = () => ({
	type: CLEAR_LIVE_CHAT_DETAILS,
});

export const scrollChatViewBottom = () => ({
	type: SCROLL_CHAT_BOTTOM,
	id: Math.random(),
});

export const setPinedMessageId = (messageId) => ({
	type: SET_PINED_MESSAGE_ID,
	messageId,
});

export const unsetPinedMessageId = () => ({
	type: UNSET_PINED_MESSAGE_ID,
});

export const saveChatSettings = (settings) => ({
	type: SAVE_CHAT_SETTING,
	settings,
});

export const getMediaChatSettings = (accId, mediaId) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	return mediaChatService.getMediaChatSettings(defaultAccountId, mediaId).then((data) => {
		if (data) {
			return data;
		} else {
			mediaChatService.getAccountChatSettings(defaultAccountId).then((data) => {
				if (data) {
					return data;
				} else {
					dispatch(showMessage('Failed to fetch chat settings', messageTypes.error));
				}
			});
		}
	});
};

export const saveMediaChatSettings = (accId, mediaId, body) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	dispatch(showMessage('Saving changes...', messageTypes.info));
	return mediaChatService
		.saveMediaChatSettings(defaultAccountId, mediaId, body)
		.then((data) => {
			dispatch(showMessage('Changes saved successfully', messageTypes.info));
			return data;
		})
		.catch((error) => {
			dispatch(showMessage('Failed to save changes', messageTypes.error));
			return error;
		});
};

export const getMessages = (accId, mediaId) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	dispatch(clearLiveChatDetails());
	return mediaChatService
		.getMessages(defaultAccountId, mediaId)
		.then((data) => {
			/*if (data) {
            data = [{
                id: '123',
                sender: {
                    name: 'Kamala Harris',
                },
                message: {
                    text: 'Hi, hope you are doing good'
                }
            },
            ]
        }*/
			if (data) {
				dispatch(saveMediaMessages(data));
				dispatch(scrollChatViewBottom());
				let pinedMessage = _.find(data, (msg) => {
					return msg.message.pinned;
				});
				if (pinedMessage) {
					dispatch(setPinedMessageId(pinedMessage.id));
				}
			}
			return data;
		})
		.catch((error) => {
			return error;
		});
};

export const setBlockMessage = (accId, mediaId, body) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	return mediaChatService
		.blockMessages(defaultAccountId, mediaId, body)
		.then((data) => {
			if (data == undefined) {
				dispatch(showMessage('Failed to block message', messageTypes.error));
			} else {
				dispatch(sendBlockMessage(body[0]));
				dispatch(showMessage('Message blocked', messageTypes.info));
			}
			return data;
		})
		.catch((error) => {
			dispatch(showMessage('Failed to block message', messageTypes.error));
			return error;
		});
};

export const setBlockUser = (accId, mediaId, body) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	return mediaChatService
		.blockUser(defaultAccountId, mediaId, body)
		.then((data) => {
			if (data == undefined) {
				dispatch(showMessage('Failed to block user', messageTypes.error));
			} else {
				dispatch(showMessage('User blocked', messageTypes.info));
			}
			return data;
		})
		.catch((error) => {
			dispatch(showMessage('Failed to block user', messageTypes.error));
			return error;
		});
};

export const setUnblockUser = (accId, mediaId, body) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	return mediaChatService
		.unblockUser(defaultAccountId, mediaId, body)
		.then((data) => {
			if (data == undefined) {
				dispatch(showMessage('Failed to unblock user', messageTypes.error));
			} else {
				dispatch(showMessage('User unblocked', messageTypes.info));
			}
			return data;
		})
		.catch((error) => {
			dispatch(showMessage('Failed to unblock user', messageTypes.error));
			return error;
		});
};

export const pinMessage = (accId, mediaId, messageId, body) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	return mediaChatService
		.pinMessage(defaultAccountId, mediaId, messageId, body)
		.then((data) => {
			if (data == undefined) {
				dispatch(showMessage('Failed to pin message', messageTypes.error));
			}
			return data;
		})
		.catch((error) => {
			dispatch(showMessage('Failed to pin message', messageTypes.error));
			return error;
		});
};

export const updateUnpinMessage = (accId, mediaId, messageId, body) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	return mediaChatService
		.updateMessage(defaultAccountId, mediaId, messageId, body)
		.then((data) => {
			if (data == undefined) {
				dispatch(showMessage('Failed to unpin message', messageTypes.error));
			}
			return data;
		})
		.catch((error) => {
			dispatch(showMessage('Failed to unpin message', messageTypes.error));
			return error;
		});
};

export const unpinMessage = (accId, mediaId, messageId, body) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	return mediaChatService
		.unpinMessage(defaultAccountId, mediaId, messageId, body)
		.then((data) => {
			if (data == undefined) {
				dispatch(showMessage('Failed to unpin message', messageTypes.error));
			}
			return data;
		})
		.catch((error) => {
			dispatch(showMessage('Failed to unpin message', messageTypes.error));
			return error;
		});
};

export const getBlockedUsersList = (accId, mediaId) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	return mediaChatService
		.getBlockedUsers(defaultAccountId, mediaId)
		.then((data) => {
			let userIds = [];
			if (data && data.map) {
				data.map((x) => {
					userIds.push(x.id);
				});
			}
			dispatch(saveBlockedUsers(userIds));
			return data;
		})
		.catch((error) => {
			return error;
		});
};

export const postMessage = (accId, mediaId, body) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	return mediaChatService
		.postMessage(defaultAccountId, mediaId, body)
		.then((data) => {
			if (data == undefined) {
				dispatch(showMessage('Failed to send message', messageTypes.error));
			}
			return data;
		})
		.catch((error) => {
			dispatch(showMessage('Failed to send message', messageTypes.error));
			return error;
		});
};

export const setChatSessionDetails = (mediaId) => (dispatch) => {
	let liveChatKey = localStorage.getItem(localStorageKeys.LIVE_MANAGER_CHAT);
	let mediaSessions = {};
	if (liveChatKey && typeof JSON.parse(liveChatKey) == 'object') {
		mediaSessions = JSON.parse(liveChatKey);
	}
	mediaSessions[mediaId] = true;
	dispatch(setChatSession(mediaSessions));
	LocalStorageService.setChatSessionDetails(JSON.stringify(mediaSessions));
};

export const getSingleMediaChatSessionDetails = (mediaId) => () => {
	let liveChatKey = LocalStorageService.getChatSessionDetails();
	if (liveChatKey) {
		let mediaSessions = JSON.parse(liveChatKey);
		if (mediaSessions[mediaId]) {
			return mediaSessions[mediaId];
		}
	}
	return null;
};

export const getMediaChatSessionDetails = (mediaId) => {
	let liveChatKey = LocalStorageService.getChatSessionDetails();
	if (liveChatKey) {
		let mediaSessions = JSON.parse(liveChatKey);
		if (mediaSessions[mediaId]) {
			return mediaSessions[mediaId];
		}
	}
	return null;
};

export const getAllQnAs =
	({ accountId, mediaId }) =>
	(dispatch, getState) => {
		const accId = accountId || getAccountId(getState);
		return mediaChatService
			.fetchAllQnAs({
				accountId: accId,
				mediaId,
			})
			.then((data) => data);
	};

export const getQuestions =
	({ accountId, mediaId, qnaId }) =>
	(dispatch, getState) => {
		const accId = accountId || getAccountId(getState);
		return mediaChatService.fetchQuestions({ accountId: accId, mediaId, qnaId }).then((data) => data);
	};

export const createQnA =
	({ accountId, mediaId, body }) =>
	(dispatch, getState) => {
		const accId = accountId || getAccountId(getState);
		return mediaChatService._createQnA({ accountId: accId, mediaId, body }).then((data) => data);
	};

export const updateQnA =
	({ accountId, mediaId, qnaId, body }) =>
	(dispatch, getState) => {
		const accId = accountId || getAccountId(getState);
		return mediaChatService._updateQnA({ accountId: accId, mediaId, qnaId, body }).then((data) => data);
	};

export const publishQnAPrompt =
	({ accountId, mediaId, qnaId }) =>
	(dispatch, getState) => {
		const accId = accountId || getAccountId(getState);
		return mediaChatService._publishQnAPrompt({ accountId: accId, mediaId, qnaId }).then((data) => data);
	};

export const unpublishQnAPrompt =
	({ accountId, mediaId, qnaId }) =>
	(dispatch, getState) => {
		if (!qnaId) {
			dispatch(showMessage('No qnas', messageTypes.error));
			return;
		}
		const accId = accountId || getAccountId(getState);
		return mediaChatService._unpublishQnAPrompt({ accountId: accId, mediaId, qnaId }).then((data) => data);
	};

export const approveQuestion =
	({ accountId, mediaId, qnaId, questionId, body }) =>
	(dispatch, getState) => {
		if (!qnaId || !questionId) {
			dispatch(showMessage('No qnas', messageTypes.error));
			return;
		}
		const accId = accountId || getAccountId(getState);
		return mediaChatService
			._approveQuestion({ accountId: accId, mediaId, qnaId, questionId, body })
			.then((data) => data);
	};

export const discardQuestion =
	({ accountId, mediaId, qnaId, questionId, body }) =>
	(dispatch, getState) => {
		if (!qnaId || !questionId) {
			dispatch(showMessage('No qnas', messageTypes.error));
			return;
		}
		const accId = accountId || getAccountId(getState);
		return mediaChatService
			._discardQuestion({ accountId: accId, mediaId, qnaId, questionId, body })
			.then((data) => data);
	};

export const answerQuestion =
	({ accountId, mediaId, qnaId, questionId, body }) =>
	(dispatch, getState) => {
		if (!qnaId || !questionId) {
			dispatch(showMessage('No qnas', messageTypes.error));
			return;
		}
		const accId = accountId || getAccountId(getState);
		return mediaChatService
			._answerQuestion({ accountId: accId, mediaId, qnaId, questionId, body })
			.then((data) => data);
	};
