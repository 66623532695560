export const featurePermissionsList = {
	library: {
		featureId: 'FeaXDl5cijPz0ijQJQJg0SmxA',
		name: 'Library',
		accountRestricted: true,
		serviceIds: [
			'ASSETS',
			'CATALOGS',
			'MEDIAS',
			'PLAYLISTS',
			'CDN_ADMIN',
			'INGEST_ASSETS',
			'INGEST_MEDIAS',
			'CUSTOM_METADATA',
			'CDN_SELFSERVICE',
			'CDN_PURGE',
			'RECORD_MEDIAS',
			'WORKFLOW',
		],
	},
	publish: {
		featureId: 'FeaHZxk2329Y0qja4hc9Fknow',
		name: 'Publish',
		accountRestricted: true,
		serviceIds: ['RESTRICTIONS', 'RESTRICTION_RULES', 'SHARING_CONNECT', 'SHARING_PUBLISH', 'SHARING_ANALYTICS'],
	},
	analytics: {
		featureId: 'FeaQVkhwYEcY0O0Q3JxCGYg3Q',
		name: 'Analytics',
		accountRestricted: true,
		serviceIds: ['CDN_ANALYTICS', 'LIBRARY_ANALYTICS', 'PLAYER_ANALYTICS'],
	},
	account: {
		featureId: 'FeaEUXAlmqRxESo2pUmqecqPQ',
		name: 'Account',
		accountRestricted: true,
		serviceIds: ['PERMISSIONS', 'ACCOUNTS_USERS'],
	},
	play2: {
		featureId: 'FeapizSC0CeWE6whJOlQ3jxUw',
		name: 'Play2',
		accountRestricted: true,
		serviceIds: ['PLAYER_CONFIGURATIONS'],
	},
	administration: {
		featureId: 'FeamQapwN2dokG5ImHB2frVYg',
		name: 'Administration',
		accountRestricted: false,
		serviceIds: [
			'CDN_ANALYTICS',
			'LIBRARY_ANALYTICS',
			'PLAYER_ANALYTICS',
			'ASSETS',
			'CATALOGS',
			'RESTRICTIONS',
			'MEDIAS',
			'PLAYLISTS',
			'RESTRICTION_RULES',
			'CDN_ADMIN',
			'INGEST_ASSETS',
			'INGEST_MEDIAS',
			'ACCOUNTS',
			'CUSTOMERS',
			'CUSTOM_METADATA',
			'FEATURES',
			'PERMISSIONS',
			'PRODUCTS',
			'SERVICES',
			'USERS',
			'USER_GROUPS',
			'USER_ROLES',
			'CDN_SELFSERVICE',
			'CDN_PURGE',
			'RECORD_MEDIAS',
			'PLAY2AVPAPI_CONFIGURATION',
			'IMPERSONATION',
			'SHARING_CONNECT',
			'SHARING_PUBLISH',
			'SHARING_ANALYTICS',
			'USERS_GET_SINGLE',
			'USERS_CREATE',
			'ACCOUNTS_USERS',
			'FEATURES_GET',
			'APPS',
			'WORKFLOW',
		],
	},
	usersAndFeatures: {
		featureId: 'Feaf1atQZWrAUy9kuZUsFPg0w',
		name: 'Users and features',
		accountRestricted: false,
		serviceIds: ['USERS_GET_SINGLE', 'USERS_CREATE', 'FEATURES_GET'],
	},
	interact: {
		featureId: 'FeaDiiUrPmmt0xLtC3BjFAzCQ',
		name: 'Interact [Legacy]',
		accountRestricted: false,
		serviceIds: ['INTERACT'],
	},
	legacy_ovp: {
		featureId: 'Fea0czhs0DMu0mPYnEY57hcQA',
		name: 'legacy_ovp',
		accountRestricted: false,
		serviceIds: ['LEGACY_OVP'],
	},
	apps: {
		featureId: 'FeaPJKTZ0ozgkWLhKkBnrGLHg',
		name: 'Apps',
		accountRestricted: true,
		serviceIds: ['APPS'],
	},
	channels: {
		featureId: 'FeaKvNQx4xLXkWtyXcofZUh9Q',
		name: 'Channels',
		accountRestricted: false,
		serviceIds: ['CHANNELS'],
	},
	scenarios: {
		featureId: 'Feak7oTx1NPqkWXAoFs2iswJA',
		name: 'Interact',
		accountRestricted: true,
		serviceIds: ['SCENARIOS'],
	},
	legacyLive: {
		featureId: 'Fea1xTSdbQcH0Kl8jMVX6roVQ',
		name: 'Legacy live',
		accountRestricted: false,
		serviceIds: ['SCENARIOS'],
	},
	speech2Text: {
		featureId: 'Feaxxz1cw3xWUCZ7JKqFteVxA',
		name: 'Speech2Text',
		accountRestricted: false,
		serviceIds: ['SPEECH2TEXT'],
	},
	ingest: {
		featureId: 'FeaBnbWlTSrCExM1tpAdQnKkQ',
		name: 'Ingest',
		accountRestricted: false,
		serviceIds: ['INGEST'],
	},
	adobeExpress: {
		accountRestricted: true,
		featureId: 'FeaHM2IjQ7xz0yWufDEWOql3A',
		name: 'Adobe Express',
		serviceIds: ['ADOBE_EXPRESS'],
	},
	accountAdmin: {
		accountRestricted: true,
		featureId: 'Feaywn4KxuYIk27FPbbp0xWxw',
		name: 'Account Admin',
		serviceIds: ['USER_ACCOUNTS'],
	},
};

export const ACCESS_LEVEL = {
	NONE: 0,
	READ: 1,
	READ_WRITE: 2,
};
