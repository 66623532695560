export const odSelectionList = {
    POSTCOVER: 'postCover',
    FULLSTREAM: 'fullStream'
};

export const LIVE_STREAM_POSITION = -10; //changing according to player

export const liveSettingsList = {
    STREAM: 'streamSettings',
    INTERACT: 'interactQueue',
    SNAPSHOT: 'snapshot',
    CHAT: 'chat',
    BROADCASTER: 'broadcaster'
};

export const streamSettingsId = {
    GENERAL: 'general',
    THUMBNAIL: 'thumbnail',
    PRECOVER: 'precover',
    POSTCOVER: 'postcover'
};

export const broadcasterSettingsId = {
    GENERATE_QR: 'generateQR',
    ABOUT: 'about',
};

export const liveManagerPopOutWindowList = {
    PLAYER: 'player',
    TIMELINE: 'timeline',
    SETTINGS: 'settings'
};

export const MIN_SNAPSHOT_TIME = 10;
export const BUFFER_TIMELINE_PERCENT = (1/3);

