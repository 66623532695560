import { createContext } from 'react';

interface HeaderContextProps {
	uploadProgressStatus?: string;
	setUploadProgressStatus: (_status: string) => void;
	uploadedFiles?: any[];
	setUploadingFiles: (_files: any[]) => void;
}

export const HeaderContext = createContext<HeaderContextProps>({
	setUploadProgressStatus: (_status) => {},
	setUploadingFiles: (_files) => {},
});
