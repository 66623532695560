import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Snackbar from '@material-ui/core/Snackbar';

import { showNotification, hideNotification } from '../../actions/notificationActions';

class SnackBar extends Component {
	state = {
		vertical: 'top',
		horizontal: 'center',
	};

	openSnackBar = () => (_) => {
		this.props.showNotification();
		this.setState({ open: true });
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.open !== this.props.open && nextProps.open) {
			setTimeout(() => {
				this.props.hideNotification();
			}, 2000);
		}
	}

	handleClose = (_) => this.props.hideNotification();

	render() {
		const { open, message } = this.props;
		const { vertical, horizontal } = this.state;

		return (
			<div>
				<Snackbar
					open={open}
					className="snackbar"
					onClose={this.handleClose}
					anchorOrigin={{ vertical, horizontal }}
					ContentProps={{ 'aria-describedby': 'message-id' }}
					message={<span id="message-id">{message}</span>}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({ notification }) => ({
	message: notification.message,
	open: notification.showNotification,
});

const mapDispatchToProps = (dispatch) => ({
	hideNotification: (_) => dispatch(hideNotification()),
	showNotification: (message) => dispatch(showNotification(message)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(SnackBar);
