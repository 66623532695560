import * as actions from '../actions/accountFeaturesActions';

const initialState = {
	features: [],
};

export const accountFeaturesReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SEND_GET_ACCOUNT_FEATURES_SUCCESS:
			return {
				...state,
				features: action.features,
			};
		default:
			return state;
	}
};
