import React from "react";
import PropTypes from 'prop-types';
import { TablePagination } from "@material-ui/core";

const Pagination = (props) => {
    return <TablePagination
        component={props.component}
        count={props.count}
        page={props.page}
        onPageChange={props.onPageChange}
        rowsPerPage={props.rowsPerPage}
        onRowsPerPageChange={props.onRowsPerPageChange}
        labelRowsPerPage={props.labelRowsPerPage}
    />;
};

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  labelRowsPerPage: PropTypes.string,
  component: PropTypes.string
};

Pagination.defaultProps = {
  count: 0,
  page: 0,
  rowsPerPage: 50,
  component: "div"
};

export default Pagination;