import * as actions from '../actions/iponlyChannelActions';

const initialState = {
	resources: [],
	channels: [],
};
export const iponlyChannelReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SEND_GET_ALL_CHANNEL_SUCCESS:
			return {
				...state,
				channels: action.channels,
			};
		case actions.SEND_GET_ALL_CHANNEL_FAILURE:
			return {
				...state,
				channels: [],
			};
		case actions.SEND_GET_RESOURCES_SUCCESS:
			return {
				...state,
				resources: action.resources,
			};
		case actions.SEND_GET_RESOURCES_FAILURE:
			return {
				...state,
				resources: [],
			};
		default:
			return state;
	}
};
