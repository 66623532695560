import * as actions from '../actions/playerActions';

const initialState = {
	play: false,
	pause: false,
};
export const playerReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.PLAY_VIDEO:
			return {
				...state,
				play: true,
				pause: false,
			};
		case actions.PAUSE_VIDEO:
			return {
				...state,
				play: false,
				pause: true,
			};
		default:
			return state;
	}
};
