import { PLAYER_IFRAME_ID, POST_MESSAGE_TYPE } from '../constants/scenarioConstant';
import { LocalStorageService } from '../services/localStorageService';
import { newAssets } from '../services/mediaService';
import {
	addNewLayoutToSceneService,
	addNewSceneToScenarioService,
	addNewWidgetService,
	createNewScenarioService,
	deleteSceneService,
	deleteWidgetService,
	getScenarioByIdService,
	updateScenarioService,
	updateSceneService,
	updateWidgetService,
} from '../services/scenarioService';
import { getCurrentRotation } from '../utils/commonUtil';

export const getAccountId = (getState) => {
	if (getState) {
		return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

export const CREATE_SCENARIO = 'CREATE_SCENARIO';
export const UPDATE_SCENARIO = 'UPDATE_SCENARIO';
export const SET_ORIGIN_SCENARIO = 'SET_ORIGIN_SCENARIO';

export const UPDATE_SCENES = 'UPDATE_SCENES';
export const UPDATE_UPLOADING_SCENES = 'UPDATE_UPLOADING_SCENES';
export const ADD_SCENES_METADATA = 'ADD_SCENES_METADATA';

export const UPDATE_EDITING_SCENE = 'UPDATE_EDITING_SCENE';
export const UPDATE_EDITING_SCENE_DURATION = 'UPDATE_EDITING_SCENE_DURATION';
export const UPDATE_SCENARIO_ACTIVE_LAYOUT = 'UPDATE_SCENARIO_ACTIVE_LAYOUT';

export const SET_UPLOADED_VIDEO_OF_SCENE_WIDGET = 'SET_UPLOADED_VIDEO_OF_SCENE_WIDGET';
export const SET_SCENE_LOADING = 'SET_SCENE_LOADING';
export const SET_IS_DATA_CHANGE = 'SET_IS_DATA_CHANGE';
export const SET_IS_TIMELINE_CHANGE = 'SET_IS_TIMELINE_CHANGE';
export const SET_IS_PLAYING = 'SET_IS_PLAYING';
export const SET_SAVE_SCENARIO = 'SET_SAVE_SCENARIO';

export const ADD_WIDGET_TO_UPDATING_API_DICS = 'ADD_WIDGET_TO_UPDATING_API_DICS';
export const EMPTY_WIDGET_UPDATING_API_DICS = 'EMPTY_WIDGET_UPDATING_API_DICS';

export const ADD_SCENE_TO_UPDATING_API_DICS = 'ADD_SCENE_TO_UPDATING_API_DICS';
export const EMPTY_SCENE_WIDGET_UPDATING_API_DICS = 'EMPTY_SCENE_WIDGET_UPDATING_API_DICS';
export const REFRESH_SCENE_MEDIA = 'REFRESH_SCENE_MEDIA';

export const setRefreshSceneMedia = (mediaIds) => ({
	type: REFRESH_SCENE_MEDIA,
	sceneMediaIdsToRefresh: mediaIds,
});

export const setSaveScenario = (shouldTriggerSave) => ({
	type: SET_SAVE_SCENARIO,
	shouldTriggerSave,
});

export const setOriginScenarioAction = (originScenario) => ({
	type: SET_ORIGIN_SCENARIO,
	originScenario,
});

export const addScenesMetadata = (sceneMetadata) => ({
	type: ADD_SCENES_METADATA,
	sceneMetadata,
});

export const updateIsDataChangedAction = (isDataChanged) => ({
	type: SET_IS_DATA_CHANGE,
	isDataChanged,
});

export const updateIsTimeLineChangedAction = (timelineChanged) => ({
	type: SET_IS_TIMELINE_CHANGE,
	timelineChanged,
});

export const createScenarioAction = (scenario) => ({
	type: CREATE_SCENARIO,
	scenario,
});

export const updateScenarioAction = (scenario) => ({
	type: UPDATE_SCENARIO,
	scenario,
});

export const setUploadedVideoOfSceneWidget = (mediaId) => ({
	type: SET_UPLOADED_VIDEO_OF_SCENE_WIDGET,
	mediaId,
});

export const setSceneLoading = (sceneLoading) => ({
	type: SET_SCENE_LOADING,
	sceneLoading,
});

export const updateScenesAction = (scenes) => ({
	type: UPDATE_SCENES,
	scenes,
});

export const updateUploadingScenesAction = (uploadingScenes) => ({
	type: UPDATE_UPLOADING_SCENES,
	uploadingScenes,
});

export const addWidgetsToUpdateWaitingList = (widgets) => ({
	type: ADD_WIDGET_TO_UPDATING_API_DICS,
	payload: widgets,
});

export const emptyWidgetsToUpdateWaitingList = () => ({
	type: EMPTY_WIDGET_UPDATING_API_DICS,
});

export const updateEditingSceneAction = (editingScene) => ({
	type: UPDATE_EDITING_SCENE,
	editingScene,
});

export const updateEditingSceneDurationAction = (duration) => ({
	type: UPDATE_EDITING_SCENE_DURATION,
	duration,
});

export const updateScenarioActiveLayoutAction = (activeLayout) => ({
	type: UPDATE_SCENARIO_ACTIVE_LAYOUT,
	activeLayout,
});

export const addScenesToUpdateWaitingList = (scenes) => ({
	type: ADD_SCENE_TO_UPDATING_API_DICS,
	payload: scenes,
});

export const emptyScenesToUpdateWaitingList = () => ({
	type: EMPTY_SCENE_WIDGET_UPDATING_API_DICS,
});

export const createScenario = (accountId, scenarioTitle, scenarioId, assetId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return newAssets(defaultAccountId, assetId)
		.then((_) => {
			return createNewScenarioService(defaultAccountId, scenarioTitle, scenarioId, assetId).then((data) => {
				return data;
			});
		})
		.catch((error) => {
			return error;
		});
};

export const getScenario =
	(accountId, scenarioId, noReset = false) =>
	(dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		return getScenarioByIdService(defaultAccountId, scenarioId)
			.then((scenario) => {
				if (scenario && !noReset) {
					dispatch(updateScenarioAction(scenario));
					dispatch(updateScenesAction(scenario.scenes ?? []));
				}
				return scenario;
			})
			.catch((error) => error);
	};

export const updateScenario = (accountId, scenarioId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return updateScenarioService(defaultAccountId, scenarioId, body);
};

export const createScene = (accountId, scenarioId, sceneId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return addNewSceneToScenarioService(defaultAccountId, scenarioId, sceneId, body);
};

export const updateScene = (accountId, scenarioId, sceneId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return updateSceneService(defaultAccountId, scenarioId, sceneId, body);
};

export const deleteScene = (accountId, scenarioId, sceneId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return deleteSceneService(defaultAccountId, scenarioId, sceneId);
};

export const addNewLayoutToScene = (accountId, scenarioId, sceneId, layoutId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return addNewLayoutToSceneService(defaultAccountId, scenarioId, sceneId, layoutId, body);
};

export const createWidget =
	(accountId, scenarioId, sceneId, layoutId, boxId, widgetId, body) => (dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		return addNewWidgetService(defaultAccountId, scenarioId, sceneId, layoutId, boxId, widgetId, body);
	};
export const updateWidget =
	(accountId, scenarioId, sceneId, layoutId, boxId, widgetId, body) => (dispatch, getState) => {
		const defaultAccountId = accountId || getAccountId(getState);
		return updateWidgetService(defaultAccountId, scenarioId, sceneId, layoutId, boxId, widgetId, body);
	};

export const deleteWidget = (accountId, scenarioId, sceneId, layoutId, boxId, widgetId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return deleteWidgetService(defaultAccountId, scenarioId, sceneId, layoutId, boxId, widgetId);
};

export const updateEditingSceneAndSendToPlayer = (editingScene) => (dispatch, getState) => {
	if (!editingScene) {
		return;
	}
	const activeLayout = getState().scenarioReducer.activeLayout ?? 'mobile';

	postSceneToPlayer(editingScene, activeLayout);
	dispatch(updateEditingSceneAction(editingScene));
};

export const postScenarioToPlayer = (scenario, activeLayout) => {
	setTimeout(() => {
		const playerIframe = document.getElementById(PLAYER_IFRAME_ID);
		if (playerIframe && playerIframe.contentWindow) {
			playerIframe.contentWindow.postMessage(
				{ type: POST_MESSAGE_TYPE.scenario, scenario: scenario, activeLayout: activeLayout },
				'*'
			);
		}
	});
};

export const postSceneToPlayer = (scene, activeLayout) => {
	if (!scene) {
		return;
	}

	setTimeout(() => {
		const playerIframe = document.getElementById(PLAYER_IFRAME_ID);
		if (playerIframe && playerIframe.contentWindow) {
			playerIframe.contentWindow.postMessage(
				{ type: POST_MESSAGE_TYPE.scene, scene: scene, activeLayout: activeLayout },
				'*'
			);
		}
	}, 1000);
};

const getUpdateValue = (getState, editingBox) => {
	const state = getState();
	const widgetDOMLists = [...document.getElementById('svgWidgetOverlay').children] ?? [];
	const editingScene = state.scenarioReducer.editingScene;
	const defaultAccountId = state.session.defaultAccountId;
	const activeLayout = state.scenarioReducer.activeLayout;
	const scenario = state.scenarioReducer.scenario;
	let updatingWidgetTemplates = {};
	const widgetsToUpdateToApi = [];

	widgetDOMLists.forEach((dom) => {
		const widgetTemplateId = dom.getAttribute('id');
		if (!widgetTemplateId) {
			return;
		}
		const child = dom.children[0];
		const rotation = getCurrentRotation(child);

		// x y only correct if we get position before rotate
		const currentStyle = child.style.transform;
		const updateStyle = currentStyle.replace(/rotate\((.*)deg\)/, 'rotate(0deg)');
		child.style.transform = updateStyle;

		const { x, y, width, height } = dom.getBBox();
		child.style.transform = currentStyle;
		let updatingWidgetTemplate = (editingScene.widgetTemplates ?? []).find(({ id }) => id === widgetTemplateId);
		let {
			style: {
				coordinate: { x: oldX, y: oldY },
				dimension: { w: oldW, h: oldH },
				rotation: oldRotation,
			},
		} = updatingWidgetTemplate;
		if (x !== oldX || y !== oldY || width !== oldW || height !== oldH || rotation !== oldRotation) {
			updatingWidgetTemplate = {
				...updatingWidgetTemplate,
				style: {
					...updatingWidgetTemplate.style,
					coordinate: { x, y },
					dimension: { w: width, h: height },
					rotation: rotation,
				},
			};

			updatingWidgetTemplates[widgetTemplateId] = updatingWidgetTemplate;

			let currentLayout = editingScene?.layouts?.find((l) => l.type === activeLayout);
			currentLayout = currentLayout ?? editingScene?.layouts?.[0] ?? {};

			const box = currentLayout.boxes?.find((box) => box.boxTemplateId === editingBox) ?? {};
			const updatingWidget = box.widgets?.find((widget) => widget.widgetTemplateId === widgetTemplateId);

			if (updatingWidget) {
				const { id: scenarioId } = scenario;

				widgetsToUpdateToApi.push({
					defaultAccountId,
					scenarioId,
					sceneId: editingScene.id,
					layoutId: currentLayout?.id,
					boxId: box.boxTemplateId,
					widgetId: updatingWidget.id,
					body: { style: updatingWidgetTemplate.style },
				});
			}
		}
	});

	return { updatingWidgetTemplates, widgetsToUpdateToApi };
};
export const updateWidgetPositionAction =
	({ editingBox }) =>
	(dispatch, getState) => {
		const state = getState();
		const editingScene = state.scenarioReducer.editingScene;
		const { updatingWidgetTemplates, widgetsToUpdateToApi } = getUpdateValue(getState, editingBox);

		const widgetTemplatesWithoutUpdating = (editingScene.widgetTemplates ?? []).filter(
			({ id }) => !updatingWidgetTemplates[id]
		);

		dispatch(
			updateEditingSceneAction({
				...editingScene,
				widgetTemplates: [...widgetTemplatesWithoutUpdating, ...Object.values(updatingWidgetTemplates)],
			})
		);

		dispatch(addWidgetsToUpdateWaitingList(widgetsToUpdateToApi));
	};
