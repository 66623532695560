import _ from 'underscore';

import { mediaTypes } from './../constants/mediaConstants';

import audio_nothumbnail_playicon from '../assets/images/audio_nothumbnail_playicon.svg';
import image_nothumbnail_playicon from '../assets/images/image_nothumbnail_playicon.svg';
import playlist_playicon from '../assets/images/playlist.svg';
import catalog_nothumbnail from '../assets/images/catalog_nothumbnail.svg';
import multi_catalog from '../assets/images/multi_catalog.svg';
import video_nothumbnail_playicon from '../assets/images/video_nothumbnail_playicon.svg';
import live_nothumbnail_playicon from '../assets/images/live_nothumbnail_playicon.svg';
import no_thumbnail from '../assets/svg/NoThumbnail.svg';

export const createDefaultMedia = (id, name, type) => {
	let nowDate = new Date() || Date.now();
	let mockMedia = {
		id: id,
		created: nowDate,
		updated: nowDate,
		createdBy: {
			id: '',
		},
		updatedBy: {
			id: '',
		},
		metadata: {
			title: name,
		},
		notReady: true,
	};
	if (type == mediaTypes.catalog) {
		mockMedia['medias'] = {
			count: 0,
		};
	}
	if (type == mediaTypes.livevideo) {
		mockMedia['asset'] = {
			resources: [{ type: mediaTypes.livevideo }],
		};
	}
	return mockMedia;
};

export const getDetails = (eachMedia, isPlaylistsLoaded) => {
	if (!eachMedia) {
		return null;
	}
	if (
		eachMedia.asset != undefined &&
		eachMedia.asset.resources != undefined &&
		eachMedia.asset.resources.length != undefined
	) {
		return getCustomDetails(eachMedia, isPlaylistsLoaded);
	} else if (
		eachMedia.asset === undefined ||
		eachMedia.asset.resources === undefined ||
		eachMedia.asset.resources.length === undefined
	) {
		return getDefaultMediaDetails(eachMedia, isPlaylistsLoaded);
	}
};

export const getCustomDetails = (eachMedia, isPlaylistsLoaded) => {
	let duration = '';
	let thumbnail = '';
	if (eachMedia.type && eachMedia.type === mediaTypes.playlists) {
		isPlaylistsLoaded = true;
	}
	let type = isPlaylistsLoaded ? mediaTypes.playlists : eachMedia.type || getTypeOfMedia(eachMedia);
	let icon = getPlayIcon(type, isPlaylistsLoaded);
	thumbnail = getThumbnail(eachMedia, isPlaylistsLoaded);
	duration = getDurationOfVideo(eachMedia);
	if (!isPlaylistsLoaded) {
		if (type == mediaTypes.video && !thumbnail) {
			thumbnail = no_thumbnail;
		} else if (type == mediaTypes.catalog) {
			thumbnail = catalog_nothumbnail;
			if (
				(eachMedia.children && eachMedia.children.length > 0) ||
				(eachMedia.medias && eachMedia.medias.count > 0)
			) {
				thumbnail = multi_catalog;
			}
		}
	}

	return {
		asset: eachMedia.asset,
		access: eachMedia.access,
		custom: eachMedia.custom,
		tracks: eachMedia.tracks || [],
		type: type,
		id: eachMedia.id,
		thumbnail: thumbnail,
		updated: eachMedia.updated,
		created: eachMedia.created,
		mediaCount: eachMedia.medias,
		metadata: eachMedia.metadata,
		title: eachMedia?.metadata?.title ?? eachMedia?.title ?? 'No title',
		duration: duration,
		playicon: icon,
		hits: eachMedia.hits ? eachMedia.hits : '0',
		mimeType: getMimeType(eachMedia, type),
		name: eachMedia.createdBy ? eachMedia.createdBy.id : '',
		description: eachMedia.metadata ? eachMedia.metadata.description : '',
		hasChildren: eachMedia.children && eachMedia.children.length > 0, //is true when having sub catalogs
		includedChildren: eachMedia.medias ? eachMedia.medias.count : 0, // is true when having medias within a catalog
		parentId: getParentIdBasedOntype(eachMedia, type, isPlaylistsLoaded),
		notReady: eachMedia.notReady,
		restrictions: getRestrictionIdList(eachMedia.restrictions),
		thumbnails: eachMedia.thumbnails,
		inheritedRestrictions: getRestrictionIdList(eachMedia.inheritedRestrictions),
		appliedRestrictions: getRestrictionIdList(eachMedia.restrictions),
		tags: eachMedia.tags,
	};
};

export const getDefaultMediaDetails = (eachMedia, isPlaylistsLoaded) => {
	let type = eachMedia.hasOwnProperty('medias')
		? mediaTypes.catalog
		: eachMedia.hasOwnProperty('scenes')
		? mediaTypes.scenario
		: '';
	let thumbnail = no_thumbnail;
	let playicon = type == mediaTypes.catalog ? catalog_nothumbnail : video_nothumbnail_playicon;
	if (eachMedia.type && eachMedia.type === mediaTypes.playlists) {
		isPlaylistsLoaded = true;
	}
	if (isPlaylistsLoaded) {
		type = eachMedia.hasOwnProperty('medias') || eachMedia.hasOwnProperty('query') ? mediaTypes.playlists : '';
		thumbnail = no_thumbnail;
		playicon = playlist_playicon;
	} else {
		if (type == mediaTypes.video) {
			thumbnail = no_thumbnail;
		} else if (type == mediaTypes.catalog) {
			thumbnail = catalog_nothumbnail;
			if (
				(eachMedia.children && eachMedia.children.length > 0) ||
				(eachMedia.medias && eachMedia.medias.count > 0)
			) {
				thumbnail = multi_catalog;
			}
		}
	}

	return {
		asset: eachMedia.asset,
		access: eachMedia.access,
		custom: eachMedia.custom,
		tracks: eachMedia.tracks || [],
		type: eachMedia.type || type,
		id: eachMedia.id,
		thumbnail: thumbnail,
		updated: eachMedia.updated,
		created: eachMedia.created,
		metadata: eachMedia.metadata,
		title: eachMedia.metadata?.title ?? 'No title',
		duration: '',
		mediaCount: eachMedia.medias,
		playicon: playicon,
		hits: eachMedia.hits ? eachMedia.hits : '0',
		mimeType: getMimeType(eachMedia, type),
		name: eachMedia.createdBy ? eachMedia.createdBy.id : '',
		description: eachMedia.metadata ? eachMedia.metadata.description : '',
		hasChildren: eachMedia.children && eachMedia.children.length > 0,
		includedChildren: eachMedia.medias ? eachMedia.medias.count : 0,
		parentId: getParentIdBasedOntype(eachMedia, type, isPlaylistsLoaded),
		notReady: eachMedia.notReady,
		restrictions: getRestrictionIdList(eachMedia.restrictions),
		inheritedRestrictions: getRestrictionIdList(eachMedia.inheritedRestrictions),
		appliedRestrictions: getRestrictionIdList(eachMedia.restrictions),
		tags: eachMedia.tags,
	};
};

export const getParentIdBasedOntype = (eachMedia, type) => {
	if (type == mediaTypes.catalog) {
		return eachMedia.parentId;
	} else {
		if (eachMedia.catalog) {
			return eachMedia.catalog.id;
		}
	}
	return '';
};

export const getRestrictionIdList = (restrictions) => {
	let restrictionList = [];
	if (restrictions && restrictions.map) {
		restrictions.map((restriction) => {
			restrictionList.push(restriction.id ?? restriction);
		});
	}
	return restrictionList;
};

export const getPlayIcon = (type = '', playlistsLoaded) => {
	if (!playlistsLoaded) {
		switch (type) {
			case mediaTypes.playlists:
				return playlist_playicon;
			case mediaTypes.liveaudio:
			case mediaTypes.livevideo:
				return live_nothumbnail_playicon;
			case mediaTypes.image:
				return image_nothumbnail_playicon;
			case mediaTypes.audio:
				return audio_nothumbnail_playicon;
			case mediaTypes.video:
				return video_nothumbnail_playicon;
			case mediaTypes.catalog:
				return catalog_nothumbnail;
			default:
				return video_nothumbnail_playicon;
		}
	} else {
		return playlist_playicon;
	}
};

export const getThumbnail = (eachMedia, playlistsLoaded) => {
	let resources = [];
	let renditions = [];
	let thumbnailId = false;

	if (!(eachMedia && eachMedia.asset && eachMedia.asset.resources)) {
		return null;
	}
	let mediaType = getTypeOfMedia(eachMedia);
	resources = eachMedia.asset.resources;
	if (eachMedia.thumbnails && eachMedia.thumbnails.length > 0) {
		thumbnailId = eachMedia.thumbnails[0].id;
	}
	if (thumbnailId) {
		let resource = getResourceFromThumbnailId(resources, thumbnailId);
		if (resource && resource.renditions && resource.renditions.length) {
			renditions = getSortedNumberBasedOnWidth(resource.renditions);
			return renditions;
		}
	}

	if (playlistsLoaded) {
		renditions = getThumbnailFromResourcesWithoutThumbnailId(resources);
		if (renditions.length > 0) {
			return renditions;
		}
		return no_thumbnail;
	}

	if (mediaType == mediaTypes.liveaudio || mediaType == mediaTypes.livevideo) {
		renditions = getThumbnailFromResourcesWithoutThumbnailId(resources);
		if (renditions.length > 0) {
			return renditions;
		}
		return no_thumbnail;
	}
	if (mediaType == mediaTypes.audio) {
		renditions = getThumbnailFromResourcesWithoutThumbnailId(resources);
		if (renditions.length > 0) {
			return renditions;
		}
		return no_thumbnail;
	}
	if (mediaType == mediaTypes.video) {
		renditions = getThumbnailFromResourcesWithoutThumbnailId(resources);
		if (renditions.length > 0) {
			return renditions;
		}
		return no_thumbnail;
	}

	renditions = getThumbnailFromResourcesWithoutThumbnailId(resources);
	if (renditions.length > 0) {
		return renditions;
	}
	return no_thumbnail;
};

export const getThumbnailFromResourcesWithoutThumbnailId = (resources) => {
	let renditions = [];
	let resourcesWithImages = resources.filter(
		(eachResource) => eachResource && eachResource.type === mediaTypes.image && eachResource.renditions
	);
	let resourcesWithThumbnail = resourcesWithImages.filter(
		(eachResource) => eachResource && eachResource.rel && eachResource.rel.indexOf('thumbnail') != -1
	);
	if (resourcesWithThumbnail.length > 0) {
		return getSortedNumberBasedOnWidth(resourcesWithThumbnail[0].renditions);
	} else if (resourcesWithImages.length > 0) {
		return getSortedNumberBasedOnWidth(resourcesWithImages[0].renditions);
	}
	return renditions;
};

export const getHighQThumbnailFromRenditions = (renditionsList) => {
	renditionsList = renditionsList.sort((a, b) => (a.width > b.width ? 1 : -1));
	let renditions = getSortedNumberBasedOnWidth(renditionsList).reverse();

	for (let rendition in renditions) {
		if (renditions && renditions[rendition] && renditions[rendition].width) {
			if (renditions[rendition].links && renditions[rendition].links.length > 0) {
				return renditions[rendition].links[0].href;
			}
		}
	}

	return getThumbnailFromRenditionsBasedOnWidth(renditionsList);
};

export const getThumbnailFromRenditionsBasedOnWidth = (renditions, width) => {
	if (!renditions) {
		return '';
	}

	if (!width) {
		width = 220;
	}

	renditions = [].slice.call(renditions).sort((a, b) => (a.width > b.width ? 1 : -1));
	renditions = getSortedNumberBasedOnWidth(renditions).reverse();

	for (let rendition in renditions) {
		if (renditions && renditions[rendition] && renditions[rendition].width) {
			let eachThumbanilWidth = Number(renditions[rendition].width);
			if (eachThumbanilWidth >= width) {
				//number can be changed based on width of parent div
				if (renditions[rendition].links) {
					return renditions[rendition].links[0].href;
				}
			}
		}
	}

	if (renditions && renditions[0] && renditions[0].links) {
		return renditions[0].links[0].href;
	}

	return '';
};

export const getSortedNumberBasedOnWidth = (list) => {
	let sortedArray = list.sort((a, b) => (a.width > b.width ? 1 : -1)); //to check where >= hold good
	return sortedArray;
};

export const getResourceFromThumbnailId = (resources, thumbnailId) =>
	_.find(resources, (res) => res.id === thumbnailId);

export const getDurationOfVideo = (eachMedia) => {
	if (!(eachMedia && eachMedia.asset && eachMedia.asset.resources)) {
		return null;
	}
	let resources = eachMedia.asset.resources;
	for (let eachResource in resources) {
		if (resources[eachResource].type == mediaTypes.video) {
			let renditions = resources[eachResource].renditions;
			if (renditions && renditions.length > 0) {
				for (let rendition in renditions) {
					let videos = renditions[rendition].videos;
					if (videos && videos.length > 0) {
						return videos[0].duration || '';
					}
				}
			}
		}
	}
	return null;
};

export const getTypeOfMedia = (eachMedia) => {
	let live = '';
	let video = '';
	let audio = '';
	let image = '';

	if (!(eachMedia && eachMedia.asset && eachMedia.asset.resources)) {
		return null;
	}
	if (eachMedia.hasOwnProperty('medias')) {
		return mediaTypes.catalog;
	}

	if (eachMedia.hasOwnProperty('scenes')) {
		return mediaTypes.scenario;
	}

	let resources = eachMedia.asset.resources;
	live = _.find(resources, function (resource) {
		return resource.type === mediaTypes.livevideo || resource.type === mediaTypes.liveaudio ? resource.type : '';
	});

	if (live && live !== undefined) {
		return live.type === mediaTypes.liveaudio ? mediaTypes.liveaudio : mediaTypes.livevideo;
	}

	video = _.find(resources, function (resource) {
		return resource.type === mediaTypes.video ? resource.type : '';
	});

	if (video) {
		return mediaTypes.video;
	}

	audio = _.find(resources, function (resource) {
		return resource.type === mediaTypes.audio ? resource.type : '';
	});

	if (audio) {
		return mediaTypes.audio;
	}

	image = _.find(resources, function (resource) {
		return resource.type === mediaTypes.image ? resource.type : '';
	});

	if (image) {
		return mediaTypes.image;
	}

	return mediaTypes.unknown;
};

export const getMimeType = (eachMedia, type) => {
	if (!(eachMedia && eachMedia.asset && eachMedia.asset.resources && eachMedia.asset.resources)) {
		return null;
	}
	let resources = eachMedia.asset.resources;
	for (let eachResource in resources) {
		let renditions = resources[eachResource].renditions;
		if (renditions && renditions.length > 0) {
			for (let rendition in renditions) {
				if (renditions[rendition].mimeType) {
					let arrSplit = renditions[rendition].mimeType.split('/');
					if (arrSplit[0] == type && arrSplit[1]) {
						return arrSplit[1];
					}
				}
			}
		}
	}
	return null;
};

export const isUrl = (string) => {
	try {
		// return Boolean(new URL(string)) //fix for IE
		let regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
		return regexp.test(string);
	} catch (e) {
		return false;
	}
};

export const getMediaResourceUrl = (media) => {
	let sourceUrl;

	if (media?.asset?.resources) {
		for (let x = 0; x < media.asset.resources.length; x++) {
			if (media.asset.resources[x].type === media.type) {
				const { renditions } = media.asset.resources[x];

				if (Array.isArray(renditions) && renditions.length > 0) {
					let highestResolution = 0;
					renditions.forEach((rendition) => {
						if (media.type === 'video') {
							const { videos, links } = rendition;
							if (videos?.[0]?.width && videos?.[0]?.width > highestResolution) {
								highestResolution = videos?.[0]?.width;
								sourceUrl = links?.[0]?.href;
							}
						} else if (media.type === 'image') {
							const { width, links } = rendition;
							if (width && width > highestResolution) {
								highestResolution = width;
								sourceUrl = links?.[0]?.href;
							}
						}
					});
				}
			}
		}
	}

	return sourceUrl;
};
