import React from 'react';

namespace Icons {
	export interface IconProps {
		color?: string;
	}

	export const Recommended: React.FC<IconProps> = ({ color }) => {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="20.845" height="20.845" viewBox="0 0 20.845 20.845">
				<path
					id="recommend_FILL0_wght400_GRAD0_opsz48"
					d="M87.3,192h5.993a1.125,1.125,0,0,0,.612-.156,1.332,1.332,0,0,0,.43-.469l2.032-4.742a1.864,1.864,0,0,0,.091-.391,2.892,2.892,0,0,0,.039-.391v-.625a.609.609,0,0,0-.7-.7H90.214l.756-3.6a.745.745,0,0,0,0-.391.711.711,0,0,0-.182-.313l-.547-.573-4.2,4.534-.208.417a.977.977,0,0,0-.1.443v5.394a1.515,1.515,0,0,0,.469,1.081A1.481,1.481,0,0,0,87.3,192Zm3.127,4.846a10.085,10.085,0,0,1-4.039-.821,10.5,10.5,0,0,1-5.563-5.563,10.411,10.411,0,0,1,0-8.1,10.416,10.416,0,0,1,2.241-3.309,10.684,10.684,0,0,1,3.322-2.228,10.411,10.411,0,0,1,8.1,0,10.406,10.406,0,0,1,5.537,5.537,10.411,10.411,0,0,1,0,8.1,10.684,10.684,0,0,1-2.228,3.322,10.416,10.416,0,0,1-3.309,2.241A10.149,10.149,0,0,1,90.422,196.845Zm0-1.563a8.852,8.852,0,1,0-6.266-2.593A8.525,8.525,0,0,0,90.422,195.281ZM90.422,186.422Z"
					transform="translate(-80 -176)"
					fill={color ?? '#fff'}
				/>
			</svg>
		);
	};

	export const PinIcon: React.FC<IconProps> = ({ color }) => (
		<svg height="10px" strokeMiterlimit="10" version="1.1" viewBox="0 0 8.818 8.9" width="10px" xmlns="http://www.w3.org/2000/svg">
			<defs />
			<clipPath id="ArtboardFrame">
				<rect height="8.9" width="8.818" x="0" y="0" />
			</clipPath>
			<g clipPath="url(#ArtboardFrame)" id="Group-243">
				<path
					d="M6.149 0C6.21732 0.0230766 6.27813 0.0641937 6.325 0.119C7.107 0.9 7.887 1.689 8.676 2.466C8.895 2.682 8.848 2.84 8.618 2.987C8.39028 3.14198 8.09788 3.1659 7.848 3.05C7.83102 3.03776 7.80969 3.03318 7.78918 3.03736C7.76867 3.04154 7.75084 3.0541 7.74 3.072C7.02667 3.906 6.31133 4.739 5.594 5.571C5.559 5.611 5.565 5.632 5.594 5.671C5.93235 6.09892 5.89846 6.71198 5.515 7.1C5.46998 7.15471 5.40285 7.1864 5.332 7.1864C5.26115 7.1864 5.19402 7.15471 5.149 7.1C4.70767 6.66 4.26733 6.219 3.828 5.777C3.779 5.728 3.756 5.721 3.702 5.777C3.058 6.416 2.432 7.077 1.762 7.686C1.323 8.086 0.862 8.46 0.399 8.834C0.337594 8.90135 0.242215 8.92608 0.155818 8.89707C0.0694221 8.86805 0.00830783 8.79076-3.34063e-09 8.7C-0.00840026 8.6267 0.016139 8.55345 0.067 8.5C0.613272 7.8015 1.19783 7.13381 1.818 6.5C2.25133 6.06467 2.68467 5.63133 3.118 5.2C3.175 5.143 3.166 5.119 3.118 5.068C2.65933 4.61267 2.20133 4.15467 1.744 3.694C1.67317 3.647 1.63134 3.56701 1.63317 3.48202C1.63499 3.39704 1.68022 3.31891 1.753 3.275C2.14223 2.91832 2.73275 2.89671 3.147 3.224C3.194 3.261 3.217 3.257 3.259 3.224C4.08633 2.50733 4.91767 1.793 5.753 1.081C5.77004 1.07146 5.78232 1.05524 5.78688 1.03625C5.79144 1.01726 5.78786 0.997233 5.777 0.981C5.63473 0.674858 5.69995 0.31236 5.94 0.0749999C5.9765 0.0437842 6.01767 0.0184776 6.062 1.05684e-08L6.149 0Z"
					fill={color ?? 'currentColor'}
					fillRule="evenodd"
					opacity="1"
					stroke="none"
				/>
			</g>
		</svg>
	);

	export const Polls: React.FC<IconProps> = ({ color }) => {
		return (
			<svg style={{ fill: color }} xmlns="http://www.w3.org/2000/svg" width="21.239" height="21.239" viewBox="0 0 21.239 21.239">
				<path
					id="Path_9855"
					data-name="Path 9855"
					d="M455.738,745.119H438.945a2.224,2.224,0,0,0-2.223,2.224v16.791a2.223,2.223,0,0,0,2.223,2.223h16.793a2.223,2.223,0,0,0,2.223-2.223V747.343A2.224,2.224,0,0,0,455.738,745.119Zm-11.431,16.688a1.011,1.011,0,0,1-1.011,1.011h-2.023a1.011,1.011,0,0,1-1.011-1.011v-3.034a1.012,1.012,0,0,1,1.011-1.011H443.3a1.012,1.012,0,0,1,1.011,1.011Zm5.056,0a1.01,1.01,0,0,1-1.011,1.011H446.33a1.011,1.011,0,0,1-1.012-1.011v-7.08a1.012,1.012,0,0,1,1.012-1.011h2.022a1.011,1.011,0,0,1,1.011,1.011Zm5.058,0a1.011,1.011,0,0,1-1.011,1.011h-2.023a1.011,1.011,0,0,1-1.011-1.011V749.671a1.012,1.012,0,0,1,1.011-1.012h2.023a1.012,1.012,0,0,1,1.011,1.012Z"
					transform="translate(-436.722 -745.119)"
					fill={color ?? '#fff'}
				/>
			</svg>
		);
	};
}

export default Icons;
