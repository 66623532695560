import { event } from 'react-ga';

export const SEND_GOOGLE_ANALYTICS_EVENT = 'SEND_GOOGLE_ANALYTICS_EVENT';

const sendGaEvent = (_) => ({
	type: SEND_GOOGLE_ANALYTICS_EVENT,
});

export const sendEventToGa = (category, action, label) => (dispatch) => {
	event({
		category: category,
		action: action,
		label: label,
	});
	dispatch(sendGaEvent());
};
