import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadEncoderUploading, uploadMediaToEncoder } from '../../../actions/publishActions';
import { FLOWS, messageTypes } from '../../../constants/mediaConstants';
import { showAlert, showMessage } from '../../../actions/globalActions';
import { useTranslation } from 'react-i18next';
import { AdobeExpressUtils } from '../../../screens/PublishScreen/AdobeExpress/utils';
import { generateUUID } from '../../../utils/commonUtil';
import { replaceMediaUsingQBrickEncode } from '../../../services/mediaService';

interface UploadingMediaByUrlProps {
	mediaTitle: string;
	mediaDescription: string;
	mediaUrl: string;
	projectId?: string;
	replaceMediaContent?: any;
	successMessage?: string;
	callback?: () => void;
}

interface UploadNewMediaProcessProps extends UploadingMediaByUrlProps {
	resolve: any;
}

interface ReplaceMediaContentProcessProps {
	resolve: any;
	mediaTitle: string;
	mediaDescription: string;
	mediaUrl: string;
	projectId?: string;
	replaceMediaContent: any;
}

const useUploadingVideoByUrl = () => {
	const dispatch = useDispatch() as any;
	const { t: translator } = useTranslation();
	const accountId = useSelector((state) => (state as any).session.defaultAccountId);

	const uploadNewMediaProcess = ({
		resolve,
		mediaTitle,
		mediaDescription,
		mediaUrl,
		projectId,
		successMessage,
		callback,
	}: UploadNewMediaProcessProps) => {
		const flowId = FLOWS.qbrickStandard;
		const callbackUrl = '';
		const mediaId = projectId ? AdobeExpressUtils.getMediaIdFromProjectId(projectId) : generateUUID();

		dispatch(
			loadEncoderUploading({
				accountId,
				flowId,
				callbackUrl,
				mediaId,
				mediaTitle,
				mediaDescription,
			})
		).then((data: any) => {
			if (!data || typeof data === 'string' || !data.id) {
				return;
			}

			// Send to encoder
			dispatch(uploadMediaToEncoder({ accountId, mediaUrl, uploadId: data.id })).then((data: any) => {
				if (data) {
					dispatch(
						showMessage(
							successMessage
								? successMessage
								: `${translator('MEDIA_LIBRARY_NOTIFICATION_REFRESH_TO_REVIEW_CONTENT')}`,
							messageTypes.info
						)
					);
				} else {
					dispatch(showAlert(`${translator('SOMETHING_HAPPENED')}`, messageTypes.error));
				}
				callback?.();
			});
			resolve({ id: mediaId });
		});
	};

	const replaceMediaContentProcess = ({
		resolve,
		mediaTitle,
		mediaUrl,
		replaceMediaContent,
	}: ReplaceMediaContentProcessProps) => {
		replaceMediaUsingQBrickEncode(
			accountId,
			FLOWS.qbrickStandard,
			{ name: mediaTitle },
			undefined,
			undefined,
			undefined,
			replaceMediaContent
		).then((data: any) => {
			if (!data || typeof data === 'string' || !data.id || !data?.upload?.url) {
				return;
			}

			const {
				upload: { url },
			} = data;

			const uploadId = url.split('/').pop();

			// Send to encoder
			dispatch(uploadMediaToEncoder({ accountId, mediaUrl, uploadId })).then((data: any) => {
				if (data) {
					dispatch(
						showMessage(
							`${translator('MEDIA_LIBRARY_NOTIFICATION_REFRESH_TO_REVIEW_CONTENT')}`,
							messageTypes.info
						)
					);
				} else {
					dispatch(showAlert(`${translator('COMMON_ERROR')}`, messageTypes.error));
				}
			});
			resolve({ id: replaceMediaContent.mediaId });
		});
	};

	const uploadVideoByUrl = useCallback(
		({
			mediaTitle,
			mediaDescription,
			mediaUrl,
			projectId,
			replaceMediaContent,
			successMessage,
			callback,
		}: UploadingMediaByUrlProps) => {
			return new Promise((resolve) => {
				if (replaceMediaContent) {
					replaceMediaContentProcess({
						resolve,
						mediaTitle,
						mediaDescription,
						mediaUrl,
						projectId,
						replaceMediaContent,
					});
					return;
				}

				uploadNewMediaProcess({
					resolve,
					mediaTitle,
					mediaDescription,
					mediaUrl,
					projectId,
					successMessage,
					callback,
				});
			});
		},
		[dispatch, accountId, translator]
	);

	return {
		uploadVideoByUrl,
	};
};

export default useUploadingVideoByUrl;
