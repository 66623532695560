export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
export const ITEM_REMOVED_NOTIFIED = "ITEM_REMOVED_NOTIFIED";

export const showNotification = (message) => ({
    type: SHOW_NOTIFICATION,
    message
});

export const hideNotification = _ => ({
    type: HIDE_NOTIFICATION
});

export const itemRemovedNotified = _ => ({
    type: ITEM_REMOVED_NOTIFIED
});
