import React from 'react';
import Popper from '@material-ui/core/Popper';
import { FormControlLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { fontWeights, fonts } from '../../../../../../../../../../constants/playerConfigurationsConstant';
import CustomSlider from '../../../../../../../../../../reusable/Slider/CustomSlider';

import './TextStylingModal.scss';

const SLIDER_OPTION_MARKS = [
	{ value: 0, label: '0' },
	{ value: 15 },
	{ value: 30 },
	{ value: 45 },
	{ value: 60 },
	{ value: 75 },
	{ value: 90 },
	{ value: 105 },
	{ value: 120 },
	{ value: 135 },
	{ value: 150, label: '1.5' },
];

interface TextStylingModalProps {
	open: boolean;
	anchorEl: any;
	id: string;
	font?: string;
	fontSize?: string;
	fontWeight?: string;
	lineHeight?: string;
	handleStylingAttributeChange: (_objKey: string | null, _attrKey: string, _value: any) => {};
}

const TextStylingModal = ({
	open,
	anchorEl,
	id,
	font,
	fontSize,
	fontWeight,
	lineHeight,
	handleStylingAttributeChange,
}: TextStylingModalProps) => {
	const { t: translator } = useTranslation();
	return (
		<Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end">
			<Paper elevation={5} className="text-styling-modal-container">
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_FONT_FAMILY')}
					onChange={({ target }) => {
						handleStylingAttributeChange(`${id}Label`, 'font', (target as HTMLInputElement).value);
					}}
					labelPlacement="start"
					control={
						<Select variant="standard" value={font}>
							{fonts.map((borderType) => (
								<MenuItem value={borderType} key={borderType}>
									{borderType}
								</MenuItem>
							))}
						</Select>
					}
				/>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_FONT_SIZE')}
					onChange={({ target }) => {
						handleStylingAttributeChange(`${id}Label`, 'fontSize', (target as HTMLInputElement).value);
					}}
					labelPlacement="start"
					control={<TextField variant="standard" type="number" value={fontSize} />}
				/>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_FONT_WEIGHT')}
					onChange={({ target }) => {
						handleStylingAttributeChange(`${id}Label`, 'fontWeight', (target as HTMLInputElement).value);
					}}
					labelPlacement="start"
					control={
						<Select variant="standard" value={fontWeight}>
							{fontWeights.map((weight) => (
								<MenuItem value={weight} key={weight}>
									{weight}
								</MenuItem>
							))}
						</Select>
					}
				/>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_STYLING_LINE_HEIGHT')}
					labelPlacement="start"
					control={
						<CustomSlider
							value={lineHeight}
							min={0}
							max={150}
							marks={SLIDER_OPTION_MARKS}
							onChange={(e: any, value: number | number[]) => {
								handleStylingAttributeChange(`${id}Label`, 'lineHeight', value);
							}}
						/>
					}
				/>
			</Paper>
		</Popper>
	);
};

export default TextStylingModal;
