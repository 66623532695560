import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './TimeInput.scss';
class TimeInput extends Component {
	state = {};

	UNSAFE_componentWillMount() {
		this.setValue(this.props.value);
	}

	componentDidUpdate(nextProps) {
		if (nextProps.value !== this.props.value) {
			this.setValue(this.props.value);
		}
	}

	setValue = (input) => {
		let timeArray = (!input ? '00:00:00:00' : input).toString().split(':');
		this.setState({
			hour: timeArray[0],
			minute: timeArray[1],
			second: timeArray.length > 2 ? timeArray[2] : '00',
			millisecond: timeArray.length > 3 ? timeArray[3].substr(0, 3) : '000',
		});
	};

	onChange = (e, type) => {
		if (this.props.disabled || this.props.readonly) {
			return;
		}

		const { hour, minute, second, millisecond } = this.state;
		const target = e.target;

		const newValueLength = parseInt(target.value).toString().length;
		const newValue =
			parseInt(target.value) >= parseInt(target.max)
				? parseInt(target.max) - 1
				: parseInt(target.value) < parseInt(target.min)
				? parseInt(target.min)
				: parseInt(target.value);

		let newHour = hour;
		let newMinute = minute;
		let newSecond = second;
		let newMillisecond = millisecond;
		switch (type) {
			case 'hour':
				newHour = newValueLength > 2 ? hour : newValue;
				break;
			case 'minute':
				newMinute = newValueLength > 2 ? minute : newValue;
				break;
			case 'second':
				newSecond = newValueLength > 2 ? second : newValue;
				break;
			case 'millisecond':
				newMillisecond = newValueLength > 3 ? millisecond : newValue;
				break;
		}

		const value =
			String(newHour).padStart(2, '0') +
			':' +
			String(newMinute).padStart(2, '0') +
			':' +
			String(newSecond).padStart(2, '0') +
			':' +
			String(newMillisecond).padStart(3, '0');
		if (this.props.onChange) {
			this.props.onChange(value);
		}
	};

	onBlur = () => {
		const { hour, minute, second, millisecond } = this.state;
		const value = hour + ':' + minute + ':' + second + ':' + millisecond;
		this.props.onSubmit?.(value);
	};

	render() {
		const { hour, minute, second, millisecond } = this.state;
		const { disabled, readonly } = this.props;

		return (
			<div
				className={['TimeInput', disabled ? 'TimeInput--disabled' : '', readonly ? 'TimeInput--readonly' : '']
					.join(' ')
					.trim()}
				onBlur={this.onBlur}
			>
				<input
					disabled={disabled}
					className="TimeInput_input"
					type="number"
					min="0"
					max="24"
					value={hour}
					onChange={(e) => this.onChange(e, 'hour')}
				/>
				<span>:</span>
				<input
					disabled={disabled}
					className="TimeInput_input"
					type="number"
					min="0"
					max="60"
					value={minute}
					onChange={(e) => this.onChange(e, 'minute')}
				/>
				<span>:</span>
				<input
					disabled={disabled}
					className="TimeInput_input"
					type="number"
					min="0"
					max="60"
					value={second}
					onChange={(e) => this.onChange(e, 'second')}
				/>
				<span>:</span>
				<input
					disabled={disabled}
					className="TimeInput_input"
					type="number"
					min="0"
					max="999"
					value={millisecond}
					onChange={(e) => this.onChange(e, 'millisecond')}
				/>
			</div>
		);
	}
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(TimeInput);
