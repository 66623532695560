import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Layout from './components/Layout';
import SectionLayout from './components/SectionLayout';
import { useSelector } from 'react-redux';
import { storageHistory } from './../../services/analyticsServices';
import Grid from '@material-ui/core/Grid';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { _optionsStorage } from './../../utils/commonUtil';

const AnalyticsStorageScreen = () => {
	const accountId = useSelector((state) => state.session.defaultAccountId);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	function reStuctureData(items) {
		let dataCollection = [];
		let colors = ['rgb(255, 208, 12)', 'rgb(0, 204, 153)', 'rgb(253, 184, 19)', 'rgb(124, 181, 236)'];
		items?.map((item, i) => {
			const obj = {
				name: item?.folderName,
				color: colors[i] || '#333',
				data: item?.entries.map((ite) => {
					const views = {
						x: ite.timestamp,
						y: ite.bytes,
					};
					return views;
				}),
			};

			dataCollection.push(obj);
		});

		return dataCollection;
	}

	useEffect(() => {
		const format = 'YYYY-MM-DD';
		const fromDate = moment().subtract(12, 'months').format(format);
		const toDate = moment().format(format);
		const query = `from=${fromDate}&to=${toDate}`;
		setLoading(true);
		storageHistory(accountId, query).then((items) => {
			setData(reStuctureData(items));
			setLoading(false);
		});
	}, [accountId]);

	return (
		<Layout title="ANALYTICS_TITLE_CDN_STORAGE">
			<SectionLayout title="ANALYTICS_TITLE_CDN_STORAGE_12_MONTHS" addClass="storage" loading={loading}>
				<Grid item xs={12}>
					<HighchartsReact
						constructorType={'chart'}
						highcharts={Highcharts}
						options={_optionsStorage(data, 'area', undefined, '', '')}
					/>
				</Grid>
			</SectionLayout>
		</Layout>
	);
};

export default AnalyticsStorageScreen;
