import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './../InteractEditorTopPanel/InteractElementProperties/InteractElementProperties.scss';
import { updateInteractTimelineItem, setSelectedInteractTimelineItem } from '../../../actions/interactTimelineActions';
import { interactSettingsType } from '../../../constants/interactConstant';
import { addNewObjectInSettings, getKeyNameByType } from '../../../services/interactScreenHelperService';
import plus_semiround from '../../../assets/images/plus_semiround.svg';
import _ from 'underscore';

class DynamicObjectCreation extends PureComponent {
	constructor(props) {
		super(props);
	}

	getCurrentlyEditedItem = () => {
		const { interactItems, selectedInteractItems } = this.props;
		if (selectedInteractItems && selectedInteractItems.length > 0) {
			let itemId = selectedInteractItems[0];
			let item = _.find(interactItems, (item) => {
				return item.id === itemId;
			});
			if (item) {
				return item;
			}
		}
		return {};
	};

	addObject = (itemObject, key) => {
		const currentlyEditedItem = this.getCurrentlyEditedItem();

		let itemsCount = Object.keys(currentlyEditedItem.settings).length;

		let uuid = key + '-' + itemsCount;
		let MAX_COUNT = currentlyEditedItem.definitions[key].config.max;
		for (let i = 1; i <= MAX_COUNT; i++) {
			uuid = key + '-' + i;
			if (currentlyEditedItem.settings.hasOwnProperty(uuid)) {
				continue;
			} else {
				break;
			}
		}

		itemObject = addNewObjectInSettings(itemObject, key, uuid);
		let xCoordinateObject = getKeyNameByType(itemObject.settings[uuid], interactSettingsType.xCoordinate);
		let yCoordinateObject = getKeyNameByType(itemObject.settings[uuid], interactSettingsType.yCoordinate);
		if (xCoordinateObject) {
			itemObject.settings[uuid][xCoordinateObject].default = 0;
		}
		if (yCoordinateObject) {
			itemObject.settings[uuid][yCoordinateObject].default = 0;
		}

		itemObject.settings = JSON.parse(JSON.stringify(itemObject.settings));
		this.props.updateInteractTimelineItem(itemObject);
	};

	isItemHavingMaxObjectType = (type, maxCount) => {
		let item = this.getCurrentlyEditedItem();
		let count = 0;
		Object.keys(item.settings).map((key) => {
			if (item.settings[key].type === type) {
				count = count + 1;
			}
		});
		if (item.settings.general.position.default === true) {
			if (count === 3) {
				return true;
			}
		} else {
			if (count === maxCount) {
				return true;
			}
		}
		return false;
	};

	render() {
		const { eacKey } = this.props;
		let item = this.getCurrentlyEditedItem();
		let definitions = item.definitions;
		return (
			<>
				{!eacKey ? null : (
					<div className="addingButtonOption">
						{this.isItemHavingMaxObjectType(eacKey, definitions[eacKey].config.max) ? null : (
							<div
								className="dynamicActionField"
								onClick={() => {
									this.addObject(item, eacKey);
								}}
							>
								<div className="wrapper">
									<img src={plus_semiround} />
									<label className="dynamicActionText"> {definitions[eacKey].config.name} </label>
								</div>
							</div>
						)}
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = ({ session, interactTimelineReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	interactGroups: interactTimelineReducer.groups,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
});

const mapDispatchToProps = (dispatch) => ({
	updateInteractTimelineItem: (item) => dispatch(updateInteractTimelineItem(item)),
	setSelectedInteractTimelineItem: (item) => dispatch(setSelectedInteractTimelineItem(item)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(DynamicObjectCreation);
