import React, { useEffect } from 'react';
import { Collapse, List, ListItem, ListItemIcon } from '@material-ui/core';
import ColorHub from '../../../ColorHub/ColorHub';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import { mediaTypes } from '../../../../constants/mediaConstants';
import { useTranslation } from 'react-i18next';
import { useListCustomStyle } from '../../../CustomStyle/useCustomStyle';
import { joinClassNames } from '../../../../services/elementHelperService';
import { ExpandMore } from '@material-ui/icons';
import VideocamIcon from '@material-ui/icons/Videocam';
interface StockMenuProps {
	mediaType: string;
	provider: string;
	onProviderChange: (_e: any) => void;
}

const StockMenu = ({ mediaType, provider, onProviderChange }: StockMenuProps) => {
	const { t: translator } = useTranslation();
	const classes = useListCustomStyle();

	useEffect(() => {
		if (mediaType === mediaTypes.video && provider !== 'Pexels') {
			onProviderChange('Pexels');
		}
	}, [mediaType, provider, onProviderChange]);

	return (
		<List>
			{mediaType === mediaTypes.image && (
				<>
					<ListItem
						key={'images'}
						button
						classes={{ selected: classes.selected }}
						style={{ borderRadius: '4px', marginBottom: '5px' }}
						className={joinClassNames(undefined, classes.customItem)}
					>
						<ListItemIcon style={{ minWidth: '30px' }}>
							<ColorHub component={<CropOriginalIcon />} color={undefined} />
						</ListItemIcon>
						<span className={classes.ellipsis}>{translator('IMAGES')}</span>

						<ListItemIcon style={{ minWidth: '0', paddingLeft: '5px' }}>
							<ExpandMore />
						</ListItemIcon>
					</ListItem>
					<Collapse in={true} timeout="auto" unmountOnExit>
						<ListItem
							key={'Unsplash'}
							button
							selected={provider === 'Unsplash'}
							classes={{ selected: classes.selected }}
							style={{ borderRadius: '4px', marginBottom: '5px', paddingLeft: 44 }}
							className={joinClassNames((classes as any)[`nested${1}`], classes.customItem)}
							onClick={() => onProviderChange('Unsplash')}
						>
							<span className={classes.ellipsis}>Unsplash</span>
						</ListItem>
						<ListItem
							key={'Pexels'}
							button
							selected={provider === 'Pexels'}
							classes={{ selected: classes.selected }}
							style={{ borderRadius: '4px', marginBottom: '5px', paddingLeft: 44 }}
							className={joinClassNames((classes as any)[`nested${1}`], classes.customItem)}
							onClick={() => onProviderChange('Pexels')}
						>
							<span className={classes.ellipsis}>Pexels</span>
						</ListItem>
					</Collapse>
				</>
			)}
			{mediaType === mediaTypes.video && (
				<>
					<ListItem
						key={'videos'}
						button
						classes={{ selected: classes.selected }}
						style={{ borderRadius: '4px', marginBottom: '5px' }}
						className={joinClassNames(undefined, classes.customItem)}
					>
						<ListItemIcon style={{ minWidth: '30px' }}>
							<ColorHub component={<VideocamIcon />} color={undefined} />
						</ListItemIcon>
						<span className={classes.ellipsis}>{translator('VIDEOS')}</span>
						<ListItemIcon style={{ minWidth: '0', paddingLeft: '5px' }}>
							<ExpandMore />
						</ListItemIcon>
					</ListItem>
					<Collapse in={true} timeout="auto" unmountOnExit>
						<ListItem
							key={'Pexels'}
							button
							selected={true}
							classes={{ selected: classes.selected }}
							style={{ borderRadius: '4px', marginBottom: '5px', paddingLeft: 44 }}
							className={joinClassNames((classes as any)[`nested${1}`], classes.customItem)}
							onClick={() => onProviderChange('Pexels')}
						>
							<span className={classes.ellipsis}>Pexels</span>
						</ListItem>
					</Collapse>
				</>
			)}
		</List>
	);
};

export default StockMenu;
