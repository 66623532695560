import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateInteractTimelineItem } from '../../../../actions/interactTimelineActions';
import InteractDraggable from './InteractDraggable';
import InteractResizable from './InteractResizable';
import _ from 'underscore';
import { resizePositions, interactSettingsType } from '../../../../constants/interactConstant';
import { getNumberWithoutDecimal } from '../../../../services/stringHelperService';
import { getKeyNameByType } from '../../../../services/interactScreenHelperService';
import {
	showSelectedSubPropertyKey,
	hideSelectedSubPropertyKey,
	setComponentStaticTrue,
	setComponentStaticFalse,
} from '../../../../actions/interactElementsActions';
import './InteractEditorSVGOverlay.scss';

class ButtonCoordinates extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filePath: '',
		};
	}

	getCurrentlyEditedItem = () => {
		const { interactItems, selectedInteractItems } = this.props;
		if (selectedInteractItems && selectedInteractItems.length > 0) {
			let itemId = selectedInteractItems[0];
			let item = _.find(interactItems, (item) => {
				return item.id === itemId;
			});
			if (item) {
				return item;
			}
		}
		return {};
	};

	onDragEnd = () => {};

	onInitiate = () => {};

	onDragged = (x, y, parentKey) => {
		this.handleElementSelection(parentKey);
		let item = {
			...this.getCurrentlyEditedItem(),
		};
		if (!item.settings.general.position.default) {
			let objectToFindKey = item.settings[parentKey];
			let xObjectKey = getKeyNameByType(objectToFindKey, interactSettingsType.xCoordinate); // ||'x'
			let yObjectKey = getKeyNameByType(objectToFindKey, interactSettingsType.yCoordinate); //|| 'y'
			item.settings[parentKey][xObjectKey].default = getNumberWithoutDecimal(
				Number(item.settings[parentKey][xObjectKey].default) + x
			);
			item.settings[parentKey][yObjectKey].default = getNumberWithoutDecimal(
				Number(item.settings[parentKey][yObjectKey].default) + y
			);

			this.props.updateInteractTimelineItem(item);
		}
	};

	onResize = (x, y, parentKey, index, position) => {
		let item = {
			...this.getCurrentlyEditedItem(),
		};
		if (!item.settings.general.position.default) {
			let objectToFindKey = item.settings[parentKey];
			let xObjectKey = getKeyNameByType(objectToFindKey, interactSettingsType.xCoordinate); // || 'x'
			let yObjectKey = getKeyNameByType(objectToFindKey, interactSettingsType.yCoordinate); //||'y'
			let widthObjectKey = getKeyNameByType(objectToFindKey, interactSettingsType.widthCoordinate); //|| 'width'
			let heightObjectKey = getKeyNameByType(objectToFindKey, interactSettingsType.heightCoordinate); //|| 'height'

			switch (position) {
				case resizePositions.topleft:
					item.settings[parentKey][xObjectKey].default =
						Number(item.settings[parentKey][xObjectKey].default) - x;
					item.settings[parentKey][yObjectKey].default =
						Number(item.settings[parentKey][yObjectKey].default) - y;
					break;
				case resizePositions.topright:
					//don't do anything with x position
					item.settings[parentKey][yObjectKey].default =
						Number(item.settings[parentKey][yObjectKey].default) - y;
					break;
				case resizePositions.bottomleft:
					item.settings[parentKey][xObjectKey].default =
						Number(item.settings[parentKey][xObjectKey].default) - x;
					//don't do anything with y position
					break;
				case resizePositions.bottomright: //don't do anything with xy position
					break;
				default:
					break;
			}
			let btnWidth = item.settings[parentKey][widthObjectKey].default + x;
			let btnHeight = item.settings[parentKey][heightObjectKey].default + y;
			item.settings[parentKey][widthObjectKey].default = getNumberWithoutDecimal(btnWidth);
			item.settings[parentKey][heightObjectKey].default = getNumberWithoutDecimal(btnHeight);
			item.settings[parentKey][xObjectKey].default = getNumberWithoutDecimal(
				item.settings[parentKey][xObjectKey].default
			);
			item.settings[parentKey][yObjectKey].default = getNumberWithoutDecimal(
				item.settings[parentKey][yObjectKey].default
			);

			this.props.updateInteractTimelineItem(item);
		}
	};

	handleElementSelection = (parentKey) => {
		this.props.showSelectedSubPropertyKey(parentKey);
	};

	renderDragAndResize = (parentKey, eachButton, index, svgPointClassName) => {
		const { selectedSubPropertyKey } = this.props;
		let btnX = _.find(eachButton, (item) => {
			return item.type === interactSettingsType.xCoordinate;
		});
		if (btnX) {
			btnX = Number(btnX.default);
		}
		let btnY = _.find(eachButton, (item) => {
			return item.type === interactSettingsType.yCoordinate;
		});
		if (btnY) {
			btnY = Number(btnY.default);
		}
		let btnWidth = _.find(eachButton, (item) => {
			return item.type === interactSettingsType.widthCoordinate;
		});
		if (btnWidth) {
			btnWidth = Number(btnWidth.default);
		}
		let btnHeight = _.find(eachButton, (item) => {
			return item.type === interactSettingsType.heightCoordinate;
		});
		if (btnHeight) {
			btnHeight = Number(btnHeight.default);
		}

		let cx = '';
		let cy = '';
		return (
			<>
				<g
					componentid={parentKey}
					onMouseOver={() => this.props.setComponentStaticTrue()}
					onMouseOut={() => this.props.setComponentStaticFalse()}
					onClick={() => {
						this.handleElementSelection(parentKey);
					}}
					className={
						!selectedSubPropertyKey || selectedSubPropertyKey === parentKey
							? 'btnContainer'
							: 'btnContainer noVisibility'
					}
				>
					<text className="coordinateText" x={btnX + 15} y={btnY - 15}>
						{eachButton.name || parentKey}
					</text>

					<InteractDraggable
						gid={parentKey}
						x={btnX}
						y={btnY}
						width={btnWidth}
						height={btnHeight}
						onDragged={(x, y) => this.onDragged(x, y, parentKey, index)}
						onDragEnd={() => this.onDragEnd()}
						onInitiate={this.onInitiate}
						svgPointClassName={svgPointClassName}
					/>
					{Object.keys(resizePositions).map((position) => {
						switch (position) {
							case resizePositions.topleft:
								cx = btnX;
								cy = btnY;
								break;
							case resizePositions.topright:
								cx = btnX + btnWidth;
								cy = btnY;
								break;
							case resizePositions.bottomleft:
								cx = btnX;
								cy = btnY + btnHeight;
								break;
							case resizePositions.bottomright:
								cx = btnX + btnWidth;
								cy = btnY + btnHeight;
								break;
							default:
								break;
						}
						return (
							<>
								<InteractResizable
									position={position}
									cx={cx}
									cy={cy}
									svgPointClassName={svgPointClassName}
									onDragged={(x, y) => this.onResize(x, y, parentKey, index, position)}
								/>
							</>
						);
					})}
				</g>
			</>
		);
	};

	checkIfObjectHasCoordinates = (obj) => {
		if (
			getKeyNameByType(obj, interactSettingsType.xCoordinate) &&
			getKeyNameByType(obj, interactSettingsType.yCoordinate) &&
			getKeyNameByType(obj, interactSettingsType.widthCoordinate) &&
			getKeyNameByType(obj, interactSettingsType.heightCoordinate)
		) {
			return true;
		}
		return false;
	};

	render() {
		let item = {
			...this.getCurrentlyEditedItem(),
		};
		let settings = item.settings;
		let svgPointClassName = 'btnRect';
		return (
			<>
				<div className="coordinateOverlay">
					<svg className={svgPointClassName} viewBox="0 0 1600 900">
						<rect x="0" y="0" width="1600" height="900" />
						{Object.keys(settings).map((eachKey, index) => (
							<>
								{this.checkIfObjectHasCoordinates(settings[eachKey]) ? (
									<>
										{this.renderDragAndResize(eachKey, settings[eachKey], index, svgPointClassName)}
									</>
								) : null}
							</>
						))}
					</svg>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, interactTimelineReducer, interactElementsReducer }) => ({
	interactItems: interactTimelineReducer.items,
	defaultAccountId: session.defaultAccountId,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
	selectedSubPropertyKey: interactElementsReducer.selectedSubPropertyKey,
});

const mapDispatchToProps = (dispatch) => ({
	updateInteractTimelineItem: (item) => dispatch(updateInteractTimelineItem(item)),
	showSelectedSubPropertyKey: (key) => dispatch(showSelectedSubPropertyKey(key)),
	hideSelectedSubPropertyKey: (_) => dispatch(hideSelectedSubPropertyKey()),
	setComponentStaticTrue: () => dispatch(setComponentStaticTrue()),
	setComponentStaticFalse: () => dispatch(setComponentStaticFalse()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ButtonCoordinates);
