import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, List, ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { WIDGET_EVENTS } from '../../../../../../../../../../constants/scenarioConstant';
import Event from './Event';

interface ActionsProps {
	scenes: Array<{ id: string; name: string }>;
	editingScene: { id: string };
	events: { type: string }[];
	openNewTabRef: React.Ref<HTMLInputElement> | undefined;
	gotoUrlRef: React.Ref<HTMLInputElement> | undefined;
	handleDestinationSceneChange: () => {};
	handleGotoUrlWidgetChange: () => {};
	handleGotoUrlOpenNewTabChange: () => {};
	handleDeleteWidgetEvent: (_eventType: string) => {};
	handleAddNewEventToWidget: (_eventType: string) => {};
	handleAddActionToEvent: (_actionType: string, _eventType: string) => {};
	handleJumpToTimeMetaDataChange: (_value: string) => {};
}

const Events = ({
	scenes,
	editingScene,
	events,
	gotoUrlRef,
	openNewTabRef,
	handleDestinationSceneChange,
	handleGotoUrlWidgetChange,
	handleGotoUrlOpenNewTabChange,
	handleDeleteWidgetEvent,
	handleAddNewEventToWidget,
	handleAddActionToEvent,
	handleJumpToTimeMetaDataChange,
}: ActionsProps) => {
	const { t: translator } = useTranslation();
	const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();

	const availableEvents = Object.keys(WIDGET_EVENTS)
		.map((key) => {
			const isUsedEvent = events.find(({ type }) => type === key);

			if (!isUsedEvent) {
				return key;
			}
		})
		.filter(Boolean);
	const blockAddNewEvent = availableEvents.length === 0;

	return (
		<>
			<Button
				className="add-event-button"
				startIcon={<AddIcon />}
				color="primary"
				variant="contained"
				onClick={(e) => {
					setMenuAnchor(e.currentTarget);
				}}
				disabled={blockAddNewEvent}
			>
				{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_ACTION_ADD_INTERACTIVE_EVENT')}
			</Button>
			<List>
				{events.length !== 0 &&
					events
						.sort(({ type: typeA }, { type: typeB }) => {
							return (
								(WIDGET_EVENTS[typeA as keyof typeof WIDGET_EVENTS]?.rank ?? 0) -
								(WIDGET_EVENTS[typeB as keyof typeof WIDGET_EVENTS]?.rank ?? 0)
							);
						})
						.map(({ type, actions }: any) => {
							const widgetEvent = WIDGET_EVENTS[type as keyof typeof WIDGET_EVENTS];
							if (!type || !widgetEvent) {
								return;
							}

							return (
								<Event
									key={type}
									type={type}
									defined={widgetEvent}
									data={actions}
									scenes={scenes}
									editingScene={editingScene}
									gotoUrlRef={gotoUrlRef}
									openNewTabRef={openNewTabRef}
									handleDestinationSceneChange={handleDestinationSceneChange}
									handleGotoUrlWidgetChange={handleGotoUrlWidgetChange}
									handleGotoUrlOpenNewTabChange={handleGotoUrlOpenNewTabChange}
									handleDeleteWidgetEvent={handleDeleteWidgetEvent}
									handleAddActionToEvent={handleAddActionToEvent}
									handleJumpToTimeMetaDataChange={handleJumpToTimeMetaDataChange}
								/>
							);
						})}
			</List>

			<Menu
				anchorEl={menuAnchor}
				onClose={() => setMenuAnchor(null)}
				open={Boolean(menuAnchor)}
				getContentAnchorEl={null}
				disableAutoFocusItem
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			>
				{Object.entries(WIDGET_EVENTS).map(([key, { icon, label }]) => {
					return (
						<MenuItem
							key={key}
							disabled={!availableEvents.includes(key)}
							onClick={() => {
								handleAddNewEventToWidget(key);
								setMenuAnchor(null);
							}}
						>
							<ListItemIcon style={{ minWidth: '30px' }}>{icon}</ListItemIcon>
							<ListItemText primary={translator(label)} />
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export default Events;
