export const channelProfiles = [
	{
		ID: 'standard-720p-169-5-profiles',
		TITLE: '720p',
	},
	{
		ID: 'standard-1080p-169-6-profiles',
		TITLE: '1080p',
	},
];
