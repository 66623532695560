export const language = {
	Abkhaz: 'Abkhaz',
	Afar: 'Afar',
	Afrikaans: 'Afrikaans',
	Akan: 'Akan',
	Albanian: 'Albanian',
	Amharic: 'Amharic',
	Arabic: 'Arabic',
	Aragonese: 'Aragonese',
	Armenian: 'Armenian',
	Assamese: 'Assamese',
	Avaric: 'Avaric',
	Avestan: 'Avestan',
	Aymara: 'Aymara',
	Azerbaijani: 'Azerbaijani',
	Bambara: 'Bambara',
	Bashkir: 'Bashkir',
	Basque: 'Basque',
	Belarusian: 'Belarusian',
	Bengali: 'Bengali',
	Bihari: 'Bihari',
	Bislama: 'Bislama',
	Bosnian: 'Bosnian',
	Breton: 'Breton',
	Bulgarian: 'Bulgarian',
	Burmese: 'Burmese',
	Catalan: 'Catalan',
	Chamorro: 'Chamorro',
	Chechen: 'Chechen',
	Chichewa: 'Chichewa',
	Chinese: 'Chinese',
	Chuvash: 'Chuvash',
	Cornish: 'Cornish',
	Corsican: 'Corsican',
	Croatian: 'Croatian',
	Czech: 'Czech',
	Danish: 'Danish',
	Divehi: 'Divehi',
	Dutch: 'Dutch',
	English: 'English',
	Esperanto: 'Esperanto',
	Estonian: 'Estonian',
	Ewe: 'Ewe',
	Faroese: 'Faroese',
	Fijian: 'Fijian',
	Finnish: 'Finnish',
	French: 'French',
	Fula: 'Fula',
	Galician: 'Galician',
	Georgian: 'Georgian',
	German: 'German',
	Greek: 'Greek',
	Guaraní: 'Guaraní',
	Gujarati: 'Gujarati',
	Haitian: 'Haitian',
	Hausa: 'Hausa',
	Hebrew: 'Hebrew',
	Herero: 'Herero',
	Hindi: 'Hindi',
	HiriMotu: 'Hiri Motu',
	Hungarian: 'Hungarian',
	Indonesian: 'Indonesian',
	Irish: 'Irish',
	Igbo: 'Igbo',
	Inupiaq: 'Inupiaq',
	Ido: 'Ido',
	Icelandic: 'Icelandic',
	Italian: 'Italian',
	Inuktitut: 'Inuktitut',
	Japanese: 'Japanese',
	Javanese: 'Javanese',
	Kalaallisut: 'Kalaallisut',
	Kannada: 'Kannada',
	Kanuri: 'Kanuri',
	Kashmiri: 'Kashmiri',
	Kazakh: 'Kazakh',
	Khmer: 'Khmer',
	Kikuyu: 'Kikuyu',
	Kinyarwanda: 'Kinyarwanda',
	Kirghiz: 'Kirghiz',
	Komi: 'Komi',
	Kongo: 'Kongo',
	Korean: 'Korean',
	Kurdish: 'Kurdish',
	Kwanyama: 'Kwanyama',
	Latin: 'Latin',
	Luxembourgish: 'Luxembourgish',
	Luganda: 'Luganda',
	Limburgish: 'Limburgish',
	Lingala: 'Lingala',
	Lao: 'Lao',
	Lithuanian: 'Lithuanian',
	Latvian: 'Latvian',
	Manx: 'Manx',
	Macedonian: 'Macedonian',
	Malagasy: 'Malagasy',
	Malay: 'Malay',
	Malayalam: 'Malayalam',
	Maltese: 'Maltese',
	Maori: 'Maori',
	Marathi: 'Marathi',
	Marshallese: 'Marshallese',
	Mongolian: 'Mongolian',
	Nauru: 'Nauru',
	Norwegian: 'Norwegian',
	NorwegianBokmal: 'Norwegian Bokmål',
	NorwegianNynorsk: 'Norwegian Nynorsk',
	NorthNbele: 'North Ndebele',
	SouthNbele: 'South Ndebele',
	Nepali: 'Nepali',
	Ndoga: 'Ndonga',
	Nuosu: 'Nuosu',
	Occitan: 'Occitan',
	Ojibwe: 'Ojibwe',
	Oromo: 'Oromo',
	Oriya: 'Oriya',
	Ossetian: 'Ossetian',
	Panjabi: 'Panjabi',
	Pali: 'Pāli',
	Persian: 'Persian',
	Polish: 'Polish',
	Pashto: 'Pashto',
	Portuguese: 'Portuguese',
	Quechua: 'Quechua',
	Kirundi: 'Kirundi',
	Romanian: 'Romanian',
	Russian: 'Russian',
	Sanskrit: 'Sanskrit',
	Sardinian: 'Sardinian',
	Sindhi: 'Sindhi',
	SamiNorthern: 'Northern Sami',
	Samoan: 'Samoan',
	Sango: 'Sango',
	Serbian: 'Serbian',
	Shona: 'Shona',
	Sinhala: 'Sinhala, Sinhalese',
	Slovak: 'Slovak',
	Slovene: 'Slovene',
	Somali: 'Somali',
	Spanish: 'Spanish',
	Sundanese: 'Sundanese',
	Swahili: 'Swahili',
	Swati: 'Swati',
	Swedish: 'Swedish',
	Tamil: 'Tamil',
	Telugu: 'Telugu',
	Tajik: 'Tajik',
	Thai: 'Thai',
	Tigrinya: 'Tigrinya',
	Tibetan: 'Tibetan',
	Turkmen: 'Turkmen',
	Tagalog: 'Tagalog',
	Tswana: 'Tswana',
	Tonga: 'Tonga',
	Turkish: 'Turkish',
	Tsonga: 'Tsonga',
	Tatar: 'Tatar',
	Twi: 'Twi',
	Tahitian: 'Tahitian',
	Uighur: 'Uighur',
	Ukrainian: 'Ukrainian',
	Urdu: 'Urdu',
	Uzbek: 'Uzbek',
	Venda: 'Venda',
	Vietnamese: 'Vietnamese',
	Volapuk: 'Volapük',
	Walloon: 'Walloon',
	Welsh: 'Welsh',
	Wolof: 'Wolof',
	Xhosa: 'Xhosa',
	Yiddish: 'Yiddish',
	Yoruba: 'Yoruba',
	Zhuang: 'Zhuang',
};

export const a11yLanguages = [
	{ key: language, value: 'English', languageCode: 'en' },
	{ key: language.Swedish, value: 'Swedish', languageCode: 'sv' },
	{ key: language.Finnish, value: 'Finnish', languageCode: 'fi' },
	{ key: language.Danish, value: 'Danish', languageCode: 'da' },
	{ key: language.NorwegianBokmal, value: 'Norwegian Bokmål', languageCode: 'nb' },
];

export const languageOptionsDropDownMenu = [
	{ key: language.Abkhaz, value: 'Abkhaz', languageCode: 'ab' },
	{ key: language.Afar, value: 'Afar', languageCode: 'aa' },
	{ key: language.Afrikaans, value: 'Afrikaans', languageCode: 'af' },
	{ key: language.Akan, value: 'Akan', languageCode: 'ak' },
	{ key: language.Albanian, value: 'Albanian', languageCode: 'sq' },
	{ key: language.Amharic, value: 'Amharic', languageCode: 'am' },
	{ key: language.Arabic, value: 'Arabic', languageCode: 'ar' },
	{ key: language.Aragonese, value: 'Aragonese', languageCode: 'an' },
	{ key: language.Armenian, value: 'Armenian', languageCode: 'hy' },
	{ key: language.Assamese, value: 'Assamese', languageCode: 'as' },
	{ key: language.Avaric, value: 'Avaric', languageCode: 'av' },
	{ key: language.Avestan, value: 'Avestan', languageCode: 'ae' },
	{ key: language.Aymara, value: 'Aymara', languageCode: 'ay' },
	{ key: language.Azerbaijani, value: 'Azerbaijani', languageCode: 'az' },
	{ key: language.Bambara, value: 'Bambara', languageCode: 'bm' },
	{ key: language.Bashkir, value: 'Bashkir', languageCode: 'ba' },
	{ key: language.Basque, value: 'Basque', languageCode: 'eu' },
	{ key: language.Belarusian, value: 'Belarusian', languageCode: 'be' },
	{ key: language.Bengali, value: 'Bengali', languageCode: 'bn' },
	{ key: language.Bihari, value: 'Bihari', languageCode: 'bh' },
	{ key: language.Bislama, value: 'Bislama', languageCode: 'bi' },
	{ key: language.Bosnian, value: 'Bosnian', languageCode: 'bs' },
	{ key: language.Breton, value: 'Breton', languageCode: 'br' },
	{ key: language.Bulgarian, value: 'Bulgarian', languageCode: 'bg' },
	{ key: language.Burmese, value: 'Burmese', languageCode: 'my' },
	{ key: language.Catalan, value: 'Catalan', languageCode: 'ca' },
	{ key: language.Chamorro, value: 'Chamorro', languageCode: 'ch' },
	{ key: language.Chechen, value: 'Chechen', languageCode: 'ce' },
	{ key: language.Chichewa, value: 'Chichewa', languageCode: 'ny' },
	{ key: language.Chinese, value: 'Chinese', languageCode: 'zh' },
	{ key: language.Chuvash, value: 'Chuvash', languageCode: 'cv' },
	{ key: language.Cornish, value: 'Cornish', languageCode: 'kw' },
	{ key: language.Corsican, value: 'Corsican', languageCode: 'co' },
	{ key: language.Croatian, value: 'Croatian', languageCode: 'hr' },
	{ key: language.Czech, value: 'Czech', languageCode: 'cs' },
	{ key: language.Danish, value: 'Danish', languageCode: 'da' },
	{ key: language.Divehi, value: 'Divehi', languageCode: 'dv' },
	{ key: language.Dutch, value: 'Dutch', languageCode: 'nl' },
	{ key: language.English, value: 'English', languageCode: 'en' },
	{ key: language.Esperanto, value: 'Esperanto', languageCode: 'eo' },
	{ key: language.Estonian, value: 'Estonian', languageCode: 'et' },
	{ key: language.Ewe, value: 'Ewe', languageCode: 'ee' },
	{ key: language.Faroese, value: 'Faroese', languageCode: 'fo' },
	{ key: language.Fijian, value: 'Fijian', languageCode: 'fj' },
	{ key: language.Finnish, value: 'Finnish', languageCode: 'fi' },
	{ key: language.French, value: 'French', languageCode: 'fr' },
	{ key: language.Fula, value: 'Fula', languageCode: 'ff' },
	{ key: language.Galician, value: 'Galician', languageCode: 'gl' },
	{ key: language.Georgian, value: 'Georgian', languageCode: 'ka' },
	{ key: language.German, value: 'German', languageCode: 'de' },
	{ key: language.Greek, value: 'Greek', languageCode: 'el' },
	{ key: language.Guaraní, value: 'Guarani', languageCode: 'gn' },
	{ key: language.Gujarati, value: 'Gujarati', languageCode: 'gu' },
	{ key: language.Haitian, value: 'Haitian', languageCode: 'ht' },
	{ key: language.Hausa, value: 'Hausa', languageCode: 'ha' },
	{ key: language.Hebrew, value: 'Hebrew', languageCode: 'he' },
	{ key: language.Herero, value: 'Herero', languageCode: 'hz' },
	{ key: language.Hindi, value: 'Hindi', languageCode: 'hi' },
	{ key: language.HiriMotu, value: 'Hiri Motu', languageCode: 'ho' },
	{ key: language.Hungarian, value: 'Hungarian', languageCode: 'hu' },
	{ key: language.Indonesian, value: 'Indonesian', languageCode: 'id' },
	{ key: language.Irish, value: 'Irish', languageCode: 'ga' },
	{ key: language.Igbo, value: 'Igbo', languageCode: 'ig' },
	{ key: language.Inupiaq, value: 'Inupiaq', languageCode: 'ik' },
	{ key: language.Ido, value: 'Ido', languageCode: 'io' },
	{ key: language.Icelandic, value: 'Icelandic', languageCode: 'is' },
	{ key: language.Italian, value: 'Italian', languageCode: 'it' },
	{ key: language.Inuktitut, value: 'Inuktitut', languageCode: 'iu' },
	{ key: language.Japanese, value: 'Japanese', languageCode: 'ja' },
	{ key: language.Javanese, value: 'Javanese', languageCode: 'jv' },
	{ key: language.Kalaallisut, value: 'Kalaallisut', languageCode: 'kl' },
	{ key: language.Kannada, value: 'Kannada', languageCode: 'kn' },
	{ key: language.Kanuri, value: 'Kanuri', languageCode: 'kr' },
	{ key: language.Kashmiri, value: 'Kashmiri', languageCode: 'ks' },
	{ key: language.Kazakh, value: 'Kazakh', languageCode: 'kk' },
	{ key: language.Khmer, value: 'Khmer', languageCode: 'km' },
	{ key: language.Kikuyu, value: 'Kikuyu', languageCode: 'ki' },
	{ key: language.Kinyarwanda, value: 'Kinyarwanda', languageCode: 'rw' },
	{ key: language.Kirghiz, value: 'Kirghiz', languageCode: 'ky' },
	{ key: language.Komi, value: 'Komi', languageCode: 'kv' },
	{ key: language.Kongo, value: 'Kongo', languageCode: 'kg' },
	{ key: language.Korean, value: 'Korean', languageCode: 'ko' },
	{ key: language.Kurdish, value: 'Kurdish', languageCode: 'ku' },
	{ key: language.Kwanyama, value: 'Kwanyama', languageCode: 'kj' },
	{ key: language.Latin, value: 'Latin', languageCode: 'la' },
	{ key: language.Luxembourgish, value: 'Luxembourgish', languageCode: 'lb' },
	{ key: language.Luganda, value: 'Luganda', languageCode: 'lg' },
	{ key: language.Limburgish, value: 'Limburgish', languageCode: 'li' },
	{ key: language.Lingala, value: 'Lingala', languageCode: 'ln' },
	{ key: language.Lao, value: 'Lao', languageCode: 'lo' },
	{ key: language.Lithuanian, value: 'Lithuanian', languageCode: 'lt' },
	{ key: language.Latvian, value: 'Latvian', languageCode: 'lv' },
	{ key: language.Manx, value: 'Manx', languageCode: 'gv' },
	{ key: language.Macedonian, value: 'Macedonian', languageCode: 'mk' },
	{ key: language.Malagasy, value: 'Malagasy', languageCode: 'mg' },
	{ key: language.Malay, value: 'Malay', languageCode: 'mg' },
	{ key: language.Malayalam, value: 'Malayalam', languageCode: 'ml' },
	{ key: language.Maltese, value: 'Maltese', languageCode: 'mt' },
	{ key: language.Maori, value: 'Maori', languageCode: 'mi' },
	{ key: language.Marathi, value: 'Marathi', languageCode: 'mr' },
	{ key: language.Marshallese, value: 'Marshallese', languageCode: 'mh' },
	{ key: language.Mongolian, value: 'Mongolian', languageCode: 'mn' },
	{ key: language.Nauru, value: 'Nauru', languageCode: 'na' },
	{ key: language.Norwegian, value: 'Norwegian', languageCode: 'no' },
	{ key: language.NorwegianBokmal, value: 'Norwegian Bokmål', languageCode: 'nb' },
	{ key: language.NorwegianNynorsk, value: 'Norwegian Nynorsk', languageCode: 'nn' },
	{ key: language.NorthNbele, value: 'North Nbele', languageCode: 'nd' },
	{ key: language.SouthNbele, value: 'South Nbele', languageCode: 'nr' },
	{ key: language.Nepali, value: 'Nepali', languageCode: 'ne' },
	{ key: language.Ndoga, value: 'Ndonga', languageCode: 'ng' },
	{ key: language.Nuosu, value: 'Nuosu', languageCode: 'ii' },
	{ key: language.Occitan, value: 'Occitan', languageCode: 'oc' },
	{ key: language.Ojibwe, value: 'Ojibwe', languageCode: 'oj' },
	{ key: language.Oromo, value: 'Oromo', languageCode: 'om' },
	{ key: language.Oriya, value: 'Oriya', languageCode: 'or' },
	{ key: language.Ossetian, value: 'Ossetian', languageCode: 'os' },
	{ key: language.Panjabi, value: 'Panjabi', languageCode: 'pa' },
	{ key: language.Pali, value: 'Pali', languageCode: 'pi' },
	{ key: language.Persian, value: 'Persian', languageCode: 'fa' },
	{ key: language.Polish, value: 'Polish', languageCode: 'pl' },
	{ key: language.Pashto, value: 'Pashto', languageCode: 'ps' },
	{ key: language.Portuguese, value: 'Portuguese', languageCode: 'pt' },
	{ key: language.Quechua, value: 'Quechua', languageCode: 'qu' },
	{ key: language.Kirundi, value: 'Kirundi', languageCode: 'rn' },
	{ key: language.Romanian, value: 'Romanian', languageCode: 'ro' },
	{ key: language.Russian, value: 'Russian', languageCode: 'ru' },
	{ key: language.Sanskrit, value: 'Sanskrit', languageCode: 'sa' },
	{ key: language.Sardinian, value: 'Sardinian', languageCode: 'sc' },
	{ key: language.Sindhi, value: 'Sindhi', languageCode: 'sd' },
	{ key: language.SamiNorthern, value: 'Northern Sami', languageCode: 'se' },
	{ key: language.Samoan, value: 'Samoan', languageCode: 'sm' },
	{ key: language.Sango, value: 'Sango', languageCode: 'sg' },
	{ key: language.Serbian, value: 'Serbian', languageCode: 'sr' },
	{ key: language.Shona, value: 'Shona', languageCode: 'sn' },
	{ key: language.Sinhala, value: 'Sinhala', languageCode: 'si' },
	{ key: language.Slovak, value: 'Slovak', languageCode: 'sk' },
	{ key: language.Slovene, value: 'Slovene', languageCode: 'sl' },
	{ key: language.Somali, value: 'Somali', languageCode: 'so' },
	{ key: language.Spanish, value: 'Spanish', languageCode: 'es' },
	{ key: language.Sundanese, value: 'Sundanese', languageCode: 'su' },
	{ key: language.Swahili, value: 'Swahili', languageCode: 'sw' },
	{ key: language.Swati, value: 'Swati', languageCode: 'ss' },
	{ key: language.Swedish, value: 'Swedish', languageCode: 'sv' },
	{ key: language.Tamil, value: 'Tamil', languageCode: 'ta' },
	{ key: language.Telugu, value: 'Telugu', languageCode: 'te' },
	{ key: language.Tajik, value: 'Tajik', languageCode: 'tg' },
	{ key: language.Thai, value: 'Thai', languageCode: 'th' },
	{ key: language.Tigrinya, value: 'Tigrinya', languageCode: 'ti' },
	{ key: language.Tibetan, value: 'Tibetan', languageCode: 'bo' },
	{ key: language.Turkmen, value: 'Turkmen', languageCode: 'tk' },
	{ key: language.Tagalog, value: 'Tagalog', languageCode: 'tl' },
	{ key: language.Tswana, value: 'Tswana', languageCode: 'tn' },
	{ key: language.Tonga, value: 'Tonga', languageCode: 'to' },
	{ key: language.Turkish, value: 'Turkish', languageCode: 'tr' },
	{ key: language.Tsonga, value: 'Tsonga', languageCode: 'ts' },
	{ key: language.Tatar, value: 'Tatar', languageCode: 'tt' },
	{ key: language.Twi, value: 'Twi', languageCode: 'tw' },
	{ key: language.Tahitian, value: 'Tahitian', languageCode: 'ty' },
	{ key: language.Uighur, value: 'Uighur', languageCode: 'ug' },
	{ key: language.Ukrainian, value: 'Ukrainian', languageCode: 'uk' },
	{ key: language.Urdu, value: 'Urdu', languageCode: 'ur' },
	{ key: language.Uzbek, value: 'Uzbeck', languageCode: 'uz' },
	{ key: language.Venda, value: 'Venda', languageCode: 've' },
	{ key: language.Vietnamese, value: 'Vietnamese', languageCode: 'vi' },
	{ key: language.Volapuk, value: 'Volapuk', languageCode: 'vo' },
	{ key: language.Walloon, value: 'Walloon', languageCode: 'wa' },
	{ key: language.Welsh, value: 'Welsh', languageCode: 'cy' },
	{ key: language.Wolof, value: 'Wolof', languageCode: 'wo' },
	{ key: language.Xhosa, value: 'Xhosa', languageCode: 'xh' },
	{ key: language.Yiddish, value: 'Yiddish', languageCode: 'yi' },
	{ key: language.Yoruba, value: 'Yoruba', languageCode: 'yo' },
	{ key: language.Zhuang, value: 'Zhuang', languageCode: 'za' },
];
