import * as actions from '../actions/sessionActions';
import { LocalStorageService } from './../services/localStorageService';

const initialState = {
	users: [],
	userId: '',
	accounts: [],
	username: '',
	password: '',
	language: '',
	logginUrl: '',
	loading: false,
	newPassword: '',
	userPermissions: LocalStorageService.getUserPermissions() ? LocalStorageService.getUserPermissions() : [],
	accessToken: '',
	isLoggedIn: false,
	confirmPassword: '',
	defaultAccountId: LocalStorageService.getDefaultAccountId() ? LocalStorageService.getDefaultAccountId() : '',
	defaultAccountName: LocalStorageService.getDefaultAccountName() ? LocalStorageService.getDefaultAccountName() : '',
	customMetadata: '',
	isAEEditorOpen: false,
	screenRecorderModalStatus: false,
};

export const sessionReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SESSION_INITIALISE:
			return {
				...state,
				isLoggedIn: true,
				userId: action.userId || LocalStorageService.getUserId(),
				username: action.username || LocalStorageService.getUserName(),
				accounts: action.accounts || LocalStorageService.getAccounts(),
				userPermissions: action.userPermissions || LocalStorageService.getUserPermissions(),
				accessToken: action.accessToken || LocalStorageService.getAccessToken(),
				defaultAccountId: action.defaultAccountId || LocalStorageService.getDefaultAccountId(),
				defaultAccountName: action.defaultAccountName || LocalStorageService.getDefaultAccountName(),
			};
		case actions.USER_PERMISSIONS_REINITIALIZE:
			localStorage.setItem('userPermissions', action.userPermissions); // no need to stringify as it is already stringified
			return {
				...state,
				userPermissions: action.userPermissions,
			};
		case actions.DEFAULT_ACCOUNTID_REINITIALIZE:
			return {
				...state,
				defaultAccountId: action.defaultAccountId,
				defaultAccountName: action.defaultAccountName,
			};
		case actions.USER_PERMISSIONS_REINITIALIZE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.USER_PERMISSIONS_REINITIALIZE_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.USER_PERMISSIONS_REINITIALIZE_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.LOGIN_FORM_UPDATE:
			return {
				...state,
				[action.field]: action.value,
			};
		case actions.SEND_LOGIN_FORM_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_LOGIN_FORM_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_LOGIN_FORM_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_PASSWORD_RESET_FORM_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_PASSWORD_RESET_FORM_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_PASSWORD_RESET_FORM_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.LOGIN_PROCESS_SUCCESS:
			return {
				...state,
			};
		case actions.SESSION_END:
			return {
				...initialState,
			};
		case actions.SAVE_CUSTOM_METADATA:
			return {
				...state,
				customMetadata: action.customMetadata,
			};
		case actions.TRIGGER_ADOBE_EXPRESS_EDITOR: {
			return {
				...state,
				isAEEditorOpen: action.isOpen,
			};
		}

		case actions.SET_SCREEN_RECORDER_MODAL_STATUS: {
			return {
				...state,
				screenRecorderModalStatus: action.isOpen,
			};
		}
		default:
			return state;
	}
};
