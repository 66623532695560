import { Button, Input, InputAdornment } from '@material-ui/core';
import React, { useRef, useState } from 'react';

import './InputMouseAction.scss';

export type InputMouseActionEventProps = {
	handleLocationAttributeChange: (_value: number, _styleProperty: string, _valueProperty?: string) => void;
	handleMouseActionSelectInput: (
		_ref: any,
		_event: any,
		_styleProperty: string,
		_updateCallback: (_updatedValue: number) => void
	) => void;
	handleOnMouseMove?: (_e: React.MouseEvent) => void;
	handleOnMouseUp?: (_e: React.MouseEvent) => void;
};

type InputMouseActionProps = InputMouseActionEventProps & {
	value: number;
	styleProperty: string;
	valueProperty?: string;
};

const getLabelText = (text: string) => {
	return text.substring(0, 1).toUpperCase();
};

const InputMouseAction = ({
	value,
	styleProperty,
	valueProperty,
	handleMouseActionSelectInput,
	handleLocationAttributeChange,
	handleOnMouseMove,
	handleOnMouseUp,
	...props
}: InputMouseActionProps) => {
	const ref = useRef<any>(null);
	const [inputMode, setInputMode] = useState(false);
	const [isDragging, setIsDragging] = useState(false);

	const roundedValue = Math.round(value * 10) / 10;
	const label = `${valueProperty ? getLabelText(valueProperty) : getLabelText(styleProperty)}:`;

	return inputMode ? (
		<Input
			{...props}
			value={Number(roundedValue).toString()}
			type="number"
			onChange={(event) => {
				valueProperty &&
					handleLocationAttributeChange(Number(event.target.value), styleProperty, valueProperty);
				!valueProperty && handleLocationAttributeChange(Number(event.target.value), styleProperty);
			}}
			onKeyDown={(event) => {
				if (event.keyCode === 13) {
					setInputMode(false);
				}
			}}
			onBlur={() => {
				setInputMode(false);
			}}
			startAdornment={<InputAdornment position="start">{label}</InputAdornment>}
		/>
	) : (
		<Button
			className="dragging-value-button"
			size="medium"
			ref={ref}
			variant="text"
			disableRipple
			disableFocusRipple
			startIcon={<>{label}</>}
			onClick={(event) => {
				if (!event.isTrusted) {
					setInputMode(true);
				}
			}}
			onDragStart={(event) => {
				event.preventDefault();
			}}
			onMouseDown={(event) => {
				event.preventDefault();
				setIsDragging(true);
				handleMouseActionSelectInput(
					ref.current,
					event,
					valueProperty ? valueProperty : styleProperty,
					(updatedValue: number) => {
						handleLocationAttributeChange(updatedValue, styleProperty, valueProperty);
					}
				);
			}}
			onMouseMove={handleOnMouseMove}
			onMouseUp={(e) => {
				setIsDragging(false);
				handleOnMouseUp?.(e);
			}}
		>
			<input className={isDragging ? 'value-label' : ''} type="number" value={roundedValue} readOnly />
		</Button>
	);
};

export default InputMouseAction;
