import * as actions from '../actions/speechToTextActions';

const initialState = {
	playPlayer: false,
	pausePlayer: true,
	positionPlayer: 0,
	clickedPosition: 0,
	hasTranscription: '',
	transcriptionData: '',
	showPreview: false,
	rawTranscribedText: 'no transcription available',
	appMode: 'edit',
	isMountedSubtitleUpdated: false,
	subtitleIsUpdating: false,
};

export const speechToTextReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.RESET_SPEECH_TO_TEXT_REDUCER:
			return {
				...state,
				plplayPlayer: false,
				pausePlayer: true,
				positionPlayer: 0,
				clickedPosition: 0,
				hasTranscription: '',
				transcriptionData: '',
			};
		case actions.PLAY_BUTTON_PLAY:
			return {
				...state,
				playPlayer: true,
				pausePlayer: false,
			};
		case actions.TOGGLE_SHOW_PREVIEW:
			return {
				...state,
				showPreview: action.showPreview,
			};
		case actions.PLAY_BUTTON_PAUSE:
			return {
				...state,
				playPlayer: false,
				pausePlayer: true,
			};
		case actions.SET_PLAYER_POSITION:
			return {
				...state,
				positionPlayer: action.position,
			};
		case actions.SET_RAW_TRANSCRIPTION_TEXT:
			return {
				...state,
				rawTranscribedText: action.rawTranscribedText,
			};
		case actions.SET_APP_MODE:
			return {
				...state,
				appMode: action.mode,
			};
		case actions.SET_IS_MOUNTED_SUB_UPDATED:
			return {
				...state,
				isMountedSubtitleUpdated: action.mountedSubUpdate,
			};
		case actions.SET_SUBTITLE_IS_UPDATING:
			return {
				...state,
				subtitleIsUpdating: action.subtitleIsUpdating,
			};
		case actions.SET_CLICKED_POSITION:
			return {
				...state,
				clickedPosition: action.clickedPosition,
			};
		case actions.TRANSCRIPTION_LOAD_REQUEST_FAILURE:
			return {
				...state,
				hasTranscription: action.error,
			};
		case actions.TRANSCRIPTION_LOAD_REQUEST_SUCCESS:
			return {
				...state,
				transcriptionData: action.data,
			};
		default:
			return state;
	}
};
