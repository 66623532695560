import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import Close from '@material-ui/icons/Close';

import './EditorModal.scss';

const EditorModal = (props) => {
	return (
		<Dialog
			open={props.open}
			onClose={(event, reason) =>
				reason !== 'backdropClick' && props.onClose?.(event)
			}
		>
			<div className="editor-dialog__title">
				{props.showHeaderIcon && (
					<HelpOutlineOutlined color="primary" />
				)}
				<div>{props.title}</div>
				{props.onClose && (
					<IconButton onClick={props.onClose}>
						<Close color="primary" />
					</IconButton>
				)}
			</div>
			<DialogContent
				align="center"
				style={{ padding: '12px' }}
			>
				{props.textContent && (
					<Typography variant="body1">{props.textContent}</Typography>
				)}
				{props.children}
			</DialogContent>
			<DialogActions
				className={
					props.actionAlign
						? `editor-dialog__action--${props.actionAlign}`
						: undefined
				}
			>
				{props.onCancel && (
					<Button
						className="cancel-button"
						variant={'outlined'}
						onClick={props.onCancel}
					>
						{props.cancelText ?? 'Cancel'}
					</Button>
				)}
				<Button
					variant={props.actionVariant ?? 'contained'}
					color={props.actionType ?? 'primary'}
					onClick={props.onAccept}
				>
					{props.actionText ?? 'Accept'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default EditorModal;
