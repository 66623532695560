import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { saveNewFilterForLibrarySearchQueryAction } from '../../actions/searchActions';
import { generateId } from '../../services/stringHelperService';
import { showMessage } from '../../actions/globalActions';
import { messageTypes } from '../../constants/mediaConstants';

interface NewSearchFilterDialogBoxProps {
	open: boolean;
	onSaveNewSearchFilter?: (_newFilterId: string) => void;
	onClose?: () => void;
	searchQuery?: string;
}

export const NewSearchFilterDialogBox: React.FC<NewSearchFilterDialogBoxProps> = ({
	open,
	onSaveNewSearchFilter,
	onClose,
	searchQuery,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch() as any;

	const { defaultAccountId } = useSelector((state) => (state as any).session);
	const [filterName, setFilterName] = useState('');

	const handleSaveNewSearchFilter = () => {
		const filterId = generateId();
		dispatch(
			saveNewFilterForLibrarySearchQueryAction(
				defaultAccountId,
				filterName,
				filterId,
				searchQuery ?? 'q=*',
				'',
				50
			)
		)
			.then((data: any) => {
				if (data) {
					onSaveNewSearchFilter?.(filterId);
					dispatch(showMessage(t('LABEL_NEW_FILTER_CREATED'), messageTypes.success));
					return;
				}

				dispatch(showMessage(t('LABEL_NEW_FILTER_CREATE_FAILED'), messageTypes.error));
			})
			.catch((error: any) =>
				dispatch(showMessage(t('LABEL_NEW_FILTER_CREATE_FAILED') + ' ' + error, messageTypes.error))
			);
	};

	return (
		<Dialog aria-labelledby="alert-dialog-title" open={open} aria-describedby="alert-dialog-description" fullWidth>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">{t("LABEL_SAVE_AS_SEARCH_FILTER")}</Typography>
				<IconButton onClick={onClose}>
					<Close/>
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<div className="dialogContentWrapper">
						<div className="dialogBoxInternalBlock">
							<label>{t('TITLE_OF_SEARCH_FILTER')}</label>
							<input
								type="text"
								value={filterName}
								className="genericTextInput"
								onChange={(e) => setFilterName(e.target.value)}
							/>
						</div>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<div className="dialogActionsWrapper">
					<div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
						<Button
							variant="contained"
							className="defaultActionBtn"
							onClick={handleSaveNewSearchFilter}
							disabled={!filterName.trim()}
						>
							{t('LABEL_CREATE_SEARCH_FILTER')}
						</Button>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default NewSearchFilterDialogBox;
