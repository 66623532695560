import { _getChannel, _getChannels, _updateChannel } from './../services/channelService';

import { LocalStorageService } from '../services/localStorageService';

export const SEND_CHANNEL_GET_SUCCESS = 'SEND_CHANNEL_GET_SUCCESS';
export const SEND_CHANNEL_GET_FAILURE = 'SEND_CHANNEL_GET_FAILURE';
export const SEND_CHANNELS_GET_SUCCESS = 'SEND_CHANNELS_GET_SUCCESS';
export const SEND_CHANNELS_GET_FAILURE = 'SEND_CHANNELS_GET_FAILURE';
export const SEND_CHANNEL_UPDATE_SUCCESS = 'SEND_CHANNEL_UPDATE_SUCCESS';
export const SEND_CHANNEL_UPDATE_FAILURE = 'SEND_CHANNEL_UPDATE_FAILURE';

const sendChannelsGetSuccess = (data) => ({
	type: SEND_CHANNELS_GET_SUCCESS,
	channels: data,
});

const sendChannelsGetFailure = (_) => ({
	type: SEND_CHANNELS_GET_FAILURE,
});

// eslint-disable-next-line no-unused-vars
const sendChannelUpdateSuccess = (data) => ({
	type: SEND_CHANNEL_UPDATE_SUCCESS,
	channels: data,
});

const sendChannelUpdateFailure = (_) => ({
	type: SEND_CHANNEL_UPDATE_FAILURE,
});

export const getAccountId = (getState) => {
	if (getState) {
		return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

export const loadChannels = (accountId) => (dispatch) => {
	const defaultAccountId = accountId;
	return _getChannels(defaultAccountId)
		.then((data) => {
			dispatch(sendChannelsGetSuccess(data));
			return data;
		})
		.catch((error) => {
			dispatch(sendChannelsGetFailure());
			return error;
		});
};

export const loadChannel = (accountId, channelId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return _getChannel(defaultAccountId, channelId)
		.then((data) => {
			return data;
		})
		.catch((error) => {
			dispatch(sendChannelsGetFailure());
			return error;
		});
};

export const updateChannel = (accountId, channelId, data) => (dispatch) => {
	const defaultAccountId = accountId;
	return _updateChannel(defaultAccountId, channelId, data)
		.then((data) => {
			_getChannels(defaultAccountId);
			return data;
		})
		.catch((error) => {
			dispatch(sendChannelUpdateFailure());
			return error;
		});
};
