import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './../InteractEditorTopPanel/InteractElementProperties/InteractElementProperties.scss';
import { updateInteractTimelineItem } from '../../../actions/interactTimelineActions';
import IconsStore from '../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../reusable/IconsStore/IconsList';
import ColorPicker from '../../../reusable/ColorPicker/ColorPicker';
import pen_white from '../../../assets/images/pen_white.svg';
import _ from 'underscore';

class FontTypography extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isCurrentlyEdited: false,
			fontStyle: '',
			fontSize: '',
			fontWeight: '',
			fontColor: '',
		};
		this.fontStyleList = [
			{ name: 'Normal', value: 'normal' },
			{ name: 'Italic', value: 'italic' },
			{ name: 'Oblique', value: 'oblique' },
		];
		this.fontWeightList = [
			{ name: 'Normal', value: 'normal' },
			{ name: 'Bold', value: 'bold' },
		];
		this.fontProperties = {
			fontStyle: 'font-style',
			fontSize: 'font-size',
			fontWeight: 'font-weight',
			fontColor: 'fill',
		};
		this.initialState = this.state;
	}

	UNSAFE_componentWillMount = () => {
		const { fieldObj } = this.props;
		this.parseValue(fieldObj.default);
		if (this.props.onfontTypographyLoaded) {
			this.props.onfontTypographyLoaded(this);
		}
	};

	componentWillUnmount() {}

	componentDidMount = () => {};

	componentDidUpdate(prevProps) {
		if (prevProps.selectedSubPropertyKey !== this.props.selectedSubPropertyKey) {
			this.setState({
				...this.initialState,
			});
			this.parseValue(this.props.fieldObj.default);
		}
	}

	parseValue = (actualValue) => {
		let value = actualValue;
		let arrValue = [];
		arrValue = value.split(';');
		if (arrValue.length > 0) {
			arrValue.map((eachValue) => {
				let eachValueArr = eachValue.split(':');
				let value = eachValueArr[1];
				switch (eachValueArr[0]) {
					case this.fontProperties.fontStyle:
						this.setState({
							fontStyle: value,
						});
						break;
					case this.fontProperties.fontSize:
						this.setState({
							fontSize: parseInt(value),
						});
						break;
					case this.fontProperties.fontWeight:
						if (!isNaN(value)) {
							if (value >= 600) {
								value = 'bold';
							} else {
								value = 'normal';
							}
						}
						this.setState({
							fontWeight: value,
						});
						break;
					case this.fontProperties.fontColor:
						this.setState({
							fontColor: value,
						});
						break;
					default:
						break;
				}
			});
		}
	};

	getCurrentlyEditedItem = () => {
		const { interactItems, selectedInteractItems } = this.props;
		if (selectedInteractItems && selectedInteractItems.length > 0) {
			let itemId = selectedInteractItems[0];
			let item = _.find(interactItems, (item) => {
				return item.id === itemId;
			});
			if (item) {
				return item;
			}
		}
		return {};
	};

	handleEditTypography = () => {
		this.setState({
			isCurrentlyEdited: true,
		});
	};

	handleCancelTypography = () => {
		this.setState({
			isCurrentlyEdited: false,
		});
	};

	handleChange = (key, value) => {
		switch (key) {
			case this.fontProperties.fontStyle:
				this.setState(
					{
						fontStyle: value,
					},
					() => {
						this.setValue();
					}
				);
				break;
			case this.fontProperties.fontSize:
				this.setState(
					{
						fontSize: value,
					},
					() => {
						this.setValue();
					}
				);
				break;
			case this.fontProperties.fontWeight:
				this.setState(
					{
						fontWeight: value,
					},
					() => {
						this.setValue();
					}
				);
				break;
			case this.fontProperties.fontColor:
				this.setState(
					{
						fontColor: value,
					},
					() => {
						this.setValue();
					}
				);
				break;
			default:
				break;
		}
	};

	setValue = () => {
		const { fontStyle, fontSize, fontWeight, fontColor } = this.state;
		const { parentKey, objKey } = this.props;
		let item = this.getCurrentlyEditedItem();
		let value =
			this.fontProperties.fontStyle +
			':' +
			fontStyle +
			';' +
			this.fontProperties.fontSize +
			':' +
			fontSize +
			'px' +
			';' +
			this.fontProperties.fontWeight +
			':' +
			fontWeight +
			';' +
			this.fontProperties.fontColor +
			':' +
			fontColor;
		let newItem = {
			...item,
		};
		newItem.settings[parentKey][objKey].default = value;
		newItem.settings = JSON.parse(JSON.stringify(newItem.settings));
		this.props.updateInteractTimelineItem(newItem);
	};

	renderFontStyle = () => {
		const { fontStyle } = this.state;
		const { t } = this.props;
		return (
			<>
				<div className="singleTypographyContent">
					<label> {t('STYLE')} </label>
					<div className="sortingDropDownWrapper fullWidthControl">
						<select
							className="sortingDropDown"
							onChange={(e) => {
								this.handleChange(this.fontProperties.fontStyle, e.target.value);
							}}
						>
							{this.fontStyleList.map((eachValue) => (
								<option
									key={eachValue.value}
									selected={eachValue.value === fontStyle}
									value={eachValue.value}
								>
									{' '}
									{eachValue.name}{' '}
								</option>
							))}
						</select>
					</div>
				</div>
			</>
		);
	};

	renderFontSize = () => {
		const { fontSize } = this.state;
		const { t } = this.props;
		return (
			<>
				<div className="singleTypographyContent fontAdjuster">
					<label className="fontSizeAdjusterTitle"> {t('SIZE')} </label>
					<div className="fontSizeAdjuster">
						<div className="fontSlider">
							<input
								type="range"
								min="1"
								max="1000"
								value={parseInt(fontSize)}
								className="slider"
								id="myRange"
								onChange={(e) => {
									this.handleChange(this.fontProperties.fontSize, e.target.value);
								}}
							/>
						</div>
						<input
							type="number"
							value={parseInt(fontSize)}
							min="1"
							max="1000"
							onChange={(e) => {
								this.handleChange(this.fontProperties.fontSize, e.target.value);
							}}
						/>{' '}
						<div className="unitOfMeasure">
							<label>px</label>
						</div>
					</div>
				</div>
			</>
		);
	};

	renderFontWeight = () => {
		const { fontWeight } = this.state;
		const { t } = this.props;
		return (
			<>
				<div className="singleTypographyContent">
					<label> {t('WEIGHT')} </label>
					<div className="sortingDropDownWrapper fullWidthControl">
						<select
							className="sortingDropDown"
							onChange={(e) => {
								this.handleChange(this.fontProperties.fontWeight, e.target.value);
							}}
						>
							{this.fontWeightList.map((eachValue) => (
								<option
									key={eachValue.value}
									selected={eachValue.value === fontWeight}
									value={eachValue.value}
								>
									{' '}
									{eachValue.name}{' '}
								</option>
							))}
						</select>
					</div>
				</div>
			</>
		);
	};

	renderFontColor = () => {
		const { fontColor } = this.state;
		const { t } = this.props;
		return (
			<>
				<div className="singleTypographyContent colorPicker">
					<label> {t('FONT_COLOR')} </label>
					<ColorPicker
						color={fontColor}
						onChangeComplete={(e) => {
							this.handleChange(this.fontProperties.fontColor, e.hex);
						}}
					/>
				</div>
			</>
		);
	};

	renderTypographyContent = () => {
		return (
			<>
				<div className="typographyContent">
					{this.renderFontStyle()}
					{this.renderFontSize()}
					{this.renderFontWeight()}
					{this.renderFontColor()}
				</div>
			</>
		);
	};

	render() {
		const { t } = this.props;
		const { isCurrentlyEdited } = this.state;
		return (
			<>
				<div className="fontTypography" ref={this.props.fontRef}>
					<label> {t('TYPOGRAPHY')} </label>
					{!isCurrentlyEdited && (
						<div
							className="typographyEdit"
							onClick={() => {
								this.handleEditTypography();
							}}
						>
							<img src={pen_white} />
						</div>
					)}
					{isCurrentlyEdited && (
						<div
							className="typographyEdit"
							onClick={() => {
								this.handleCancelTypography();
							}}
						>
							<IconsStore iconName={IconsList.CLOSE} />
						</div>
					)}
					{isCurrentlyEdited && this.renderTypographyContent()}
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, interactTimelineReducer, interactElementsReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	interactGroups: interactTimelineReducer.groups,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
	selectedSubPropertyKey: interactElementsReducer.selectedSubPropertyKey,
});

const mapDispatchToProps = (dispatch) => ({
	updateInteractTimelineItem: (item) => dispatch(updateInteractTimelineItem(item)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(FontTypography);
