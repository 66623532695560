import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setViewEngagementLoading } from '../../../actions/viewEngagementActions';
import { getMediaChatSettings } from '../../../actions/liveChatActions';
import { showMessage } from '../../../actions/globalActions';
import { ViewerEngagementTemplate } from './ViewerEngagementTemplate';

interface DataProps {
	defaultAccountId: string;
	mediaId: string;
	shouldUpdateSettings?: boolean;
	callback?: () => void;
}

interface ReturnDataProps {
	settings?: ViewerEngagementTemplate.Settings;
}

export const useFetchSettings = ({
	defaultAccountId,
	mediaId,
	shouldUpdateSettings,
	callback,
}: DataProps): ReturnDataProps => {
	const dispatch = useDispatch() as any;

	const [settings, setSettings] = useState<ViewerEngagementTemplate.Settings | undefined>(undefined);

	useEffect(() => {
		if (!shouldUpdateSettings) {
			return;
		}

		dispatch(setViewEngagementLoading(true));
		dispatch(getMediaChatSettings(defaultAccountId, mediaId))
			.then((data: any) => {
				setSettings(data);

				callback?.();
				dispatch(setViewEngagementLoading(false));
			})
			.catch((error: any) => {
				dispatch(showMessage(`Can't fetch Chat Settings data. ${error}`));
				dispatch(setViewEngagementLoading(false));
			});
	}, [defaultAccountId, mediaId, shouldUpdateSettings]);

	return { settings };
};
