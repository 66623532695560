import React, { useState } from 'react';

import { InspectUtils } from './Utils';
import { Collapse, List, ListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { detailsMenuKeys } from '../../../constants/inspectScreenMenu';

interface InspectMediaMenuProps {
	onSelectedMenuChange: (_itemId: string) => void;
	embedVersion?: string;
}

const InspectMediaMenu: React.FC<InspectMediaMenuProps> = ({ onSelectedMenuChange, embedVersion }) => {
	const { t } = useTranslation();

	const [detailMenuOpen, setDetailMenuOpen] = useState(true);
	const [selectedMenu, setSelectedMenu] = useState(detailsMenuKeys.playlistMediaDetails);

	const classes = InspectUtils.useListCustomStyle();

	return (
		<List style={{ padding: '5px' }}>
			<div onClick={() => setDetailMenuOpen((prevState) => !prevState)} className={'inspect-menu_parent-item'}>
				<div className={'inspect-menu_parent-item_label'}>Details</div>
				<div className={'inspect-menu_parent-item_icon'}>
					{detailMenuOpen ? <ExpandLess htmlColor="#fff" /> : <ExpandMore htmlColor="#fff" />}
				</div>
			</div>
			<Collapse in={detailMenuOpen} timeout="auto" unmountOnExit>
				{InspectUtils.PLAYLIST_MENU_ITEMS.map((item) => {
					if (embedVersion === '2' && item.id === detailsMenuKeys.embedVideo) {
						return null;
					}

					return (
						<ListItem
							button
							key={item.id}
							classes={{ selected: classes.selected }}
							selected={selectedMenu === item.id}
							onClick={() => {
								setSelectedMenu(item.id);
								onSelectedMenuChange(item.id);
							}}
							className={classes.customItem}
						>
							{t(item.text)}
						</ListItem>
					);
				})}
			</Collapse>
		</List>
	);
};

export default InspectMediaMenu;
