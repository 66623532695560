import BackendClient from './backendClient';
import { cdnApiEndpointV1, analyticsEndpointV1, readApiEndpointV1 } from '../utils/config';
import { formatByte } from './../utils/commonUtil';

export const reportsSummary = (accountId, query) =>
	BackendClient.get(`${analyticsEndpointV1}accounts/${accountId}/analytics/player/reports/summary?${query}`);
export const histogramsReportsSummary = (accountId, query) =>
	BackendClient.get(
		`${analyticsEndpointV1}accounts/${accountId}/analytics/player/histograms/reports/summary?${query}`
	);
export const reportsMilestones = (accountId, query) =>
	BackendClient.get(`${analyticsEndpointV1}accounts/${accountId}/analytics/player/reports/milestones?${query}`);

export const getThumb = (accountId, ids) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/medias?q=id:(${ids})
&fields=*(id,asset(resources/*(rel,id,renditions/*(links))),thumbnails)`);

// CDN Analytics
const statsbydimension = (accountId, params) =>
	BackendClient.post(`${cdnApiEndpointV1}analytics/cdn/accounts/${accountId}/statsbydimension`, params);
const stats = (accountId, params) =>
	BackendClient.post(`${cdnApiEndpointV1}analytics/cdn/accounts/${accountId}/stats`, params);
const statsbypaths = (accountId, params) =>
	BackendClient.post(`${cdnApiEndpointV1}analytics/cdn/accounts/${accountId}/statsbypaths`, params);
export const storageHistory = (accountId, query) =>
	BackendClient.get(`${cdnApiEndpointV1}analytics/cdn/accounts/${accountId}/storage/history?${query}`);

export const getLocations = async (accountId, tStart, tEnd) => {
	const dateFrom = convertUtcToEpoch(tStart) * 1000;
	const dateTo = convertUtcToEpoch(tEnd) * 1000 + 86400000;
	const resolution = (dateTo - dateFrom) / 288;

	const params = {
		paths: ['*'],
		metric: 'size',
		dimension: 'countrylabel',
		tStart: tStart,
		tEnd: tEnd,
		resolution: resolution,
		sort: {
			limit: 200,
		},
	};

	const paramCity = { ...params, dimension: 'citylabel' };
	const paramRegion = { ...params, dimension: 'regionlabel' };

	let [countrylabel, citylabel, regionlabel] = await Promise.all([
		statsbydimension(accountId, params),
		statsbydimension(accountId, paramCity),
		statsbydimension(accountId, paramRegion),
	]);

	const dimensions =
		countrylabel && citylabel && regionlabel
			? {
					countrylabel: Object.keys(countrylabel),
					citylabel: Object.keys(citylabel),
					regionlabel: Object.keys(regionlabel),
			  }
			: {};

	return dimensions;
};
export const getStat = async (accountId, metric, tStart, tEnd, city, country, region, path) => {
	const dateFrom = convertUtcToEpoch(tStart) * 1000;
	const dateTo = convertUtcToEpoch(tEnd) * 1000 + 86400000;
	const resolution = (dateTo - dateFrom) / 288;
	const params = {
		metric: metric,
		tStart: tStart,
		tEnd: tEnd,
		resolution: resolution,
		forceEsIndex: 'batch',
		dimensions: {
			countrylabel: [],
			citylabel: [],
			regionlabel: [],
		},
		paths: [],
	};

	if (city) {
		params.dimensions.citylabel.push(city);
	}
	if (country) {
		params.dimensions.countrylabel.push(country);
	}
	if (region) {
		params.dimensions.regionlabel.push(region);
	}
	if (path) {
		params.paths.push(path);
	}

	const response = await stats(accountId, params);

	if (response?.length > 0) {
		metric === 'size'
			? (response.total = formatByte(sumOfResponse(response)))
			: (response.total = sumOfResponse(response));

		return response;
	}
};
export const getVolume = async (accountId, tStart, tEnd, city, country, region) => {
	return getStat(accountId, 'size', tStart, tEnd, city, country, region);
};
export const getAsset = async (accountId, tStart, tEnd, city, country, region) => {
	const response = getStat(accountId, 'uv', tStart, tEnd, city, country, region);
	response.then((data) => {
		response.total = formatByte(sumOfResponse(data));
		return response;
	});
};
export const getHits = async (accountId, tStart, tEnd, city, country, region) => {
	const response = getStat(accountId, 'hits', tStart, tEnd, city, country, region);
	response.then((data) => {
		response.total = formatByte(sumOfResponse(data));
		return response;
	});
};
export const getRealTime = async (accountId, tStart, tEnd, resolution) => {
	const params = {
		metric: 'size',
		dimension: 'application',
		forceEsIndex: 'realtime',
		tStart: tStart,
		tEnd: tEnd,
		resolution: resolution,
	};

	let response = await statsbydimension(accountId, params);

	return response;
};
export const getTopUrls = async (accountId, tStart, tEnd) => {
	const params = {
		metrics: ['hits', 'size'],
		paths: [],
		tStart: tStart,
		tEnd: tEnd,
		sort: {
			metric: 'size',
			order: 'desc',
			limit: 500,
		},
	};

	let response = await statsbypaths(accountId, params);

	if (response) {
		const newData = [];
		Object.keys(response).map((key) => newData.push({ url: key, ...response[key] }));
		return newData;
	}
};
export const getTechnologyStatsByDimension = async (accountId, tStart, tEnd, dimension = 'os') => {
	const dateFrom = convertUtcToEpoch(tStart) * 1000;
	const dateTo = convertUtcToEpoch(tEnd) * 1000 + 86400000;
	const resolution = (dateTo - dateFrom) / 288;

	const params = {
		paths: ['*'],
		metric: 'size',
		dimension: dimension,
		tStart: tStart,
		tEnd: tEnd,
		resolution: resolution,
		sort: { limit: 200 },
	};

	const response = await statsbydimension(accountId, params);
	if (response) {
		let arrData = [];
		let newData = [
			{
				name: 'OS',
				data: arrData,
			},
		];

		Object.keys(response).map((key) => {
			const total = response[key].reduce((total, data) => {
				return total + data.value;
			}, 0);
			arrData.push({ name: key, y: total });
		});
		return newData;
	}
};

export const getLocationsStatsByDimension = async (accountId, tStart, tEnd, dimension = 'citylabel') => {
	const dateFrom = convertUtcToEpoch(tStart) * 1000;
	const dateTo = convertUtcToEpoch(tEnd) * 1000 + 86400000;
	const resolution = (dateTo - dateFrom) / 288;

	const sizeParams = {
		paths: ['*'],
		metric: 'size',
		dimension: dimension,
		tStart: tStart,
		tEnd: tEnd,
		resolution: resolution,
		sort: { limit: 200 },
	};

	const hitParams = { ...sizeParams, metric: 'hits' };

	let [sizeResponse, hitsResponse] = await Promise.all([
		statsbydimension(accountId, sizeParams),
		statsbydimension(accountId, hitParams),
	]);

	if (sizeResponse && hitsResponse) {
		let arrData = [];
		if (sizeResponse['United States']) {
			sizeResponse['United States of America'] = sizeResponse['United States'];
			delete sizeResponse['United States'];
		}
		Object.keys(sizeResponse).map((key) => {
			const total = sizeResponse[key].reduce((total, data) => {
				return total + data.value;
			}, 0);
			arrData.push({ name: key, value: total });
		});
		Object.keys(hitsResponse).map((key, i) => {
			const total = hitsResponse[key].reduce((total, data) => {
				return total + data.value;
			}, 0);
			arrData[i]['hits'] = total;
		});

		return arrData;
	}
};

function sumOfResponse(volume) {
	let total = 0;

	volume.forEach(function (el) {
		total += el.value;
	});
	return total;
}

function convertUtcToEpoch(utcDate) {
	let date = new Date(utcDate);
	let epoch = date.getTime() / 1000.0;

	return epoch;
}
