import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'underscore';

import ConfirmationDialog from '../../reusable/ConfirmationDialog';
import {
	createInteractTimelineItem,
	createInteractTimelineGroup,
	setLiveQueueItem,
	removeInteractTimelineItem,
	removeSelectedInteractTimelineItem,
	stopFetchInteractItems,
	hideTrimTool,
	updateInteractTimelineItem,
} from '../../actions/interactTimelineActions';
import { isRequestFail } from '../../services/inspectScreenHelperService';
import { mediaNames, messageTypes } from '../../constants/mediaConstants';
import {
	getTrackOfInteractItem,
	getBodyOfSequence,
	getInteractItemFromValue,
} from '../../services/interactScreenHelperService';
import {
	interactTimelineItemTypes,
	interactTimelineGroupTypes,
	interactTimelineIcons,
	interactMimeType,
	tracksSequencesTypes,
	sequenceTypes,
} from '../../constants/interactConstant';
import { getTimelineTimeFromSeconds } from '../../services/timeStampService';
import { getMediaQueryDetails } from '../../actions/publishActions';
import {
	getInteractElements,
	hideInteractDeleteDialog,
	clearInteractItemsToDelete,
} from '../../actions/interactElementsActions';
import { showNotification } from '../../actions/notificationActions';
import { deleteSequence, updateSequence } from '../../actions/tracksActions';
import { deleteTrackFromMedia } from '../../actions/inspectScreenActions';
import InteractSaveProject from '../../screens/InteractEditorScreen/InteractSaveProject';
import { updateTimelineItem } from '../../actions/liveManagerActions';

class LiveInteractHelper extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedTimelineItem: {},
		};
		this.interactSaveRef = null;
	}

	componentDidMount() {
		if (this.props.onRef) {
			this.props.onRef(this);
		}
		this.fetchInteractElements();
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.interactElementsWidget &&
			this.props.interactElementsWidget !== prevProps.interactElementsWidget
		) {
			this.fetchExistingInteractItems();
		}
		if (this.props.fetchInteractItemsCmd && this.props.fetchInteractItemsCmd !== prevProps.fetchInteractItemsCmd) {
			this.fetchExistingInteractItems();
		}
	}

	fetchInteractElements = () => {
		const { defaultAccountId } = this.props;
		this.props.getInteractElements(defaultAccountId);
	};

	fetchExistingInteractItems = () => {
		const { defaultAccountId, mediaId, t } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (isRequestFail(this, media)) {
				this.props.showNotification(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_DETAILS_FILES_FAILED_FETCH_MEDIA_DETAILS')
				);
				this.props.stopFetchInteractItems();
				return;
			}
			let tracks = media.tracks;
			if (!tracks) {
				return;
			}
			let chapterTrack = _.find(media.tracks, (track) => {
				return (
					track &&
					track.sequences &&
					track.sequences.length > 0 &&
					track.sequences[0].type === sequenceTypes.chapter
				);
			});
			let chapterTrackId = '';
			if (chapterTrack) {
				//added to load only first chapter track
				chapterTrackId = chapterTrack.id;
			}
			let videoTrack = _.find(media.tracks, (track) => {
				return (
					track &&
					track.sequences &&
					track.sequences.length > 0 &&
					track.sequences[0].type === sequenceTypes.video
				);
			});
			let videoTrackId = '';
			if (videoTrack) {
				//added to load only first video track
				videoTrackId = videoTrack.id;
			}
			tracks.map((track) => {
				if (track && track.sequences && track.sequences.length > 0) {
					track.sequences.map((sequence) => {
						if (
							(sequence.type === 'custom' &&
								sequence.custom &&
								sequence.custom.mimeType === interactMimeType &&
								sequence.custom.interactiveWidget) ||
							(sequence.type === sequenceTypes.chapter && track.id === chapterTrackId) ||
							(sequence.type === sequenceTypes.video && track.id === videoTrackId)
						) {
							setTimeout(() => {
								// added timeout because groups are not getting updated using redux immediately
								this.loadInteractItemsFromSequences(sequence);
							}, 50);
						}
					});
				}
			});
			this.props.stopFetchInteractItems();
		});
	};

	checkIfItemInLiveQueue = (item) => {
		const { isOnLiveMode } = this.props;
		if (isOnLiveMode) {
			if (item.start && !item.end) {
				this.props.setLiveQueueItem(item);
			}
		}
	};

	loadInteractItemsFromSequences = (sequence) => {
		const { mediaId, interactGroups, interactElementsWidget, t, isOnLiveMode } = this.props;
		let defaultWidgets = JSON.parse(interactElementsWidget);
		const index = sequence.type === interactTimelineItemTypes.chapter ? 3 : 2;
		let widget = _.find(defaultWidgets, (widget) => {
			return sequence.custom && widget.templateId === sequence.custom.interactiveWidget.template.id;
		});
		if (!widget) {
			widget = _.find(defaultWidgets, (widget) => {
				return (
					sequence.type === interactTimelineItemTypes.chapter &&
					widget.templateGroup === interactTimelineItemTypes.chapter
				);
			});
		}
		let otherSequence =
			sequence.type === interactTimelineItemTypes.chapter || sequence.type === interactTimelineItemTypes.video;
		if (!widget && !otherSequence) {
			this.props.showNotification(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_TEMPLATE_NOT_FOUND') +
					' ' +
					sequence.custom.interactiveWidget.template.id
			);
			return;
		}

		let foundGroup = _.find(this.props.interactGroups, (group) => {
			return widget && widget.templateGroup === group.id;
		});
		let group = '';
		if (
			!foundGroup &&
			(sequence.type === interactTimelineItemTypes.chapter || sequence.type === interactTimelineItemTypes.video)
		) {
			if (interactGroups.length > 0 && sequence.type === interactTimelineItemTypes.video) {
				group = interactGroups[0];
			} else if (interactGroups.length == 0 && sequence.type === interactTimelineItemTypes.video) {
				group = 'group-' + mediaId;
			} else if (!foundGroup && sequence.type === interactTimelineItemTypes.chapter) {
				group = {
					id: interactTimelineGroupTypes.chapterGroup,
					className: interactTimelineGroupTypes.chapterGroup,
					content: `<img src=${interactTimelineIcons.chaptersIcon} ></img>`,
					order: index,
					groupEditable: false,
				};
				this.props.createInteractTimelineGroup(group);
			}
		} else if (!foundGroup) {
			group = {
				id: widget.templateGroup,
				className: widget.templateGroup,
				content: `<img src=${interactTimelineIcons.buttonsIcon} ></img>`,
				order: index,
				groupEditable: false,
			};
			this.props.createInteractTimelineGroup(group);
		}
		let storedWidget = {};
		let item = {};
		if (sequence.type === interactTimelineItemTypes.video || sequence.type === interactTimelineItemTypes.chapter) {
			item = {
				id: sequence.id,
				className:
					sequence.type === sequenceTypes.chapter
						? interactTimelineItemTypes.chapter
						: interactTimelineItemTypes.video,
				content:
					sequence.type === interactTimelineItemTypes.video
						? ''
						: sequence.name || sequence.content || sequence.type,
				editable: isOnLiveMode ? false : true,
				group: group && group.id ? group.id : foundGroup ? foundGroup.id : group,
				start: !sequence.start && sequence.start != 0 ? null : getTimelineTimeFromSeconds(sequence.start),
				end: !sequence.end && sequence.end != 0 ? null : getTimelineTimeFromSeconds(sequence.end),
				mimeType: '', // unable to add type as custom because of vis timeline
				itemType: sequence.type === sequenceTypes.chapter ? sequenceTypes.chapter : sequenceTypes.video,
				settings: {},
			};
			if (sequence.type === interactTimelineItemTypes.video) {
				if (sequence.selectedRenditions) {
					item['selectedRenditions'] = sequence.selectedRenditions;
				}
				if (sequence.resource) {
					item['resource'] = sequence.resource;
				}
			}
		} else if (sequence.custom) {
			storedWidget = getInteractItemFromValue(widget, sequence.custom);
			item = {
				...storedWidget,
				id: sequence.id,
				className: interactTimelineItemTypes.interactItem,
				content:
					sequence.name ||
					sequence.content ||
					(sequence.custom && sequence.custom.interactiveWidget
						? sequence.custom.interactiveWidget.content
						: '') ||
					widget.templateName,
				editable: isOnLiveMode ? false : true,
				group: widget.templateGroup,
				start: !sequence.start && sequence.start != 0 ? null : getTimelineTimeFromSeconds(sequence.start),
				end: !sequence.end && sequence.end != 0 ? null : getTimelineTimeFromSeconds(sequence.end),
				mimeType: interactMimeType,
				itemType: sequenceTypes.custom,
				index: sequence.custom ? sequence.custom.index : null,
			};
		}

		this.props.createInteractTimelineItem(item, 'setScreenUnModified');
		this.checkIfItemInLiveQueue(item);
	};

	handleDialogCallback = (e) => {
		const { interactItemsToDelete, defaultAccountId, mediaId, t, interactItems } = this.props;
		if (e && e.ok) {
			if (window.location.href.match('liveManager')) {
				this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
					if (!media) {
						this.props.showMessage(t('COMMON_MEDIA_NOT_FOUND'), messageTypes.error);
						return;
					}
					if (interactItems.length == 1) {
						this.deleteTrack(media, interactItemsToDelete[0]);
					} else {
						this.deleteSequence(interactItemsToDelete, media);
					}
				});
			} else {
				interactItemsToDelete.map((item) => {
					if (item.itemType === sequenceTypes.video) {
						this.interactSaveRef.removeTrim(item);
						this.props.hideTrimTool();
					} else {
						this.props.removeInteractTimelineItem(item);
						this.props.removeSelectedInteractTimelineItem(item.id);
					}
				});
			}
		}
		this.props.clearInteractItemsToDelete();
		this.props.hideInteractDeleteDialog();
	};

	deleteSequence = (itemList, media) => {
		const { defaultAccountId, mediaId, t } = this.props;
		itemList.map((item) => {
			let track = getTrackOfInteractItem(media, item.id);
			if (!track) {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_TRACK_NOT_FOUND'),
					messageTypes.error
				);
				return;
			}
			this.props
				.deleteSequence(defaultAccountId, mediaId, track.id, tracksSequencesTypes.customs, item.id)
				.then((res) => {
					if (typeof res === 'string') {
						this.props.removeInteractTimelineItem(item);
						this.props.showMessage(t('COMMON_DELETED') + ' ' + item.content, messageTypes.info);
						this.updateSequenceAfterDeleteItem(item, media);
					} else {
						this.props.showMessage(t('COMMON_DELETION_FAILED') + ' ' + item.content, messageTypes.error);
					}
				});
			this.props.removeSelectedInteractTimelineItem(item.id);
		});
	};

	deleteTrack = (media, item) => {
		const { defaultAccountId, mediaId, t } = this.props;
		let track = getTrackOfInteractItem(media, item.id);
		this.props.deleteTrackFromMedia(defaultAccountId, mediaId, track.id).then(() => {
			this.props.removeInteractTimelineItem(item);
			this.props.showMessage(
				t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_DELETED_INTERACT_ITEMS'),
				messageTypes.info
			); //deletes entire track with sequences
		});
	};

	updateSequenceAfterDeleteItem = (interactItemToDelete, media) => {
		const { defaultAccountId, mediaId, t, interactItems } = this.props;
		const itemsToUpdate =
			interactItems && interactItems.length > 0
				? interactItems.filter((i) => i.index > interactItemToDelete.index)
				: [];
		if (itemsToUpdate.length === 0) {
			return;
		}

		itemsToUpdate.forEach((item) => {
			if (item.index - 1 < 0) {
				return;
			}
			item.index = item.index - 1;

			const customBody = getBodyOfSequence(item);
			const track = getTrackOfInteractItem(media, item.id);

			if (!track) {
				this.props.showMessage(
					t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_TRACK_NOT_FOUND'),
					messageTypes.error
				);
				return;
			}

			this.props
				.updateSequence(defaultAccountId, mediaId, track.id, tracksSequencesTypes.customs, item.id, customBody)
				.then(() => {
					this.props.updateInteractTimelineItem(item, 'setScreenUnModified');
					this.props.updateTimelineItem(item);
				});
		});
	};

	renderDeleteDialog = () => {
		const { deleteDialogDisplay, t, interactItemsToDelete } = this.props;
		if (deleteDialogDisplay) {
			return (
				<>
					<ConfirmationDialog
						openDialog={deleteDialogDisplay}
						title={t('COMMON_DELETED')}
						message={
							typeof deleteDialogDisplay == 'string'
								? deleteDialogDisplay
								: interactItemsToDelete.length > 1
								? t('COMMON_DELETE_CONFIRMATION')
								: t('COMMON_DELETE_CONFIRMATION_SINGLE')
						}
						callback={this.handleDialogCallback}
						okButtonText={t('COMMON_DELETED')}
						t={t}
					/>
				</>
			);
		} else {
			return null;
		}
	};

	render() {
		const { mediaId, t, showMessage } = this.props;
		return (
			<>
				<InteractSaveProject
					mediaId={mediaId}
					t={t}
					showMessage={showMessage}
					noUI={true}
					onRef={(ref) => (this.interactSaveRef = ref)}
				/>
				{this.renderDeleteDialog()}
			</>
		);
	}
}

const mapStateToProps = ({ session, liveManager, interactElementsReducer, interactTimelineReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	isPlayerAtLivePosition: liveManager.isPlayerAtLivePosition,
	interactElementsWidget: interactElementsReducer.interactElementsWidget,
	interactItems: interactTimelineReducer.items,
	interactGroups: interactTimelineReducer.groups,
	deleteDialogDisplay: interactElementsReducer.deleteDialogDisplay,
	interactItemsToDelete: interactElementsReducer.interactItemsToDelete,
	fetchInteractItemsCmd: interactTimelineReducer.fetchInteractItemsCmd,
});

const mapDispatchToProps = (dispatch) => ({
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	createInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(createInteractTimelineItem(item, setScreenUnModified)),
	createInteractTimelineGroup: (item) => dispatch(createInteractTimelineGroup(item)),
	showNotification: (message) => dispatch(showNotification(message)),
	getInteractElements: () => dispatch(getInteractElements()),
	setLiveQueueItem: (item) => dispatch(setLiveQueueItem(item)),
	hideInteractDeleteDialog: (_) => dispatch(hideInteractDeleteDialog()),
	removeInteractTimelineItem: (item) => dispatch(removeInteractTimelineItem(item)),
	removeSelectedInteractTimelineItem: (item) => dispatch(removeSelectedInteractTimelineItem(item)),
	clearInteractItemsToDelete: (_) => dispatch(clearInteractItemsToDelete()),
	deleteTrackFromMedia: (accId, mediaId, trackId) => dispatch(deleteTrackFromMedia(accId, mediaId, trackId)),
	deleteSequence: (accId, mediaId, trackId, sequenceType, sequenceId) =>
		dispatch(deleteSequence(accId, mediaId, trackId, sequenceType, sequenceId)),
	updateSequence: (accId, mediaId, trackId, sequenceType, sequenceId, body) =>
		dispatch(updateSequence(accId, mediaId, trackId, sequenceType, sequenceId, body)),
	stopFetchInteractItems: (_) => dispatch(stopFetchInteractItems()),
	hideTrimTool: (_) => dispatch(hideTrimTool()),
	updateTimelineItem: (item) => dispatch(updateTimelineItem(item)),
	updateInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(updateInteractTimelineItem(item, setScreenUnModified)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LiveInteractHelper));
