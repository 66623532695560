import React from 'react';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Avatar from 'react-avatar';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import edit from '../../../assets/images/edit.svg';
import trashcan from '../../../assets/images/trashcan.svg';
import down_arrow from '../../../assets/images/down_arrow.svg';
import IconsStore from '../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../reusable/IconsStore/IconsList';

export const UserLicences = ({
	t,
	login,
	allUsers,
	searchUser,
	currentPage,
	pageNumbers,
	hideDetails,
	handleChange,
	handleDetails,
	currentDataSet,
	ipRestrictions,
	handleLastPage,
	handleNextPage,
	relatedAccounts,
	handleFirstPage,
	usernameExpanded,
	usernameToDelete,
	handleUserDelete,
	showNewUserDialog,
	handlePreviousPage,
	indexOfLastDataSet,
	handleUserCreation,
	indexOfFirstDataSet,
	userDetailsExpanded,
	addToUserDeleteBulk,
	addToSelectUserDelete,
	deleteUserDialogStatus,
	handleTheModelBoxInput,
	handleNewUserDialogBox,
	handleDeleteUserDialog,
	formSubmissionSuccess,
	handleHideNewUserDialog,
	handleMultipleUserDelete,
	handleHideDeleteUserDialog,
	addToUserDeleteBulkAsAnArray,
	stringToColor,
	getInitials,
}) => (
	<>
		<div className="licencesContentWrapper">
			<div className="usersListingTopHeader">
				<input
					type="text"
					placeholder="Search Account"
					className="searchTextInput"
					onChange={(e) => searchUser(e)}
				/>
				<Button variant="contained" className="newUserBtn defaultActionBtn" onClick={handleNewUserDialogBox}>
					{t('SETTINGS_NEW_USER_LABEL')}
				</Button>
			</div>
			<div className="usersListingTableHeader">
				<h4>{t('COMMON_LIST_USER_LICENCES_LABEL')}</h4>
				<Button
					variant="contained"
					disabled={addToUserDeleteBulk.length < 1}
					className="defaultActionBtn deleteButtonWithIcon"
					onClick={handleMultipleUserDelete}
				>
					{t('COMMON_DELETED')}
				</Button>
			</div>
			<div className="paginatedTableWrapper">
				{currentDataSet &&
					currentDataSet.map((eachSet, i) => (
						<>
							<div
								key={eachSet.userId}
								className={`tableContentSingleWrapper ${
									i == currentDataSet.length - 1 && 'endOfTableContent'
								}`}
							>
								<div>
									<Checkbox
										style={{ color: '#6b748f' }}
										checked={addToUserDeleteBulk.indexOf(eachSet.userId) !== -1}
										onChange={() => addToSelectUserDelete(eachSet.userId, eachSet.login)}
									/>
								</div>
								<div>
									{/* <img className="headerIcon" src={logoIcon} /> */}
									<Avatar
										className="eaderIcon"
										color={stringToColor(eachSet.login)}
										name={getInitials(eachSet.login)}
										size="45"
										round
									/>
									<button>
										{userDetailsExpanded != eachSet.userId ? (
											<img
												className="headerIcon"
												src={edit}
												onClick={() =>
													handleDetails(eachSet.login, eachSet.userId, userDetailsExpanded)
												}
											/>
										) : (
											<img
												className="headerIcon"
												src={down_arrow}
												onClick={() => hideDetails()}
											/>
										)}
									</button>
								</div>
								<div>
									{userDetailsExpanded != eachSet.userId ? (
										<h3 onClick={() => handleDetails(eachSet.login, eachSet.userId)}>
											{eachSet.login}
										</h3>
									) : (
										<h3 onClick={() => hideDetails()}>{eachSet.login}</h3>
									)}
								</div>
								<div>
									<button onClick={() => handleDeleteUserDialog(eachSet.login, eachSet.userId)}>
										<img className="headerIcon" src={trashcan} />
									</button>
								</div>
							</div>
							{userDetailsExpanded != '' && userDetailsExpanded == eachSet.userId && (
								<div className="userDetailsView">
									<div className="userDetailsViewInternalBlock">
										<h4 className="profileBlockHeaderTitle">
											{t('SETTINGS_PERSONAL_INFORMATION')}
										</h4>
									</div>
									<div className="userDetailsViewInternalBlock">
										<label>{t('COMMON_EMAIL')}</label>
										<input type="text" className="genericTextInput" value={usernameExpanded} />
									</div>
									<div className="userDetailsViewInternalBlock">
										<label>{t('SETTINGS_USER_LIST_IP_RESTRICTIONS_LABEL')}</label>
										<input
											type="text"
											className="genericTextInput"
											value={eachSet.ipRestrictions}
										/>
									</div>
									<div className="userDetailsViewInternalBlock profileConnectedAccounts">
										<h4 className="profileBlockHeaderTitle">{t('COMMON_CONNECTED_ACCOUNTS')}</h4>
									</div>
									<div className="userDetailsViewInternalBlock">
										{relatedAccounts &&
											relatedAccounts.map((eachAccount, i) => (
												<label key={i}>{eachAccount.name}</label>
											))}
									</div>
								</div>
							)}
						</>
					))}
			</div>
			<div className="paginationContentWrapper">
				<div>
					<span>{t('ACCOUNTS_PER_PAGE')}</span>
					<div className="mediaPerPageDropDownSelectBox">
						<select onChange={handleChange('media')} className="mediaPerPageDropDown">
							{[5, 10, 15].map((n) => (
								<option key={n} value={n}>
									{n}
								</option>
							))}
						</select>
					</div>
				</div>
				<div>
					<span>
						{indexOfFirstDataSet + 1} - {currentPage == pageNumbers ? allUsers.length : indexOfLastDataSet}{' '}
						{t('COMMON_LABEL_OF')} {allUsers.length}
					</span>
				</div>
				<div>
					<button disabled={currentPage == 1} onClick={handleFirstPage}>
						<svg viewBox="0 0 9.39 10.18">
							<defs />
							<g id="Layer_2" data-name="Layer 2">
								<g id="Layer_1-2" data-name="Layer 1">
									<path
										className="cls-1"
										d="M8.89,10.18A.49.49,0,0,1,8.54,10L3.6,5.09,8.54.15a.5.5,0,0,1,.71,0,.5.5,0,0,1,0,.7L5,5.09,9.25,9.33a.5.5,0,0,1,0,.7A.51.51,0,0,1,8.89,10.18Z"
									/>
									<path
										className="cls-1"
										d="M.5,10.18a.5.5,0,0,1-.5-.5V.5A.5.5,0,0,1,.5,0,.5.5,0,0,1,1,.5V9.68A.5.5,0,0,1,.5,10.18Z"
									/>
								</g>
							</g>
						</svg>
					</button>
					<button disabled={currentPage == 1} onClick={handlePreviousPage}>
						<svg viewBox="0 0 5.8 10.18">
							<defs />
							<g id="Layer_2" data-name="Layer 2">
								<path
									className="cls-1"
									d="M5.3,10.18A.51.51,0,0,1,4.94,10L0,5.09,4.94.15a.5.5,0,0,1,.71,0,.48.48,0,0,1,0,.7L1.41,5.09,5.65,9.33a.48.48,0,0,1,0,.7A.5.5,0,0,1,5.3,10.18Z"
									id="Layer_1-2"
									data-name="Layer 1"
								/>
							</g>
						</svg>
					</button>
					<button disabled={currentPage == pageNumbers} onClick={handleNextPage}>
						<svg viewBox="0 0 5.8 10.18">
							<defs />
							<g id="Layer_2" data-name="Layer 2">
								<path
									className="cls-1"
									d="M.5,10.18A.47.47,0,0,1,.15,10a.48.48,0,0,1,0-.7L4.38,5.09.15.85a.48.48,0,0,1,0-.7.48.48,0,0,1,.7,0l5,4.94L.85,10A.47.47,0,0,1,.5,10.18Z"
									id="Layer_1-2"
									data-name="Layer 1"
								/>
							</g>
						</svg>
					</button>
					<button disabled={currentPage == pageNumbers} onClick={handleLastPage}>
						<svg viewBox="0 0 9.39 10.18">
							<defs />
							<g id="Layer_2" data-name="Layer 2">
								<g id="Layer_1-2" data-name="Layer 1">
									<path
										className="cls-1"
										d="M.5,10.18A.47.47,0,0,1,.15,10a.48.48,0,0,1,0-.7L4.38,5.09.15.85a.48.48,0,0,1,0-.7.48.48,0,0,1,.7,0l5,4.94L.85,10A.47.47,0,0,1,.5,10.18Z"
									/>
									<path
										className="cls-1"
										d="M8.89,10.18a.5.5,0,0,1-.5-.5V.5a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5V9.68A.5.5,0,0,1,8.89,10.18Z"
									/>
								</g>
							</g>
						</svg>
					</button>
				</div>
			</div>
		</div>
		<Dialog
			open={showNewUserDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">{t('SETTINGS_NEW_USER_LABEL')}</Typography>
				<IconButton onClick={handleHideNewUserDialog}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<div className="dialogContentWrapper">
						<div className="dialogBoxInternalBlock">
							<label>{t('COMMON_EMAIL')}</label>
							<input
								type="text"
								className="genericTextInput"
								onChange={handleTheModelBoxInput('login')}
								value={!formSubmissionSuccess ? login : ''}
								required
							/>
						</div>
						<div className="dialogBoxInternalBlock">
							<label>{t('SETTINGS_USER_LIST_IP_RESTRICTIONS_LABEL')}</label>
							<input
								type="text"
								className="genericTextInput"
								onChange={handleTheModelBoxInput('ipRestrictions')}
								value={!formSubmissionSuccess ? ipRestrictions : ''}
								required
								placeholder={'0.0.0.0'}
							/>
						</div>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<div className="dialogActionsWrapper">
					<div className="dialogBoxInternalBlock createNewUserBtnContentWrapper">
						<Button variant="contained" className="defaultActionBtn" onClick={handleUserCreation}>
							{t('COMMON_CREATE_USER_LABEL')}
						</Button>
					</div>
				</div>
			</DialogActions>
		</Dialog>
		<Dialog
			open={deleteUserDialogStatus}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle className="dialogTitle" disableTypography>
				<Typography variant="h6">{t('COMMON_LABEL_ARE_YOU_SURE_TO_DELETE_USER')}</Typography>
				<IconButton onClick={handleHideDeleteUserDialog}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					<div className="dialogContentWrapper">
						<div className="dialogBoxInternalBlock createNewUserBtnContentWrapper">
							{addToUserDeleteBulkAsAnArray && addToUserDeleteBulkAsAnArray.length > 0 ? (
								addToUserDeleteBulkAsAnArray.map((eachItem, i) => <label key={i}>{eachItem}</label>)
							) : (
								<label>{usernameToDelete}</label>
							)}
						</div>
						<div className="dialogBoxInternalBlock">
							<label>{t('COMMON_LABEL_USER_DELETE_EXPLANATION')}</label>
						</div>
					</div>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<div className="dialogActionsWrapper">
					<div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
						<Button
							variant="contained"
							className="defaultActionBtn neutralBtn"
							onClick={handleHideDeleteUserDialog}
						>
							{t('COMMON_BACK')}
						</Button>
						<Button
							variant="contained"
							className="defaultActionBtn deleteButtonWithIcon"
							onClick={handleUserDelete}
						>
							<IconsStore iconName={IconsList.TRASHCAN} />
							{t('COMMON_DELETED')}
						</Button>
					</div>
				</div>
			</DialogActions>
		</Dialog>
	</>
);
