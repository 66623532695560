import React, { CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import type { AnimateLayoutChanges } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { UniqueIdentifier } from '@dnd-kit/core';
import { iOS } from './utils';
import TreeItem from './treeItem/TreeItem';
import type { Props as TreeItemProps } from './treeItem/TreeItem';

interface WidgetItemProps extends TreeItemProps {
	id: UniqueIdentifier;
	collapsed?: boolean;
	depth: number;
	index?: number;
	editingWidget: any;
	onWidgetClick: (_e: any, _widget: any) => {};
	onDuplicateWidget: (_e: any, _widget: any, _isEditable: boolean) => {};
	onRemoveWidget: (_e: any, _widget: any, _isEditable: boolean) => {};
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
	isSorting || wasDragging ? false : true;

const WidgetItem = ({ id, depth, widgetData, ...props }: WidgetItemProps) => {
	const {
		attributes,
		isDragging,
		isSorting,
		listeners,
		setDraggableNodeRef,
		setDroppableNodeRef,
		transform,
		transition,
	} = useSortable({
		id,
		animateLayoutChanges,
	});

	const style: CSSProperties = {
		transform: CSS.Translate.toString(transform),
		transition,
	};

	return (
		<TreeItem
			ref={setDraggableNodeRef}
			wrapperRef={setDroppableNodeRef}
			style={style}
			depth={depth}
			ghost={isDragging}
			disableSelection={iOS}
			disableInteraction={isSorting}
			handleProps={{
				...attributes,
				...listeners,
			}}
			widgetData={widgetData}
			{...props}
		/>
	);
};

export default WidgetItem;
