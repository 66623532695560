import BackendClient from './backendClient';
import { readApiEndpointV1, writeApiEndpointV1 } from '../utils/config';

// SCENARIO
export const createNewScenarioService = (accountId, scenarioTitle, scenarioId, assetId) =>
	BackendClient.post(
		`${writeApiEndpointV1}accounts/${accountId}/scenarios`,
		{
			id: scenarioId,
			metadata: {
				title: scenarioTitle,
			},
			asset: {
				id: assetId,
			},
		},
		false
	);

export const loadScenariosService = (accountId, mediaPerPage, offset, sortType) =>
	BackendClient.get(
		`${readApiEndpointV1}accounts/${accountId}/scenarios?limit=${mediaPerPage}&offset=${offset}&sort=${encodeURIComponent(
			sortType
		)}`,
		false
	);

export const getScenarioByIdService = (accountId, scenarioId) =>
	BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/scenarios/${scenarioId}`);

export const updateScenarioService = (accountId, scenarioId, body) =>
	BackendClient.put(`${writeApiEndpointV1}accounts/${accountId}/scenarios/${scenarioId}`, body, false, false);

export const deleteScenarioService = (accountId, scenarioId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/scenarios/${scenarioId}`);

// SCENE
export const addNewSceneToScenarioService = (accountId, scenarioId, sceneId, body) =>
	BackendClient.post(
		`${writeApiEndpointV1}accounts/${accountId}/scenarios/${scenarioId}/scenes`,
		{ id: sceneId, ...(body ?? {}) },
		false
	);

export const updateSceneService = (accountId, scenarioId, sceneId, body) =>
	BackendClient.put(
		`${writeApiEndpointV1}accounts/${accountId}/scenarios/${scenarioId}/scenes/${sceneId}`,
		body,
		false,
		false
	);

export const deleteSceneService = (accountId, scenarioId, sceneId) =>
	BackendClient.delete(`${writeApiEndpointV1}accounts/${accountId}/scenarios/${scenarioId}/scenes/${sceneId}`);

// Layouts
export const addNewLayoutToSceneService = (accountId, scenarioId, sceneId, layoutId, body) =>
	BackendClient.post(
		`${writeApiEndpointV1}accounts/${accountId}/scenarios/${scenarioId}/scenes/${sceneId}/layouts`,
		{ id: layoutId, ...(body ?? {}) },
		false
	);

// Widgets
export const addNewWidgetService = (accountId, scenarioId, sceneId, layoutId, boxId, widgetId, body) =>
	BackendClient.post(
		`${writeApiEndpointV1}accounts/${accountId}/scenarios/${scenarioId}/scenes/${sceneId}/layouts/${layoutId}/boxes/${boxId}/widgets`,
		{ id: widgetId, ...(body ?? {}) },
		false
	);

export const updateWidgetService = (accountId, scenarioId, sceneId, layoutId, boxId, widgetId, body) =>
	BackendClient.put(
		`${writeApiEndpointV1}accounts/${accountId}/scenarios/${scenarioId}/scenes/${sceneId}/layouts/${layoutId}/boxes/${boxId}/widgets/${widgetId}`,
		{ ...(body ?? {}) },
		false,
		false
	);

export const deleteWidgetService = (accountId, scenarioId, sceneId, layoutId, boxId, widgetId) =>
	BackendClient.delete(
		`${writeApiEndpointV1}accounts/${accountId}/scenarios/${scenarioId}/scenes/${sceneId}/layouts/${layoutId}/boxes/${boxId}/widgets/${widgetId}`
	);
