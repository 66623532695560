import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withTranslation } from 'react-i18next';
import { compose } from 'underscore';

import { fetchPlayerEmbedConfiguration, loadPlayerConfigById } from '../../../../../actions/publishActions';
import { showNotification } from '../../../../../actions/notificationActions';
import {
	setSidePlayerConfigId,
	setVideoPlayerMuted,
	updatePreviewLink,
} from '../../../../../actions/inspectScreenActions';

import { PlayerBaseUrl, play2ConfigBaseUrl, readPublicApiEndpointV1 } from '../../../../../utils/config';
import copy from '../../../../../assets/images/copy.svg';
import eye from '../../../../../assets/images/eye.svg';
import { qbrickPlayerList } from '../../../../../constants/mediaConstants';

import './EmbedVideo.scss';

class EmbedVideo extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			previewLink: '',
			themeOptions: [],
			replayCheck: true,
			autoStartCheck: true,
			embedLinkCopied: false,
			themeOptionsDefault: '',
			iFrameLinkCopied: false,
			allowDownloadCheck: true,
			startMutedCheck: false,
			previewLinkCopied: false,
			socialSharingCheck: true,
			embedSectionExpanded: false,
			iframeSectionExpanded: false,
			previewSectionExpanded: false,
		};
	}

	componentDidMount() {
		this.props.fetchPlayerEmbedConfiguration(this.props.defaultAccountId).then((data) => {
			let defaultPlayer = '';
			if (data && data.length > 0) {
				defaultPlayer = data.find(({ name }) => name === 'Qbrick Player')?.id;
				if (!defaultPlayer) {
					defaultPlayer = data.find(({ id }) => id === 'default')?.id ?? data[0].id;
				}
			}

			this.setState(
				{
					themeOptions: data ?? [],
					themeOptionsDefault: defaultPlayer ?? data[0]?.id ?? '',
				},
				() => {
					this.props.setSidePlayerConfigId(defaultPlayer ?? data[0]?.id ?? '');
					this.getSettingsByConfiguration();
				}
			);
		});

		this.props.updatePreviewLink?.(this._getPreviewLink());
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.state.startMutedCheck !== prevState.startMutedCheck ||
			this.state.autoStartCheck !== prevState.autoStartCheck ||
			this.state.socialSharingCheck !== prevState.socialSharingCheck ||
			this.state.allowDownloadCheck !== prevState.allowDownloadCheck ||
			this.state.replayCheck !== prevState.replayCheck ||
			this.state.themeOptionsDefault !== prevState.themeOptionsDefault ||
			this.props.id !== prevProps.id ||
			this.props.defaultAccountId !== prevProps.defaultAccountId
		) {
			this.props.updatePreviewLink(this._getPreviewLink());
		}
	}

	_getIframeTag = (_) => {
		const {
			replayCheck,
			autoStartCheck,
			socialSharingCheck,
			themeOptionsDefault,
			startMutedCheck,
			allowDownloadCheck,
		} = this.state;
		const { id, defaultAccountId, placementType, mediaDetails } = this.props;
		let volumeValue;
		if (startMutedCheck) {
			volumeValue = '&volume=0';
		} else {
			volumeValue = '&volume';
		}
		let mediaIdOrPlaylistId = placementType && placementType.toUpperCase() === 'MEDIAS' ? 'mediaId' : 'playlistId';
		const title = `${placementType && placementType.toUpperCase() === 'MEDIAS' ? 'Video' : 'Playlist'}: ${
			mediaDetails?.metadata?.title
		}`;

		if (
			themeOptionsDefault === qbrickPlayerList.shoppingPlayer ||
			themeOptionsDefault === qbrickPlayerList.qbrickPlayer ||
			themeOptionsDefault.includes('-qp') ||
			themeOptionsDefault.includes('-sp')
		) {
			return `<iframe title="${title}" src="${PlayerBaseUrl}play2/embed/qbrick-player?accountId=${defaultAccountId}&${mediaIdOrPlaylistId}=${id}&configId=${themeOptionsDefault}&pageStyling=adaptive&autoplay=${autoStartCheck}&repeat=${replayCheck}&sharing=${socialSharingCheck}&download=${allowDownloadCheck}${volumeValue}" allowFullScreen="true" frameborder="0" border="0" height="360" width="640"></iframe>`;
		} else if (themeOptionsDefault === 'shoppingcompanion' || themeOptionsDefault.includes('-sc')) {
			return `Not applicable`;
		} else {
			return `<iframe title="${title}" src="${PlayerBaseUrl}play2/embed/player?accountId=${defaultAccountId}&${mediaIdOrPlaylistId}=${id}&configId=${themeOptionsDefault}&pageStyling=adaptive&autoplay=${autoStartCheck}&repeat=${replayCheck}&sharing=${socialSharingCheck}&download=${allowDownloadCheck}${volumeValue}" allowFullScreen="true" frameborder="0" border="0" height="360" width="640"></iframe>`;
		}
	};

	_getPreviewLink = (_) => {
		const {
			replayCheck,
			autoStartCheck,
			socialSharingCheck,
			themeOptionsDefault: themeOptionsDefaultState,
			allowDownloadCheck,
			startMutedCheck,
		} = this.state;
		const { id, defaultAccountId, placementType } = this.props;
		let volumeValue;
		if (startMutedCheck) {
			volumeValue = '&volume=0';
		} else {
			volumeValue = '&volume';
		}
		let mediaIdOrPlaylistId = placementType && placementType.toUpperCase() === 'MEDIAS' ? 'mediaId' : 'playlistId';
		const themeOptionsDefault =
			themeOptionsDefaultState && typeof themeOptionsDefaultState === 'string' ? themeOptionsDefaultState : '';

		if (
			themeOptionsDefault === qbrickPlayerList.shoppingPlayer ||
			themeOptionsDefault === qbrickPlayerList.qbrickPlayer ||
			themeOptionsDefault.includes('-qp') ||
			themeOptionsDefault.includes('-sp')
		) {
			return `${PlayerBaseUrl.replace(
				'#!/',
				''
			)}play2/embed/qbrick-player?accountId=${defaultAccountId}&${mediaIdOrPlaylistId}=${id}&configId=${themeOptionsDefault}&pageStyling=adaptive&autoplay=${autoStartCheck}&repeat=${replayCheck}&sharing=${socialSharingCheck}&download=${allowDownloadCheck}${volumeValue}`;
		} else if (themeOptionsDefault === 'shoppingcompanion' || themeOptionsDefault.includes('-sc')) {
			return `https://play2.qbrick.com/shopping-companion/preview-shop.html?accountid=${defaultAccountId}&mediaid=${id}`;
		} else {
			return `${PlayerBaseUrl.replace(
				'#!/',
				''
			)}play2/embed/player?accountId=${defaultAccountId}&${mediaIdOrPlaylistId}=${id}&configId=${themeOptionsDefault}&pageStyling=adaptive&autoplay=${autoStartCheck}&repeat=${replayCheck}&sharing=${socialSharingCheck}&download=${allowDownloadCheck}${volumeValue}`;
		}
	};

	_getEmbedCode = (_) => {
		const { replayCheck, autoStartCheck, socialSharingCheck, startMutedCheck, themeOptionsDefault } = this.state;
		let volumeValue;

		if (startMutedCheck) {
			volumeValue = `data-gobrain-volume="0"`;
		} else {
			volumeValue = '';
		}

		const { id, defaultAccountId, placementType, mediaDetails } = this.props;
		let mediaOrPlaylists = placementType && placementType.toUpperCase() === 'MEDIAS' ? 'medias' : 'playlists';

		const title = `${placementType && placementType.toUpperCase() === 'MEDIAS' ? 'Video' : 'Playlist'}: ${
			mediaDetails?.metadata?.title
		}`;

		if (
			themeOptionsDefault === qbrickPlayerList.shoppingPlayer ||
			themeOptionsDefault === qbrickPlayerList.qbrickPlayer ||
			themeOptionsDefault.includes('-qp') ||
			themeOptionsDefault.includes('-sp')
		) {
			return `<div title="${title}" data-gobrain-widgetId="player" data-gobrain-language="en" data-gobrain-autoplay="${autoStartCheck}" ${volumeValue} data-gobrain-repeat="${replayCheck}" data-gobrain-moduleSettings="{&quot;TopControls&quot;:{&quot;download&quot;:{&quot;enabled&quot;:${this.state.allowDownloadCheck}},&quot;sharing&quot;:{&quot;enabled&quot;:${socialSharingCheck}}},&quot;MobileControls&quot;:{&quot;download&quot;:{&quot;enabled&quot;:${this.state.allowDownloadCheck}},&quot;sharing&quot;:{&quot;enabled&quot;:${socialSharingCheck}}}}" data-gobrain-config="${play2ConfigBaseUrl}${defaultAccountId}/configurations/${themeOptionsDefault}" data-gobrain-data="${readPublicApiEndpointV1}${defaultAccountId}/${mediaOrPlaylists}/${id}"></div><script src="https://play2.qbrick.com/qbrick-player/framework/GoBrain.min.js"></script>`.trim();
		} else if (themeOptionsDefault === 'shoppingcompanion' || themeOptionsDefault.includes('-sc')) {
			return `<div title="${title}"
      data-shopping-companion-id="${id}"
      data-shopping-companion-media-id="${id}"
      data-shopping-companion-account-id="${defaultAccountId}"
    ></div>
    <script
      id="qbrick-shopping-companion"
      src="https://play2.qbrick.com/shopping-companion/loader/shopping-companion-loader.js"
    ></script>
    `;
		} else {
			return `<div title="${title}" data-gobrain-widgetId="player" data-gobrain-autoplay="${autoStartCheck}" ${volumeValue} data-gobrain-repeat="${replayCheck}" data-gobrain-moduleSettings="{&quot;TopControls&quot;:{&quot;download&quot;:{&quot;enabled&quot;:${
				this.state.allowDownloadCheck
			}},&quot;sharing&quot;:{&quot;enabled&quot;:${socialSharingCheck}}},&quot;MobileControls&quot;:{&quot;download&quot;:{&quot;enabled&quot;:${
				this.state.allowDownloadCheck
			}},&quot;sharing&quot;:{&quot;enabled&quot;:${socialSharingCheck}}}}" data-gobrain-config="${play2ConfigBaseUrl}${defaultAccountId}/configurations/${themeOptionsDefault}" data-gobrain-data="${readPublicApiEndpointV1}${defaultAccountId}/${mediaOrPlaylists}/${id}"></div><script src="${'https://play2.qbrick.com/framework/GoBrain.min.js'}"></script>`.trim();
		}
	};

	copyPreviewLink = (_) => {
		this.setState({ previewLinkCopied: true });
		this.props.showNotification(this.props.t('LABEL_PREVIEW_LINK_COPIED'));
	};

	copyEmbedLink = (_) => {
		this.setState({ embedLinkCopied: true });
		this.props.showNotification(this.props.t('LABEL_EMBED_CODE_COPIED'));
	};

	copyIFrameLink = (_) => {
		this.setState({ iFrameLinkCopied: true });
		this.props.showNotification(this.props.t('LABEL_IFRAME_CODE_COPIED'));
	};

	expandTheiFrameSection = (_) => this.setState({ iframeSectionExpanded: true });

	collpaseTheiFrameSection = (_) => this.setState({ iframeSectionExpanded: false });

	expandThePreviewSection = (_) => this.setState({ previewSectionExpanded: true });

	collapseThePreviewSection = (_) => this.setState({ previewSectionExpanded: false });

	expandTheEmbedSection = (_) => this.setState({ embedSectionExpanded: true });

	collpaseTheEmbedSection = (_) => this.setState({ embedSectionExpanded: false });

	changeAllowDownloadCheck = (_) =>
		this.setState((prevState) => ({
			allowDownloadCheck: !prevState.allowDownloadCheck,
			previewSectionExpanded: true,
		}));

	changeStartMutedCheck = (_) =>
		this.setState(
			(prevState) => ({
				startMutedCheck: !prevState.startMutedCheck,
				previewSectionExpanded: true,
			}),
			() => {
				this.props.setVideoPlayerMuted(this.state.startMutedCheck);
			}
		);

	changeSocialSharingCheck = (_) =>
		this.setState((prevState) => ({
			socialSharingCheck: !prevState.socialSharingCheck,
			previewSectionExpanded: true,
		}));

	changeReplayCheck = (_) =>
		this.setState((prevState) => ({
			replayCheck: !prevState.replayCheck,
			previewSectionExpanded: true,
		}));

	changeAutoStartCheck = (_) =>
		this.setState((prevState) => ({
			autoStartCheck: !prevState.autoStartCheck,
			previewSectionExpanded: true,
		}));

	handleThemeChange = (e) => {
		this.props.setSidePlayerConfigId(e.target.value);
		this.setState({ themeOptionsDefault: e.target.value }, () => {
			this.getSettingsByConfiguration();
		});
	};

	getAutoplay = (config) => {
		var autoplay = true;
		if (config.settings !== undefined && config.settings.autoplay !== undefined) {
			autoplay = config.settings.autoplay;
		}
		return autoplay;
	};

	getRepeat = (config) => {
		var repeat = true;
		if (config.settings !== undefined && config.settings.repeat !== undefined) {
			repeat = config.settings.repeat;
		}
		return repeat;
	};

	getSharing = (config) => {
		var sharing = true;
		if (config.modules !== undefined) {
			for (var i = 0; i < config.modules.length; i++) {
				if (
					config.modules[i].type === 'Controls' &&
					config.modules[i].settings !== undefined &&
					config.modules[i].settings.sharing !== undefined
				) {
					sharing = config.modules[i].settings.sharing.enabled;
				}
			}
		}
		return sharing;
	};

	getDownload = (config) => {
		var download = false;
		if (config.modules !== undefined) {
			for (var i = 0; i < config.modules.length; i++) {
				if (
					config.modules[i].type === 'Controls' &&
					config.modules[i].settings !== undefined &&
					config.modules[i].settings.download !== undefined
				) {
					download = config.modules[i].settings.download.enabled;
				}
			}
		}
		return download;
	};

	getSettingsByConfiguration = () => {
		const { defaultAccountId, t } = this.props;
		const { themeOptionsDefault } = this.state;
		this.props.loadPlayerConfigById(defaultAccountId, themeOptionsDefault).then((response) => {
			if (!response) {
				this.props.showNotification(t('COMMON_FAILED_TO_LOAD_CONFIGURATION') + ' ' + themeOptionsDefault);
			} else {
				this.setState({
					autoStartCheck: this.getAutoplay(response),
					replayCheck: this.getRepeat(response),
					socialSharingCheck: this.getSharing(response),
					allowDownloadCheck: this.getDownload(response),
				});
			}
		});
	};

	render() {
		const {
			replayCheck,
			themeOptions,
			autoStartCheck,
			socialSharingCheck,
			allowDownloadCheck,
			embedSectionExpanded,
			iframeSectionExpanded,
			previewSectionExpanded,
			startMutedCheck,
			themeOptionsDefault,
		} = this.state;

		const { t } = this.props;
		const previewLink = this._getPreviewLink();
		const embedCode = this._getEmbedCode();
		const iFrameCode = this._getIframeTag();

		return (
			<>
				{
					<>
						<div className="sharePreviewSection">
							<div id="themeSelectionBox" className="previewAndShareInternalBlock">
								<div className="fullWidthControl">
									<label>
										{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_EMBED_VIDEO_PLAYER_CONFIGURATION_LABEL')}
									</label>
									<select onChange={this.handleThemeChange} className="genericDropDown">
										{themeOptions &&
											themeOptions.map &&
											themeOptions.map((eachOption, i) => (
												<option
													key={i}
													value={eachOption.id}
													selected={themeOptionsDefault === eachOption.id}
												>
													{eachOption.name}
												</option>
											))}
									</select>
								</div>
							</div>
							<div className="previewAndShareInternalBlock">
								<div className="halfWidth">
									<Checkbox
										id="autoStartCheck"
										checked={autoStartCheck}
										inputStyle={{ color: 'white' }}
										onChange={this.changeAutoStartCheck}
										style={{ color: '#126CFC', paddingLeft: 0 }}
									/>
									<label for="autoStartCheck">{t('COMMON_AUTO_START')}</label>
								</div>
								<div className="halfWidth">
									<Checkbox
										id="replayCheck"
										checked={replayCheck}
										inputStyle={{ color: 'white' }}
										onChange={this.changeReplayCheck}
										style={{ color: '#126CFC', paddingLeft: 0 }}
									/>
									<label for="replayCheck">{t('COMMON_REPLAY')}</label>
								</div>
							</div>
							<div className="previewAndShareInternalBlock">
								<div className="halfWidth">
									<Checkbox
										id="socialSharingCheck"
										checked={socialSharingCheck}
										inputStyle={{ color: 'white' }}
										onChange={this.changeSocialSharingCheck}
										style={{ color: '#126CFC', paddingLeft: 0 }}
									/>
									<label for="socialSharingCheck">{t('COMMON_SOCIAL_SHARING')}</label>
								</div>
								<div className="halfWidth">
									<Checkbox
										id="allowDownloadCheck"
										checked={allowDownloadCheck}
										inputStyle={{ color: 'white' }}
										onChange={this.changeAllowDownloadCheck}
										style={{ color: '#126CFC', paddingLeft: 0 }}
									/>
									<label for="allowDownloadCheck">{t('COMMON_ALLOW_DOWNLOAD')}</label>
								</div>
							</div>
							<div className="previewAndShareInternalBlock">
								<div className="halfWidth">
									<Checkbox
										id="startMutedCheck"
										checked={startMutedCheck}
										inputStyle={{ color: 'white' }}
										onChange={this.changeStartMutedCheck}
										style={{ color: '#126CFC', paddingLeft: 0 }}
									/>
									<label for="startMutedCheck">{t('COMMON_MUTE_AT_START')}</label>
								</div>
							</div>

							<div className="previewAndShareInternalBlock expandablePreviewSection">
								<div className={`fullWidthControl ${previewSectionExpanded ? 'expandableHeight' : ''}`}>
									<label>{t('COMMON_PREVIEW')}</label>
									{!previewSectionExpanded ? (
										<div className="expandableStatus">
											<button
												onClick={this.expandThePreviewSection}
												className="previewButtonsInside"
											>
												{t('COMMON_SHOW')}
												<svg
													className="headerIcon headerTopMenuBtnIcons"
													viewBox="0 0 15.51 9.66"
												>
													<defs />
													<g id="Layer_2" data-name="Layer 2">
														<path
															className="cls-1"
															d="M0,1.88A1.89,1.89,0,0,1,3.13.47L7.81,4.6,12.36.49a1.88,1.88,0,1,1,2.53,2.79l-7,6.38L.64,3.3A1.89,1.89,0,0,1,0,1.88Z"
															id="Layer_1-2"
															data-name="Layer 1"
														/>
													</g>
												</svg>
											</button>
										</div>
									) : (
										<div className="collapsibleStatus">
											<textarea value={previewLink} />
											<button
												onClick={this.collapseThePreviewSection}
												className="previewButtonsInside"
											>
												{t('COMMON_HIDE')}
												<svg viewBox="0 0 15.51 9.66">
													<defs />
													<g id="Layer_2" data-name="Layer 2">
														<path
															className="cls-1"
															d="M15.51,7.77A1.86,1.86,0,0,1,15,9a1.89,1.89,0,0,1-2.66.17L7.7,5.06,3.15,9.17A1.89,1.89,0,0,1,.62,6.37L7.67,0l7.21,6.36A1.87,1.87,0,0,1,15.51,7.77Z"
															id="Layer_1-2"
															data-name="Layer 1"
														/>
													</g>
												</svg>
											</button>
										</div>
									)}
								</div>
							</div>
							<div className="previewAndShareInternalBlock linksSharingBlock">
								<CopyToClipboard text={previewLink} onCopy={this.copyPreviewLink}>
									<div className="flexWrapper">
										<img src={copy} />
										<a>
											{t('COMMON_COPY')} {t('COMMON_LINK')}
										</a>
									</div>
								</CopyToClipboard>
								<a target="_blank" href={previewLink}>
									<img src={eye} />
									{t('COMMON_VIEW')}
								</a>
							</div>
							<div className="previewAndShareInternalBlock expandablePreviewSection">
								<div
									className={`fullWidthControl ${
										embedSectionExpanded ? 'expandableHeightEmbed' : ''
									}`}
								>
									<label>{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_SIDE_BAR_EMBED')}</label>
									{!embedSectionExpanded ? (
										<div className="expandableStatus">
											<button
												onClick={this.expandTheEmbedSection}
												className="previewButtonsInside"
											>
												{t('COMMON_SHOW')}
												<svg
													className="headerIcon headerTopMenuBtnIcons"
													viewBox="0 0 15.51 9.66"
												>
													<defs />
													<g id="Layer_2" data-name="Layer 2">
														<path
															className="cls-1"
															d="M0,1.88A1.89,1.89,0,0,1,3.13.47L7.81,4.6,12.36.49a1.88,1.88,0,1,1,2.53,2.79l-7,6.38L.64,3.3A1.89,1.89,0,0,1,0,1.88Z"
															id="Layer_1-2"
															data-name="Layer 1"
														/>
													</g>
												</svg>
											</button>
										</div>
									) : (
										<div className="collapsibleStatus">
											<textarea id="embedTextarea" value={embedCode} />
											<button
												onClick={this.collpaseTheEmbedSection}
												className="previewButtonsInside"
											>
												{t('COMMON_HIDE')}
												<svg viewBox="0 0 15.51 9.66">
													<defs />
													<g id="Layer_2" data-name="Layer 2">
														<path
															className="cls-1"
															d="M15.51,7.77A1.86,1.86,0,0,1,15,9a1.89,1.89,0,0,1-2.66.17L7.7,5.06,3.15,9.17A1.89,1.89,0,0,1,.62,6.37L7.67,0l7.21,6.36A1.87,1.87,0,0,1,15.51,7.77Z"
															id="Layer_1-2"
															data-name="Layer 1"
														/>
													</g>
												</svg>
											</button>
										</div>
									)}
								</div>
							</div>
							<div id="linkSharingContainer" className="previewAndShareInternalBlock linksSharingBlock">
								<CopyToClipboard text={embedCode} onCopy={this.copyEmbedLink}>
									<div className="flexWrapper">
										<img src={copy} />
										<a>
											{t('COMMON_COPY')} {t('COMMON_LINK')}
										</a>
									</div>
								</CopyToClipboard>
							</div>
							<div className="previewAndShareInternalBlock expandablePreviewSection">
								<div
									className={`fullWidthControl ${
										iframeSectionExpanded ? 'expandableHeightiFrame' : ''
									}`}
								>
									<label>{t('COMMON_IFRAME')}</label>
									{!iframeSectionExpanded ? (
										<div className="expandableStatus">
											<button
												onClick={this.expandTheiFrameSection}
												className="previewButtonsInside"
											>
												{t('COMMON_SHOW')}
												<svg
													className="headerIcon headerTopMenuBtnIcons"
													viewBox="0 0 15.51 9.66"
												>
													<defs />
													<g id="Layer_2" data-name="Layer 2">
														<path
															className="cls-1"
															d="M0,1.88A1.89,1.89,0,0,1,3.13.47L7.81,4.6,12.36.49a1.88,1.88,0,1,1,2.53,2.79l-7,6.38L.64,3.3A1.89,1.89,0,0,1,0,1.88Z"
															id="Layer_1-2"
															data-name="Layer 1"
														/>
													</g>
												</svg>
											</button>
										</div>
									) : (
										<div className="collapsibleStatus">
											<textarea id="iFrameTextarea" value={iFrameCode} />
											<button
												onClick={this.collpaseTheiFrameSection}
												className="previewButtonsInside"
											>
												{t('COMMON_HIDE')}
												<svg viewBox="0 0 15.51 9.66">
													<defs />
													<g id="Layer_2" data-name="Layer 2">
														<path
															className="cls-1"
															d="M15.51,7.77A1.86,1.86,0,0,1,15,9a1.89,1.89,0,0,1-2.66.17L7.7,5.06,3.15,9.17A1.89,1.89,0,0,1,.62,6.37L7.67,0l7.21,6.36A1.87,1.87,0,0,1,15.51,7.77Z"
															id="Layer_1-2"
															data-name="Layer 1"
														/>
													</g>
												</svg>
											</button>
										</div>
									)}
								</div>
							</div>
							<div id="iFrameLinkContainer" className="previewAndShareInternalBlock linksSharingBlock">
								<CopyToClipboard text={iFrameCode} onCopy={this.copyIFrameLink}>
									<div className="flexWrapper">
										<img src={copy} />
										<a>
											{t('COMMON_COPY')} {t('COMMON_LINK')}
										</a>
									</div>
								</CopyToClipboard>
							</div>
						</div>
					</>
				}
			</>
		);
	}
}

EmbedVideo.propTypes = {
	id: PropTypes.string.isRequired,
	placementType: PropTypes.string.isRequired, // Medias or Playlists
};

const mapStateToProps = ({ session, inspectScreenReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	playerConfigId: inspectScreenReducer.playerConfigId,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	fetchPlayerEmbedConfiguration: (accId) => dispatch(fetchPlayerEmbedConfiguration(accId)),
	loadPlayerConfigById: (accId, id) => dispatch(loadPlayerConfigById(accId, id)),
	setVideoPlayerMuted: (value) => dispatch(setVideoPlayerMuted(value)),
	setSidePlayerConfigId: (value) => dispatch(setSidePlayerConfigId(value)),
	updatePreviewLink: (previewLink) => dispatch(updatePreviewLink(previewLink)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(EmbedVideo);
