import {
    _getIPonlyChannel,
    _getIPonlyChannels,
    _createIPonlyChannel,
    _updateIPonlyChannel,
    _deleteIPonlyChannel,
    _getIPonlyResources
} from "../services/iponlyChannelService"

export const SEND_ADD_CHANNEL_REQUEST = "SEND_ADD_CHANNEL_REQUEST";
export const SEND_ADD_CHANNEL_SUCCESS = "SEND_ADD_CHANNEL_SUCCESS";
export const SEND_ADD_CHANNEL_FAILURE = "SEND_ADD_CHANNEL_FAILURE";

export const SEND_UPDATE_CHANNEL_REQUEST = "SEND_UPDATE_CHANNEL_REQUEST";
export const SEND_UPDATE_CHANNEL_SUCCESS = "SEND_UPDATE_CHANNEL_SUCCESS";
export const SEND_UPDATE_CHANNEL_FAILURE = "SEND_UPDATE_CHANNEL_FAILURE";

export const SEND_GET_CHANNEL_REQUEST = "SEND_GET_CHANNEL_REQUEST";
export const SEND_GET_CHANNEL_SUCCESS = "SEND_GET_CHANNEL_SUCCESS";
export const SEND_GET_CHANNEL_FAILURE = "SEND_GET_CHANNEL_FAILURE";

export const SEND_GET_ALL_CHANNEL_REQUEST = "SEND_GET_ALL_CHANNEL_REQUEST";
export const SEND_GET_ALL_CHANNEL_SUCCESS = "SEND_GET_ALL_CHANNEL_SUCCESS";
export const SEND_GET_ALL_CHANNEL_FAILURE = "SEND_GET_ALL_CHANNEL_FAILURE";

export const SEND_DELETE_CHANNEL_REQUEST = "SEND_DELETE_CHANNEL_REQUEST";
export const SEND_DELETE_CHANNEL_SUCCESS = "SEND_DELETE_CHANNEL_SUCCESS";
export const SEND_DELETE_CHANNEL_FAILURE = "SEND_DELETE_CHANNEL_FAILURE";

export const SEND_GET_RESOURCES_REQUEST = "SEND_GET_RESOURCES_REQUEST";
export const SEND_GET_RESOURCES_SUCCESS = "SEND_GET_RESOURCES_SUCCESS";
export const SEND_GET_RESOURCES_FAILURE = "SEND_GET_RESOURCES_FAILURE";

const addChannelRequest = _ => ({
    type: SEND_ADD_CHANNEL_REQUEST
});

const addChannelRequestSuccess = _ => ({
    type: SEND_ADD_CHANNEL_SUCCESS
});

const addChannelRequestFailure = _ => ({
    type: SEND_ADD_CHANNEL_FAILURE
});

const getChannelRequest = _ => ({
    type: SEND_GET_CHANNEL_REQUEST
});

const getChannelRequestSuccess = _ => ({
    type: SEND_GET_CHANNEL_SUCCESS
});

const getChannelRequestFailure = _ => ({
    type: SEND_GET_CHANNEL_FAILURE
});

const getChannelsRequest = _ => ({
    type: SEND_GET_ALL_CHANNEL_REQUEST
});

const getChannelsRequestSuccess = _ => ({
    type: SEND_GET_ALL_CHANNEL_SUCCESS
});

const getChannelsRequestFailure = _ => ({
    type: SEND_GET_ALL_CHANNEL_FAILURE
});

const updateChannelRequest = _ => ({
    type: SEND_UPDATE_CHANNEL_REQUEST
});

const updateChannelRequestSuccess = _ => ({
    type: SEND_UPDATE_CHANNEL_SUCCESS
});

const updateChannelRequestFailure = _ => ({
    type: SEND_UPDATE_CHANNEL_FAILURE
});
const deleteChannelRequest = _ => ({
    type: SEND_DELETE_CHANNEL_REQUEST
});

const deleteChannelRequestSuccess = _ => ({
    type: SEND_DELETE_CHANNEL_SUCCESS
});

const deleteChannelRequestFailure = _ => ({
    type: SEND_DELETE_CHANNEL_FAILURE
});

const getResourcesRequest = _ => ({
    type: SEND_GET_RESOURCES_REQUEST
});

const getResourcesRequestSuccess = _ => ({
    type: SEND_GET_RESOURCES_SUCCESS
});

const getResourcesRequestFailure = _ => ({
    type: SEND_GET_RESOURCES_FAILURE
});


export const createIPonlyChannel = (accountId, resourceId, channelName, data) => (dispatch) => {
    dispatch(addChannelRequest());
    return _createIPonlyChannel(accountId, resourceId, channelName, data)
        .then(data => {
            dispatch(addChannelRequestSuccess());
            return data
        })
        .catch(error => {
            dispatch(addChannelRequestFailure());
            return error
        })
};

export const updateIPonlyChannel = (accountId, resourceId, channelName, data) => (dispatch) => {
    dispatch(updateChannelRequest());
    return _updateIPonlyChannel(accountId, resourceId, channelName, data)
        .then(data => {
            dispatch(updateChannelRequestSuccess());
            return data
        })
        .catch(error => {
            dispatch(updateChannelRequestFailure());
            return error
        })
};

export const getIPonlyChannel = (accountId, resourceId, channelName) => (dispatch) => {
    dispatch(getChannelRequest());
    return _getIPonlyChannel(accountId, resourceId, channelName)
        .then(data => {
            dispatch(getChannelRequestSuccess());
            return data
        })
        .catch(error => {
            dispatch(getChannelRequestFailure());
            return error
        })
};

export const getIPonlyChannels = (accountId, resourceId) => (dispatch) => {
    dispatch(getChannelsRequest());
    return _getIPonlyChannels(accountId, resourceId)
        .then(data => {
            dispatch(getChannelsRequestSuccess());
            return data
        })
        .catch(error => {
            dispatch(getChannelsRequestFailure());
            return error
        })
};

export const deleteIPonlyChannel = (accountId, resourceId, channelName) => (dispatch) => {
    dispatch(deleteChannelRequest());
    return _deleteIPonlyChannel(accountId, resourceId, channelName)
        .then(data => {
            dispatch(deleteChannelRequestSuccess());
            return data
        })
        .catch(error => {
            dispatch(deleteChannelRequestFailure());
            return error
        })
};

export const getIPonlyResources = (accountId) => (dispatch) => {
    dispatch(getResourcesRequest());
    return _getIPonlyResources(accountId)
        .then(data => {
            dispatch(getResourcesRequestSuccess());
            return data
        })
        .catch(error => {
            dispatch(getResourcesRequestFailure());
            return error
        })
};
