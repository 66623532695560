import * as actions from '../actions/profileActions';

const initialState = {
	login: '',
	loading: false,
	ipRestrictions: '',
	order: '',
	nameInEditor: '',
	nameInApi: '',
	type: '',
	inMedia: false,
	inCatalog: false,
	inPlaylist: false,
	isRequired: false,
	users: [],
	enabledEmbed: {},
	hasAdvancedSetting: false,
};

export const profileReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SEND_UPDATE_ENABLED_EMBED:
			return {
				...state,
				enabledEmbed: action.enabledEmbed,
			};
		case actions.HAS_ADVANCED_SETTINGS:
			return {
				...state,
				hasAdvancedSettings: action.hasAdvancedSettings,
			};
		case actions.SEND_LOAD_USERS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_LOAD_USERS_SUCCESS:
			return {
				...state,
				loading: false,
				users: action.users,
			};
		case actions.SEND_LOAD_USERS_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_USER_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_USER_DELETE_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_USER_DELETE_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_USER_CREATE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_USER_CREATE_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_USER_CREATE_REQUEST_FAILURE:
			return {
				...state,
				loadinig: false,
			};
		case actions.PROFILE_NEW_USER_FORM_UPDATE:
			return {
				...state,
				[action.field]: action.value,
			};
		case actions.PROFILE_NEW_CUSTOM_PROPERTY_FORM_UPDATE:
			return {
				...state,
				[action.field]: action.value,
			};
		case actions.SEND_LOAD_CUSTOM_PROPERTIES_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_LOAD_CUSTOM_PROPERTIES_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_LOAD_CUSTOM_PROPERTIES_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_DELETE_CUSTOM_PROPERTY_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_DELETE_CUSTOM_PROPERTY_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_DELETE_CUSTOM_PROPERTY_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_UPDATE_CUSTOM_PROPERTIES_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_CREATE_CUSTOM_PROPERTIES_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_CREATE_CUSTOM_PROPERTIES_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_CREATE_CUSTOM_PROPERTIES_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
};
