import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import closeIcon from '../../../assets/images/Close.svg';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import './MediaDetailDialog.scss';
import InspectMediaScreen from '../InspectScreen/InspectMediaScreen';
import { toggleMediaProperties } from '../../../actions/inspectScreenActions';
import { withStyles } from '@material-ui/core/styles';

const styles = (_) => ({
	dialogPaper: {
		minHeight: '100vh',
		maxHeight: '100vh',
		maxWidth: '94%',
		minWidth: '94%',
		margin: '0px',
		marginLeft: '6%',
		zIndex: '10000 !important',
		borderRadius: '0px',
	},
});

class MediaDetailDialog extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			openDialog: true,
		};
	}

	componentWillUnmount() {}

	componentDidMount = () => {};

	componentDidUpdate() {
		if (this.props.deletedMediaId === this.props.mediaId) {
			this.handleClose();
		}
	}
	handleClose = () => {
		this.props.toggleMediaProperties(false);
	};

	render() {
		const { classes } = this.props;

		return (
			<Dialog
				id="MediaDetailDialog"
				onClose={this.handleClose}
				classes={{ paper: classes.dialogPaper }}
				open={this.state.openDialog}
			>
				<button className="closeButton" onClick={this.handleClose}>
					<img src={closeIcon} className="closeButton"></img>
				</button>

				<DialogContent className="dialogContent">
					<DialogContentText>
						<div className="MediaDetailDialogWrapper">
							<InspectMediaScreen mediaId={this.props.mediaId} dialogMode={true}></InspectMediaScreen>
						</div>
					</DialogContentText>
				</DialogContent>
				<div></div>
			</Dialog>
		);
	}
}

MediaDetailDialog.propTypes = {
	mediaId: PropTypes.string.isRequired,
};

MediaDetailDialog.defaultProps = {};

const mapStateToProps = ({ inspectScreenReducer }) => ({
	deletedMediaId: inspectScreenReducer.deletedMediaId,
});

const mapDispatchToProps = (dispatch) => ({
	toggleMediaProperties: (toggle) => dispatch(toggleMediaProperties(toggle)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(withStyles(styles)(MediaDetailDialog));
