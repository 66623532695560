import React from 'react';
import Technology from './components/Technology';

const AnalyticsOperatingSystemsScreen = () => {
	return (
		<Technology
			dimension="os"
			titleSec1="ANALYTICS_TITLE_CDN_OPERATING_SYSTEM"
			titleSec2="ANALYTICS_TITLE_CDN_VOLUME_PER_OPERATING_SYSTEM"
			column="Operating System"
		/>
	);
};

export default AnalyticsOperatingSystemsScreen;
