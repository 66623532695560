export const getTag = (key: string): string => {
	if (key.indexOf('Outlined') !== -1) {
		return 'Outlined';
	} else if (key.indexOf('TwoTone') !== -1) {
		return 'Two tone';
	} else if (key.indexOf('Rounded') !== -1) {
		return 'Rounded';
	} else if (key.indexOf('Sharp') !== -1) {
		return 'Sharp';
	} else {
		return 'Filled';
	}
};
