import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
	playButtonPlay,
	playButtonPause,
	setPlayerPosition,
	setClickedPosition,
} from '../../../../actions/speechToTextActions';

import './SpeechToTextControlBar.scss';

class SpeechToTextControlBar extends Component {
	constructor(props) {
		super(props);
		this.currrentPosition = React.createRef();
	}

	togglePlayButton = () => {
		if (this.props.playPlayer === true) {
			this.props.playButtonPause();
		} else {
			this.props.playButtonPlay();
		}
	};

	setPlayerPositionOnClick = (evt) => {
		const e = evt.target;
		const dim = e.getBoundingClientRect();
		var x = evt.clientX - dim.left;
		const positionDif = evt.currentTarget.offsetWidth - x;
		const position = (positionDif / evt.currentTarget.offsetWidth) * 100;
		this.props.setPlayerPosition(100 - position);
		this.props.setClickedPosition(100 - position);
	};

	setPlayerPositionOnStart = () => {
		this.props.setPlayerPosition(0);
		this.props.setClickedPosition(0);
	};

	formatSrtTime = (millis) => {
		var thours = '00',
			tminutes = '00',
			tseconds = '00',
			tms = '00';
		var hours, minutes, seconds;
		thours += hours = Math.floor(millis / 1000 / 3600);
		tminutes += minutes = Math.floor(millis / 1000 / 60) - hours * 60;
		tseconds += seconds = Math.floor(millis / 1000) - hours * 3600 - minutes * 60;
		tms += Math.floor(millis) - hours * 3600000 - minutes * 60000 - seconds * 1000;
		return (
			thours.substr(thours.length - 2) +
			':' +
			tminutes.substr(tminutes.length - 2) +
			':' +
			tseconds.substr(tseconds.length - 2) +
			':' +
			tms.substr(tms.length - 3)
		);
	};

	render() {
		return (
			<div id="playerCtrl">
				<button id="toStartBtn" onClick={() => this.setPlayerPositionOnStart()} />
				<button
					id="playBtn"
					className={this.props.playPlayer ? 'playBtnPause' : ''}
					onClick={() => this.togglePlayButton()}
				/>
				<div className="videoDuration">
					{this.formatSrtTime(this.props.msPosition + this.props.trimItem.start)}{' '}
				</div>
				<div id="progressBar" onClick={(event) => this.setPlayerPositionOnClick(event)}>
					<div
						ref={this.currrentPosition}
						id="progress-current"
						style={{ width: (this.props.positionPlayer > 100 ? 100 : this.props.positionPlayer) + '%' }}
					></div>
					{/* <LinearProgress size={20} variant="determinate" value={this.props.positionPlayer}/> */}
				</div>
				<div className="videoDuration">
					{this.props.trimItem.exists
						? this.formatSrtTime(this.props.trimItem.end)
						: this.formatSrtTime(this.props.videoDuration)}{' '}
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ speechToText, interactTimelineReducer }) => ({
	playPlayer: speechToText.playPlayer,
	pausePlayer: speechToText.pausePlayer,
	positionPlayer: speechToText.positionPlayer,
	interactItems: interactTimelineReducer.items,
});

const mapDispatchToProps = (dispatch) => ({
	playButtonPlay: (_) => dispatch(playButtonPlay()),
	playButtonPause: (_) => dispatch(playButtonPause()),
	setPlayerPosition: (position) => dispatch(setPlayerPosition(position)),
	setClickedPosition: (clickedPosition) => dispatch(setClickedPosition(clickedPosition)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SpeechToTextControlBar);
