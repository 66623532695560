import {
    createNewTrack,
    createNewSequence,
    removeSequence,
    updateSavedSequence
} from "../services/tracksService";
import { LocalStorageService } from './../services/localStorageService';

export const getAccountId = getState => {
    if (getState) {
        return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
    } else {
        return LocalStorageService.getDefaultAccountId();
    }
};
export const CREATE_TRACK_REQUEST = "CREATE_TRACK_REQUEST";
export const CREATE_TRACK_REQUEST_SUCCESS = "CREATE_TRACK_REQUEST_SUCCESS";
export const CREATE_TRACK_REQUEST_FAILURE = "CREATE_TRACK_REQUEST_FAILURE";

export const CREATE_SEQUENCE_REQUEST = "CREATE_SEQUENCE_REQUEST";
export const CREATE_SEQUENCE_REQUEST_SUCCESS = "CREATE_SEQUENCE_REQUEST_SUCCESS";
export const CREATE_SEQUENCE_REQUEST_FAILURE = "CREATE_SEQUENCE_REQUEST_FAILURE";

export const DELETE_SEQUENCE_REQUEST = "DELETE_SEQUENCE_REQUEST";
export const DELETE_SEQUENCE_REQUEST_SUCCESS = "DELETE_SEQUENCE_REQUEST_SUCCESS";
export const DELETE_SEQUENCE_REQUEST_FAILURE = "DELETE_SEQUENCE_REQUEST_FAILURE";

export const UPDATE_SEQUENCE_REQUEST = "UPDATE_SEQUENCE_REQUEST";
export const UPDATE_SEQUENCE_REQUEST_SUCCESS = "UPDATE_SEQUENCE_REQUEST_SUCCESS";
export const UPDATE_SEQUENCE_REQUEST_FAILURE = "UPDATE_SEQUENCE_REQUEST_FAILURE";

const createTrackRequest = _ => ({
    type: CREATE_TRACK_REQUEST
});

const createTrackRequestSuccess = _ => ({
    type: CREATE_TRACK_REQUEST_SUCCESS
});

const createTrackRequestFailure = _ => ({
    type: CREATE_TRACK_REQUEST_FAILURE
});

const createSequenceRequest = _ => ({
    type: CREATE_SEQUENCE_REQUEST
});
const createSequenceRequestSuccess = _ => ({
    type: CREATE_SEQUENCE_REQUEST_SUCCESS
});
const createSequenceRequestFailure = _ => ({
    type: CREATE_SEQUENCE_REQUEST_FAILURE
});

const deleteSequenceRequest = _ => ({
    type: DELETE_SEQUENCE_REQUEST
});
const deleteSequenceRequestSuccess = _ => ({
    type: DELETE_SEQUENCE_REQUEST_SUCCESS
});
const deleteSequenceRequestFailure = _ => ({
    type: DELETE_SEQUENCE_REQUEST_FAILURE
});

const updateSequenceRequest = _ => ({
    type: UPDATE_SEQUENCE_REQUEST
});
const updateSequenceRequestSuccess = _ => ({
    type: UPDATE_SEQUENCE_REQUEST_SUCCESS
});
const updateSequenceRequestFailure = _ => ({
    type: UPDATE_SEQUENCE_REQUEST_FAILURE
});

export const createTrack = (accId, mediaId, body) => (dispatch, getState) => {
    const defaultAccountId = accId || getAccountId(getState);
     dispatch(createTrackRequest());
    return createNewTrack(defaultAccountId, mediaId, body).then(data => {
         dispatch(createTrackRequestSuccess());
        return data;
    }).catch(error => {
         dispatch(createTrackRequestFailure());
        return error;
    });
};

export const createSequence = (accId, mediaId, trackId, sequenceType, body) => (dispatch, getState) => {
    const defaultAccountId = accId || getAccountId(getState);
     dispatch(createSequenceRequest());
    return createNewSequence(defaultAccountId, mediaId, trackId, sequenceType, body).then(data => {
         dispatch(createSequenceRequestSuccess());
        return data;
    }).catch(error => {
         dispatch(createSequenceRequestFailure());
        return error;
    });
};

export const deleteSequence = (accId, mediaId, trackId, sequenceType, sequenceId) => (dispatch, getState) => {
    const defaultAccountId = accId || getAccountId(getState);
     dispatch(deleteSequenceRequest());
    return removeSequence(defaultAccountId, mediaId, trackId, sequenceType, sequenceId).then(data => {
         dispatch(deleteSequenceRequestSuccess());
        return data;
    }).catch(error => {
         dispatch(deleteSequenceRequestFailure());
        return error;
    });
};

export const updateSequence = (accId, mediaId, trackId, sequenceType, sequenceId, body) => (dispatch, getState) => {
    const defaultAccountId = accId || getAccountId(getState);
     dispatch(updateSequenceRequest());
    return updateSavedSequence(defaultAccountId, mediaId, trackId, sequenceType, sequenceId, body).then(data => {
         dispatch(updateSequenceRequestSuccess());
        return data;
    }).catch(error => {
         dispatch(updateSequenceRequestFailure());
        return error;
    });
};