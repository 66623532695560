import React, { Component } from 'react';

import PopoverContent from './PopoverContent';

import './Popover.scss';
import { joinClassNames } from '../../services/elementHelperService';

export default class Popover extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: !!this.props.visible,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.visible !== prevProps.visible) {
			this.setState({ visible: this.props.visible });
		}
	}

	onShow = (e) => {
		e.stopPropagation();
		this.setState({ visible: true });
	};

	onClose = (_) => {
		this.setState({ visible: false });
	};

	onToggle = (e) => {
		e.stopPropagation();
		this.props.onTriggerClick?.();
		this.setState({ visible: !this.state.visible });
	};

	onClickOutSide = (_) => {
		this.onClose();
		this.props.onClickOutSide && this.props.onClickOutSide();
	};

	render() {
		const { visible } = this.state;

		const { style, trigger, children, placement, showArrow, className, triggerNode, closeOnClick } = this.props;
		return (
			<div className={joinClassNames('popover', visible ? 'popover--open' : '')}>
				{triggerNode &&
					React.cloneElement(triggerNode, {
						onClick: trigger === 'click' || trigger === 'hover' ? this.onToggle.bind(this) : null,
						onMouseOver: trigger === 'hover' ? this.onShow.bind(this) : null,
					})}

				{visible && (
					<PopoverContent
						style={style}
						showArrow={showArrow}
						placement={placement}
						className={className}
						closeOnClick={closeOnClick}
						onClose={this.onClose.bind(this)}
						onClickOutSide={this.onClickOutSide.bind(this)}
					>
						{children}
					</PopoverContent>
				)}
			</div>
		);
	}
}
