export const getLastIndexCatalogId = () => {
	let pathArray = window.location.hash.split('/catalogs/');
	let catalogId = '';
	if (pathArray.length > 1) {
		catalogId = pathArray[pathArray.length - 1];
		if (catalogId.indexOf('/') != -1) {
			catalogId = catalogId.split('/')[0];
		}
		return catalogId;
	} else {
		return '';
	}
};

export const getLastIndexSmartFilterId = () => {
	let pathArray = window.location.hash.split('/smartfilters/');
	let smartfilterId = '';
	if (pathArray.length > 1) {
		smartfilterId = pathArray[pathArray.length - 1];
		if (smartfilterId.indexOf('/') != -1) {
			smartfilterId = smartfilterId.split('/')[0];
		}
		return smartfilterId;
	} else {
		return '';
	}
};
