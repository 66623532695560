import { _getFeatures } from '../services/permissionService';

export const SEND_GET_ACCOUNT_FEATURES_REQUEST = 'SEND_GET_ACCOUNT_FEATURES_REQUEST';
export const SEND_GET_ACCOUNT_FEATURES_SUCCESS = 'SEND_GET_ACCOUNT_FEATURES_SUCCESS';
export const SEND_GET_ACCOUNT_FEATURES_FAILURE = 'SEND_GET_ACCOUNT_FEATURES_FAILURE';

const getAccountFeaturesRequest = (_) => ({
	type: SEND_GET_ACCOUNT_FEATURES_REQUEST,
});

const getAccountFeaturesRequestSuccess = (features) => ({
	type: SEND_GET_ACCOUNT_FEATURES_SUCCESS,
	features,
});

const getAccountFeaturesRequestFailure = (_) => ({
	type: SEND_GET_ACCOUNT_FEATURES_FAILURE,
});

export const getAccountFeatures = (accountId) => (dispatch) => {
	dispatch(getAccountFeaturesRequest());
	return _getFeatures(accountId)
		.then((data) => {
			dispatch(getAccountFeaturesRequestSuccess(data));
			return data;
		})
		.catch((error) => {
			dispatch(getAccountFeaturesRequestFailure());
			return error;
		});
};
