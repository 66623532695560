import * as actions from '../actions/interactElementsActions';

const initialState = {
	itemDroppedOnOverlay: '',
	interactPreview: false,
	deletionFlagForDroppedItemOnOverlay: false,
	deleteDialogDisplay: false,
	interactItemsToDelete: [],
	interactElementsWidget: '',
	selectedSubPropertyKey: '',
	isComponentStatic: false,
	loading: false,
	isElementUpdating: false,
	mosaicTileChange: '',
	elementPropertiesError: false,
};
export const interactElementsReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SEND_TILE_WINDOW_CHANGE:
			return {
				...state,
				mosaicTileChange: Math.random(),
			};
		case actions.ITEM_DROPPED_ON_OVERLAY:
			return {
				...state,
				itemDroppedOnOverlay: action.item,
			};
		case actions.ITEM_DROPPED_ON_INTERACT_QUEUE:
			return {
				...state,
				itemDroppedOnOverlay: action.item,
			};
		case actions.SEND_OPEN_INTERACT_PREVIEW:
			return {
				...state,
				interactPreview: true,
			};
		case actions.SEND_CLOSE_INTERACT_PREVIEW:
			return {
				...state,
				interactPreview: false,
			};
		case actions.CLEAR_INTERACT_ELEMENTS_STORE:
			return {
				...initialState,
			};
		case actions.ITEM_DROPPED_ON_OVERLAY_CLEARED:
			return {
				...state,
				itemDroppedOnOverlay: '',
			};
		case actions.SET_DELETION_FLAG_FOR_DROPPED_ITEM_ON_OVERLAY:
			return {
				...state,
				deletionFlagForDroppedItemOnOverlay: action.flag,
			};
		case actions.SHOW_INTERACT_ITEM_DELETE:
			return {
				...state,
				deleteDialogDisplay: action.msg || true,
			};
		case actions.SET_COMPONENT_STATIC_TRUE: //tobe removed
			return {
				...state,
				isComponentStatic: true,
			};
		case actions.SET_COMPONENT_STATIC_FALSE:
			return {
				...state,
				isComponentStatic: false,
			};
		case actions.HIDE_INTERACT_ITEM_DELETE:
			return {
				...state,
				deleteDialogDisplay: false,
			};
		case actions.SET_INTERACT_ITEMS_TO_DELETE:
			return {
				...state,
				interactItemsToDelete: action.items || [],
			};
		case actions.SET_IS_ELEMENT_UPDATING:
			return {
				...state,
				isElementUpdating: action.isElementUpdating,
			};
		case actions.CLEAR_INTERACT_ITEMS_TO_DELETE:
			return {
				...state,
				interactItemsToDelete: [],
			};
		case actions.SET_INTERACT_ELEMENTS_WIDGET:
			return {
				...state,
				interactElementsWidget: action.data,
			};
		case actions.SHOW_SELECTED_SUB_PROPERTY_KEY:
			return {
				...state,
				selectedSubPropertyKey: action.key,
			};
		case actions.HIDE_SELECTED_SUB_PROPERTY_KEY:
			return {
				...state,
				selectedSubPropertyKey: '',
			};
		case actions.POST_INTERACT_ASSET_REQUEST:
		case actions.DELETE_INTERACT_ASSET_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.POST_INTERACT_ASSET_REQUEST_SUCCESS:
		case actions.DELETE_INTERACT_ASSET_REQUEST_SUCCESS:
		case actions.POST_INTERACT_ASSET_REQUEST_FAILURE:
		case actions.DELETE_INTERACT_ASSET_REQUEST_FAILURE: {
			return {
				...state,
				loading: false,
			};
		}
		case actions.SET_ELEMENT_PROPERTIES_ERROR: {
			return {
				...state,
				elementPropertiesError: true,
			};
		}

		case actions.RESET_ELEMENT_PROPERTIES_ERROR: {
			return {
				...state,
				elementPropertiesError: false,
			};
		}

		case actions.UPDATE_SELECTED_ELEMENT_PROPERTIES: {
			return {
				...state,
				updateSelectedElement: Math.random(),
			};
		}

		default:
			return state;
	}
};
