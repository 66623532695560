import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import IconsStore from '../../reusable/IconsStore/IconsStore';

class LiveManagerSettingsList extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount = () => {};

	handleToggle = (e) => {
		let target = '';
		if (e.target.classList.contains('toggle')) {
			target = e.target;
		} else if (e.target.parentElement.classList.contains('toggle')) {
			target = e.target.parentElement;
		}

		if (target.classList.contains('active')) {
			target.classList.remove('active');
		} else {
			target.classList.add('active');
		}
	};

	renderToggleButton = () => {
		const { iconName, title } = this.props;
		return (
			<>
				<div
					className="toggle active"
					onClick={(e) => {
						this.handleToggle(e);
					}}
				>
					<div className="toggleButton"></div>
					<IconsStore iconName={iconName} color={'#ffffff'} />
					<label className="propertySectionTitle"> {title} </label>
				</div>
			</>
		);
	};
	render() {
		return (
			<>
				<div className="fieldSettings">
					{this.renderToggleButton()}
					{React.Children.toArray(this.props.children).map((item, index) => (
						<div key={index} className="toggleDescription">
							{item}
						</div>
					))}
				</div>
			</>
		);
	}
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = () => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LiveManagerSettingsList);
