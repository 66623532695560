import React from 'react';

import WidgetTemplateItem from './WidgetTemplateItem';
import type { AllWidgetTemplatesProps } from '../WidgetsTab';

interface CreatedWidgetListProps {
	duplicateWidget: (_widget: object, _templateToCopy?: object) => {};
	allWidgetTemplates: any[];
}

const CreatedWidgetList = ({ duplicateWidget, allWidgetTemplates }: CreatedWidgetListProps) => {
	return (
		<>
			{allWidgetTemplates.map(
				({ id, name, widgetTemplates }: AllWidgetTemplatesProps) =>
					widgetTemplates.length > 0 && (
						<WidgetTemplateItem
							key={id}
							name={name}
							widgetTemplates={widgetTemplates}
							duplicateWidget={duplicateWidget}
						/>
					)
			)}
		</>
	);
};

export default CreatedWidgetList;
