import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import vis from 'vis-timeline/dist/vis-timeline-graph2d.min.js';
import 'vis-timeline/dist/vis-timeline-graph2d.min.css';
import keys from 'lodash/keys';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import each from 'lodash/each';

import { showNotification } from '../../actions/notificationActions';

import { TimelineEvents } from './TimelineEvents';

const _defaultEventProperties = {};
const _defaultFunction = function () {};

TimelineEvents.forEach((e) => {
	//setting default props for vis-timeline events
	_defaultEventProperties[`${e}EventHandler`] = _defaultFunction;
});

const _defaultProps = {
	options: {},
	items: [],
	groups: [],
	selectedItems: [],
	customTimes: [],
};

class Timeline extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			customTimes: [],
		};
	}

	componentDidMount = () => {
		const { container } = this.refs;
		const { options } = this.props;
		this.timeline = new vis.Timeline(container, undefined, undefined, options);
		if (this.props.timelineRef) {
			this.props.timelineRef(this);
		}
		this.addEventHandlers();
	};

	addEventHandlers = () => {
		TimelineEvents.forEach((e) => {
			this.timeline.on(e, this.props[`${e}EventHandler`]);
		});
	};

	shouldComponentUpdate(nextProps) {
		const { items, groups, options, customTimes, selectedItems } = this.props;

		const itemsUpdated = items !== nextProps.items;
		const groupsUpdated = groups !== nextProps.groups;
		const optionsUpdated = options !== nextProps.options;
		const customTimesUpdated = customTimes !== nextProps.customTimes;
		const selectedItemsUpdated = selectedItems !== nextProps.selectedItems;

		const shouldUpdate =
			itemsUpdated || groupsUpdated || optionsUpdated || customTimesUpdated || selectedItemsUpdated;

		return shouldUpdate;
	}

	initializeWithDefaultValues = () => {
		let _items = new vis.DataSet();
		_items.add([]);
		this.timeline.setItems(_items);
		let _groups = new vis.DataSet();
		_groups.add([]);
		this.timeline.setGroups(_groups);
	};

	initialize = (prevProps = null) => {
		const { items, groups, selectedItems, options } = this.props;
		const addOrUpdateItems = items.length === 0 || (prevProps && prevProps.items != items);
		const addOrUpdateGroups = groups.length === 0 || (prevProps && prevProps.groups != groups);

		if (options.start !== prevProps.options.start || options.end !== prevProps.options.end) {
			this.timeline.setWindow(options.start, options.end, { animation: true });
		}

		if (options !== prevProps.options) {
			this.timeline.setOptions(options);
		}

		if (addOrUpdateGroups) {
			let _groups = new vis.DataSet();
			let newGroups = groups.filter((group, index) => !groups.find((g, i) => g.id === group.id && index !== i));
			_groups.add(newGroups);
			this.timeline.setGroups(_groups);
		}

		if (addOrUpdateItems) {
			try {
				let _items = new vis.DataSet();
				_items.add(items);
				if (items.length === prevProps.items.length) {
					//todo need to find a better solution when items length is same. i.e to find if an item id got added or removed
					let itemMismatch = false;
					items.forEach((item, index) => {
						if (item.id === prevProps.items[index].id) {
							this.timeline.itemsData.update(item);
						} else {
							itemMismatch = true;
						}
					});
					if (itemMismatch) {
						this.timeline.setItems(_items);
					}
				} else {
					this.timeline.setItems(_items);
				}
			} catch (e) {
				console.error('TIMELINE---' + e + '---' + e.message);
			}
		}

		if (
			selectedItems &&
			selectedItems.length == 1 &&
			prevProps.selectedItems &&
			prevProps.selectedItems.length == 1
		) {
			if (selectedItems[0] !== prevProps.selectedItems[0]) {
				this.timeline.setSelection(selectedItems);
			}
		} else if (selectedItems !== prevProps.selectedItems) {
			this.timeline.setSelection(selectedItems);
		}

		this.manageCustomTimes();
	};

	manageCustomTimes = () => {
		const { customTimes } = this.props;

		const prevKeys = keys(this.state.customTimes);
		const newKeys = keys(customTimes);
		const keysToAdd = difference(newKeys, prevKeys);
		const keysToRemove = difference(prevKeys, newKeys);
		const keysToUpdate = intersection(prevKeys, newKeys);

		each(keysToRemove, (id) => this.timeline.removeCustomTime(id));
		each(keysToAdd, (id) => {
			const datetime = customTimes[id];
			this.timeline.addCustomTime(datetime, id);
		});
		each(keysToUpdate, (id) => {
			const datetime = customTimes[id];
			this.timeline.setCustomTime(datetime, id);
		});
		this.setState({ customTimes });
	};

	componentWillUnmount() {
		if (this.timeline) {
			this.timeline.destroy();
		}
	}

	componentDidUpdate(prevProps) {
		this.initialize(prevProps);
	}

	render() {
		return <div ref="container" />;
	}
}

Timeline.propTypes = {
	options: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired,
	groups: PropTypes.array.isRequired,
	selectedItems: PropTypes.array,
	customTimes: PropTypes.shape({
		datetime: PropTypes.instanceOf(Date),
		id: PropTypes.string,
	}),
};

Timeline.defaultProps = {
	..._defaultProps,
	..._defaultEventProperties,
};

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Timeline);
