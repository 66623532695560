
export const SET_LAST_BLOCK_DATASET_INDEX = "SET_LAST_BLOCK_DATASET_INDEX";
export const SET_FIRST_BLOCK_DATASET_INDEX = "SET_FIRST_BLOCK_DATASET_INDEX";
export const SET_DATA_PER_PAGE = "SET_DATA_PER_PAGE";
export const SET_FIRST_PAGE_HANDLER = "SET_FIRST_PAGE_HANDLER";
export const SET_NEXT_PAGE_HANDLER = "SET_NEXT_PAGE_HANDLER";
export const SET_PREVIOUS_PAGE_HANDLER = "SET_PREVIOUS_PAGE_HANDLER";
export const SET_LAST_PAGE_HANDLER = "SET_LAST_PAGE_HANDLER";
export const SET_FIRST_PAGE_FLAG = "SET_FIRST_PAGE_FLAG";
export const SET_LAST_PAGE_FLAG = "SET_LAST_PAGE_FLAG";
export const SET_TOTAL_ITEMS_COUNT = "SET_TOTAL_ITEMS_COUNT";
export const SET_TOTAL_CATALOGS_COUNT = "SET_TOTAL_CATALOGS_COUNT";
export const SET_TOTAL_ALL_MEDIAS_COUNT = "SET_TOTAL_ALL_MEDIAS_COUNT";
export const SET_CURRENT_MEDIA_OFFSET = "SET_CURRENT_MEDIA_OFFSET";
export const SET_CURRENT_CATALOG_OFFSET = "SET_CURRENT_CATALOG_OFFSET";
export const SET_CURRENT_OFFSET = "SET_CURRENT_OFFSET";
export const SET_MEDIA_OFFSET = "SET_MEDIA_OFFSET";
export const SET_CATALOG_OFFSET = "SET_CATALOG_OFFSET";
export const SET_TOTAL_PLAYLISTS_COUNT = "SET_TOTAL_PLAYLISTS_COUNT";
export const SET_CURRENT_PLAYLIST_OFFSET = "SET_CURRENT_PLAYLIST_OFFSET";
export const CLEAR_PAGINATION_STORE = "CLEAR_PAGINATION_STORE";
export const SET_TOTAL_SCENARIOS_COUNT = "SET_TOTAL_SCENARIOS_COUNT";

export const SUCCESS_CACHE_PAGINATED_DATA = "SUCCESS_CACHE_PAGINATED_DATA";
export const SUCCESS_CACHE_PAGE_COUNT_DATA = "SUCCESS_CACHE_PAGE_COUNT_DATA";
export const SET_LAST_PAGINATION_ACTION = "SET_LAST_PAGINATION_ACTION";
export const SET_DISPLAYED_MEDIA = "SET_DISPLAYED_MEDIA";

export const PUSH_DISPLAYED_MEDIA_COUNT_TO_STACK = "PUSH_DISPLAYED_MEDIA_COUNT_TO_STACK";
export const POP_DISPLAYED_MEDIA_COUNT_FROM_STACK = "POP_DISPLAYED_MEDIA_COUNT_FROM_STACK";
export const CLEAR_DISPLAYED_MEDIA_COUNT_FROM_STACK = "CLEAR_DISPLAYED_MEDIA_COUNT_FROM_STACK";

export const cachePaginatedDataLocal = (data, hash) => ({
    type: SUCCESS_CACHE_PAGINATED_DATA,
    // data:{[hash]:data},
    data,
    hash
});

export const cachePageCountData = (data) => ({
    type: SUCCESS_CACHE_PAGE_COUNT_DATA,
    data
});

export const setFirstPageHandler = firstPageHandler => ({
    type: SET_FIRST_PAGE_HANDLER,
    firstPageHandler
});

export const setNextPageHandler = nextPageHandler => ({
    type: SET_NEXT_PAGE_HANDLER,
    nextPageHandler
});

export const setPreviousPageHandler = previousPageHandler => ({
    type: SET_PREVIOUS_PAGE_HANDLER,
    previousPageHandler
});

export const setLastPageHandler = lastPageHandler => ({
    type: SET_LAST_PAGE_HANDLER,
    lastPageHandler
});

export const setLastBlockDataSetIndex = lastBlockDataSetIndex => ({
    type: SET_LAST_BLOCK_DATASET_INDEX,
    lastBlockDataSetIndex
});

export const setFirstBlockDataSetIndex = firstBlockDataSetIndex => ({
    type: SET_FIRST_BLOCK_DATASET_INDEX,
    firstBlockDataSetIndex
});

export const setDataPerPage = dataPerPage => ({
    type: SET_DATA_PER_PAGE,
    dataPerPage
});

export const setFirstPageFlag = firstPageFlag => ({
    type: SET_FIRST_PAGE_FLAG,
    firstPageFlag
});


export const setLastPageFlag = lastPageFlag => ({
    type: SET_LAST_PAGE_FLAG,
    lastPageFlag
});

export const setTotalItemsCount = totalItemsCount => ({
    type: SET_TOTAL_ITEMS_COUNT,
    totalItemsCount
});

export const setTotalAllMediasCount = totalAllMediasCount => ({
    type: SET_TOTAL_ALL_MEDIAS_COUNT,
    totalAllMediasCount
});

export const setTotalCatalogsCount = totalCatalogsCount => ({
    type: SET_TOTAL_CATALOGS_COUNT,
    totalCatalogsCount
});

export const setTotalPlaylistsCount = totalPlaylistsCount => ({
    type: SET_TOTAL_PLAYLISTS_COUNT,
    totalPlaylistsCount
});

export const setCurrentMediaOffset = currentMediaOffset => ({
    type: SET_CURRENT_MEDIA_OFFSET,
    currentMediaOffset
});

export const setCurrentCatalogOffset = currentCatalogOffset => ({
    type: SET_CURRENT_CATALOG_OFFSET,
    currentCatalogOffset
});

export const setCurrentPlaylistOffset = currentPlaylistOffset => ({
    type: SET_CURRENT_PLAYLIST_OFFSET,
    currentPlaylistOffset
});

export const setMediaOffset = mediaOffset => ({
    type: SET_MEDIA_OFFSET,
    mediaOffset
});

export const setCatalogOffset = catalogOffset => ({
    type: SET_CATALOG_OFFSET,
    catalogOffset
});

export const setCurrentOffset = currentOffset => ({
    type: SET_CURRENT_OFFSET,
    currentOffset
});

export const setLastPaginationAction = (action) => ({
    type: SET_LAST_PAGINATION_ACTION,
    action
});

export const setDisplayedMedia = (media) => ({
    type: SET_DISPLAYED_MEDIA,
    media
});

export const pushMediaCountToStack = (object) => ({
    type: PUSH_DISPLAYED_MEDIA_COUNT_TO_STACK,
    object
});

export const popMediaCountFromStack = () => ({
    type: POP_DISPLAYED_MEDIA_COUNT_FROM_STACK
});

export const clearMediaCountStack = () => ({
    type: CLEAR_DISPLAYED_MEDIA_COUNT_FROM_STACK
});

export const clearPaginationStore = _ => ({
    type: CLEAR_PAGINATION_STORE
});

export const setScenariosCount = (count) => ({
    type: SET_TOTAL_SCENARIOS_COUNT,
    count
});
