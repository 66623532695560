import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import arrow_down from '../../../../../../../../../../../assets/images/arrow-90deg-down.svg';

interface GoToUrlActionProps {
	metadata?: { url?: string; openNewTab?: boolean };
	gotoUrlRef: React.Ref<HTMLInputElement> | undefined;
	openNewTabRef: React.Ref<HTMLInputElement> | undefined;
	handleGotoUrlWidgetChange: () => {};
	handleGotoUrlOpenNewTabChange: () => {};
}

const GoToUrlAction = ({
	metadata,
	gotoUrlRef,
	openNewTabRef,
	handleGotoUrlWidgetChange,
	handleGotoUrlOpenNewTabChange,
}: GoToUrlActionProps) => {
	const { t: translator } = useTranslation();
	return (
		<>
			<FormControlLabel
				className={'scenario-chart__control'}
				label={<img src={arrow_down} />}
				labelPlacement="start"
				control={
					<FormControl>
						<TextField
							variant="standard"
							value={metadata?.url}
							onChange={handleGotoUrlWidgetChange}
							inputRef={gotoUrlRef}
						/>
						<FormHelperText>
							{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_ENTER_THE_URL_HELPER_TEXT')}
						</FormHelperText>
					</FormControl>
				}
			/>
			<FormControlLabel
				className={'scenario-chart__control'}
				label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_OPEN_URL_IN_NEW_TAB_HELPER_TEXT')}
				labelPlacement="start"
				control={
					<FormControl>
						<Box>
							<Checkbox
								color="primary"
								style={{ padding: 0 }}
								onChange={handleGotoUrlOpenNewTabChange}
								checked={metadata?.openNewTab ?? true}
								inputRef={openNewTabRef}
							/>
						</Box>
						<FormHelperText>
							{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_OPEN_URL_IN_NEW_TAB_CHECK_BOX')}
						</FormHelperText>
					</FormControl>
				}
			/>
		</>
	);
};

export default GoToUrlAction;
