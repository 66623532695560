import { identityConfigurations } from './../utils/config';

export const OauthService = {
	service: identityConfigurations,

	createLoginUrl(state) {
		let response_type = 'token';
		let nonce = this.createAndSaveNonce();

		if (typeof state === 'undefined') {
			state = '';
		}
		if (state) {
			state = nonce + ';' + state;
		} else {
			state = nonce;
		}

		if (this.service.oidc) {
			response_type = 'id_token+token';
		}

		return `${this.service.loginUrl}?response_type=${response_type}&client_id=${encodeURIComponent(
			this.service.clientId
		)}&state=${encodeURIComponent(state)}&redirect_uri=${encodeURIComponent(
			this.service.redirectUri
		)}&scope=${encodeURIComponent(this.service.scope)}${
			this.service.oidc && '&nonce=' + encodeURIComponent(nonce)
		}`;
	},

	createAndSaveNonce() {
		const nonce = this.createNonce();
		localStorage.setItem('nonce', nonce);
		return nonce;
	},

	createNonce() {
		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		for (let i = 0; i < 40; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	},

	createIframe() {
		const url = this.createLoginUrl();
		let iframe = document.createElement('iframe');
		iframe.setAttribute('src', url);
		iframe.setAttribute('id', 'oauthFrame');
		let iframePlaceholder = document.getElementById('iframe-placeholder');

		if (iframePlaceholder !== null) {
			iframePlaceholder.appendChild(iframe);
		} else {
			iframe.classList.add('hidden-oidc-iframe');
			document.body.appendChild(iframe);
		}
	},
};
