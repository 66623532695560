import React, { Component } from 'react';
import { connect } from 'react-redux';

import { OauthClient } from '../../reusable/OauthClient/OauthClient';

import { generateUUID } from './../../utils/commonUtil';
import { twitterOAuthService } from './../../services/twitterOAuthService';

import {
	loadTwitterAuthentication,
	shareThePostOnSocialMedia,
	handleDeleteTheSharedPost,
	retrieveSharedPostsStatus,
	loadSocialMediaPaltformDetails,
	handleSocialMediaAuthorization,
} from '../../actions/publishActions';
import { showNotification } from '../../actions/notificationActions';

import { mediaTypes, messageTypes } from '../../constants/mediaConstants';
import {
	sharedPostType,
	socialMediaTypes,
	socialMediaPlatforms,
	sharedPostTypeStatus,
	socialMediaPlatformIds,
} from '../../constants/socialSharingConstants';
import { getDetails, getThumbnailFromRenditionsBasedOnWidth } from '../../services/mediaDisplayService';
import { showMessage } from '../../actions/globalActions';
import {
	youtubeClientId,
	linkedinClientId,
	facebookClientId,
	googleAuthorizationUrl,
	twitterAuthorizationUrl,
	socialSharingRedirectUrl,
	facebookAuthorizationUrl,
	linkedinAuthorizationUrl,
	facebookAuthorizationScope,
	linkedinAuthorizationScope,
	googleAuthorizationScope,
} from '../../utils/config';
import './SocialSharing.scss';

class SocialSharing extends Component {
	constructor(props) {
		super(props);
		this.interval = null;
		this.state = {
			youTubeLoginExpired: true,
			twitterLoginExpired: true,
			facebookLoginExpired: true,
			linkedlnLoginExpired: true,
			youtubeSharedPostSharedId: '',
			twitterSharedPostSharedId: '',
			linkedlnSharedPostSharedId: '',
			facebookSharedPostSharedId: '',
		};
		this.timerForTwitter = null;
		this.twitterPopup = null;
		this.twitterUrl = '';
	}

	UNSAFE_componentWillMount() {
		this.loadPlatformDetails();
		this.loadPostsSharingStatus();
	}

	loadPostsSharingStatus = (_) => {
		this.props.retrieveSharedPostsStatus(this.props.defaultAccountId, this.props.mediaId).then((data) => {
			if (data && data.shares && data.shares.map) {
				data.shares.map((eachSharedPost) => {
					switch (eachSharedPost.type) {
						case sharedPostType.twitter:
							{
								if (eachSharedPost.status === sharedPostTypeStatus.failed) {
									this.setState({
										twitterSharedPostSharedId: eachSharedPost.shareId,
										twitterSharedPostStatus: eachSharedPost.status,
									});
								}
								if (eachSharedPost.status === sharedPostTypeStatus.shared) {
									this.setState({
										twitterSharedPostSharedId: eachSharedPost.shareId,
										twittershare: eachSharedPost.externalReference,
										twitterSharedPostStatus: eachSharedPost.status,
									});
								}
							}
							break;
						case sharedPostType.linkedln:
							{
								if (eachSharedPost.status === sharedPostTypeStatus.failed) {
									this.setState({
										linkedlnSharedPostSharedId: eachSharedPost.shareId,
										linkedlnSharedPostStatus: eachSharedPost.status,
									});
								}
								if (eachSharedPost.status === sharedPostTypeStatus.shared) {
									this.setState({
										linkedlnSharedPostSharedId: eachSharedPost.shareId,
										linklnshare: eachSharedPost.externalReference,
										linkedlnSharedPostStatus: eachSharedPost.status,
									});
								}
							}
							break;
						case sharedPostType.youtube:
							{
								if (eachSharedPost.status === sharedPostTypeStatus.failed) {
									this.setState({
										youtubeSharedPostSharedId: eachSharedPost.shareId,
										youtubeSharedPostStatus: eachSharedPost.status,
									});
								}
								if (eachSharedPost.status === sharedPostTypeStatus.shared) {
									this.setState({
										youtubeSharedPostSharedId: eachSharedPost.shareId,
										youtubeshare: eachSharedPost.externalReference,
										youtubeSharedPostStatus: eachSharedPost.status,
									});
								}
							}
							break;
						case sharedPostType.facebook:
							{
								if (eachSharedPost.status === sharedPostTypeStatus.failed) {
									this.setState({
										facebookSharedPostSharedId: eachSharedPost.shareId,
										facebookSharedPostStatus: eachSharedPost.status,
									});
								}
								if (eachSharedPost.status === sharedPostTypeStatus.shared) {
									this.setState({
										facebookSharedPostSharedId: eachSharedPost.shareId,
										facebookshare: eachSharedPost.externalReference,
										facebookSharedPostStatus: eachSharedPost.status,
									});
								}
							}
							break;
						default:
							break;
					}
				});
			}
		});
	};

	getPostLink = (type, link) => {
		switch (type) {
			case sharedPostType.twitter: {
				return `https://twitter.com/statuses/${link}`;
			}
			case sharedPostType.linkedln: {
				return `https://www.linkedin.com/feed/update/urn:li:share:${link}`;
			}
			case sharedPostType.youtube: {
				return `https://www.youtube.com/watch?v=${link}`;
			}
			case sharedPostType.facebook: {
				return `https://www.facebook.com/${link}`;
			}
			default:
				break;
		}
	};

	resetPlatforms = () => {
		this.setState({
			youTubeLoginExpired: true,
			twitterLoginExpired: true,
			facebookLoginExpired: true,
			linkedlnLoginExpired: true,
		});
	};

	loadPlatformDetails = (_) => {
		this.resetPlatforms();
		this.props.loadSocialMediaPaltformDetails(this.props.defaultAccountId).then((data) => {
			const currentDate = new Date();
			data &&
				data.map((eachSocialMedia) => {
					if (eachSocialMedia.type === socialMediaTypes.facebook) {
						if (new Date(eachSocialMedia.expiresAt) > currentDate) {
							this.setState({ facebookLoginExpired: false });
						}
					}
					if (eachSocialMedia.type === socialMediaTypes.youtube) {
						if (new Date(eachSocialMedia.expiresAt) > currentDate) {
							this.setState({ youTubeLoginExpired: false });
						}
					}
					if (eachSocialMedia.type === socialMediaTypes.linkedln) {
						if (new Date(eachSocialMedia.expiresAt) > currentDate) {
							this.setState({ linkedlnLoginExpired: false });
						}
					}
					if (eachSocialMedia.type === socialMediaTypes.twitter) {
						if (new Date(eachSocialMedia.expiresAt) > currentDate) {
							this.setState({ twitterLoginExpired: false });
						}
					}
				});
		});
	};

	onFacebookSuccess = (response, accountId) => {
		this.props
			.handleSocialMediaAuthorization(
				accountId,
				response.code,
				socialMediaPlatformIds.facebook,
				socialMediaPlatforms.facebook,
				facebookClientId
			)
			.then((_) => {
				this.loadPlatformDetails();
			});
	};

	onFacebookFailure = (_) => this.props.showNotification(this.props.t('LABEL_AUTHENTICATION_FAILED'));

	onLinkedInSuccess = (response, accountId) => {
		this.props
			.handleSocialMediaAuthorization(
				accountId,
				response.code,
				socialMediaPlatformIds.linkedIn,
				socialMediaPlatforms.linkedIn,
				linkedinClientId
			)
			.then((_) => {
				this.loadPlatformDetails();
			});
	};

	onLinkedInFailure = (_) => this.props.showNotification(this.props.t('LABEL_AUTHENTICATION_FAILED'));

	onYoutubeSuccess = (response, accountId) => {
		this.props
			.handleSocialMediaAuthorization(
				accountId,
				response.code,
				socialMediaPlatformIds.youtube,
				socialMediaPlatforms.youtube,
				youtubeClientId
			)
			.then((_) => {
				this.loadPlatformDetails();
			});
	};

	onYoutubeFailure = (_) => this.props.showNotification(this.props.t('LABEL_AUTHENTICATION_FAILED'));

	loadTwitterAuthentication = (accId) => {
		const startTime = new Date().getTime();
		this.props.loadTwitterAuthentication(accId).then((data) => {
			if (data && data.oauth_token) {
				this.url = twitterAuthorizationUrl + data.oauth_token;
				twitterOAuthService.openPopUp(this.url);
				// Later this have to be handlled with a hook
				this.interval = setInterval((_) => {
					if (new Date().getTime() - startTime > 120000) {
						clearInterval(this.interval);
						return;
					} else {
						this.loadPlatformDetails();
					}
				}, 2000);
			}
		});
	};

	shareThePostWithRelavantSocialMedia = (accountId, mediaId, socialMediaType) => {
		let socialSharingPost = {};
		const { mediaDetails } = this.props;
		if (mediaDetails.asset && mediaDetails.asset.resources && mediaDetails.asset.resources.map) {
			const generalMediaPost = getDetails(mediaDetails, true);
			let thumbnail = generalMediaPost.thumbnail;
			if (thumbnail instanceof Array) {
				thumbnail = getThumbnailFromRenditionsBasedOnWidth(thumbnail, 640);
			}
			if (!mediaDetails.asset || !mediaDetails.asset.resources) {
				this.props.showMessage('The video does not have valid resources or assets.', messageTypes.error);
				return;
			}
			var videoResource = mediaDetails.asset.resources.find(function (res) {
				return res.type === mediaTypes.video;
			});
			if (!videoResource || !videoResource.renditions) {
				this.props.showMessage(
					'No video resource with valid renditions were found in the media. ',
					messageTypes.error
				);
				return;
			}
			if (videoResource.renditions && videoResource.renditions.length > 0) {
				socialSharingPost = {
					id: generateUUID(),
					title: mediaDetails.title,
					description: mediaDetails.description || 'Shared via Qbrick Video Platform (QVP).',
					source: {
						type: 'VideoPackage',
						videoUrl:
							videoResource.renditions[0] &&
							videoResource.renditions[0].type === mediaTypes.video &&
							videoResource.renditions[0].links[0].href,
						imageUrl: thumbnail,
					},
				};
			}
		}
		switch (socialMediaType) {
			case sharedPostType.facebook:
				socialSharingPost.type = sharedPostType.facebook;
				socialSharingPost.platformId = socialMediaPlatformIds.facebook;
				break;
			case sharedPostType.youtube:
				socialSharingPost.type = sharedPostType.youtube;
				socialSharingPost.platformId = socialMediaPlatformIds.youtube;
				break;
			case sharedPostType.linkedln:
				socialSharingPost.type = sharedPostType.linkedln;
				socialSharingPost.platformId = socialMediaPlatformIds.linkedIn;
				break;
			case sharedPostType.twitter:
				socialSharingPost.type = sharedPostType.twitter;
				socialSharingPost.platformId = socialMediaPlatformIds.twitter;
				break;
			default:
				break;
		}

		if (!socialSharingPost.type) {
			this.props.showMessage('No sharing platform type identified', messageTypes.error);
			return;
		}

		this.props.shareThePostOnSocialMedia(accountId, mediaId, socialSharingPost).then((data) => {
			if (data) {
				this.loadPostsSharingStatus();
				this.props.showMessage(this.props.t('LABEL_POST_SHARED'), messageTypes.info);
			} else {
				this.props.showMessage('Failed to share the post', messageTypes.error);
			}
		});
	};

	deleteTheSharedPost = (accountId, mediaId, sharedId) =>
		this.props.handleDeleteTheSharedPost(accountId, mediaId, sharedId).then((data) => {
			if (data) {
				this.loadPostsSharingStatus();
				this.props.showNotification(this.props.t('LABEL_POST_SHARED_DELETED'));
			}
		});

	render() {
		const { t, mediaDetails, defaultAccountId } = this.props;

		const {
			linklnshare,
			twittershare,
			youtubeshare,
			facebookshare,
			youTubeLoginExpired,
			twitterLoginExpired,
			facebookLoginExpired,
			linkedlnLoginExpired,
			twitterSharedPostStatus,
			youtubeSharedPostStatus,
			facebookSharedPostStatus,
			linkedlnSharedPostStatus,
			youtubeSharedPostSharedId,
			twitterSharedPostSharedId,
			linkedlnSharedPostSharedId,
			facebookSharedPostSharedId,
		} = this.state;

		return (
			<>
				<div id="socialSharing">
					<div className="socialSharingBlocks">
						<label className="title"> {t('LABEL_FACEBOOK')} </label>
						<div className="statusSection">
							<div className={'logo facebook' + (facebookLoginExpired ? ' disabled' : '')}></div>
							<div className="accountStatus">
								<label> {facebookLoginExpired ? t('NOT_CONNECTED') : t('CONNECTED')} </label>
								<label className="companyName"> {!facebookLoginExpired ? t('QBRICK_AB') : '-'} </label>
							</div>
							{facebookLoginExpired ? (
								<>
									<OauthClient
										clientId={facebookClientId}
										onFailure={this.onFacebookFailure}
										redirectUri={socialSharingRedirectUrl}
										authorizeUrl={facebookAuthorizationUrl}
										scope={facebookAuthorizationScope}
										onSuccess={(response) => this.onFacebookSuccess(response, defaultAccountId)}
										renderElement={({ onClick }) => (
											<button className="defaultActionBtn neutralBtn" onClick={onClick}>
												{t('CONNECT_ACCOUNT')}
											</button>
										)}
									/>
								</>
							) : (
								<>
									{
										<button
											className={
												'socialSharingBtnOverriden defaultActionBtn' +
												(facebookSharedPostStatus === sharedPostTypeStatus.shared
													? ' disabled'
													: '')
											}
											onClick={() =>
												this.shareThePostWithRelavantSocialMedia(
													defaultAccountId,
													mediaDetails.id,
													sharedPostType.facebook
												)
											}
										>
											{facebookSharedPostStatus === sharedPostTypeStatus.failed
												? t('LABEL_RETRY')
												: facebookSharedPostStatus === sharedPostTypeStatus.shared
												? t('LABEL_SHARED')
												: t('LABEL_SHARE')}
										</button>
									}
									{facebookSharedPostSharedId && facebookSharedPostSharedId !== '' && (
										<>
											<label
												className="commonLinkSharing"
												onClick={() =>
													this.deleteTheSharedPost(
														defaultAccountId,
														mediaDetails.id,
														facebookSharedPostSharedId
													)
												}
											>
												{t('LABEL_DELETE_SHARE')}
											</label>
											{facebookSharedPostStatus === sharedPostTypeStatus.shared && (
												<>
													<label> 1 share </label>
													<a
														className="commonLinkSharing"
														href={this.getPostLink(sharedPostType.facebook, facebookshare)}
														target="_blank"
													>
														{t('LABEL_VIEW_LINK')}
													</a>
												</>
											)}
										</>
									)}
								</>
							)}
						</div>
					</div>
					<div className="socialSharingBlocks">
						<label className="title"> {t('LABEL_YOUTUBE')} </label>
						<div className="statusSection">
							<div className={'logo youtube' + (youTubeLoginExpired ? ' disabled' : '')}></div>
							<div className="accountStatus">
								<label> {youTubeLoginExpired ? t('NOT_CONNECTED') : t('CONNECTED')} </label>
								<label className="companyName"> {!youTubeLoginExpired ? t('QBRICK_AB') : '-'} </label>
								{<p>{t('LABEL_YOUTUBE_SPECIFIC_MESSAGE')}</p>}
							</div>
							{youTubeLoginExpired ? (
								<>
									<OauthClient
										clientId={youtubeClientId}
										onFailure={this.onYoutubeFailure}
										authorizeUrl={googleAuthorizationUrl}
										redirectUri={socialSharingRedirectUrl}
										onSuccess={(response) => this.onYoutubeSuccess(response, defaultAccountId)}
										renderElement={({ onClick }) => (
											<button className="defaultActionBtn neutralBtn" onClick={onClick}>
												{t('CONNECT_ACCOUNT')}
											</button>
										)}
										scope={`${googleAuthorizationScope}`}
									/>
								</>
							) : (
								<>
									{
										<button
											className={
												'socialSharingBtnOverriden defaultActionBtn' +
												(youtubeSharedPostStatus === sharedPostTypeStatus.shared
													? ' disabled'
													: '')
											}
											onClick={() =>
												this.shareThePostWithRelavantSocialMedia(
													defaultAccountId,
													mediaDetails.id,
													sharedPostType.youtube
												)
											}
										>
											{youtubeSharedPostStatus === sharedPostTypeStatus.failed
												? t('LABEL_RETRY')
												: youtubeSharedPostStatus === sharedPostTypeStatus.shared
												? t('LABEL_SHARED')
												: t('LABEL_SHARE')}
										</button>
									}
									{youtubeSharedPostSharedId && youtubeSharedPostSharedId !== '' && (
										<>
											<label
												className="commonLinkSharing"
												onClick={() =>
													this.deleteTheSharedPost(
														defaultAccountId,
														mediaDetails.id,
														youtubeSharedPostSharedId
													)
												}
											>
												{t('LABEL_DELETE_SHARE')}
											</label>
											{youtubeSharedPostStatus === sharedPostTypeStatus.shared && (
												<>
													<label> 1 share </label>
													<a
														className="commonLinkSharing"
														href={this.getPostLink(sharedPostType.youtube, youtubeshare)}
														target="_blank"
													>
														{t('LABEL_VIEW_LINK')}
													</a>
												</>
											)}
										</>
									)}
								</>
							)}
						</div>
					</div>
					<div className="socialSharingBlocks">
						<label className="title"> {t('LABEL_LINKEDIN')} </label>
						<div className="statusSection">
							<div className={'logo linkln' + (linkedlnLoginExpired ? ' disabled' : '')}></div>
							<div className="accountStatus">
								<label> {linkedlnLoginExpired ? t('NOT_CONNECTED') : t('CONNECTED')} </label>
								<label className="companyName"> {!linkedlnLoginExpired ? t('QBRICK_AB') : '-'} </label>
							</div>
							{!linkedlnLoginExpired &&
								linkedlnSharedPostSharedId &&
								linkedlnSharedPostSharedId !== '' && (
									<>
										<div className="accountLinkData">
											<label
												className="commonLinkSharing"
												onClick={() =>
													this.deleteTheSharedPost(
														defaultAccountId,
														mediaDetails.id,
														linkedlnSharedPostSharedId
													)
												}
											>
												{t('LABEL_DELETE_SHARE')}
											</label>
											{linkedlnSharedPostStatus === sharedPostTypeStatus.shared && (
												<>
													<label> 1 share </label>
													<a
														className="commonLinkSharing"
														href={this.getPostLink(sharedPostType.linkedln, linklnshare)}
														target="_blank"
													>
														{t('LABEL_VIEW_LINK')}
													</a>
												</>
											)}
										</div>
									</>
								)}
							{linkedlnLoginExpired ? (
								<>
									<OauthClient
										scope={linkedinAuthorizationScope}
										clientId={linkedinClientId}
										onFailure={this.onLinkedInFailure}
										redirectUri={socialSharingRedirectUrl}
										authorizeUrl={linkedinAuthorizationUrl}
										onSuccess={(response) => this.onLinkedInSuccess(response, defaultAccountId)}
										renderElement={({ onClick }) => (
											<button className="defaultActionBtn neutralBtn" onClick={onClick}>
												{t('CONNECT_ACCOUNT')}
											</button>
										)}
									/>
								</>
							) : (
								<>
									{
										<button
											className={
												'socialSharingBtnOverriden defaultActionBtn' +
												(linkedlnSharedPostStatus === sharedPostTypeStatus.shared
													? ' disabled'
													: '')
											}
											onClick={() =>
												this.shareThePostWithRelavantSocialMedia(
													defaultAccountId,
													mediaDetails.id,
													sharedPostType.linkedln
												)
											}
										>
											{linkedlnSharedPostStatus === sharedPostTypeStatus.failed
												? t('LABEL_RETRY')
												: linkedlnSharedPostStatus === sharedPostTypeStatus.shared
												? t('LABEL_SHARED')
												: t('LABEL_SHARE')}
										</button>
									}
								</>
							)}
						</div>
					</div>
					<div className="socialSharingBlocks">
						<label className="title"> {t('LABEL_TWITTER')} </label>
						<div className="statusSection">
							<div className={'logo twitter' + (twitterLoginExpired ? ' disabled' : '')}></div>
							<div className="accountStatus">
								<label> {twitterLoginExpired ? t('NOT_CONNECTED') : t('CONNECTED')} </label>
								<label className="companyName"> {!twitterLoginExpired ? t('QBRICK_AB') : '-'} </label>
							</div>
							{!twitterLoginExpired && twitterSharedPostSharedId && twitterSharedPostSharedId !== '' && (
								<>
									<div className="accountLinkData">
										<label
											className="commonLinkSharing"
											onClick={() =>
												this.deleteTheSharedPost(
													defaultAccountId,
													mediaDetails.id,
													twitterSharedPostSharedId
												)
											}
										>
											{t('LABEL_DELETE_SHARE')}
										</label>
										{twitterSharedPostStatus === sharedPostTypeStatus.shared && (
											<>
												<label> 1 share </label>
												<a
													className="commonLinkSharing"
													href={this.getPostLink(sharedPostType.twitter, twittershare)}
													target="_blank"
												>
													{t('LABEL_VIEW_LINK')}
												</a>
											</>
										)}
									</div>
								</>
							)}
							{twitterLoginExpired ? (
								<>
									<button
										className="defaultActionBtn neutralBtn"
										onClick={() => this.loadTwitterAuthentication(defaultAccountId)}
									>
										{t('CONNECT_ACCOUNT')}
									</button>
								</>
							) : (
								<>
									{
										<button
											className={
												'socialSharingBtnOverriden defaultActionBtn' +
												(twitterSharedPostStatus === sharedPostTypeStatus.shared
													? ' disabled'
													: '')
											}
											onClick={() =>
												this.shareThePostWithRelavantSocialMedia(
													defaultAccountId,
													mediaDetails.id,
													sharedPostType.twitter
												)
											}
										>
											{twitterSharedPostStatus === sharedPostTypeStatus.failed
												? t('LABEL_RETRY')
												: twitterSharedPostStatus === sharedPostTypeStatus.shared
												? t('LABEL_SHARED')
												: t('LABEL_SHARE')}
										</button>
									}
								</>
							)}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session }) => ({
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	loadTwitterAuthentication: (accountId) => dispatch(loadTwitterAuthentication(accountId)),
	loadSocialMediaPaltformDetails: (accId) => dispatch(loadSocialMediaPaltformDetails(accId)),
	retrieveSharedPostsStatus: (accId, mediaId) => dispatch(retrieveSharedPostsStatus(accId, mediaId)),
	shareThePostOnSocialMedia: (accountId, mediaId, post) =>
		dispatch(shareThePostOnSocialMedia(accountId, mediaId, post)),
	handleDeleteTheSharedPost: (accountId, mediaId, shareId) =>
		dispatch(handleDeleteTheSharedPost(accountId, mediaId, shareId)),
	handleSocialMediaAuthorization: (accountId, code, platformId, platform, clientId) =>
		dispatch(handleSocialMediaAuthorization(accountId, code, platformId, platform, clientId)),
	showMessage: (stackMessage, type) => dispatch(showMessage(stackMessage, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialSharing);
