import { removeTrackFromMedia } from '../services/mediaService';
import { LocalStorageService } from './../services/localStorageService';

export const UPDATE_PREVIEW_LINK = 'UPDATE_PREVIEW_LINK';
export const UPDATE_DOWNLOAD_LINK = 'UPDATE_DOWNLOAD_LINK';
export const SEND_MEDIA_DELETED = 'SEND_MEDIA_DELETED';
export const SET_VIDEO_PLAYER_MUTED = 'SET_VIDEO_PLAYER_MUTED';
export const DISABLE_CAPTURE_FROM_VIDEO = 'DISABLE_CAPTURE_FROM_VIDEO';
export const SET_SIDE_PLAYER_CONFIG_ID = 'SET_SIDE_PLAYER_CONFIG_ID';
export const DELETE_TRACK_FROM_MEDIA_REQUEST = 'DELETE_TRACK_FROM_MEDIA_REQUEST';
export const DELETE_TRACK_FROM_MEDIA_REQUEST_SUCCESS = 'DELETE_TRACK_FROM_MEDIA_REQUEST_SUCCESS';
export const DELETE_TRACK_FROM_MEDIA_REQUEST_FAILURE = 'DELETE_TRACK_FROM_MEDIA_REQUEST_FAILURE';
export const TOGGLE_MEDIA_PROPERTIES = 'TOGGLE_MEDIA_PROPERTIES';
export const UPDATE_MEDIA_PROPERTIES = 'UPDATE_MEDIA_PROPERTIES';
export const UPDATE_MENU_KEYS = 'UPDATE_MENU_KEYS';
export const CLEAR_INSPECT_SCREEN_STORAGE = 'CLEAR_INSPECT_SCREEN_STORAGE';
export const ADD_MEDIA_DETAILS = 'ADD_MEDIA_DETAILS';
export const RESIZE_SCREEN_AFTER_CALCULATION = 'RESIZE_SCREEN_AFTER_CALCULATION';
export const SET_CURRENT_THUMBNAIL = 'SET_CURRENT_THUMBNAIL';
export const SET_INTERACT_BUTTON_IMAGE_UPLOADED = 'SET_INTERACT_BUTTON_IMAGE_UPLOADED';

export const resizeScreenAfterCalculation = () => ({
	type: RESIZE_SCREEN_AFTER_CALCULATION,
});

export const updateMenuKeys = (key) => ({
	type: UPDATE_MENU_KEYS,
	key,
});

export const updatePreviewLink = (previewLink) => ({
	type: UPDATE_PREVIEW_LINK,
	previewLink,
});

export const updateDownloadLink = (downloadLink) => ({
	type: UPDATE_DOWNLOAD_LINK,
	downloadLink,
});

export const addMediaDetails = (details) => ({
	type: ADD_MEDIA_DETAILS,
	details,
});

export const toggleCaptureFromVideoButton = (value) => ({
	type: DISABLE_CAPTURE_FROM_VIDEO,
	value,
});

export const setVideoPlayerMuted = (value) => ({
	type: SET_VIDEO_PLAYER_MUTED,
	value,
});

export const clearInspectScreenStorage = () => ({
	type: CLEAR_INSPECT_SCREEN_STORAGE,
});

export const sendMediaDeleted = (mediaId) => ({
	type: SEND_MEDIA_DELETED,
	mediaId: mediaId,
});

export const setCurrentThumbnail = (currentThumbnail) => ({
	type: SET_CURRENT_THUMBNAIL,
	currentThumbnail: currentThumbnail,
});

export const setInteractButtonImageUploaded = (value) => ({
	type: SET_INTERACT_BUTTON_IMAGE_UPLOADED,
	interactButtonImageUploaded: value,
});

const deleteTrackFromMediaRequest = (_) => ({
	type: DELETE_TRACK_FROM_MEDIA_REQUEST,
});

const deleteTrackFromMediaRequestSuccess = (_) => ({
	type: DELETE_TRACK_FROM_MEDIA_REQUEST_SUCCESS,
});

const deleteTrackFromMediaRequestFailure = (_) => ({
	type: DELETE_TRACK_FROM_MEDIA_REQUEST_FAILURE,
});

export const toggleMediaProperties = (toggle) => ({
	type: TOGGLE_MEDIA_PROPERTIES,
	toggle,
});

export const updateMediaProperties = (updatedMedia) => ({
	type: UPDATE_MEDIA_PROPERTIES,
	updatedMedia,
});

export const setSidePlayerConfigId = (value) => ({
	type: SET_SIDE_PLAYER_CONFIG_ID,
	value,
});

export const getAccountId = (getState) => {
	if (getState) {
		return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

export const deleteTrackFromMedia = (accId, mediaId, tracksId) => (dispatch, getState) => {
	const defaultAccountId = accId || getAccountId(getState);
	dispatch(deleteTrackFromMediaRequest());
	return removeTrackFromMedia(defaultAccountId, mediaId, tracksId)
		.then((data) => {
			dispatch(deleteTrackFromMediaRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(deleteTrackFromMediaRequestFailure());
			return error;
		});
};
