export const transformOrigin = ({ target }) => {
	// edit origin
	const { width: svgWidth, height: svgHeight, x, y } = target.getBBox();

	const centerPoint = {
		x: x + svgWidth - svgWidth / 2,
		y: y + svgHeight - svgHeight / 2,
	};
	target.style.transformOrigin = `${centerPoint.x}px ${centerPoint.y}px`;
};

export const updateRotation = (currentRotation, { target }) => {
	currentRotation &&
		currentRotation[0] &&
		(target.style.transform = target.style.transform.replace(/rotate\((.*)deg\)/, currentRotation[0]));
};
