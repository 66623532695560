import { HAL_9000_ACCOUNT_ID, publicConfigIds } from '../constants/playerConfigurationsConstant';
import { LocalStorageService } from '../services/localStorageService';
import {
	_createNewPlayerConfiguration,
	_updatePlayerConfiguration,
	_deletePlayerConfiguration,
	_loadPlayerConfiguration,
	_loadPlayerConfigurationById,
} from '../services/playerConfigurationsService';

export const SEND_ADD_NEW_PLAYER_CONFIG_REQUEST = 'SEND_ADD_NEW_PLAYER_CONFIG_REQUEST';
export const SEND_ADD_NEW_PLAYER_CONFIG_REQUEST_SUCCESS = 'SEND_ADD_NEW_PLAYER_CONFIG_REQUEST_SUCCESS';
export const SEND_ADD_NEW_PLAYER_CONFIG_REQUEST_FAILURE = 'SEND_ADD_NEW_PLAYER_CONFIG_REQUEST_FAILURE';

export const SEND_UPDATE_PLAYER_CONFIG_REQUEST = 'SEND_UPDATE_PLAYER_CONFIG_REQUEST';
export const SEND_UPDATE_PLAYER_CONFIG_REQUEST_SUCCESS = 'SEND_UPDATE_PLAYER_CONFIG_REQUEST_SUCCESS';
export const SEND_UPDATE_PLAYER_CONFIG_REQUEST_FAILURE = 'SEND_UPDATE_PLAYER_CONFIG_REQUEST_FAILURE';

export const SEND_DELETE_PLAYER_CONFIG_REQUEST = 'SEND_DELETE_PLAYER_CONFIG_REQUEST';
export const SEND_DELETE_PLAYER_CONFIG_REQUEST_SUCCESS = 'SEND_DELETE_PLAYER_CONFIG_REQUEST_SUCCESS';
export const SEND_DELETE_PLAYER_CONFIG_REQUEST_FAILURE = 'SEND_DELETE_PLAYER_CONFIG_REQUEST_FAILURE';

export const SEND_LOAD_PLAYER_CONFIG_REQUEST = 'SEND_LOAD_PLAYER_CONFIG_REQUEST';
export const SEND_LOAD_PLAYER_CONFIG_REQUEST_SUCCESS = 'SEND_LOAD_PLAYER_CONFIG_REQUEST_SUCCESS';
export const SEND_LOAD_PLAYER_CONFIG_REQUEST_FAILURE = 'SEND_LOAD_PLAYER_CONFIG_REQUEST_FAILURE';

export const SET_PLAYER_CONFIGS = 'SET_PLAYER_CONFIGS';
export const SET_SELECTED_PLAYER_CONFIG = 'SET_SELECTED_PLAYER_CONFIG';
export const ADD_NEW_PLAYER_CONFIG = 'ADD_NEW_PLAYER_CONFIG';
export const DELETE_PLAYER_CONFIG = 'ADD_NEW_PLAYER_CONFIG';
export const UPDATE_PLAYER_CONFIG = 'ADD_NEW_PLAYER_CONFIG';

export const sendAddNewPlayerConfigRequest = (_) => ({
	type: SEND_ADD_NEW_PLAYER_CONFIG_REQUEST,
});
export const sendAddNewPlayerConfigRequestSuccess = (_) => ({
	type: SEND_ADD_NEW_PLAYER_CONFIG_REQUEST_SUCCESS,
});
export const sendAddNewPlayerConfigRequestFailure = (_) => ({
	type: SEND_ADD_NEW_PLAYER_CONFIG_REQUEST_FAILURE,
});

export const sendUpdatePlayerConfigRequest = (_) => ({
	type: SEND_UPDATE_PLAYER_CONFIG_REQUEST,
});
export const sendUpdatePlayerConfigRequestSuccess = (_) => ({
	type: SEND_UPDATE_PLAYER_CONFIG_REQUEST_SUCCESS,
});
export const sendUpdatePlayerConfigRequestFailure = (_) => ({
	type: SEND_UPDATE_PLAYER_CONFIG_REQUEST_FAILURE,
});

export const sendDeletePlayerConfigRequest = (_) => ({
	type: SEND_DELETE_PLAYER_CONFIG_REQUEST,
});
export const sendDeletePlayerConfigRequestSuccess = (_) => ({
	type: SEND_DELETE_PLAYER_CONFIG_REQUEST_SUCCESS,
});
export const sendDeletePlayerConfigRequestFailure = (_) => ({
	type: SEND_DELETE_PLAYER_CONFIG_REQUEST_FAILURE,
});

export const sendLoadPlayerConfigRequest = (_) => ({
	type: SEND_LOAD_PLAYER_CONFIG_REQUEST,
});
export const sendLoadPlayerConfigRequestSuccess = (_) => ({
	type: SEND_LOAD_PLAYER_CONFIG_REQUEST_SUCCESS,
});
export const sendLoadPlayerConfigRequestFailure = (_) => ({
	type: SEND_LOAD_PLAYER_CONFIG_REQUEST_FAILURE,
});

export const getAccountId = (getState) => {
	if (getState) {
		return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

export const loadPlayerConfiguration = (accountId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return _loadPlayerConfiguration(defaultAccountId)
		.then((data) => data)
		.catch((error) => {
			dispatch(sendLoadPlayerConfigRequestFailure());
			return error;
		});
};

export const loadPlayerConfigurationById = (accountId, playerConfigId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendLoadPlayerConfigRequest());
	return _loadPlayerConfigurationById(defaultAccountId, playerConfigId)
		.then((data) => data)
		.catch((error) => {
			dispatch(sendLoadPlayerConfigRequestFailure());
			return error;
		});
};

export const addNewPlayerConfiguration = (accountId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendAddNewPlayerConfigRequest());
	return _createNewPlayerConfiguration(defaultAccountId, body)
		.then((data) => {
			dispatch(sendAddNewPlayerConfigRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendAddNewPlayerConfigRequestFailure());
			return error;
		});
};

export const updatePlayerConfiguration = (accountId, playerConfigId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendUpdatePlayerConfigRequest());
	return _updatePlayerConfiguration(defaultAccountId, playerConfigId, body)
		.then((data) => {
			dispatch(sendUpdatePlayerConfigRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendUpdatePlayerConfigRequestFailure());
			return error;
		});
};

export const deletePlayerConfiguration = (accountId, playerConfigId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendDeletePlayerConfigRequest());
	return _deletePlayerConfiguration(defaultAccountId, playerConfigId)
		.then((data) => {
			dispatch(sendDeletePlayerConfigRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendDeletePlayerConfigRequestFailure());
			return error;
		});
};

export const getPublicPlayerConfigurations = () => (dispatch) => {
	return _loadPlayerConfiguration(HAL_9000_ACCOUNT_ID)
		.then((data) => {
			if (!data) {
				dispatch(sendLoadPlayerConfigRequestFailure());
				return [];
			}
			const publicConfigs = data.filter(
				(c) => c.id === publicConfigIds.Enterprise || c.id === publicConfigIds.Interactive
			);
			return publicConfigs;
		})
		.catch(() => {
			dispatch(sendLoadPlayerConfigRequestFailure());
			return [];
		});
};

export const getPlayerConfigurationBody = (playerConfig) => {
	return {
		id: playerConfig.id,
		created: playerConfig.created,
		updated: playerConfig.updated,
		createdBy: { id: playerConfig.createdBy.id },
		updatedBy: { id: playerConfig.updatedBy.id },
		name: playerConfig.name,
		recommended: playerConfig.recommended,
		layouts: [
			getPlayerConfigurationLayout(playerConfig.layouts[0]),
			getPlayerConfigurationLayout(playerConfig.layouts[1]),
		],
	};
};

export const getPlayerConfigurationLayout = (layout) => {
	return {
		type: layout.type,
		playbackOptions: layout.playbackOptions
			? {
					autostart: layout.playbackOptions.autostart,
					replay: layout.playbackOptions.replay,
					muteAtStart: layout.playbackOptions.muteAtStart,
					maintainProportions: layout.playbackOptions.maintainProportions,
					softFullscreen: layout.playbackOptions.softFullscreen,
					subtitlesAutoOn: layout.playbackOptions.subtitlesAutoOn,
			  }
			: {},
		topControls: layout.topControls
			? {
					enabled: layout.topControls.enabled,
					showTitle: layout.topControls.showTitle,
					showAccountName: layout.topControls.showAccountName,
					accountName: layout.topControls.accountName,
					showSocialSharing: layout.topControls.showSocialSharing,
					showDownloadButton: layout.topControls.showDownloadButton,
					showOpenInNewWindow: layout.topControls.showOpenInNewWindow,
					alwaysVisible: layout.topControls.alwaysVisible,
			  }
			: {},
		bottomControls: layout.bottomControls
			? {
					enabled: layout.bottomControls.enabled,
					showPlaybackControls: layout.bottomControls.showPlaybackControls,
					showPlayPauseButton: layout.bottomControls.showPlayPauseButton,
					showTimeline: layout.bottomControls.showTimeline,
					showRewindForwardButtons: layout.bottomControls.showRewindForwardButtons,
					showAudioControls: layout.bottomControls.showAudioControls,
					showCastDevices: layout.bottomControls.showCastDevices,
					showLanguageSettingsButton: layout.bottomControls.showLanguageSettingsButton,
					showSubtitlesSettings: layout.bottomControls.showSubtitlesSettings,
					showAudioTracksSettings: layout.bottomControls.showAudioTracksSettings,
					showSettingsButton: layout.bottomControls.showSettingsButton,
					showVideoQualitySettings: layout.bottomControls.showVideoQualitySettings,
					showFullscreen: layout.bottomControls.showFullscreen,
					showPictureInPicture: layout.bottomControls.showPictureInPicture,
					alwaysVisible: layout.bottomControls.alwaysVisible,
			  }
			: {},
		engagementFeatures: layout.engagementFeatures
			? {
					enabled: layout.engagementFeatures.enabled,
					showShoppingCart: layout.engagementFeatures.showShoppingCart,
					showChat: layout.engagementFeatures.showChat,
					showLiveLikes: layout.engagementFeatures.showLiveLikes,
					openChatOnPlay: layout.engagementFeatures.openChatOnPlay,
			  }
			: {},
		accessibilitySettings: layout.accessibilitySettings
			? {
					language: layout.accessibilitySettings.language,
			  }
			: {},
		playerStyling: layout.playerStyling
			? {
					controlBackgroundColor: layout.playerStyling.controlBackgroundColor,
					controlBackgroundOpacity: layout.playerStyling.controlBackgroundOpacity,
					menuBackgroundColor: layout.playerStyling.menuBackgroundColor,
					menuBackgroundOpacity: layout.playerStyling.menuBackgroundOpacity,
					textColor: layout.playerStyling.textColor,
					textOpacity: layout.playerStyling.textOpacity,
					hoverColor: layout.playerStyling.hoverColor,
					hoverOpacity: layout.playerStyling.hoverOpacity,
					playerFont: layout.playerStyling.playerFont,
			  }
			: {},
	};
};
