import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './InteractElementsList.scss';
import IconsStore from '../../../../reusable/IconsStore/IconsStore';
import pencil from '../../../../assets/images/pencil.svg';
import { IconsList } from '../../../../reusable/IconsStore/IconsList';
import { getFormattedTimeWithMilliseconds } from '../../../../services/timeStampService';
import {
	setSelectedInteractTimelineItem,
	createInteractTimelineItem,
	createInteractTimelineGroup,
} from '../../../../actions/interactTimelineActions';
import { setInteractItemsToDelete, showInteractDeleteDialog } from '../../../../actions/interactElementsActions';
import { interactTimelineItemTypes, sequenceTypes } from '../../../../constants/interactConstant';
import { messageTypes } from '../../../../constants/mediaConstants';
import _ from 'underscore';
import AssetLibrary from '../../../../reusable/AssetLibrary/AssetLibrary';
import { showNotification } from '../../../../actions/notificationActions';
import {
	getInteractGroupItemFromPreset,
	getIteracItemEndDate,
	checkIfItemIsOverlapping,
	isTimeBeyondRange,
} from '../../../../services/interactScreenHelperService';

class InteractElementsList extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isPlayerPlaying: false,
			openAssetLibrary: false,
		};
	}

	componentWillUnmount() {
		this.removePlayerEventHandlers();
	}

	componentDidMount = () => {
		if (this.props.onRef) {
			this.props.onRef(this);
		}
		this._initPlayer();
	};

	componentDidUpdate(prevProps) {
		if (
			prevProps.interacMediaLoopStarted !== this.props.interacMediaLoopStarted &&
			this.props.interacMediaLoopStarted &&
			this.player &&
			this.player.play
		) {
			this.player.play();
		}
	}

	handleRemoveInteractElement = (e, item) => {
		e.stopPropagation();
		this.props.setInteractItemsToDelete([item]);
		this.props.showInteractDeleteDialog();
	};

	_initPlayer = () => {
		this.player = window.GoBrain.widgets()['interactPlayer-od'];
		this.addPlayerEventHandlers();
	};

	handleInteractElementSelection = (e, item) => {
		/* if (isPlayerPlaying) { */
		if (this.player && this.player.pause) {
			this.player.pause(); //pausing the player if an item is selected
		}

		//this.setState({ isPlayerPlaying: true })
		setTimeout(() => {
			this.props.setSelectedInteractTimelineItem([item.id]);
		}, 200);
		/*  } else {
             this.props.setSelectedInteractTimelineItem([item.id])
         } */
	};

	addPlayerEventHandlers = () => {
		if (this.player) {
			this.player.on('play', this.handlePlayerPlayEvent);
			this.player.on('pause', this.handlePlayerPauseEvent);
		}
	};

	removePlayerEventHandlers = () => {
		if (this.player) {
			this.player.off('play', this.handlePlayerPlayEvent);
			this.player.off('pause', this.handlePlayerPauseEvent);
		}
	};

	handlePlayerPlayEvent = () => {
		this.setState({
			isPlayerPlaying: true,
		});
	};

	handlePlayerPauseEvent = () => {
		this.setState({
			isPlayerPlaying: false,
		});
	};

	renderEachItem = (item) => {
		const { mediaId, isOnLiveMode, liveQueueItem } = this.props;
		if (item.itemType == sequenceTypes.video || item.id === mediaId) {
			return null;
		}
		let start = getFormattedTimeWithMilliseconds(item.start, 'HH:mm:ss:SSS');
		let end = getFormattedTimeWithMilliseconds(item.end, 'HH:mm:ss:SSS');

		let timestamp;
		if (isOnLiveMode) {
			timestamp = '';
		} else {
			timestamp = ' (' + start + ' - ' + end + ')';
		}
		return (
			<>
				<div
					className="interactItemElements"
					viewonly={liveQueueItem && liveQueueItem.id == item.id ? 'disabled' : ''}
					attr={item.id == interactTimelineItemTypes.trimTool ? 'trimtool' : ''}
					key={item.id}
					onClick={(e) => this.handleInteractElementSelection(e, item)}
				>
					<div className="interactItemDetailElements">
						<span className="interactItemDetailTitle">{item.content}</span>
						<span className="interactItemDetailTypeTime">
							{item.templateName + (timestamp ? ' - ' + timestamp : '')}
						</span>
					</div>
					<div className="interactItemDetailPenIcon">
						<img src={pencil} />
					</div>
					<div
						className="deleteInteractItemElements"
						onClick={(e) => this.handleRemoveInteractElement(e, item)}
					>
						<IconsStore iconName={IconsList.INTERACT_CLOSE_DARK_MODE} />
					</div>
				</div>
			</>
		);
	};

	showAssetLibrary = () => {
		this.setState({
			openAssetLibrary: true,
		});
	};

	closeAssetLibrary = () => {
		this.setState({
			openAssetLibrary: false,
		});
	};

	addPresets = (_item) => {
		const { interactElementsWidget, interactGroups, interactItems } = this.props;
		if (!interactElementsWidget) {
			this.props.showMessage('templates not found', messageTypes.error);
			return;
		}
		let objectData = _.find(JSON.parse(interactElementsWidget), (widget) => {
			return widget.templateId === _item.templateId;
		});
		if (!objectData) {
			this.props.showMessage('element template not found', messageTypes.error);
			return;
		}
		let startTime = null;
		let endTime = null;
		if (!this.props.isOnLiveMode && this.props.bottomPanelRef) {
			startTime = this.props.bottomPanelRef.getCustomTimeState();
			endTime = getIteracItemEndDate(startTime, this);
		}
		let itemAndGroup = getInteractGroupItemFromPreset(objectData, _item, startTime, endTime); //start and end are passed as null
		let group = _.find(interactGroups, (group) => {
			return group.id === objectData.templateGroup;
		});
		if (!this.props.isOnLiveMode && itemAndGroup.item) {
			itemAndGroup.item.editable = true;
		}
		//return;
		if (!this.props.isOnLiveMode && this.props.bottomPanelRef) {
			itemAndGroup.item = this.props.bottomPanelRef.getModifiedItemStartAndEndTime(itemAndGroup.item, group);
			if (checkIfItemIsOverlapping(this, itemAndGroup.item) || isTimeBeyondRange(this, itemAndGroup.item)) {
				return;
			}
		}
		if (!group) {
			this.props.createInteractTimelineGroup(itemAndGroup.group);
		}
		const itemIndex =
			interactItems.length > 0
				? interactItems.sort((a, b) => a.index - b.index)[interactItems.length - 1].index + 1
				: 0;
		this.props.createInteractTimelineItem({ ...itemAndGroup.item, index: itemAndGroup.item.index ?? itemIndex });
		this.props.showMessage('Added Element', messageTypes.info);
		//this.props.setSelectedInteractTimelineItem([itemAndGroup.item.id])
	};

	renderAssetLibrary = () => {
		const { openAssetLibrary } = this.state;
		const { t } = this.props;
		if (openAssetLibrary) {
			return (
				<>
					<AssetLibrary t={t} onClose={this.closeAssetLibrary} addPresets={this.addPresets} />
				</>
			);
		} else {
			return null;
		}
	};

	render() {
		const { mediaId, interactItems, t, isOnLiveMode, liveQueueItem } = this.props;
		let itemArray = [];
		if (isOnLiveMode) {
			itemArray = interactItems.filter((item) => {
				return (
					(item.templateId && !(item.start && item.end)) ||
					(item.start && !item.end) ||
					(liveQueueItem && liveQueueItem.id == item.id)
				);
			});
		}
		interactItems.sort((a, b) => a.index - b.index);

		return (
			<>
				<div id="interactItemList" className="interactItemList">
					{interactItems.length == 0 || (interactItems.length == 1 && interactItems[0].id == mediaId) ? (
						<div className="interactElementInfo">
							<label>
								{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_INTERACTIVE_MANAGER_VIEW_YOUR_ELEMENTS')}
							</label>
						</div>
					) : (
						<>
							<div className="itemList">
								{isOnLiveMode
									? itemArray.map((item) => this.renderEachItem(item))
									: interactItems.map((item) => this.renderEachItem(item))}
							</div>
						</>
					)}
				</div>
				{this.renderAssetLibrary()}
			</>
		);
	}
}

const mapStateToProps = ({ session, interactTimelineReducer, interactElementsReducer, inspectScreenReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	interactGroups: interactTimelineReducer.groups,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
	interacMediaLoopStarted: interactTimelineReducer.interacMediaLoopStarted,
	interactElementsWidget: interactElementsReducer.interactElementsWidget,
	liveQueueItem: interactTimelineReducer.liveQueueItem,
	mediaDetails: inspectScreenReducer.mediaOriginalDetails,
});

const mapDispatchToProps = (dispatch) => ({
	setSelectedInteractTimelineItem: (item) => dispatch(setSelectedInteractTimelineItem(item)),
	setInteractItemsToDelete: (items) => dispatch(setInteractItemsToDelete(items)),
	showInteractDeleteDialog: (_) => dispatch(showInteractDeleteDialog()),
	createInteractTimelineItem: (item, setScreenUnModified) =>
		dispatch(createInteractTimelineItem(item, setScreenUnModified)),
	createInteractTimelineGroup: (item) => dispatch(createInteractTimelineGroup(item)),
	showNotification: (message) => dispatch(showNotification(message)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InteractElementsList);
