import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import LivePlayer from '../../../components/LivePlayer/LivePlayer';
import { mediaNames } from '../../../constants/mediaConstants';
import {
	dropItemOnOverlay,
	setComponentStaticTrue,
	setComponentStaticFalse,
} from '../../../actions/interactElementsActions';
import { menuKeys } from './../../../constants/inspectScreenMenu';
import InteractEditorSVGOverlay from './InteractEditorSVGOverlay/InteractEditorSVGOverlay';
import { getChannelPreview } from '../../../services/channelService';

import './InteractTopPanelMiddleSection.scss';

class InteractTopPanelMiddleSection extends PureComponent {
	constructor(props) {
		super(props);
	}

	onPlayerCreated = () => {};

	onDrop = (event) => {
		event.stopPropagation();
		var data = JSON.parse(event.dataTransfer.getData('text'));
		this.props.dropItemOnOverlay(data);
	};

	allowDrop = (event) => {
		event.preventDefault();
	};

	_getChannelPreviewLink = (_) => {
		const { defaultAccountId, channelId } = this.props;

		const link = getChannelPreview(defaultAccountId, channelId);
		return link;
	};

	render() {
		const { mediaId, selectedLeftMenuKey, interactPreview, isOnLiveMode, showPlayer } = this.props;

		return (
			<>
				<div
					id="interactPlayerContainer"
					className="interactOverlayDropWrapper"
					onDragOver={(event) => this.allowDrop(event)}
					onDrop={(event) => this.onDrop(event)}
				>
					<InteractEditorSVGOverlay />
					{showPlayer &&
						(!isOnLiveMode ? (
							<LivePlayer
								configUrl={
									'https://video.qbrick.com/play2/api/v1/accounts/Accpy7KNHj1IUilFL5BMuNL0Q/configurations/interactive-manager'
								}
								onPlayerCreated={this.onPlayerCreated}
								isLive={false}
								entityId={mediaId}
								defaultAccountId={this.props.defaultAccountId}
								entityType={mediaNames.medias}
								widgetPrefix="interactPlayer"
								container="interactContainer"
								width="inherit"
								hidePlayButton={false}
								pause={selectedLeftMenuKey != menuKeys.interactiveManager || interactPreview}
								keepPreviousStateAfterSeeking={true}
								interactControls={true}
							/>
						) : (
							<LivePlayer
								configUrl={
									'https://video.qbrick.com/play2/api/v1/accounts/Accpy7KNHj1IUilFL5BMuNL0Q/configurations/live-manager'
								}
								onPlayerCreated={this.onPlayerCreated}
								isLive={true}
								entityId={mediaId}
								defaultAccountId={this.props.defaultAccountId}
								entityType="medias"
								widgetPrefix="interactPlayer"
								container="interactContainer"
								width="inherit"
								hidePlayButton={false}
								data={this._getChannelPreviewLink()}
								autoPlay={true}
								calledFromInteract={true}
								keepPreviousStateAfterSeeking={true}
								interactControls={true}
							/>
						))}
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, inspectScreenReducer, interactElementsReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	selectedLeftMenuKey: inspectScreenReducer.selectedLeftMenuKey,
	interactPreview: interactElementsReducer.interactPreview,
});

const mapDispatchToProps = (dispatch) => ({
	dropItemOnOverlay: (item) => dispatch(dropItemOnOverlay(item)),
	setComponentStaticTrue: () => dispatch(setComponentStaticTrue()),
	setComponentStaticFalse: () => dispatch(setComponentStaticFalse()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InteractTopPanelMiddleSection);
