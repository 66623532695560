import { Box, Button } from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import React from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import CardData from '../../../../../../../utils/CardData';
import { AdobeExpressUtils, EDITED_MEDIA_ID_PREFIX } from '../../../../../../../../PublishScreen/AdobeExpress/utils';
import useAEEditor from '../../../../../../../../../hooks/useAEEditor';
import { useDispatch, useSelector } from 'react-redux';
import { checkPermissionGrantedFor } from '../../../../../../../../../services/componentReusableService';
import { getMediaResourceUrl } from '../../../../../../../../../services/mediaDisplayService';
import { getVideoDurationBySecondFromUrl } from '../../../../../../../utils/helper';
import { updateAdobeExpressEditorModal } from '../../../../../../../../../actions/sessionActions';

type VideoProps = {
	widgetTemplate: { settings: any };
	uploadedFile: {};
	currentMediaDetail: any;
	uploadWidgetContent: () => {};
	onUploadFileClick: () => {};
	handleReturnedMedia: (_: any) => {};
};

const Video = ({
	uploadedFile,
	widgetTemplate,
	currentMediaDetail,
	uploadWidgetContent,
	onUploadFileClick,
	handleReturnedMedia,
}: VideoProps) => {
	const ccEverywhere = useSelector((state: any) => state.publish.ccEverywhere);
	const { userPermissions } = useSelector((state) => (state as any).session);
	const { uploadToPlatformCallback } = useAEEditor({
		afterJobFunc: ({ data, mediaId, mediaTitle }) => {
			dispatch(updateAdobeExpressEditorModal(false));

			if (!data || !mediaId || !mediaTitle) {
				return;
			}

			const { asset } = data;
			mediaId &&
				getVideoDurationBySecondFromUrl(asset[0].data).then((duration) => {
					handleReturnedMedia([{ id: mediaId, duration, metadata: { title: mediaTitle } }]);
				});
		},
	});
	const { t: translator } = useTranslation();
	const dispatch = useDispatch();

	const {
		settings: { mediaId },
	} = widgetTemplate;

	const isMediaExportedFromAEEditor = mediaId.startsWith(EDITED_MEDIA_ID_PREFIX);

	return (
		<CardData title={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT')}>
			<div className={'scene-widget__control'}>
				<Dropzone multiple={false} accept={'.mp4, .m4a, .m4v, .mp3, .mov, .wmv'} onDrop={uploadWidgetContent}>
					{({ getRootProps, getInputProps }) => (
						<div className="scene-widget__fileUpload--placeholder" onClick={onUploadFileClick}>
							<div {...getRootProps({ className: 'dropzone' })}>
								<input {...getInputProps()} />
							</div>
							{
								<img
									style={{
										height: '100%',
										width: '100%',
										display: uploadedFile ? 'block' : 'none',
									}}
									id="widget-thumbnail"
								/>
							}
							{!uploadedFile && <CloudUpload />}
						</div>
					)}
				</Dropzone>
				<Box display={'flex'} flexDirection={'column'}>
					<Button variant="contained" color="primary" onClick={onUploadFileClick}>
						{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_REPLACE_VIDEO')}
					</Button>
					{mediaId && checkPermissionGrantedFor(userPermissions, 'adobeExpress') && (
						<Button
							style={{ marginTop: 10 }}
							variant="contained"
							color="primary"
							onClick={() => {
								if (isMediaExportedFromAEEditor) {
									const projectId = AdobeExpressUtils.getAdobeExpressProjectIdFromMediaId(mediaId);
									projectId &&
										AdobeExpressUtils.editDesign({
											ccEverywhere,
											projectId,
											actionCallback: uploadToPlatformCallback,
											mediaName: currentMediaDetail?.metadata?.title,
										});
								} else {
									AdobeExpressUtils.createNewProject({
										ccEverywhere,
										actionCallback: uploadToPlatformCallback,
										dataUrl: getMediaResourceUrl(currentMediaDetail),
										mediaName: currentMediaDetail?.metadata?.title,
									});
								}
								dispatch(updateAdobeExpressEditorModal(true));
							}}
						>
							{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_EDIT_AND_REPLACE_VIDEO')}
						</Button>
					)}
				</Box>
			</div>
		</CardData>
	);
};

export default Video;
