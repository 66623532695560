import * as actions from '../actions/breadCrumbActions';

const initialState = {
	locationArray: [],
	screenName: '',
};
export const breadCrumbReducer = (state = initialState, action) => {
	let newObj = {
		...state,
	};
	switch (action.type) {
		case actions.ADD_NEW_PATH_TO_LOCATION_ARRAY: {
			let arr = JSON.parse(JSON.stringify(state.locationArray));
			arr.push(action.location);
			return {
				...state,
				locationArray: arr,
			};
		}
		case actions.UPDATE_LOCATION_ARRAY:
			return {
				...state,
				locationArray: JSON.parse(JSON.stringify(action.newArray)),
			};
		case actions.CLEAR_LOCATION_ARRAY:
			newObj.locationArray = [];
			newObj.screenName = '';
			return newObj;
		case actions.UPDATE_SCREEN_NAME: {
			let newLocation = JSON.parse(JSON.stringify(newObj.locationArray));
			if (newLocation.length > 0) {
				newLocation[newLocation.length - 1].text = action.name;
				newObj.locationArray = newLocation;
			}
			newObj.screenName = action.name;
			return newObj;
		}
		default:
			return state;
	}
};
