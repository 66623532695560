import { SET_SHOW_UPLOAD_MEDIAS_DIALOG } from '../actions/uploadingActions';

const initialState = {
	showUploadMediasDialog: false,
};

export const uploadingReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SHOW_UPLOAD_MEDIAS_DIALOG:
			return {
				...state,
				showUploadMediasDialog: action.showUploadMediasDialog,
			};
		default:
			return state;
	}
};
