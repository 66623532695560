import { LocalStorageService } from './localStorageService';
import { OauthService } from './oauthService';

export const TokenService = {
	timeOutStore: {},
	validateLocalToken() {
		let lastTokenReceived = LocalStorageService.getLastTokenReceived();
		let accessToken = LocalStorageService.getAccessToken();
		if (lastTokenReceived && accessToken) {
			this.checkTimeOutForToken();
		}
	},

	checkTimeOutForToken() {
		let currentTime = new Date().getTime();
		let lastTokenReceived = LocalStorageService.getLastTokenReceived();
		let secondsLapsed = (currentTime - lastTokenReceived) / 1000;
		let previousExpireTime = LocalStorageService.getExpiresIn();
		let newExpiresTime = 0;
		if (secondsLapsed > 0 && secondsLapsed < Number(previousExpireTime)) {
			if (previousExpireTime && previousExpireTime > secondsLapsed) {
				newExpiresTime = previousExpireTime - secondsLapsed;
				this.triggerTimeOutForNewToken(newExpiresTime);
				return;
			} else {
				this.tryForANewtoken();
			}
		}
	},

	triggerTimeOutForNewToken(time) {
		clearTimeout(this.timeOutStore);
		this.timeOutStore = setTimeout(
			(_) => this.tryForANewtoken(),
			(time - 60) * 1000
		);
	},

	saveTokenTime(expiresIn) {
		let now = new Date().getTime();
		localStorage.setItem('lastTokenReceived', now);
		TokenService.triggerTimeOutForNewToken(expiresIn);
	},

	tryForANewtoken() {
		this.requestNewToken();
	},

	requestNewToken() {
		OauthService.createIframe();
	},
};
