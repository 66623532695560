import * as actions from '../actions/navActions';
import { KEYS, getdefaultSelectedSubItems } from './../layout/nav/navigationItems';

const initialState = {
	open: false,
	selectedItemKey: KEYS.dashboard,
	selectedSubItemList: getdefaultSelectedSubItems(),
	showMainNavTexts: true,
	currentComponentKey: '',
};

export const navReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.DRAWER_OPEN:
			return {
				...state,
				open: true,
				showMainNavTexts: true,
			};
		case actions.DRAWER_CLOSE:
			return {
				...state,
				open: false,
				showMainNavTexts: false,
			};
		case actions.SHOW_MAIN_NAV_TEXTS:
			return {
				...state,
				showMainNavTexts: true,
			};
		case actions.HIDE_MAIN_NAV_TEXTS:
			return {
				...state,
				showMainNavTexts: false,
			};
		case actions.UPDATE_SELECTED_KEY:
			return {
				...state,
				selectedItemKey: action.key,
			};
		case actions.UPDATE_SUB_NAV_SELECTED_KEY: {
			let newState = JSON.parse(JSON.stringify(state));
			newState.selectedSubItemList[action.parent] = action.key;
			return {
				...state,
				selectedSubItemList: newState.selectedSubItemList,
			};
		}
		case actions.UPDATE_CURRENT_COMPONENT_KEY:
			return {
				...state,
				currentComponentKey: action.key,
			};
		default:
			return state;
	}
};
