import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ExpandLess from '@material-ui/icons/ExpandLess';

import './CardData.scss';

const CardData = (props) => {
	const { children, innerRef, box, className, title, activeBorder, noPadding, ...rest } = props;
	const [expanded, setExpanded] = useState(!!children);
	const toggleCardContent = () => {
		setExpanded((prevState) => !prevState);
	};

	return (
		<Card
			variant="outlined"
			{...rest}
			ref={innerRef}
			className={[
				'cardData__container',
				className,
				!!box ? 'cardData__container--box' : '',
				activeBorder ? 'activeBorder' : '',
				noPadding ? 'noPadding' : '',
			]
				.join(' ')
				.trim()}
		>
			{title && (
				<>
					<CardActions>
						<IconButton onClick={toggleCardContent}>
							{expanded ? <ExpandLess /> : <ExpandMore />}
						</IconButton>
						{title}
					</CardActions>
					<Divider />
					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<CardContent>{children}</CardContent>
					</Collapse>
				</>
			)}

			{!title && <CardContent>{children}</CardContent>}
		</Card>
	);
};

export default CardData;
