import { LocalStorageService } from '../services/localStorageService';
import { _closePoll, _createPoll, _deletePoll, _getPollResult, _getPolls, _publishPoll, _unpublishPoll, _updatePoll } from '../services/pollService';

export const CREATE_POLL = 'CREATE_POLL';
export const UPDATE_POLL = 'UPDATE_POLL';
export const PUBLISH_POLL = 'PUBLISH_POLL';
export const UNPUBLISH_POLL = 'UNPUBLISH_POLL';
export const CLOSE_POLL = 'CLOSE_POLL';
export const SHOW_CREATE_POLL_FORM = 'SHOW_CREATE_POLL_FORM';
export const SHOW_POLL_QUEUE = 'SHOW_POLL_QUEUE';
export const SHOW_NEW_POLL_IN_ARCHIVE = 'SHOW_NEW_POLL_IN_ARCHIVE';
export const SET_LOADING = 'SET_LOADING';
export const SET_ACTIVE_POLL = 'SET_ACTIVE_POLL';

export const getAccountId = (getState) => {
	if (getState) {
		return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

export const showCreatePollFormAction = (show) => ({
	type: SHOW_CREATE_POLL_FORM,
	showCreatePollForm: show,
});

export const showPollQueueAction = (show) => ({
	type: SHOW_POLL_QUEUE,
	showPollQueue: show,
});

export const showNewPollInArchiveAction = (show) => ({
	type: SHOW_NEW_POLL_IN_ARCHIVE,
	showNewPollInArchive: show,
});

export const setPollLoadingAction = (loading) => ({
	type: SET_LOADING,
	loading: loading,
});

export const setActivePoll = (activePoll) => ({
	type: SET_ACTIVE_POLL,
	activePoll: activePoll,
});

export const getPolls = (defaultAccountId, mediaId) => (dispatch, getState) => {
	const accountId = defaultAccountId || getAccountId(getState);
	return _getPolls(accountId, mediaId);
};

export const getPollResult = (defaultAccountId, mediaId, pollId) => (dispatch, getState) => {
	const accountId = defaultAccountId || getAccountId(getState);
	return _getPollResult(accountId, mediaId, pollId);
};

export const createPoll = (defaultAccountId, mediaId, body) => (dispatch, getState) => {
	const accountId = defaultAccountId || getAccountId(getState);
	return _createPoll(accountId, mediaId, body);
};

export const updatePoll = (defaultAccountId, mediaId, pollId, body) => (dispatch, getState) => {
	const accountId = defaultAccountId || getAccountId(getState);
	return _updatePoll(accountId, mediaId, pollId, body);
};

export const publishPoll = (defaultAccountId, mediaId, pollId) => (dispatch, getState) => {
	const accountId = defaultAccountId || getAccountId(getState);
	return _publishPoll(accountId, mediaId, pollId);
};

export const unpublishPoll = (defaultAccountId, mediaId, pollId) => (dispatch, getState) => {
	const accountId = defaultAccountId || getAccountId(getState);
	return _unpublishPoll(accountId, mediaId, pollId);
};

export const closePoll = (defaultAccountId, mediaId, pollId) => (dispatch, getState) => {
	const accountId = defaultAccountId || getAccountId(getState);
	return _closePoll(accountId, mediaId, pollId);
};

export const deletePoll = (defaultAccountId, mediaId, pollId) => (dispatch, getState) => {
	const accountId = defaultAccountId || getAccountId(getState);
	return _deletePoll(accountId, mediaId, pollId);
};
