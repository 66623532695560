import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import {
	listCustomProperties,
	removeCustomProperty,
	updateCustomProperties,
	createCustomProperties,
	profileNewCustomPropertyFormUpdate,
} from '../../../actions/profileActions';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import trashcan from '../../../assets/images/trashcan.svg';
import IconsStore from '../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../reusable/IconsStore/IconsList';

class CustomProperties extends Component {
	state = {
		customProperties: [],
		currentNameInEditor: '',
		currentNameInApi: '',
		currentType: 'string',
		currentInMedia: true,
		currentInCatalog: false,
		currentInPlaylist: false,
		currentIsRequired: false,
		currentMultiSelect: {},
		currentMultiSelectArray: [],
		nameInApiExists: false,
		showNewPropertyDialog: false,
		showEditPropertyDialog: false,
		currentlySelectedItem: '',
		currentlySelectedMultiSelectedItem: '',
		customPropertyToDelete: '',
		customPropertyToEdit: {},
		isCustomPropertyCreate: false,
		deleteCustomPropertyDialogStatus: false,
	};

	UNSAFE_componentWillMount() {
		this.listCustomPropertiesHandler(this.props.defaultAccountId);
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		return (
			nextProps.defaultAccountId !== this.props.defaultAccountId &&
			this.listCustomPropertiesHandler(nextProps.defaultAccountId)
		);
	}

	listCustomPropertiesHandler = (defaultAccountId) =>
		this.props.listCustomProperties(defaultAccountId).then((data) => {
			if (data) {
				this.setState({ customProperties: this.getCustomPropertiesArray(data) });
			}

			if (data === undefined) {
				this.setState({ isCustomPropertyCreate: true });
			}
		});

	getCustomPropertiesArray = (data) => {
		let result = [];

		let mediaProps;
		if (data.properties.media.properties == null) {
			mediaProps = [];
		} else {
			mediaProps = data.properties.media.properties;
		}
		let mediaProperties = [];
		for (var i in mediaProps) {
			mediaProperties.push([i, mediaProps[i]]);
		}

		let catalogProps;
		if (data.properties.catalog.properties == null) {
			catalogProps = [];
		} else {
			catalogProps = data.properties.catalog.properties;
		}
		let catalogProperties = [];
		for (let i in catalogProps) {
			catalogProperties.push([i, catalogProps[i]]);
		}

		let playlistProps;
		if (data.properties.playlist.properties == null) {
			playlistProps = [];
		} else {
			playlistProps = data.properties.playlist.properties;
		}
		let playlistProperties = [];
		for (let i in playlistProps) {
			playlistProperties.push([i, playlistProps[i]]);
		}

		let requiredMedia;
		if (data.properties.media.required == null) {
			requiredMedia = [];
		} else {
			requiredMedia = data.properties.media.required;
		}

		let requiredCatalog;
		if (data.properties.catalog.required == null) {
			requiredCatalog = [];
		} else {
			requiredCatalog = data.properties.catalog.required;
		}

		let requiredPlaylist;
		if (data.properties.playlist.required == null) {
			requiredPlaylist = [];
		} else {
			requiredPlaylist = data.properties.playlist.required;
		}

		let allRequired = [];
		let allReq = Object.assign(requiredMedia, requiredPlaylist, requiredCatalog);
		for (let i in allReq) {
			allRequired.push([i, allReq[i]]);
		}

		let allProperties = [];
		if (mediaProps != null || catalogProps != null || playlistProps != null) {
			let allProps = Object.assign(mediaProps, catalogProps, playlistProps);
			for (let i in allProps) {
				allProperties.push([i, allProps[i]]);
			}
		}

		for (let i = 0; i < allProperties.length; i++) {
			let checkArray = allProperties[i][1].enum;
			let tempObj;
			if (Array.isArray(checkArray) && checkArray.length) {
				let tempMultiSelectArray = allProperties[i][1].enum;
				let resultArray = [];
				let tempItem;
				tempMultiSelectArray.map((item) => {
					tempItem = { value: Object.keys(item)[0], name: item[Object.keys(item)[0]] };
					resultArray.push(tempItem);
				});
				tempObj = {
					order: i + 1,
					nameInEditor: allProperties[i][1].title,
					nameInApi: allProperties[i][0],
					type: allProperties[i][1].type,
					inMedia: mediaProperties.some((data) => data.includes(allProperties[i][0])),
					inCatalog: catalogProperties.some((data) => data.includes(allProperties[i][0])),
					inPlaylist: playlistProperties.some((data) => data.includes(allProperties[i][0])),
					isRequired: allRequired.some((data) => data.includes(allProperties[i][0])),
					multiSelect: resultArray,
				};
			} else {
				tempObj = {
					order: i + 1,
					nameInEditor: allProperties[i][1].title,
					nameInApi: allProperties[i][0],
					type: allProperties[i][1].type,
					inMedia: mediaProperties.some((data) => data.includes(allProperties[i][0])),
					inCatalog: catalogProperties.some((data) => data.includes(allProperties[i][0])),
					inPlaylist: playlistProperties.some((data) => data.includes(allProperties[i][0])),
					isRequired: allRequired.some((data) => data.includes(allProperties[i][0])),
				};
			}
			result.push(tempObj);
		}

		return result;
	};

	getCustomPropertiesJSON = (data) => {
		let catalogSchema;
		let catalogJSON = {};
		let catalogReq = [];
		for (var i = 0; i < data.length; i++) {
			if (data[i].inCatalog === true) {
				let tempObj;
				let checkArray = data[i].multiSelect;
				if (Array.isArray(checkArray) && checkArray.length) {
					let tempMultiSelectArray = data[i].multiSelect;
					let resultArray = [];
					let tempItem;
					tempMultiSelectArray.map((item) => {
						tempItem = { [item.value]: item.name };
						resultArray.push(tempItem);
					});
					tempObj = {
						type: data[i].type,
						description: data[i].nameInEditor,
						title: data[i].nameInEditor,
						enum: resultArray,
					};
				} else {
					tempObj = { type: data[i].type, description: data[i].nameInEditor, title: data[i].nameInEditor };
				}

				let tempresult = { [data[i].nameInApi]: tempObj };
				catalogJSON = Object.assign(catalogJSON, tempresult);

				if (data[i].isRequired === true) {
					let temp = data[i].nameInApi;
					catalogReq.push(temp);
				}
			}
		}

		let mediaSchema;
		let mediaJSON = {};
		let mediaReq = [];
		for (let i = 0; i < data.length; i++) {
			if (data[i].inMedia === true) {
				let tempObj;
				let checkArray = data[i].multiSelect;
				if (Array.isArray(checkArray) && checkArray.length) {
					let tempMultiSelectArray = data[i].multiSelect;
					let resultArray = [];
					let tempItem;
					tempMultiSelectArray.map((item) => {
						tempItem = { [item.value]: item.name };
						resultArray.push(tempItem);
					});
					tempObj = {
						type: data[i].type,
						description: data[i].nameInEditor,
						title: data[i].nameInEditor,
						enum: resultArray,
					};
				} else {
					tempObj = { type: data[i].type, description: data[i].nameInEditor, title: data[i].nameInEditor };
				}
				let tempresult = { [data[i].nameInApi]: tempObj };
				mediaJSON = Object.assign(mediaJSON, tempresult);

				if (data[i].isRequired === true) {
					let temp = data[i].nameInApi;
					mediaReq.push(temp);
				}
			}
		}
		let playlistSchema;
		let playlistJSON = {};
		let playlistReq = [];
		for (let i = 0; i < data.length; i++) {
			if (data[i].inPlaylist === true) {
				let tempObj;
				let checkArray = data[i].multiSelect;
				if (Array.isArray(checkArray) && checkArray.length) {
					let tempMultiSelectArray = data[i].multiSelect;
					let resultArray = [];
					let tempItem;
					tempMultiSelectArray.map((item) => {
						tempItem = { [item.value]: item.name };
						resultArray.push(tempItem);
					});
					tempObj = {
						type: data[i].type,
						description: data[i].nameInEditor,
						title: data[i].nameInEditor,
						enum: resultArray,
					};
				} else {
					tempObj = { type: data[i].type, description: data[i].nameInEditor, title: data[i].nameInEditor };
				}
				let tempresult = { [data[i].nameInApi]: tempObj };
				playlistJSON = Object.assign(playlistJSON, tempresult);

				if (data[i].isRequired === true) {
					let temp = data[i].nameInApi;
					playlistReq.push(temp);
				}
			}
		}

		if (Array.isArray(catalogReq) && catalogReq.length) {
			catalogSchema = {
				type: 'object',
				id: '#catalog',
				properties: catalogJSON,
				additionalProperties: false,
				required: catalogReq,
			};
		} else {
			catalogSchema = {
				type: 'object',
				id: '#catalog',
				properties: catalogJSON,
				additionalProperties: false,
			};
		}

		if (Array.isArray(mediaReq) && mediaReq.length) {
			mediaSchema = {
				type: 'object',
				id: '#media',
				properties: mediaJSON,
				additionalProperties: false,
				required: mediaReq,
			};
		} else {
			mediaSchema = {
				type: 'object',
				id: '#media',
				properties: mediaJSON,
				additionalProperties: false,
			};
		}

		if (Array.isArray(playlistReq) && playlistReq.length) {
			playlistSchema = {
				type: 'object',
				id: '#playlist',
				properties: playlistJSON,
				additionalProperties: false,
				required: playlistReq,
			};
		} else {
			playlistSchema = {
				type: 'object',
				id: '#playlist',
				properties: playlistJSON,
				additionalProperties: false,
			};
		}

		let metadataSchema = {
			$schema: 'http://json-schema.org/draft-04/schema#',
			id: '#root',
			type: 'object',
			properties: {
				catalog: catalogSchema,
				media: mediaSchema,
				playlist: playlistSchema,
			},
		};

		return metadataSchema;
	};

	showNewPropertyDialogBoxHandler = (_) => this.setState({ showNewPropertyDialog: true });

	showEditPropertyDialogBoxHandler = (data) =>
		this.setState({ showEditPropertyDialog: true, customPropertyToEdit: data });

	hideNewPropertyDialogHandler = (_) =>
		this.setState({ showNewPropertyDialog: false }, () => {
			this.resetCurrentCustomPropperties();
		});

	hideEditPropertyDialogHandler = (_) =>
		this.setState({ showEditPropertyDialog: false }, () => {
			this.resetCustomPropertyToEdit();
		});

	handleDeleteCustomPropertyDialogHandler = (nameInEditor, nameInApi) =>
		this.setState({
			customPropertyToDelete: nameInEditor,
			deleteCustomPropertyDialogStatus: true,
			currentlySelectedItem: nameInApi,
		});

	handleHideDeleteCustomPropertyDialogHandler = (_) =>
		this.setState({
			deleteCustomPropertyDialogStatus: false,
			currentlySelectedItem: '',
			customPropertyToDelete: '',
		});

	handleCustomPropertyDelete = (_) => {
		const { defaultAccountId } = this.props;
		const { customProperties, currentlySelectedItem } = this.state;
		let filtered = customProperties.filter((data) => data.nameInApi != currentlySelectedItem);
		this.setState({ customProperties: filtered, deleteCustomPropertyDialogStatus: false }, () => {
			this.props.updateCustomProperties(defaultAccountId, this.getCustomPropertiesJSON(filtered));
		});
	};

	handleCustomPropertyCreation = (_) => {
		const { defaultAccountId } = this.props;
		const {
			customProperties,
			currentNameInApi,
			currentNameInEditor,
			currentType,
			currentInMedia,
			currentInCatalog,
			currentInPlaylist,
			currentIsRequired,
			currentMultiSelectArray,
		} = this.state;
		const checkNameInApi = (obj) => obj.nameInApi === currentNameInApi;
		if (customProperties.some(checkNameInApi)) {
			this.setState({ nameInApiExists: true });
		} else {
			let result = customProperties;
			let tempObj;
			if (Array.isArray(currentMultiSelectArray) && currentMultiSelectArray.length) {
				tempObj = {
					order: customProperties.length + 1,
					nameInEditor: currentNameInEditor,
					nameInApi: currentNameInApi,
					type: currentType,
					inMedia: currentInMedia,
					inCatalog: currentInCatalog,
					inPlaylist: currentInPlaylist,
					isRequired: currentIsRequired,
					multiSelect: currentMultiSelectArray,
				};
			} else {
				tempObj = {
					order: customProperties.length + 1,
					nameInEditor: currentNameInEditor,
					nameInApi: currentNameInApi,
					type: currentType,
					inMedia: currentInMedia,
					inCatalog: currentInCatalog,
					inPlaylist: currentInPlaylist,
					isRequired: currentIsRequired,
				};
			}

			if (tempObj.inMedia === false && tempObj.inCatalog === false && tempObj.inPlaylist === false) {
				tempObj.inMedia = true;
			}
			result.push(tempObj);

			if (this.state.isCustomPropertyCreate) {
				this.setState({ showNewPropertyDialog: false, customProperties: result }, () => {
					this.props.createCustomProperties(defaultAccountId, this.getCustomPropertiesJSON(customProperties));

					this.resetCurrentCustomPropperties();
					this.setState({ isCustomPropertyCreate: false });
				});
			} else {
				this.setState({ showNewPropertyDialog: false, customProperties: result }, () => {
					this.props.updateCustomProperties(defaultAccountId, this.getCustomPropertiesJSON(customProperties));
					this.resetCurrentCustomPropperties();
				});
			}
		}
	};

	handleMultiSelectItemDelete = (value) => {
		const { currentMultiSelectArray } = this.state;
		let filtered = currentMultiSelectArray.filter((data) => data.value != value);
		this.setState({ currentMultiSelectArray: filtered });
	};

	handleMultiSelectItemOnEditDelete = (value) => {
		const { customPropertyToEdit } = this.state;
		let result = customPropertyToEdit;
		let tempObj = customPropertyToEdit.multiSelect;
		let filtered = tempObj.filter((data) => data.value != value);
		result.multiSelect = filtered;
		this.setState({ customPropertyToEdit: result });
	};

	handleNewCustomPropertyBoxInput = (field) => (event) =>
		this.props.updateNewCustomPropertyForm(field, event.target.value);
	handleNewCustomPropertyBoxInput = (field) => (event) =>
		this.props.updateNewCustomPropertyForm(field, event.target.value);

	handleNameInApiHandler = (event) => this.setState({ currentNameInApi: event.target.value });

	handleNameInEditorHandler = (event) => this.setState({ currentNameInEditor: event.target.value });

	handleTypeHandler = (event) => this.setState({ currentType: event.target.value });

	handleInMediaHandler = (field) => (event) => this.setState({ [field]: event.target.checked });

	handleInCatalogHandler = (field) => (event) => this.setState({ [field]: event.target.checked });

	handleInPlaylistHandler = (field) => (event) => this.setState({ [field]: event.target.checked });

	handleIsRequiredHandler = (field) => (event) => this.setState({ [field]: event.target.checked });

	handleMultiSelectValueHandler = (event) => {
		let tempObj = this.state.currentMultiSelect;
		tempObj.value = event.target.value;
		this.setState({ currentMultiSelect: tempObj });
	};

	handleMultiSelectNameHandler = (event) => {
		let tempObj = this.state.currentMultiSelect;
		tempObj.name = event.target.value;
		this.setState({ currentMultiSelect: tempObj });
	};

	handleEditTypeHandler = (event) => {
		let tempObj = this.state.customPropertyToEdit;
		tempObj.type = event.target.value;
		this.setState({ customPropertyToEdit: tempObj });
	};

	handleEditInMediaHandler = (event) => {
		let tempObj = this.state.customPropertyToEdit;
		tempObj.inMedia = event.target.checked;
		this.setState({ customPropertyToEdit: tempObj });
	};

	handleEditInCatalogHandler = (event) => {
		let tempObj = this.state.customPropertyToEdit;
		tempObj.inCatalog = event.target.checked;
		this.setState({ customPropertyToEdit: tempObj });
	};

	handleEditInPlaylistHandler = (event) => {
		let tempObj = this.state.customPropertyToEdit;
		tempObj.inPlaylist = event.target.checked;
		this.setState({ customPropertyToEdit: tempObj });
	};

	handleEditIsRequiredHandler = (event) => {
		let tempObj = this.state.customPropertyToEdit;
		tempObj.isRequired = event.target.checked;
		this.setState({ customPropertyToEdit: tempObj });
	};

	handleEditNameInEditorHandler = (event) => {
		let tempObj = this.state.customPropertyToEdit;
		tempObj.nameInEditor = event.target.value;
		this.setState({ customPropertyToEdit: tempObj });
	};

	handleEditNameInApiHandler = (event) => {
		let tempObj = this.state.customPropertyToEdit;
		tempObj.nameInApi = event.target.value;
		this.setState({ customPropertyToEdit: tempObj, currentNameInApi: event.target.value });
	};

	handleAddMultiSelectHandler = (_) => {
		const { currentMultiSelect, currentMultiSelectArray } = this.state;
		let result = currentMultiSelectArray;
		let tempObj = {
			value: currentMultiSelect.value,
			name: currentMultiSelect.name,
		};
		result.push(tempObj);
		this.setState({ currentMultiSelectArray: result }, () => {
			this.resetCurrentMultiSelect();
		});
	};

	handleAddMultiSelectOnEditHandler = () => {
		const { currentMultiSelect, customPropertyToEdit } = this.state;
		let result = { ...customPropertyToEdit };
		let resultArray = result.multiSelect || [];
		let tempObj = {
			value: currentMultiSelect.value,
			name: currentMultiSelect.name,
		};
		resultArray.push(tempObj);
		result.multiSelect = resultArray;
		this.setState({
			customPropertyToEdit: result,
			currentMultiSelect: { name: '', value: '' },
		});
	};

	handleCustomPropertyEdit = (_) => {
		const { defaultAccountId } = this.props;
		const { customProperties, customPropertyToEdit } = this.state;
		let filtered = customProperties.filter((data) => data.nameInApi != customPropertyToEdit.nameInApi);

		if (filtered.filter((e) => e.nameInApi === customPropertyToEdit.nameInApi) > 0) {
			this.setState({ nameInApiExists: true });
		} else {
			let tempObj = [customPropertyToEdit];
			let result = customProperties.map((item) => tempObj.find((data) => data.order === item.order) || item);
			//let result = _(customProperties).differenceBy(tempObj, 'order').concat(tempObj).value();

			this.setState({ showEditPropertyDialog: false, customProperties: result }, () => {
				this.props.updateCustomProperties(defaultAccountId, this.getCustomPropertiesJSON(result));
				this.resetCustomPropertyToEdit();
			});
		}
	};

	resetCustomPropertyToEdit = (_) => {
		this.setState({ customPropertyToEdit: {} });
	};

	resetCurrentMultiSelect = (_) => {
		let tempObj = {
			value: '',
			name: '',
		};
		this.setState({ currentMultiSelect: tempObj });
	};

	resetCurrentCustomPropperties = (_) => {
		let tempObj = {
			value: '',
			name: '',
		};
		this.setState({
			currentNameInEditor: '',
			currentNameInApi: '',
			currentType: 'string',
			currentInMedia: true,
			currentInCatalog: false,
			currentInPlaylist: false,
			currentIsRequired: false,
			currentMultiSelect: tempObj,
			currentMultiSelectArray: [],
			nameInApiExists: false,
		});
	};

	render() {
		const {
			customProperties,
			currentType,
			currentInMedia,
			currentInCatalog,
			currentInPlaylist,
			currentIsRequired,
			currentNameInEditor,
			currentNameInApi,
			currentMultiSelect,
			currentMultiSelectArray,
			nameInApiExists,
			customPropertyToDelete,
			customPropertyToEdit,
			showNewPropertyDialog,
			showEditPropertyDialog,
			deleteCustomPropertyDialogStatus,
		} = this.state;

		const { t } = this.props;

		return (
			<>
				<div className="customPropertiesContentWrapper">
					<div className="customPropertiesTopHeader">
						<h3>{t('SETTING_CUSTOM_PROPERTIES')}</h3>
						<Button
							variant="contained"
							className="newUserBtn defaultActionBtn"
							onClick={this.showNewPropertyDialogBoxHandler}
							startIcon={<IconsStore iconName={IconsList.PLUS_NEW} color="#fff" />}
						>
							{t('COMMON_NEW')}
						</Button>
					</div>
					<div className="customPropertiesTableHeading">
						<h4>
							{customProperties.length} {t('SETTING_CUSTOM_PROPERTIES_LOWERCASE')}
						</h4>
					</div>
					<div className="customPropertiesTableWrapper">
						<div className="customPropertiesTableRowCollection customPropertiesTableHeader">
							<div className="customPropertiesTableRow ">
								<div className="customPropertiesTableItem">
									<label>{t('SETTING_ORDER')}</label>
								</div>
								<div className="customPropertiesTableItem">
									<label>{t('COMMON_DESCRIPTION')}</label>
								</div>
								<div className="customPropertiesTableItem">
									<label>{t('SETTING_API_NAME')}</label>
								</div>
								<div className="customPropertiesTableItem">
									<label>{t('COMMON_TYPE')}</label>
								</div>
								<div className="customPropertiesTableItem">
									<label>{t('COMMON_DELETE')}</label>
								</div>
							</div>
						</div>
						<div className="customPropertiesTableRowCollection customPropertiesTableItems">
							{customProperties &&
								customProperties.map((eachSet, i) => {
									return (
										<div
											key={i}
											className="customPropertiesTableRow"
											onClick={() => this.showEditPropertyDialogBoxHandler(eachSet)}
										>
											<div className="customPropertiesTableItem" data-header={t('SETTING_ORDER')}>
												{eachSet.order}
											</div>
											<div
												className="customPropertiesTableItem"
												data-header={t('COMMON_DESCRIPTION')}
											>
												{eachSet.nameInEditor}
											</div>
											<div
												className="customPropertiesTableItem"
												data-header={t('SETTING_API_NAME')}
											>
												{eachSet.nameInApi}
											</div>
											<div className="customPropertiesTableItem" data-header={t('COMMON_TYPE')}>
												{eachSet.type === 'string'
													? 'Text'
													: eachSet.type === 'date'
													? 'Date'
													: eachSet.type === 'timestamp'
													? 'Date and time'
													: eachSet.type === 'number'
													? 'Number'
													: eachSet.type === 'boolean'
													? 'Boolean'
													: eachSet.type === 'multiselect'
													? 'Multi selection'
													: eachSet}
											</div>
											<div
												className="customPropertiesTableItem"
												data-header={t('COMMON_DELETED')}
											>
												<button
													onClick={(event) => {
														this.handleDeleteCustomPropertyDialogHandler(
															eachSet.nameInEditor,
															eachSet.nameInApi
														);
														event.stopPropagation();
													}}
												>
													<img className="headerIcon" src={trashcan} />
												</button>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</div>
				<Dialog
					open={showNewPropertyDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle className="dialogTitle" disableTypography>
						<Typography variant="h6">{t('SETTING_ADD_CUSTOM_PROPERTY')}</Typography>
						<IconButton onClick={this.hideNewPropertyDialogHandler}>
							<Close />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<div className="customPropertiesDialogBoxInternalBlock">
							<label>{t('COMMON_DESCRIPTION')}</label>
							<input
								type="text"
								className="genericTextInput"
								onChange={(event) => this.handleNameInEditorHandler(event)}
								value={currentNameInEditor}
								required
							/>
						</div>
						<div className="customPropertiesDialogBoxInternalBlock">
							<label>
								{t('SETTING_API_NAME')}
								<span className="alreadyExistsSpan">
									{nameInApiExists ? t('SETTING_ALREADY_EXISTS_IN_SCHEMA') : ''}
								</span>
							</label>
							<input
								type="text"
								className={nameInApiExists ? 'genericTextInput alreadyExistsInput' : 'genericTextInput'}
								onChange={(event) => this.handleNameInApiHandler(event)}
								value={currentNameInApi}
								required
							/>
						</div>

						<label className="customPropertiesDialogLabel">{t('COMMON_TYPE')}</label>
						<div className="dialogContentMidSectionWrapper">
							<div className="typeDropDownWrapper">
								<select
									className="typeDropDown"
									onChange={(event) => this.handleTypeHandler(event)}
									value={currentType}
								>
									<option value={'string'}>{'Text'}</option>
									<option value={'date'}>{'Date'}</option>
									<option value={'timestamp'}>{'Date and time'}</option>
									<option value={'number'}>{'Number'}</option>
									<option value={'boolean'}>{'Boolean'}</option>
									<option value={'multiselect'}>{'Multi selection'}</option>
								</select>
							</div>
							<div className="requiredCheckBoxWrapper">
								<FormControl>
									<FormGroup aria-label="position" row>
										<FormControlLabel
											value="required"
											control={
												<Checkbox
													color="primary"
													checked={currentIsRequired}
													onChange={this.handleIsRequiredHandler('currentIsRequired')}
													value="currentIsRequired"
												/>
											}
											label={
												<span className="customPropertiesLabel">
													{t('SETTING_MAKE_REQUIRED')}
												</span>
											}
											labelPlacement="end"
										/>
									</FormGroup>
								</FormControl>
							</div>
						</div>
						<div
							className={
								currentType === 'multiselect'
									? 'customPropertiesMultiSelectDialogBoxInternalBlock'
									: 'hideDiv'
							}
						>
							<label>{t('SETTING_ADD_TO_OPTIONS')}</label>
							<div className="customPropertiesMultiSelectInput">
								<label>{t('COMMON_VALUE')}:</label>
								<input
									type="text"
									className="genericTextInput"
									onChange={(event) => this.handleMultiSelectValueHandler(event)}
									value={currentMultiSelect.value}
									required
								/>
								<label>{t('COMMON_NAME')}:</label>
								<input
									type="text"
									className="genericTextInput"
									onChange={(event) => this.handleMultiSelectNameHandler(event)}
									value={currentMultiSelect.name}
									required
								/>
								<button variant="contained" onClick={this.handleAddMultiSelectHandler}>
									{t('COMMON_ADD')}
								</button>
							</div>
							{currentMultiSelectArray && currentMultiSelectArray.length > 0 ? (
								<label className="optionsLabel">{t('COMMON_OPTIONS')}</label>
							) : (
								''
							)}
							{currentMultiSelectArray &&
								currentMultiSelectArray.map((eachSet, i) => (
									<div key={i} className="multiSelectTableRow">
										<label>
											{t('COMMON_VALUE')}: {eachSet.value}
										</label>
										<label>
											{t('COMMON_NAME')}: {eachSet.name}
										</label>
										<div className="customPropertiesTableItem">
											<button
												className="deleteIconMultiSelect"
												onClick={(event) => {
													this.handleMultiSelectItemDelete(eachSet.value);
													event.stopPropagation();
												}}
											>
												<IconsStore
													width="10px"
													height="10px"
													color="red"
													iconName={IconsList.CLOSE}
												/>
											</button>
										</div>
									</div>
								))}
						</div>
						<FormControl component="fieldset">
							<FormLabel className="customPropertiesDialogLabel" component="legend">
								{t('SETTING_USE_WITH')}:
							</FormLabel>
							<FormGroup aria-label="position" row>
								<FormControlLabel
									value="start"
									control={
										<Checkbox
											color="primary"
											checked={currentInMedia}
											onChange={this.handleInMediaHandler('currentInMedia')}
											value="currentInMedia"
										/>
									}
									label={<span className="customPropertiesLabel">{t('COMMON_MEDIA')}</span>}
									labelPlacement="end"
								/>
								<FormControlLabel
									value="bottom"
									control={
										<Checkbox
											color="primary"
											checked={currentInCatalog}
											onChange={this.handleInCatalogHandler('currentInCatalog')}
											value="currentInCatalog"
										/>
									}
									label={
										<span className="customPropertiesLabel">{t('COMMON_MEDIA_TYPE_CATALOG')}</span>
									}
									labelPlacement="end"
								/>
								<FormControlLabel
									value="end"
									control={
										<Checkbox
											color="primary"
											checked={currentInPlaylist}
											onChange={this.handleInPlaylistHandler('currentInPlaylist')}
											value="currentInPlaylist"
										/>
									}
									label={
										<span className="customPropertiesLabel">{t('COMMON_MEDIA_TYPE_PLAYLIST')}</span>
									}
									labelPlacement="end"
								/>
							</FormGroup>
						</FormControl>
						<div className="customPropsDialogText">
							<p>{t('SETTING_CUSTOM_PROPERTIES_INFO')}</p>
						</div>
					</DialogContent>
					<DialogActions>
						<div className="dialogActionsWrapper">
							<div className="dialogBoxInternalBlock createNewUserBtnContentWrapper">
								<Button
									variant="contained"
									disabled={
										currentInMedia === false &&
										currentInCatalog === false &&
										currentInPlaylist === false
									}
									className="defaultActionBtn"
									onClick={this.handleCustomPropertyCreation}
								>
									{t('COMMON_SAVE')}
								</Button>
							</div>
						</div>
					</DialogActions>
				</Dialog>
				<Dialog
					open={showEditPropertyDialog}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle className="dialogTitle" disableTypography>
						<Typography variant="h6">{t('SETTING_EDIT_CUSTOM_PROPERTY')}</Typography>
						<IconButton onClick={this.hideEditPropertyDialogHandler}>
							<Close />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<div className="customPropertiesDialogBoxInternalBlock">
							<label>{t('COMMON_DESCRIPTION')}</label>
							<input
								type="text"
								className="genericTextInput"
								onChange={(event) => this.handleEditNameInEditorHandler(event)}
								value={customPropertyToEdit.nameInEditor}
								required
							/>
						</div>
						<div className="customPropertiesDialogBoxInternalBlock">
							<label>
								{t('SETTING_API_NAME')}
								<span className="alreadyExistsSpan">
									{nameInApiExists ? t('SETTING_ALREADY_EXISTS_IN_SCHEMA') : ''}
								</span>
							</label>
							<div className="editNameInApiProp">{customPropertyToEdit.nameInApi}</div>
						</div>

						<label className="customPropertiesDialogLabel">{t('COMMON_TYPE')}</label>
						<div className="dialogContentMidSectionWrapper">
							<div className="typeDropDownWrapper">
								<select
									className="typeDropDown"
									onChange={(event) => this.handleEditTypeHandler(event)}
									value={customPropertyToEdit.type}
								>
									<option value={'string'}>{'Text'}</option>
									<option value={'date'}>{'Date'}</option>
									<option value={'timestamp'}>{'Date and time'}</option>
									<option value={'number'}>{'Number'}</option>
									<option value={'boolean'}>{'Boolean'}</option>
									<option value={'multiselect'}>{'Multi selection'}</option>
								</select>
							</div>
							<div className="requiredCheckBoxWrapper">
								<FormControl>
									<FormGroup aria-label="position" row>
										<FormControlLabel
											value="required"
											control={
												<Checkbox
													color="primary"
													checked={customPropertyToEdit.isRequired}
													onChange={this.handleEditIsRequiredHandler}
													value="customPropertyToEdit.isRequired"
												/>
											}
											label={
												<span className="customPropertiesLabel">
													{t('SETTING_MAKE_REQUIRED')}
												</span>
											}
											labelPlacement="end"
										/>
									</FormGroup>
								</FormControl>
							</div>
						</div>
						<div
							className={
								customPropertyToEdit.type === 'multiselect'
									? 'customPropertiesMultiSelectDialogBoxInternalBlock'
									: 'hideDiv'
							}
						>
							<label>{t('SETTING_ADD_TO_OPTIONS')}</label>
							<div className="customPropertiesMultiSelectInput">
								<label>{t('COMMON_NAME')}:</label>
								<input
									type="text"
									className="genericTextInput"
									onChange={(event) => this.handleMultiSelectNameHandler(event)}
									value={currentMultiSelect.name}
									required
								/>
								<label>{t('COMMON_VALUE')}:</label>
								<input
									type="text"
									className="genericTextInput"
									onChange={(event) => this.handleMultiSelectValueHandler(event)}
									value={currentMultiSelect.value}
									required
								/>
								<button variant="contained" onClick={this.handleAddMultiSelectOnEditHandler}>
									{t('COMMON_ADD')}
								</button>
							</div>
							{customPropertyToEdit.multiSelect && customPropertyToEdit.multiSelect.length > 0 ? (
								<label className="optionsLabel">{t('COMMON_OPTIONS')}</label>
							) : (
								''
							)}
							{customPropertyToEdit.multiSelect &&
								customPropertyToEdit.multiSelect.map((eachSet, i) => (
									<div key={i} className="multiSelectTableRow">
										<label>
											{t('COMMON_NAME')}: {eachSet.name}
										</label>
										<label>
											{t('COMMON_VALUE')}: {eachSet.value}
										</label>
										<div className="customPropertiesTableItem">
											<button
												className="deleteIconMultiSelect"
												onClick={(event) => {
													this.handleMultiSelectItemOnEditDelete(eachSet.value);
													event.stopPropagation();
												}}
											>
												<IconsStore
													width="10px"
													height="10px"
													color="red"
													iconName={IconsList.CLOSE}
												/>
											</button>
										</div>
									</div>
								))}
						</div>
						<FormControl component="fieldset">
							<FormLabel className="customPropertiesDialogLabel" component="legend">
								{t('SETTING_USE_WITH')}:
							</FormLabel>
							<FormGroup aria-label="position" row>
								<FormControlLabel
									value="start"
									control={
										<Checkbox
											color="primary"
											checked={customPropertyToEdit.inMedia}
											onChange={this.handleEditInMediaHandler}
											value="customPropertyToEdit.inMedia"
										/>
									}
									label={<span className="customPropertiesLabel">{t('COMMON_MEDIA')}</span>}
									labelPlacement="end"
								/>
								<FormControlLabel
									value="bottom"
									control={
										<Checkbox
											color="primary"
											checked={customPropertyToEdit.inCatalog}
											onChange={this.handleEditInCatalogHandler}
											value="customPropertyToEdit.inCatalog"
										/>
									}
									label={
										<span className="customPropertiesLabel">{t('COMMON_MEDIA_TYPE_CATALOG')}</span>
									}
									labelPlacement="end"
								/>
								<FormControlLabel
									value="end"
									control={
										<Checkbox
											color="primary"
											checked={customPropertyToEdit.inPlaylist}
											onChange={this.handleEditInPlaylistHandler}
											value="customPropertyToEdit.inPlaylist"
										/>
									}
									label={
										<span className="customPropertiesLabel">{t('COMMON_MEDIA_TYPE_PLAYLIST')}</span>
									}
									labelPlacement="end"
								/>
							</FormGroup>
						</FormControl>
						<div className="customPropsDialogText">
							<p>{t('SETTING_CUSTOM_PROPERTIES_INFO')}</p>
						</div>
					</DialogContent>
					<DialogActions>
						<div className="dialogActionsWrapper">
							<div className="dialogBoxInternalBlock createNewUserBtnContentWrapper">
								<Button
									variant="contained"
									disabled={
										customPropertyToEdit.inMedia === false &&
										customPropertyToEdit.inCatalog === false &&
										customPropertyToEdit.inPlaylist === false
									}
									className="defaultActionBtn"
									onClick={this.handleCustomPropertyEdit}
								>
									{t('COMMON_SAVE_CHANGES')}
								</Button>
							</div>
						</div>
					</DialogActions>
				</Dialog>
				<Dialog
					open={deleteCustomPropertyDialogStatus}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle className="dialogTitle" disableTypography>
						<Typography variant="h6">
							{t('SETTING_LABEL_ARE_YOU_SURE_TO_DELETE_CUSTOM_PROPERTY')}
						</Typography>
						<IconButton onClick={this.handleHideDeleteCustomPropertyDialogHandler}>
							<Close />
						</IconButton>
					</DialogTitle>
					<DialogContent>
						<div className="dialogBoxInternalBlock createNewUserBtnContentWrapper">
							{<label>{customPropertyToDelete}</label>}
						</div>
					</DialogContent>
					<DialogActions>
						<div className="dialogActionsWrapper">
							<div className="dialogBoxInternalBlock deleteUserBtnContentWrapper">
								<Button
									variant="contained"
									className="defaultActionBtn neutralBtn"
									onClick={this.handleHideDeleteCustomPropertyDialog}
								>
									{t('COMMON_BACK')}
								</Button>
								<Button
									variant="contained"
									className="defaultActionBtn deleteButtonWithIcon"
									onClick={this.handleCustomPropertyDelete}
								>
									<IconsStore iconName={IconsList.TRASHCAN} />
									{t('COMMON_DELETE')}
								</Button>
							</div>
						</div>
					</DialogActions>
				</Dialog>
			</>
		);
	}
}

const mapStateToProps = ({ session }) => ({
	loading: session.loading,
	defaultAccountId: session.defaultAccountId,
	customMetadata: session.customMetadata ? JSON.parse(session.customMetadata) : '',
});

const mapDispatchToProps = (dispatch) => ({
	listCustomProperties: (accountId) => dispatch(listCustomProperties(accountId)),
	updateCustomProperties: (accountId, data) => dispatch(updateCustomProperties(accountId, data)),
	createCustomProperties: (accountId, data) => dispatch(createCustomProperties(accountId, data)),
	removeCustomProperty: (accountId, nameInApi) => dispatch(removeCustomProperty(accountId, nameInApi)),
	updateNewCustomPropertyForm: (field, value) => dispatch(profileNewCustomPropertyFormUpdate(field, value)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(CustomProperties);
