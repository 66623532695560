import { workflowTypes } from '../constants/workflowTypes';
import { workflowEndpoint, ipOnlyEndpointV2 } from '../utils/config';
import BackendClient from './backendClient';

export const _createLiveMediaFromChannel = (accountId, body) =>
	BackendClient.post(`${workflowEndpoint}accounts/${accountId}/jobs`, body, false);

export const _createLegacyLiveMedia = (accountId, body) =>
	BackendClient.post(
		`${workflowEndpoint}accounts/${accountId}/jobs`,
		{
			workflow: {
				id: workflowTypes.CREATE_LEGACY_LIVE_EVENT_FROM_CHANNEL.ID,
			},
			input: body,
		},
		false
	);

export const _startLiveMedia = (accountId, body) =>
	BackendClient.post(`${workflowEndpoint}accounts/${accountId}/jobs`, body, false);

export const _stopLiveMedia = (accountId, body) =>
	BackendClient.post(`${workflowEndpoint}accounts/${accountId}/jobs`, body, false);

export const _createSnapshot = (accountId, extractVod, body) =>
	BackendClient.post(`${workflowEndpoint}accounts/${accountId}/jobs`, body, false);

export const _createIPOnlyChannel = (accountId, body) =>
	BackendClient.post(`${workflowEndpoint}accounts/${accountId}/jobs`, body, false);

export const _deleteIPOnlyChannel = (accountId, body) =>
	BackendClient.post(`${workflowEndpoint}accounts/${accountId}/jobs`, body, false);

export const _sendEmailNotification = (accountId, body) =>
	BackendClient.post(`${workflowEndpoint}accounts/${accountId}/jobs`, body, false);

export const _registerNewJob = (accountId, body) =>
	BackendClient.post(`${workflowEndpoint}accounts/${accountId}/jobs`, body, false);

export const _uploadFileToUrl = (url, xCallbackUrl, body) =>
	BackendClient.postUploadSubtitle(`${url}`, body, true, xCallbackUrl);

export const _getPublishedStreams = (accountId, applicationId) =>
	BackendClient.get(
		`${ipOnlyEndpointV2}live-ingest/accounts/${accountId}/applications/${applicationId}/publishedstreams`
	);
