import React, { memo, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { screenRoutes } from './../../../constants/routesPath';
import { NavigationItems } from '../../../layout/nav/navigationItems';
import './Sidebar.scss';
import { TreeView, TreeItem } from '@material-ui/lab';
import IconsStore from '../../../reusable/IconsStore/IconsStore';
import { IconsList } from '../../../reusable/IconsStore/IconsList';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const Sidebar = ({}) => {
	const { t } = useTranslation();
	let history = useHistory();
	let location = useLocation();
	const [navActive, setNavActive] = useState(false);

	// menu list
	const menuItems = NavigationItems.getAnalyticItems(t);

	// TODO: get the array list from menu list Object.
	// check acive child item.
	const cdn5 = [
		screenRoutes.ANALYTICS_OPERATING_SYSTEMS,
		screenRoutes.ANALYTICS_BROWSERS,
		screenRoutes.ANALYTICS_BROWSERS_TYPE,
		screenRoutes.ANALYTICS_DEVICE_TYPE,
		screenRoutes.ANALYTICS_PROTOCOL,
	];
	const cdn6 = [screenRoutes.ANALYTICS_CITIES, screenRoutes.ANALYTICS_COUNTRIES, screenRoutes.ANALYTICS_REGIONS];

	let subSelected = [];
	let expandedItems = ['playItem', 'cdnItem'];
	if (cdn5.includes(location?.pathname)) {
		expandedItems.push('cdn5');
		subSelected = ['cdnItem', 'cdn5'];
	} else if (cdn6.includes(location?.pathname)) {
		expandedItems.push('cdn6');
		subSelected = ['cdnItem', 'cdn6'];
	}
	const subHasPlayItem = menuItems[0].children.some((item) => item.key === location?.pathname);
	const subHasCdnItem = menuItems[1].children.some((item) => item.key === location?.pathname);
	const mediaanalytics = location?.pathname.includes('mediaanalytics');
	if (subHasPlayItem || mediaanalytics) {
		subSelected = ['playItem'];
	} else if (subHasCdnItem) {
		subSelected = ['cdnItem'];
	}

	// Switch Screens
	const switchScreenFunc = (selectedNewKey) => {
		selectedNewKey ? history.push(selectedNewKey) : history.push(screenRoutes.ANALYTICS);
	};

	const renderLabel = (item) => (
		<Typography onClick={() => switchScreenFunc(item.key)} component="div" className="listItemLable">
			{item.text}
		</Typography>
	);

	const renderList = (items) => {
		const nestedList = (items) =>
			items.map((item) => (
				<TreeItem
					key={item.id}
					nodeId={item.id}
					label={item?.children?.length > 0 ? item.text : renderLabel(item)}
					className={
						(subSelected.includes(item.id) ? ' activechild ' : '') +
						(location?.pathname.includes(item.key) ||
						(mediaanalytics && item.key === screenRoutes.ANALYTICS_VIDEO_PERFORMANCE)
							? 'listItem selectedItem'
							: 'listItem') +
						(item?.children?.length > 0 ? ' hasChild' : '')
					}
				>
					{item?.children?.length > 0 && nestedList(item.children, true)}
				</TreeItem>
			));

		return (
			<TreeView
				className="analitycsTree"
				defaultCollapseIcon={<IconsStore iconName={IconsList.EXPANDED} width={'10px'} height={'10px'} />}
				defaultExpandIcon={<IconsStore iconName={IconsList.NOT_EXPANDED} width={'10px'} height={'10px'} />}
				defaultExpanded={expandedItems}
			>
				{nestedList(items)}
			</TreeView>
		);
	};

	return (
		<>
			<span className="navBtn">
				<IconButton
					aria-label="menu"
					color="inherit"
					size="small"
					onClick={() => {
						setNavActive(!navActive);
					}}
				>
					<MenuIcon />
				</IconButton>
			</span>

			<div className={`analyticsSideBar ${navActive ? 'active' : ''}`}>{renderList(menuItems)}</div>
		</>
	);
};

export default memo(Sidebar);
