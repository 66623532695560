import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { showNotification } from '../../actions/notificationActions';
import {
	checkIfItemIsOverlapping,
	isTimeBeyondRange,
	getGroupItemsSortedByStartTime,
	getIteracItemEndDate,
} from '../../services/interactScreenHelperService';
import { createInteractTimelineItem, updateInteractTimelineItem } from '../../actions/interactTimelineActions';
import _ from 'underscore';
import { generateId } from '../../services/stringHelperService';
import { getDeepCopyOfObject } from '../../services/objectsHelperService';
import {
	getTimelineStartDate,
	isDateBetweenTwoDates,
	returnDecreasedTime,
	getSecondsFromTimelineTime,
	getTimelineTimeFromSeconds,
	returnAddedTime,
} from '../../services/timeStampService';
import { sequenceTypes } from '../../constants/interactConstant';
class InteractItemCopyPaste extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			lastItemCopied: '',
		};
	}

	UNSAFE_componentWillMount = () => {
		window.addEventListener('keydown', this.handleCopyPaste, false);
		//  window.addEventListener('copy', this.handleCopy)
		//  window.addEventListener('paste', this.handlePaste)
	};

	componentWillUnmount() {
		//   window.removeEventListener('copy', this.handleCopy)
		//  window.removeEventListener('paste', this.handlePaste)
		window.removeEventListener('keydown', this.handleCopyPaste, false);
	}

	handleCopyPaste = (e) => {
		const { selectedInteractItems, t, interactItems } = this.props;
		const { lastItemCopied } = this.state;
		e = e || window.event;
		var key = e.which || e.keyCode; // keyCode detection
		var ctrl = e.ctrlKey ? e.ctrlKey : e.metaKey ? e.metaKey : key === 17 || key === 91 ? true : false; // ctrl detection
		if (this.props.bottomPanelRef && this.props.bottomPanelRef.state.isTimelineFocused) {
			if (key == 86 && ctrl) {
				//To Do check the functionality on MAC
				this.duplicateInteractItem(lastItemCopied);
			} else if (key == 67 && ctrl) {
				//To Do check the functionality on MAC
				if (selectedInteractItems.length > 0) {
					let videoItem = _.find(interactItems, (item) => {
						return item.itemType == sequenceTypes.video && selectedInteractItems[0] === item.id;
					});
					if (!videoItem) {
						this.setLastItemCopied(selectedInteractItems[0]);
						this.props.showNotification(t('COPIED_INTERACT_ITEM'));
					} else {
						this.resetLastItemCopied();
					}
				}
			}
		} else {
			this.resetLastItemCopied();
		}
	};

	setLastItemCopied = (id) => {
		this.setState({
			lastItemCopied: id,
		});
	};

	resetLastItemCopied = () => {
		this.setState({
			lastItemCopied: '',
		});
	};

	getCustomTimeState = () => {
		if (this.props.bottomPanelRef) {
			return this.props.bottomPanelRef.state.customTimes.interactPointer;
		}
		return getTimelineStartDate();
	};

	duplicateInteractItem = (itemId) => {
		const { mediaId, interactItems, mediaDetails, t } = this.props;
		let foundItem = '';
		if (!itemId) {
			return;
		}

		if (itemId !== mediaId) {
			foundItem = _.find(interactItems, (item) => {
				return item.id === itemId;
			});
		}
		if (foundItem) {
			let itemStartDate = this.getCustomTimeState();
			let itemEndDate = getIteracItemEndDate(itemStartDate, this);
			let item = getDeepCopyOfObject(foundItem); // deep copies an item
			/* let item = { //only duplicates item
                 ...foundItem
             }*/
			item.id = generateId();
			item.start = itemStartDate;
			item.end = itemEndDate;

			if (checkIfItemIsOverlapping(this, item, 'noMessage')) {
				//Todo write a single code to handle dropping of items and duplicating items
				let sortedItems = getGroupItemsSortedByStartTime(interactItems, foundItem.group);
				let prevItemEndDate = '';
				let nextItemStartDate = '';
				for (var i = 0; i < sortedItems.length; i++) {
					if (sortedItems[i].id !== mediaId) {
						if (
							isDateBetweenTwoDates(this.getCustomTimeState(), sortedItems[i].start, sortedItems[i].end)
						) {
							item.start = new Date(sortedItems[i].end);
							item.start = returnAddedTime(item.start, 1, 'ms');
							//item.start.setMilliseconds(item.start.getMilliseconds() + 1)
							item.end = getIteracItemEndDate(item.start, this);
							if (!checkIfItemIsOverlapping(this, item)) {
								break;
							}
						}
						if (sortedItems[i].start > this.getCustomTimeState()) {
							if (i > 0) {
								if (this.getCustomTimeState() < sortedItems[i - 1].end) {
									prevItemEndDate = new Date(sortedItems[i - 1].end);
									prevItemEndDate = returnAddedTime(prevItemEndDate, 1, 'ms');
									//prevItemEndDate.setMilliseconds(prevItemEndDate.getMilliseconds() + 1)
								} else {
									prevItemEndDate = this.getCustomTimeState();
								}
							}
							nextItemStartDate = new Date(sortedItems[i].start);
							nextItemStartDate = returnDecreasedTime(nextItemStartDate, 1, 'ms');
							//nextItemStartDate.setMilliseconds(nextItemStartDate.getMilliseconds() - 1)
							break;
						}
					}
				}
				if (prevItemEndDate && nextItemStartDate) {
					item.start = prevItemEndDate;
					item.end = nextItemStartDate;
				}
			}
			if (!checkIfItemIsOverlapping(this, item) && isTimeBeyondRange(this, item, 'noMessage')) {
				let positionToMove = getSecondsFromTimelineTime(item.end);
				if (positionToMove > mediaDetails.duration) {
					let startPos = getSecondsFromTimelineTime(item.start);
					if (positionToMove > startPos) {
						item.end = getTimelineTimeFromSeconds(positionToMove);
						item.end = returnDecreasedTime(item.end, 1, 'ms');
					}
				}
			}
			if (checkIfItemIsOverlapping(this, item) || isTimeBeyondRange(this, item)) {
				return;
			}
			this.props.createInteractTimelineItem(item);
			this.props.showNotification(t('DUPLICATED_ITEM_SUCCESSFULLY'));
		} else {
			this.props.showNotification(t('UNABLE_TO_FIND_COPIED_ITEM'));
		}
	};

	render() {
		return <></>;
	}
}

const mapStateToProps = ({ session, interactTimelineReducer, inspectScreenReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	isInteractScreenModified: interactTimelineReducer.isInteractScreenModified,
	mediaDetails: inspectScreenReducer.mediaOriginalDetails,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
});

const mapDispatchToProps = (dispatch) => ({
	showNotification: (message) => dispatch(showNotification(message)),
	createInteractTimelineItem: (item) => dispatch(createInteractTimelineItem(item)),
	updateInteractTimelineItem: (item) => dispatch(updateInteractTimelineItem(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InteractItemCopyPaste);
