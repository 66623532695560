import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Mosaic, MosaicWindow } from 'react-mosaic-component';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import LivePlayerContainer from '../../../components/LiveManagerTopPanel/LivePlayerContainer/LivePlayerContainer';
import LiveManagerTopHeader from '../../../components/LiveManagerTopPanel/LiveManagerTopHeader/LiveManagerTopHeader';
import LiveManagerBottomPanel from './../../../components/LiveManagerBottomPanel/LiveManagerBottomPanel';
import LiveManagerSettings from '../../../components/LiveManagerSettings/LiveManagerSettings';
import {
	clearLiveManagerStore,
	setLiveSettingsId,
	setSaveAsOnDemandOption,
	updateLiveChannel,
} from '../../../actions/liveManagerActions';
import { getPublishedStreams } from '../../../actions/workflowActions';
import { resetSnapShotSettings } from '../../../actions/snapshotSettingsActions';
import { getMediaQueryDetails } from '../../../actions/publishActions';
import { loadChannel } from '../../../actions/channelActions';
import { liveManagerPopOutWindowList, MIN_SNAPSHOT_TIME } from '../../../constants/liveManagerConstants';
import SmallLivePlayer from './../../../components/LiveManagerTopPanel/SmallLivePlayer/SmallLivePlayer';
import { liveEventStatusList } from '../../../constants/liveEventStatus';
import LiveManagerInteractSave from './../../../components/LiveManagerSettings/LiveManagerInteractQueue/LiveManagerInteractSave';
import { liveSettingsList } from '../../../constants/liveManagerConstants';
import { getTimeDifferenceInSeconds } from '../../../services/timeStampService';
import zoomInIcon from '../../../assets/images/zoom_in.svg';
import zoomOutIcon from '../../../assets/images/zoom_out.svg';
import startPointIcon from '../../../assets/images/square_start_bracket.svg';
import endPointIcon from '../../../assets/images/square_end_bracket.svg';
import current_time from '../../../assets/images/time.svg';
import { mediaNames, mediaTypes } from '../../../constants/mediaConstants';

import 'react-mosaic-component/react-mosaic-component.css';
import './../../../components/LiveManagerTopPanel/LiveManagerTopPanel.scss';
import './LiveManagerScreen.scss';

class LiveManagerScreen extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isStreamPublished: false,
			currentNode: {
				direction: 'row',
				first: {
					direction: 'column',
					first: liveManagerPopOutWindowList.PLAYER,
					second: liveManagerPopOutWindowList.TIMELINE,
					splitPercentage: 50,
				},
				second: liveManagerPopOutWindowList.SETTINGS,
				splitPercentage: 67,
			},
		};
		this.liveInteractSave = null;
		this.playerContainer = null;
		this.bottomPanellRef = null;
		this.intervalId = null;
		this.isPrevCheckDone = true;

		//For checking window being idle
		this.timeBeforeReload = 0;
	}

	renderTiles = (index) => {
		switch (index) {
			//case liveManagerPopOutWindowList.STREAMSETTINGS: return this.renderTopPanel()
			case liveManagerPopOutWindowList.PLAYER:
				return this.renderPlayerContainer();
			case liveManagerPopOutWindowList.TIMELINE:
				if (!this.props.moderatorMode) {
					return this.renderBottomPanel();
				}
				return;
			case liveManagerPopOutWindowList.SETTINGS:
				return this.renderSettingsContainer();
		}
	};

	UNSAFE_componentWillMount = () => {
		this.props.resetSnapShotSettings();
		this.props.clearLiveManagerStore();
	};

	componentWillUnmount() {
		this.props.resetSnapShotSettings();
		this.props.clearLiveManagerStore();
		if (this.intervalId) {
			clearInterval(this.intervalId);
		}
		this.clearVisibilityEvent();
	}

	onSaveLoaded = (liveInteractSave) => {
		this.liveInteractSave = liveInteractSave;
	};

	componentDidMount() {
		this.getMediaChannel();
		let path = window.location.href.split('/liveManager/');
		if (path.length > 1) {
			path = path[1].split('?');
			this.currentView = path[0];
			let header = document.getElementById('mainHeader');
			if (header) {
				header.classList.add('hide');
			}
		}

		if (this.intervalId) {
			clearInterval(this.intervalId);
		}

		if (this.props.liveEventStatus !== liveEventStatusList.INITIAL) {
			this.setState({ isStreamPublished: true });
		} else {
			this.intervalId = setInterval(this.checkPublishedStream, 3000);
		}

		this.initWatchingTabActiveEvent();

		if (this.props.moderatorMode) {
			this.setState({
				currentNode: {
					direction: 'row',
					first: liveManagerPopOutWindowList.PLAYER,
					second: liveManagerPopOutWindowList.SETTINGS,
					splitPercentage: 70,
				},
			});
			this.props.setLiveSettingsId(liveSettingsList.CHAT);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { defaultAccountId, mediaId, liveEventStatus, liveChannel } = this.props;
		if (liveEventStatus !== liveEventStatusList.INITIAL && this.intervalId) {
			clearInterval(this.intervalId);
			this.setState({ isStreamPublished: true });
		}

		if (liveEventStatus === liveEventStatusList.INITIAL) {
			if (
				this.props.mediaId !== prevProps.mediaId ||
				this.props.defaultAccountId !== prevProps.defaultAccountId ||
				prevProps.liveChannel?.id !== liveChannel?.id
			) {
				this.getMediaChannel();
			}

			if (
				prevProps.liveChannel !== liveChannel ||
				this.state.isStreamPublished !== prevState.isStreamPublished ||
				prevProps.defaultAccountId !== defaultAccountId ||
				prevProps.mediaId !== mediaId
			) {
				if (this.intervalId) {
					clearInterval(this.intervalId);
				}
				this.isPrevCheckDone = true;
				this.intervalId = setInterval(this.checkPublishedStream, 3000);
			}
		}
	}

	onVisibilitychangeHandler = () => {
		const { t } = this.props;
		if (document.hidden) {
			this.timeBeforeReload = Date.now();
		} else {
			const currentTime = Date.now() - this.timeBeforeReload;
			// 5 mins
			if (currentTime >= 300000) {
				if (!alert(t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_IDLE_PROMPT'))) {
					this.timeBeforeReload = Date.now();
					window.location.reload();
				}
			}
		}
	};

	initWatchingTabActiveEvent = () => {
		document.addEventListener('visibilitychange', this.onVisibilitychangeHandler);
	};

	clearVisibilityEvent = () => {
		document.removeEventListener('visibilitychange', this.onVisibilitychangeHandler);
	};

	onPlayerContainerLoaded = (playerContainer) => {
		this.playerContainer = playerContainer;
	};

	onBottomPanellRef = (bottomPanellRef) => {
		this.bottomPanellRef = bottomPanellRef;
	};

	renderPlayerContainer = (showPopOut) => {
		const { mediaId, mediaDetails, isVisible, liveChannel, t, moderatorMode } = this.props;
		const { isStreamPublished } = this.state;
		const showPlayer = this.props.showPlayer || window.location.href.includes('selectedMenu=liveManager');

		return (
			<>
				<LivePlayerContainer
					onPlayerContainerLoaded={this.onPlayerContainerLoaded}
					t={t}
					liveComponentPopOut={this.liveComponentPopOut}
					showPopOut={showPopOut}
					isVisible={isVisible}
					channelId={liveChannel?.id}
					mediaId={mediaId}
					mediaDetails={mediaDetails}
					showPlayer={showPlayer}
					isStreamPublished={isStreamPublished}
					moderatorMode={moderatorMode}
				/>
			</>
		);
	};

	renderSettingsContainer = (showPopOut) => {
		const { mediaId, mediaDetails, isVisible, t, showMessage, SaveAsOnDemand, reloadData, moderatorMode } =
			this.props;
		const SaveAsOnDemandFromData =
			mediaDetails.custom && mediaDetails.custom.livemanager && mediaDetails.custom.livemanager.SaveAsOnDemand;
		this.props.setSaveAsOnDemandOption(SaveAsOnDemandFromData ?? SaveAsOnDemand);
		return (
			<>
				<LiveManagerSettings
					t={t}
					mediaId={mediaId}
					isVisible={isVisible}
					showPopOut={showPopOut}
					liveComponentPopOut={this.liveComponentPopOut}
					showMessage={showMessage}
					liveInteractSave={this.liveInteractSave}
					SaveAsOnDemand={SaveAsOnDemandFromData ?? SaveAsOnDemand}
					mediaDetails={mediaDetails}
					reloadData={reloadData}
					defaultEmails={this.props.defaultEmails}
					moderatorMode={moderatorMode}
				></LiveManagerSettings>
			</>
		);
	};

	renderBottomPanel = (showPopOut) => {
		const { mediaId, mediaDetails, liveChannel, showMessage, t } = this.props;
		const showPlayer = this.props.showPlayer || window.location.href.includes('selectedMenu=liveManager');
		return (
			<LiveManagerBottomPanel
				t={t}
				channelId={liveChannel?.id}
				mediaId={mediaId}
				mediaDetails={mediaDetails}
				bottomPanellRef={this.onBottomPanellRef}
				showPopOut={showPopOut}
				showMessage={showMessage}
				showPlayer={showPlayer}
			/>
		);
	};

	renderSmallPlayer = () => {
		const { mediaId, isVisible, liveEventStatus, t } = this.props;
		if (liveEventStatus === liveEventStatusList.STARTED) {
			return (
				<>
					<SmallLivePlayer isVisible={isVisible} mediaId={mediaId} t={t} />
				</>
			);
		} else {
			return null;
		}
	};

	getTimelineActionButtons = () => {
		const { liveEventStatus, isPlayerAtLivePosition, liveStreamPositionTime, t } = this.props;
		//const { inpoint } = this.state
		return (
			<>
				<div className="LiveManagerBottomPanelContainer" onClick={this.bottomPanellRef.setTimeLineFocus}>
					<label className="liveManagerSemiboldHeaders">
						{' '}
						{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_TIMELINE')}{' '}
					</label>
					<div className="timelineActionButtons">
						<label className="snapshotText">{`${t(
							'MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_SNAPSHOT'
						)}:`}</label>
						<Button
							disabled={
								liveEventStatus === liveEventStatusList.INITIAL ||
								(liveEventStatus === liveEventStatusList.STARTED && !this.bottomPanellRef.showTimeline)
									? true
									: false
							}
							className="in-point"
							onClick={() => this.bottomPanellRef.handleInpointClick()}
						>
							<img src={startPointIcon} className="in-point-img"></img>
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_IN')}
						</Button>
						<Button
							disabled={
								liveEventStatus === liveEventStatusList.INITIAL ||
								(liveEventStatus === liveEventStatusList.STARTED && !this.bottomPanellRef.inpoint) ||
								(this.bottomPanellRef.inpoint &&
									getTimeDifferenceInSeconds(liveStreamPositionTime, this.bottomPanellRef.inpoint) <
										MIN_SNAPSHOT_TIME)
									? true
									: false
							}
							className="out-point"
							onClick={() => this.bottomPanellRef.handleOutpointClick()}
						>
							<img src={endPointIcon} className="out-point-img"></img>
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_OUT')}
						</Button>
						<Button
							disabled={
								liveEventStatus !== liveEventStatusList.STARTED ||
								(liveEventStatus === liveEventStatusList.STARTED && isPlayerAtLivePosition)
									? true
									: false
							}
							className="live-time"
							onClick={() => this.bottomPanellRef.handleSetToLiveTime()}
						>
							<img src={current_time} className="live-time-img"></img>
							{t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_JUMP_TO_LIVE')}
						</Button>
					</div>

					<div className="zoomOptions">
						<div className="zoom" onClick={(e) => this.bottomPanellRef.handleZoomInClick(e)}>
							<img src={zoomInIcon} />
						</div>
						<div className="zoom" onClick={(e) => this.bottomPanellRef.handleZoomOutClick(e)}>
							<img src={zoomOutIcon} />
						</div>
					</div>
				</div>
			</>
		);
	};

	renderHeaders = (index) => {
		const { selectedItem, liveSettingsId, timelineItems, snapshotPlayStarted, t, moderatorMode } = this.props;
		let item = '';
		if (liveSettingsId === liveSettingsList.SNAPSHOT) {
			item = _.find(timelineItems ? timelineItems : this.props.timelineItems, (item) => {
				return item.id === selectedItem;
			});
		}

		switch (index) {
			case liveManagerPopOutWindowList.PLAYER:
				return (
					snapshotPlayStarted &&
					item &&
					item.content && (
						<label className="bigLivePlayerLiveText liveManagerSemiboldHeaders">{item.content}</label>
					)
				);
			case liveManagerPopOutWindowList.TIMELINE:
				return <>{this.bottomPanellRef && this.getTimelineActionButtons()}</>;
			case liveManagerPopOutWindowList.SETTINGS:
				return (
					<label className="liveManagerSemiboldHeaders">
						{!moderatorMode && t('MEDIA_LIBRARY_MEDIA_INSPECT_PAGE_LIVE_MANAGER_SETTINGS_MENU')}
					</label>
				);
		}
	};

	onChange = (currentNode) => {
		this.setState({ currentNode });
		localStorage.setItem('liveManagerPanel', JSON.stringify(currentNode));
	};

	onRelease = (currentNode) => {
		this.setState({ currentNode });
		localStorage.setItem('liveManagerPanel', JSON.stringify(currentNode));
	};

	onDragEnd = () => {};

	getMediaChannel = () => {
		const { mediaId, defaultAccountId, liveChannel } = this.props;
		this.props.getMediaQueryDetails(defaultAccountId, mediaId, mediaNames.medias).then((media) => {
			if (!media) {
				this.isPrevCheckDone = true;
				return;
			}
			let channel = liveChannel;

			if (
				!channel &&
				media.hasOwnProperty('asset') &&
				media.asset &&
				media.asset.hasOwnProperty('resources') &&
				media.asset.resources &&
				media.asset.resources.length > 0
			) {
				const livevideo = media.asset.resources.find((r) => r.type === mediaTypes.livevideo);
				if (!livevideo || !livevideo.renditions) {
					return;
				}

				const renditions = livevideo.renditions ? livevideo.renditions : [];
				if (!renditions || renditions.length === 0) {
					return;
				}

				const rendition = renditions.find((r) => r.type === mediaTypes.livevideo);
				if (!rendition) {
					return;
				}
				channel = rendition.channel;
			}

			if (!channel) {
				return;
			}

			this.props.loadChannel(defaultAccountId, channel.id).then((data) => {
				this.props.updateLiveChannel(data);
			});
		});
	};

	checkPublishedStream = () => {
		const { defaultAccountId, getPublishedStreams, liveChannel } = this.props;
		if (!this.isPrevCheckDone) {
			return;
		}
		this.isPrevCheckDone = false;

		if (!liveChannel) {
			this.isPrevCheckDone = true;
			this.getMediaChannel();
			return;
		}

		getPublishedStreams(defaultAccountId, liveChannel.liveOrigin?.applicationId ?? 'liveorigin1')
			.then((data) => {
				if (!data || !data.hasOwnProperty('streams')) {
					this.isPrevCheckDone = true;
					return;
				}

				const streams = data.streams;
				if (!streams || streams.length === 0) {
					this.setState({ isStreamPublished: false }, () => (this.isPrevCheckDone = true));
					return;
				}

				this.setState(
					(prevState) => {
						const publishedStreams = streams.filter(
							(s) =>
								s.hasOwnProperty('streamName') && s.streamName.includes(liveChannel.id) && s.isConnected
						);
						const isStreamPublished = publishedStreams && publishedStreams.length > 0;
						if (isStreamPublished !== prevState.isStreamPublished) {
							return { isStreamPublished: isStreamPublished };
						}
						return null;
					},
					() => (this.isPrevCheckDone = true)
				);
			})
			.catch(() => (this.isPrevCheckDone = true));
	};

	render() {
		const { t, liveManagerLoaded, mediaId, mediaDetails, isVisible, moderatorMode } = this.props;
		const { isStreamPublished } = this.state;
		return (
			<>
				{!moderatorMode && (
					<LiveManagerInteractSave
						showMessage={this.props.showMessage}
						onRef={this.onSaveLoaded}
						mediaId={mediaId}
						t={t}
					/>
				)}
				{/*loading && <Spinner />*/}
				{!moderatorMode && (
					<LiveManagerTopHeader
						t={t}
						mediaDetails={mediaDetails}
						mediaId={mediaId}
						isVisible={isVisible}
						isStreamPublished={isStreamPublished}
						defaultEmails={this.props.defaultEmails}
					/>
				)}

				{liveManagerLoaded && this.renderSmallPlayer()}
				<div
					className={`LiveManagerScreenWrapper ${moderatorMode ? 'moderatorMode' : ''}`}
					id="LiveManagerScreenWrapper"
				>
					{liveManagerLoaded && (
						<Mosaic
							renderTile={(count, path) => (
								<MosaicWindow
									title={this.renderHeaders(count)}
									renderToolbar={() => (
										<div className="customToolbar">{this.renderHeaders(count)}</div>
									)}
									path={path}
									onDragEnd={(type) => this.onDragEnd(type)}
								>
									{this.renderTiles(count)}
								</MosaicWindow>
							)}
							value={this.state.currentNode}
							onChange={this.onChange}
							onRelease={this.onRelease}
						/>
					)}
				</div>
			</>
		);
	}
}

LiveManagerScreen.propTypes = {
	mediaId: PropTypes.string.isRequired,
	isVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ liveManager, tracksReducer, snapshotSettings, inspectScreenReducer }) => ({
	liveEventStatus: liveManager.liveEventStatus,
	isPlayerAtLivePosition: liveManager.isPlayerAtLivePosition,
	loading: tracksReducer.loading,
	liveSettingsId: liveManager.liveSettingsId,
	timelineItems: liveManager.items,
	selectedItem: liveManager.selectedItem,
	snapshotPlayStarted: snapshotSettings.snapshotPlayStarted,
	liveStreamPositionTime: liveManager.liveStreamPositionTime,
	SaveAsOnDemand: liveManager.SaveAsOnDemand,
	liveChannel: liveManager.liveChannel,
	mediaOriginalDetails: inspectScreenReducer.mediaOriginalDetails,
});

const mapDispatchToProps = (dispatch) => ({
	clearLiveManagerStore: (_) => dispatch(clearLiveManagerStore()),
	resetSnapShotSettings: () => dispatch(resetSnapShotSettings()),
	setSaveAsOnDemandOption: (boolStatus) => dispatch(setSaveAsOnDemandOption(boolStatus)),
	getPublishedStreams: (accountId, applicationId) => dispatch(getPublishedStreams(accountId, applicationId)),
	getMediaQueryDetails: (accId, mediaId, mediaName) => dispatch(getMediaQueryDetails(accId, mediaId, mediaName)),
	loadChannel: (accId, channelId) => dispatch(loadChannel(accId, channelId)),
	updateLiveChannel: (liveChannel) => dispatch(updateLiveChannel(liveChannel)),
	setLiveSettingsId: (id) => dispatch(setLiveSettingsId(id)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(LiveManagerScreen);
