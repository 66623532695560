import { _getOTP, _postOTP, _deleteOTP } from './../services/otpService'

export const SEND_OTP_GET_REQUEST = "SEND_OTP_GET_REQUEST";
export const SEND_OTP_GET_SUCCESS = "SEND_OTP_GET_SUCCESS";
export const SEND_OTP_GET_FAILURE = "SEND_OTP_GET_FAILURE";

export const SEND_OTP_POST_REQUEST = "SEND_OTP_POST_REQUEST";
export const SEND_OTP_POST_SUCCESS = "SEND_OTP_POST_SUCCESS";
export const SEND_OTP_POST_FAILURE = "SEND_OTP_POST_FAILURE";

export const SEND_OTP_DELETE_REQUEST = "SEND_OTP_DELETE_REQUEST";
export const SEND_OTP_DELETE_SUCCESS = "SEND_OTP_DELETE_SUCCESS";
export const SEND_OTP_DELETE_FAILURE = "SEND_OTP_DELETE_FAILURE";

export const SET_APPS = "SET_APPS";
export const TOGGLE_FULLSCREEN_MODE = "TOGGLE_FULLSCREEN_MODE";
export const SET_SELECTED_APP = "SET_SELECTED_APP";

const sendOTPGetRequest = _ => ({
    type: SEND_OTP_GET_REQUEST
});
const sendOTPGetSuccess = data => ({
    type: SEND_OTP_GET_SUCCESS,
    data
});
const sendOTPGetFailure = _ => ({
    type: SEND_OTP_GET_FAILURE
});
const sendOTPPostRequest = _ => ({
    type: SEND_OTP_POST_REQUEST
});
const sendOTPPostSuccess = data => ({
    type: SEND_OTP_POST_SUCCESS,
    data
});
const sendOTPPostFailure = _ => ({
    type: SEND_OTP_POST_FAILURE
});
const sendOTPDeleteRequest = _ => ({
    type: SEND_OTP_DELETE_REQUEST
});
const sendOTPDeleteSuccess = data => ({
    type: SEND_OTP_DELETE_SUCCESS,
    data
});
const sendOTPDeleteFailure = _ => ({
    type: SEND_OTP_DELETE_FAILURE
});

export const postOTP = (accountId, mediaId, code) => (dispatch) => {
    dispatch(sendOTPPostRequest());
    return _postOTP(accountId, mediaId, code).then(data => {
        dispatch(sendOTPPostSuccess());
        //dispatch(setApps(data))
        return data
    })
        .catch(error => {
            dispatch(sendOTPPostFailure());
            return error
        })
};

export const getOTP = (otp) => (dispatch) => {
    dispatch(sendOTPGetRequest());
    return _getOTP(otp).then(data => {
        dispatch(sendOTPGetSuccess());
        //dispatch(setApps(data))
        return data
    })
        .catch(error => {
            dispatch(sendOTPGetFailure());
            return error
        })
};

export const deleteOTP = (otp) => (dispatch) => {
    dispatch(sendOTPDeleteRequest());
    return _deleteOTP(otp).then(data => {
        dispatch(sendOTPDeleteSuccess());
        return data
    })
        .catch(error => {
            dispatch(sendOTPDeleteFailure());
            return error
        })
};