import * as actions from "../actions/notificationActions"

const initialState = {
    message: '',
    showNotification: false,
    itemRemovedNotification: false
};

export const notificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SHOW_NOTIFICATION:
            return {
                ...state,
                showNotification: true,
                message: action.message
            };
        case actions.HIDE_NOTIFICATION:
            return {
                ...state,
                showNotification: false
            };
        case actions.ITEM_REMOVED_NOTIFIED:
            return {
                ...state,
                itemRemovedNotification: true
            };
        default:
            return state
    }
};


