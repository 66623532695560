import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Layout from './Layout';
import FilterByDates from './FilterByDates';
import SectionLayout from './SectionLayout';
import Grid from '@material-ui/core/Grid';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { _optionsPIE } from './../../../utils/commonUtil';
import { useSelector } from 'react-redux';
import { getTechnologyStatsByDimension } from './../../../services/analyticsServices';
import ReuseTable from './ReuseTable';
import { osColumns } from './../../../constants/analyticsConstant';

const Technology = ({ dimension, titleSec1, titleSec2, column }) => {
	const accountId = useSelector((state) => state.session.defaultAccountId);
	const [endDate, setEndDate] = useState(moment().valueOf());
	const [startDate, setStartDate] = useState(moment().subtract(7, 'days').valueOf());
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const getFilterData = (sDate, eDate) => {
		setStartDate(sDate);
		setEndDate(eDate);
	};
	useEffect(async () => {
		setLoading(true);
		const responce = await getTechnologyStatsByDimension(accountId, startDate, endDate, dimension);
		if (responce) {
			setData(responce);
			setLoading(false);
		}
	}, [startDate, endDate, accountId]);

	osColumns[0]['label'] = column;

	return (
		<Layout title="ANALYTICS_TITLE_CDN_ANALYTICS">
			<FilterByDates getFilterData={getFilterData} />
			<SectionLayout title={titleSec1} addClass="tech" loading={loading}>
				<Grid item xs={12}>
					<HighchartsReact
						constructorType={'chart'}
						highcharts={Highcharts}
						options={_optionsPIE(data, 'pie')}
					/>
				</Grid>
			</SectionLayout>
			<SectionLayout title={titleSec2} addClass="volumePerformance" loading={loading}>
				<ReuseTable columns={osColumns} data={data[0]?.data} />
			</SectionLayout>
		</Layout>
	);
};

export default Technology;
