import * as actions from '../actions/pollActions';
import _ from 'underscore';

const initialState = {
	showCreatePollForm: false,
	showPollQueue: false,
	showNewPollInArchive: false,
	loading: false,
	activePoll: false,
};

export const pollReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SHOW_CREATE_POLL_FORM: {
			return {
				...state,
				showCreatePollForm: action.showCreatePollForm,
			};
		}
		case actions.SHOW_POLL_QUEUE: {
			return {
				...state,
				showPollQueue: action.showPollQueue,
			};
		}
		case actions.SHOW_NEW_POLL_IN_ARCHIVE: {
			return {
				...state,
				showNewPollInArchive: action.showNewPollInArchive,
			};
		}
		case actions.SET_ACTIVE_POLL: {
			return {
				...state,
				activePoll: action.activePoll,
			};
		}
		case actions.SET_LOADING: {
			return {
				...state,
				loading: action.loading,
			};
		}
		default:
			return state;
	}
};
