import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './../InteractEditorTopPanel/InteractElementProperties/InteractElementProperties.scss';
import rewind from '../../../assets/images/rewind.svg';
import loop from '../../../assets/images/loop.svg';
import {
	updateInteractTimelineItem,
	setSelectedInteractTimelineItem,
	setPlayerPosition,
	startInteractMediaLoop,
	stopInteractMediaLoop,
} from '../../../actions/interactTimelineActions';
import Button from '@material-ui/core/Button';
import _ from 'underscore';

class InteractMediaControls extends PureComponent {
	constructor(props) {
		super(props);
	}

	componentWillUnmount() {}

	componentDidMount = () => {};

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps.selectedInteractItems) !== JSON.stringify(this.props.selectedInteractItems)) {
			this.props.stopInteractMediaLoop();
		}
	}

	getCurrentlyEditedItem = () => {
		const { interactItems, selectedInteractItems } = this.props;
		if (selectedInteractItems && selectedInteractItems.length > 0) {
			let itemId = selectedInteractItems[0];
			let item = _.find(interactItems, (item) => {
				return item.id === itemId;
			});
			if (item) {
				return item;
			}
		}
		return '';
	};

	rewindToInteractTime = () => {
		let item = this.getCurrentlyEditedItem();
		if (item) {
			this.props.setPlayerPosition(item.start);
		}
	};

	loopPlayer = () => {
		this.getCurrentlyEditedItem();
		this.props.startInteractMediaLoop();
		this.rewindToInteractTime();
	};

	handleClick = () => {
		if (!this.props.interacMediaLoopStarted) {
			this.loopPlayer();
		} else {
			this.props.stopInteractMediaLoop();
		}
	};

	render() {
		const { interacMediaLoopStarted } = this.props;
		return (
			<>
				<div className="InteractMediaControlsSection">
					<div className="interactTime">
						<label className="inlineLabel"> Review</label>
						<div className="InteractMediaControls snapShotMediaControls">
							<Button
								variant="contained"
								className="defaultActionBtn rewind"
								onClick={this.rewindToInteractTime}
							>
								<img src={rewind} />
								Rewind
							</Button>
							<Button
								variant="contained"
								className="defaultActionBtn loopback"
								onClick={this.handleClick}
							>
								<img className={interacMediaLoopStarted ? 'rotate' : ' '} src={loop} /> Loop
							</Button>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, interactTimelineReducer, inspectScreenReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	interactGroups: interactTimelineReducer.groups,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
	mediaDetails: inspectScreenReducer.mediaOriginalDetails,
	interacMediaLoopStarted: interactTimelineReducer.interacMediaLoopStarted,
});

const mapDispatchToProps = (dispatch) => ({
	updateInteractTimelineItem: (item) => dispatch(updateInteractTimelineItem(item)),
	setSelectedInteractTimelineItem: (item) => dispatch(setSelectedInteractTimelineItem(item)),
	setPlayerPosition: (datetime) => dispatch(setPlayerPosition(datetime)),
	startInteractMediaLoop: (_) => dispatch(startInteractMediaLoop()),
	stopInteractMediaLoop: (_) => dispatch(stopInteractMediaLoop()),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InteractMediaControls);
