import {
    _getPermission,
    _addPermission,
    _updatePermission,
    _deletePermission
} from "../services/permissionService"

export const SEND_ADD_PERMISSION_REQUEST = "SEND_ADD_PERMISSION_REQUEST";
export const SEND_ADD_PERMISSION_SUCCESS = "SEND_ADD_PERMISSION_SUCCESS";
export const SEND_ADD_PERMISSION_FAILURE = "SEND_ADD_PERMISSION_FAILURE";

export const SEND_UPDATE_PERMISSION_REQUEST = "SEND_UPDATE_PERMISSION_REQUEST";
export const SEND_UPDATE_PERMISSION_SUCCESS = "SEND_UPDATE_PERMISSION_SUCCESS";
export const SEND_UPDATE_PERMISSION_FAILURE = "SEND_UPDATE_PERMISSION_FAILURE";

export const SEND_GET_PERMISSION_REQUEST = "SEND_GET_PERMISSION_REQUEST";
export const SEND_GET_PERMISSION_SUCCESS = "SEND_GET_PERMISSION_SUCCESS";
export const SEND_GET_PERMISSION_FAILURE = "SEND_GET_PERMISSION_FAILURE";

export const SEND_DELETE_PERMISSION_REQUEST = "SEND_DELETE_PERMISSION_REQUEST";
export const SEND_DELETE_PERMISSION_SUCCESS = "SEND_DELETE_PERMISSION_SUCCESS";
export const SEND_DELETE_PERMISSION_FAILURE = "SEND_DELETE_PERMISSION_FAILURE";

const addPermissionRequest = _ => ({
    type: SEND_ADD_PERMISSION_REQUEST
});

const addPermissionRequestSuccess = _ => ({
    type: SEND_ADD_PERMISSION_SUCCESS
});

const addPermissionRequestFailure = _ => ({
    type: SEND_ADD_PERMISSION_FAILURE
});

const getPermissionRequest = _ => ({
    type: SEND_GET_PERMISSION_REQUEST
});

const getPermissionRequestSuccess = _ => ({
    type: SEND_GET_PERMISSION_SUCCESS
});

const getPermissionRequestFailure = _ => ({
    type: SEND_GET_PERMISSION_FAILURE
});

const updatePermissionRequest = _ => ({
    type: SEND_UPDATE_PERMISSION_REQUEST
});

const updatePermissionRequestSuccess = _ => ({
    type: SEND_UPDATE_PERMISSION_SUCCESS
});

const updatePermissionRequestFailure = _ => ({
    type: SEND_UPDATE_PERMISSION_FAILURE
});
const deletePermissionRequest = _ => ({
    type: SEND_DELETE_PERMISSION_REQUEST
});

const deletePermissionRequestSuccess = _ => ({
    type: SEND_DELETE_PERMISSION_SUCCESS
});

const deletePermissionRequestFailure = _ => ({
    type: SEND_DELETE_PERMISSION_FAILURE
});

export const addPermission = (accountId, userId, data) => (dispatch) => {
    dispatch(addPermissionRequest());
    return _addPermission(accountId, userId, { permissions: data })
        .then(data => {
            dispatch(addPermissionRequestSuccess());
            return data
        })
        .catch(error => {
            dispatch(addPermissionRequestFailure());
            return error
        })
};

export const updatePermission = (accountId, userId, data) => (dispatch) => {
    dispatch(updatePermissionRequest());
    return _updatePermission(accountId, userId, { permissions: data })
        .then(data => {
            dispatch(updatePermissionRequestSuccess());
            return data
        })
        .catch(error => {
            dispatch(updatePermissionRequestFailure());
            return error
        })
};

export const getPermission = (accountId, userId) => (dispatch) => {
    dispatch(getPermissionRequest());
    return _getPermission(accountId, userId)
        .then(data => {
            dispatch(getPermissionRequestSuccess());
            return data
        })
        .catch(error => {
            dispatch(getPermissionRequestFailure());
            return error
        })
};

export const deletePermission = (accountId, userId, featureId) => (dispatch) => {
    dispatch(deletePermissionRequest());
    return _deletePermission(accountId, userId, featureId)
        .then(data => {
            dispatch(deletePermissionRequestSuccess());
            return data
        })
        .catch(error => {
            dispatch(deletePermissionRequestFailure());
            return error
        })
};
