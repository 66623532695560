import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import IconButton from '@material-ui/core/IconButton';
import { Clear } from '@material-ui/icons';
import {
	Divider,
	FormControl,
	FormHelperText,
	Input,
	InputAdornment,
	MenuItem,
	OutlinedInput,
	Select,
	Typography,
} from '@material-ui/core';

import ColorPicker from '../../../../../../../../../../reusable/ColorPicker/ColorPicker';
import CustomSlider from '../../../../../../../../../../reusable/Slider/CustomSlider';
import { TextRadiusKeys, transformKeys } from '../../../../../../../../../../constants/scenarioConstant';
import { defaultRadius } from '../../../../../../../../utils/helper';
import { capitalizeFirstLetter } from '../../../../../../../../../../services/stringHelperService';
import { joinClassNames } from '../../../../../../../../../../services/elementHelperService';
import CardData from '../../../../../../../../utils/CardData';

import TextStylingModal from './TextStylingModal';

interface Map {
	[key: string]: string | undefined;
}

interface TextProps {
	widgetTemplate: {
		style: {
			textAlignment?: string;
			contentSpacing?: string;
			headerLabel?: {
				text: string;
				font: string;
				fontSize: string;
				fontWeight: string;
				lineHeight: string;
				color: string;
				shadowColor: string;
			};
			textLabel?: {
				text: string;
				font: string;
				fontSize: string;
				fontWeight: string;
				lineHeight: string;
				color: string;
				shadowColor: string;
			};
			backgroundSettings?: {
				backgroundColor: string;
				borderType: string;
				borderWidth: string;
				borderColor: string;
				hoverBackgroundColor: string;
				hoverBorderType: string;
				hoverBorderWidth: string;
				hoverBorderColor: string;
			};
			transformSettings?: Map;
		};
	};
	isAtHoverSetting: boolean;
	setHoverSetting: (_value: boolean) => {};
	handleStylingAttributeChange: (_objKey: string | null, _attrKey: string, _value: any) => {};
}

const SLIDER_OPTION_MARKS = [
	{ value: 0, label: '0px' },
	{ value: 20 },
	{ value: 40 },
	{ value: 60 },
	{ value: 80 },
	{ value: 100 },
	{ value: 120 },
	{ value: 140 },
	{ value: 160 },
	{ value: 180 },
	{ value: 200, label: '200px' },
];

const BORDER_TYPES = ['Solid', 'Dashed', 'Dotted'];

const Text = ({ widgetTemplate, isAtHoverSetting, setHoverSetting, handleStylingAttributeChange }: TextProps) => {
	const { t: translator } = useTranslation();

	const [headerStylingModalAnchor, setHeaderStylingModalAnchor] = useState<{} | null>(null);
	const [paragraphStylingModalAnchor, setParagraphStylingModalAnchor] = useState<{} | null>(null);
	const [isSameAllBorderRadius, setChangeAllBorderRadius] = useState(true);
	const [isSameAllPaddings, setChangeAllPaddings] = useState(true);

	const {
		style: {
			textAlignment,
			contentSpacing,
			headerLabel: {
				text: headerText,
				font: headerFont,
				fontSize: headerFontSize,
				fontWeight: headerFontWeight,
				lineHeight: headerLineHeight,
				color: headerColor,
				shadowColor: headerShadowColor,
			} = {},
			textLabel: {
				text: paragraphText,
				font: paragraphFont,
				fontSize: paragraphFontSize,
				fontWeight: paragraphFontWeight,
				lineHeight: paragraphLineHeight,
				color: paragraphColor,
				shadowColor: paragraphShadowColor,
			} = {},
			backgroundSettings: {
				backgroundColor,
				borderType,
				borderWidth,
				borderColor,
				hoverBackgroundColor,
				hoverBorderType,
				hoverBorderWidth,
				hoverBorderColor,
			} = {},
			transformSettings = {
				paddingBottom: defaultRadius,
				paddingLeft: defaultRadius,
				paddingRight: defaultRadius,
				paddingTop: defaultRadius,
				radiusBottom: defaultRadius,
				radiusLeft: defaultRadius,
				radiusRight: defaultRadius,
				radiusTop: defaultRadius,
				hoverRadiusBottom: defaultRadius,
				hoverRadiusLeft: defaultRadius,
				hoverRadiusRight: defaultRadius,
				hoverRadiusTop: defaultRadius,
			},
		},
	} = widgetTemplate;

	const handleBorderRadiusChange = (key: string, value: string) => {
		if (!isSameAllBorderRadius) {
			handleStylingAttributeChange(
				`transformSettings`,
				isAtHoverSetting ? `hover${capitalizeFirstLetter(key)}` : `${key}`,
				value
			);
			return;
		}

		handleStylingAttributeChange(`transformSettings`, isAtHoverSetting ? 'hoverRadiusTop' : 'radiusTop', value);
		handleStylingAttributeChange(`transformSettings`, isAtHoverSetting ? 'hoverRadiusRight' : 'radiusRight', value);
		handleStylingAttributeChange(`transformSettings`, isAtHoverSetting ? 'hoverRadiusLeft' : 'radiusLeft', value);
		handleStylingAttributeChange(
			`transformSettings`,
			isAtHoverSetting ? 'hoverRadiusBottom' : 'radiusBottom',
			value
		);
	};

	const handlePaddingChange = (key: string, value: string) => {
		if (!isSameAllPaddings) {
			handleStylingAttributeChange(`transformSettings`, key, value);
			return;
		}

		handleStylingAttributeChange(`transformSettings`, 'paddingTop', value);
		handleStylingAttributeChange(`transformSettings`, 'paddingRight', value);
		handleStylingAttributeChange(`transformSettings`, 'paddingLeft', value);
		handleStylingAttributeChange(`transformSettings`, 'paddingBottom', value);
	};

	return (
		<>
			<CardData title={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_CONTENT_LABEL')}>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_CONTENT_HEADER_LABEL')}
					onChange={({ target }) => {
						handleStylingAttributeChange('headerLabel', 'text', (target as HTMLInputElement).value);
					}}
					labelPlacement="start"
					control={<TextField variant="standard" multiline minRows={1} value={headerText} />}
				/>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_CONTENT_PARAGRAPH_LABEL')}
					onChange={({ target }) => {
						handleStylingAttributeChange('textLabel', 'text', (target as HTMLInputElement).value);
					}}
					labelPlacement="start"
					control={<TextField variant="standard" multiline minRows={8} value={paragraphText} />}
				/>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_CONTENT_ALIGNMENT')}
					labelPlacement="start"
					control={
						<ToggleButtonGroup>
							<ToggleButton
								value={'left'}
								aria-label="left aligned"
								size="small"
								className="toggle-button-custom"
								onClick={() => {
									handleStylingAttributeChange(null, 'textAlignment', 'left');
								}}
								selected={textAlignment === 'left'}
							>
								<FormatAlignLeftIcon />
							</ToggleButton>
							<ToggleButton
								value={'center'}
								aria-label="left aligned"
								size="small"
								className="toggle-button-custom"
								onClick={() => {
									handleStylingAttributeChange(null, 'textAlignment', 'center');
								}}
								selected={textAlignment === 'center'}
							>
								<FormatAlignCenterIcon />
							</ToggleButton>
							<ToggleButton
								value={'right'}
								aria-label="right aligned"
								size="small"
								className="toggle-button-custom"
								onClick={() => {
									handleStylingAttributeChange(null, 'textAlignment', 'right');
								}}
								selected={textAlignment === 'right'}
							>
								<FormatAlignRightIcon />
							</ToggleButton>
						</ToggleButtonGroup>
					}
				/>
			</CardData>
			<CardData title={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_STYLING_LABEL')}>
				<Typography variant="subtitle2" className="text-widget-sidebar-subtitle">
					{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_CONTENT_HEADER_LABEL')}
				</Typography>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_STYLING_TEXT_STYLE')}
					labelPlacement="start"
					control={
						<div>
							<span className="text-widget-sidebar-edit-button-label">{translator('EDIT')}</span>
							<IconButton
								size="small"
								onClick={({ currentTarget }) => {
									setHeaderStylingModalAnchor(headerStylingModalAnchor ? null : currentTarget);
									setParagraphStylingModalAnchor(null);
								}}
							>
								<EditIcon />
							</IconButton>
						</div>
					}
				/>
				<TextStylingModal
					open={!!headerStylingModalAnchor}
					anchorEl={headerStylingModalAnchor}
					id="header"
					font={headerFont}
					fontSize={headerFontSize}
					fontWeight={headerFontWeight}
					lineHeight={headerLineHeight}
					handleStylingAttributeChange={handleStylingAttributeChange}
				/>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_STYLING_TEXT_COLOR')}
					labelPlacement="start"
					control={
						<div className="scene-widgets__sort-input-control">
							<ColorPicker
								width="40px"
								height="22px"
								padding="2px"
								color={headerColor}
								onChangeComplete={(color: any) => {
									handleStylingAttributeChange('headerLabel', 'color', color.hex);
								}}
							/>
							<Input value={headerColor} />
							<IconButton
								size="small"
								onClick={() => handleStylingAttributeChange('headerLabel', 'color', '')}
							>
								<Clear />
							</IconButton>
						</div>
					}
				/>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_STYLING_TEXT_SHADOW')}
					labelPlacement="start"
					control={
						<div className="scene-widgets__sort-input-control">
							<ColorPicker
								width="40px"
								height="22px"
								padding="2px"
								color={headerShadowColor}
								onChangeComplete={(color: any) => {
									handleStylingAttributeChange('headerLabel', 'shadowColor', color.hex);
								}}
							/>
							<Input value={headerShadowColor} />
							<IconButton
								size="small"
								onClick={() => handleStylingAttributeChange('headerLabel', 'shadowColor', '')}
							>
								<Clear />
							</IconButton>
						</div>
					}
				/>
				<Divider className="divider-extra-space" />
				<Typography variant="subtitle2" className="text-widget-sidebar-subtitle">
					{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_CONTENT_PARAGRAPH_LABEL')}
				</Typography>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_STYLING_TEXT_STYLE')}
					labelPlacement="start"
					control={
						<div>
							<span className="text-widget-sidebar-edit-button-label">{translator('EDIT')}</span>
							<IconButton
								size="small"
								onClick={({ currentTarget }) => {
									setHeaderStylingModalAnchor(null);
									setParagraphStylingModalAnchor(paragraphStylingModalAnchor ? null : currentTarget);
								}}
							>
								<EditIcon />
							</IconButton>
						</div>
					}
				/>
				<TextStylingModal
					open={!!paragraphStylingModalAnchor}
					anchorEl={paragraphStylingModalAnchor}
					id="text"
					font={paragraphFont}
					fontSize={paragraphFontSize}
					fontWeight={paragraphFontWeight}
					lineHeight={paragraphLineHeight}
					handleStylingAttributeChange={handleStylingAttributeChange}
				/>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_STYLING_TEXT_COLOR')}
					labelPlacement="start"
					control={
						<div className="scene-widgets__sort-input-control">
							<ColorPicker
								width="40px"
								height="22px"
								padding="2px"
								color={paragraphColor}
								onChangeComplete={(color: any) => {
									handleStylingAttributeChange('textLabel', 'color', color.hex);
								}}
							/>
							<Input value={paragraphColor} />
							<IconButton
								size="small"
								onClick={() => handleStylingAttributeChange('textLabel', 'color', '')}
							>
								<Clear />
							</IconButton>
						</div>
					}
				/>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_STYLING_TEXT_SHADOW')}
					labelPlacement="start"
					control={
						<div className="scene-widgets__sort-input-control">
							<ColorPicker
								width="40px"
								height="22px"
								padding="2px"
								color={paragraphShadowColor}
								onChangeComplete={(color: any) => {
									handleStylingAttributeChange('textLabel', 'shadowColor', color.hex);
								}}
							/>
							<Input value={paragraphShadowColor} />
							<IconButton
								size="small"
								onClick={() => handleStylingAttributeChange('textLabel', 'shadowColor', '')}
							>
								<Clear />
							</IconButton>
						</div>
					}
				/>
				<Divider className="divider-extra-space" />
				<Typography variant="subtitle2" className="text-widget-sidebar-subtitle">
					{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_STYLING_CONTENT_LABEL')}
				</Typography>
				<FormControlLabel
					className={'scenario-chart__control'}
					label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_STYLING_CONTENT_SPACING')}
					labelPlacement="start"
					control={
						<CustomSlider
							value={contentSpacing}
							min={0}
							max={200}
							marks={SLIDER_OPTION_MARKS}
							onChange={(e: any, value: number | number[]) => {
								handleStylingAttributeChange(null, 'contentSpacing', value);
							}}
						/>
					}
				/>
			</CardData>
			<CardData title={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_BACKGROUND_LABEL')}>
				<div className="scene-widget__control">
					<ButtonGroup className="scene-widget__buttonGroup">
						<Button
							variant="outlined"
							color={isAtHoverSetting ? undefined : 'primary'}
							onClick={() => setHoverSetting(false)}
						>
							{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_NORMAL')}
						</Button>
						<Button
							variant="outlined"
							color={isAtHoverSetting ? 'primary' : undefined}
							onClick={() => setHoverSetting(true)}
						>
							{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_HOVER')}
						</Button>
					</ButtonGroup>
				</div>
				{!isAtHoverSetting && (
					<>
						<FormControlLabel
							className={'scenario-chart__control'}
							label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_BACKGROUND_COLOR')}
							labelPlacement="start"
							control={
								<div className="scene-widgets__sort-input-control">
									<ColorPicker
										width="40px"
										height="22px"
										padding="2px"
										color={backgroundColor}
										onChangeComplete={(color: any) =>
											handleStylingAttributeChange(
												'backgroundSettings',
												'backgroundColor',
												color.hex
											)
										}
									/>
									<Input value={backgroundColor} />
									<IconButton
										size="small"
										onClick={() =>
											handleStylingAttributeChange('backgroundSettings', 'backgroundColor', '')
										}
									>
										<Clear />
									</IconButton>
								</div>
							}
						/>
						<FormControlLabel
							className={'scenario-chart__control'}
							label={translator(
								'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_BACKGROUND_BORDER_TYPE'
							)}
							onChange={({ target }) => {
								handleStylingAttributeChange(
									`backgroundSettings`,
									'borderType',
									(target as HTMLInputElement).value
								);
							}}
							labelPlacement="start"
							control={
								<Select variant="standard" value={borderType}>
									{BORDER_TYPES.map((borderType) => (
										<MenuItem value={borderType} key={borderType}>
											{borderType}
										</MenuItem>
									))}
								</Select>
							}
						/>
						<FormControlLabel
							className={'scenario-chart__control'}
							label={translator(
								'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_BACKGROUND_BORDER_WIDTH'
							)}
							onChange={({ target }) => {
								handleStylingAttributeChange(
									`backgroundSettings`,
									'borderWidth',
									(target as HTMLInputElement).value
								);
							}}
							labelPlacement="start"
							control={<TextField variant="standard" type="number" value={borderWidth} />}
						/>
						<FormControlLabel
							className={'scenario-chart__control'}
							label={translator(
								'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_BACKGROUND_BORDER_COLOR'
							)}
							labelPlacement="start"
							control={
								<div className="scene-widgets__sort-input-control">
									<ColorPicker
										width="40px"
										height="22px"
										padding="2px"
										color={borderColor}
										onChangeComplete={(color: any) =>
											handleStylingAttributeChange(`backgroundSettings`, 'borderColor', color.hex)
										}
									/>
									<Input value={borderColor} />
									<IconButton
										size="small"
										onClick={() =>
											handleStylingAttributeChange(`backgroundSettings`, 'borderColor', '')
										}
									>
										<Clear />
									</IconButton>
								</div>
							}
						/>
						<div className="scene-widget__control-column">
							<div className="scene-widget__control-label">
								{translator(
									'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_BACKGROUND_BORDER_RADIUS'
								)}
							</div>
							<div className="scene-widget__control-column--item">
								<div className="scene-widget__control-container">
									{Object.entries(TextRadiusKeys).map(([key, displayVal], index) => {
										return (
											<FormControl
												disabled={isSameAllBorderRadius && index !== 0}
												key={displayVal}
												className="scene-widgets__transform-input"
												onChange={({ target }) =>
													handleBorderRadiusChange(key, (target as HTMLInputElement).value)
												}
												variant="outlined"
											>
												<OutlinedInput
													type="number"
													endAdornment={<InputAdornment position="end">px</InputAdornment>}
													value={transformSettings[key]}
												/>
												<FormHelperText>{displayVal}</FormHelperText>
											</FormControl>
										);
									})}
								</div>
								<IconButton
									onClick={() => setChangeAllBorderRadius(!isSameAllBorderRadius)}
									className={joinClassNames(
										'scene-widget__lock-btn',
										isSameAllBorderRadius ? 'scene-widget__lock-btn--active' : ''
									)}
								>
									{isSameAllBorderRadius ? <LockIcon /> : <LockOpenIcon />}
								</IconButton>
							</div>
						</div>
					</>
				)}

				{isAtHoverSetting && (
					<>
						<FormControlLabel
							className={'scenario-chart__control'}
							label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_BACKGROUND_COLOR')}
							labelPlacement="start"
							control={
								<div className="scene-widgets__sort-input-control">
									<ColorPicker
										width="40px"
										height="22px"
										padding="2px"
										color={hoverBackgroundColor}
										onChangeComplete={(color: any) =>
											handleStylingAttributeChange(
												'backgroundSettings',
												'hoverBackgroundColor',
												color.hex
											)
										}
									/>
									<Input value={hoverBackgroundColor} />
									<IconButton
										size="small"
										onClick={() =>
											handleStylingAttributeChange(
												'backgroundSettings',
												'hoverBackgroundColor',
												''
											)
										}
									>
										<Clear />
									</IconButton>
								</div>
							}
						/>
						<FormControlLabel
							className={'scenario-chart__control'}
							label={translator(
								'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_BACKGROUND_BORDER_TYPE'
							)}
							onChange={({ target }) => {
								handleStylingAttributeChange(
									`backgroundSettings`,
									'hoverBorderType',
									(target as HTMLInputElement).value
								);
							}}
							labelPlacement="start"
							control={
								<Select variant="standard" value={hoverBorderType}>
									{BORDER_TYPES.map((borderType) => (
										<MenuItem value={borderType} key={borderType}>
											{borderType}
										</MenuItem>
									))}
								</Select>
							}
						/>
						<FormControlLabel
							className={'scenario-chart__control'}
							label={translator(
								'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_BACKGROUND_BORDER_WIDTH'
							)}
							onChange={({ target }) => {
								handleStylingAttributeChange(
									`backgroundSettings`,
									'hoverBorderWidth',
									(target as HTMLInputElement).value
								);
							}}
							labelPlacement="start"
							control={<TextField variant="standard" type="number" value={hoverBorderWidth} />}
						/>
						<FormControlLabel
							className={'scenario-chart__control'}
							label={translator(
								'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_BACKGROUND_BORDER_COLOR'
							)}
							labelPlacement="start"
							control={
								<div className="scene-widgets__sort-input-control">
									<ColorPicker
										width="40px"
										height="22px"
										padding="2px"
										color={hoverBorderColor}
										onChangeComplete={(color: any) =>
											handleStylingAttributeChange(
												`backgroundSettings`,
												'hoverBorderColor',
												color.hex
											)
										}
									/>
									<Input value={hoverBorderColor} />
									<IconButton
										size="small"
										onClick={() =>
											handleStylingAttributeChange(`backgroundSettings`, 'hoverBorderColor', '')
										}
									>
										<Clear />
									</IconButton>
								</div>
							}
						/>
						<div className="scene-widget__control-column">
							<div className="scene-widget__control-label">
								{translator(
									'SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_BACKGROUND_BORDER_RADIUS'
								)}
							</div>
							<div className="scene-widget__control-column--item">
								<div className="scene-widget__control-container">
									{Object.entries(TextRadiusKeys).map(([key, displayVal], index) => {
										return (
											<FormControl
												disabled={isSameAllBorderRadius && index !== 0}
												key={displayVal}
												className="scene-widgets__transform-input"
												onChange={({ target }) =>
													handleBorderRadiusChange(key, (target as HTMLInputElement).value)
												}
												variant="outlined"
											>
												<OutlinedInput
													type="number"
													endAdornment={<InputAdornment position="end">px</InputAdornment>}
													value={transformSettings[`hover${capitalizeFirstLetter(key)}`]}
												/>
												<FormHelperText>{displayVal}</FormHelperText>
											</FormControl>
										);
									})}
								</div>
								<IconButton
									onClick={() => setChangeAllBorderRadius(!isSameAllBorderRadius)}
									className={[
										'scene-widget__lock-btn',
										isSameAllBorderRadius ? 'scene-widget__lock-btn--active' : '',
									]
										.join(' ')
										.trim()}
								>
									{isSameAllBorderRadius ? <LockIcon /> : <LockOpenIcon />}
								</IconButton>
							</div>
						</div>
					</>
				)}

				<Divider className="divider-extra-space" />
				<div className="scene-widget__control-column">
					<div className="scene-widget__control-label">Padding</div>
					<div className="scene-widget__control-column--item">
						<div className="scene-widget__control-container">
							{Object.entries(transformKeys).map(([key, displayVal], index) => {
								return (
									<FormControl
										disabled={isSameAllPaddings && index !== 0}
										key={displayVal}
										className="scene-widgets__transform-input"
										onChange={({ target }) =>
											handlePaddingChange(key, (target as HTMLInputElement).value)
										}
										variant="outlined"
									>
										<OutlinedInput
											type="number"
											endAdornment={<InputAdornment position="end">px</InputAdornment>}
											value={transformSettings[key]}
										/>
										<FormHelperText>{displayVal}</FormHelperText>
									</FormControl>
								);
							})}
						</div>
						<IconButton
							onClick={() => setChangeAllPaddings(!isSameAllPaddings)}
							className={[
								'scene-widget__lock-btn',
								isSameAllPaddings ? 'scene-widget__lock-btn--active' : '',
							]
								.join(' ')
								.trim()}
						>
							{isSameAllPaddings ? <LockIcon /> : <LockOpenIcon />}
						</IconButton>
					</div>
				</div>
			</CardData>
		</>
	);
};

export default Text;
