import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import _ from 'underscore';

import LivePlayer from '../../LivePlayer/LivePlayer';
import { mediaNames } from '../../../constants/mediaConstants';
import { liveEventStatusList } from '../../../constants/liveEventStatus';
import { getChannelPreview } from '../../../services/channelService';
import { play2ConfigBaseUrl } from '../../../utils/config';

import './LivePlayerContainer.scss';

class LivePlayerContainer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			containerHeight: 'max',
		};
	}

	componentDidMount = () => {
		if (this.props.onPlayerContainerLoaded) {
			this.props.onPlayerContainerLoaded(this);
		}
	};

	componentDidUpdate = (prevProps) => {
		if (prevProps.liveEventStatus != this.props.liveEventStatus) {
			if (this.props.liveEventStatus != liveEventStatusList.INITIAL) {
				window.GoBrain.widgets()['channel-preview'];
			}
		}
	};

	_getChannelPreviewLink = (_) => {
		const { defaultAccountId, channelId } = this.props;
		//const channelId = 'default'
		const link = getChannelPreview(defaultAccountId, channelId);
		return link;
	};

	renderPlayer = () => {
		const { isVisible, liveEventStatus, mediaId, showPlayer, isStreamPublished, moderatorMode } = this.props;

		return (
			<>
				<div id="LivePlayerContainer">
					{showPlayer &&
						(liveEventStatus === liveEventStatusList.INITIAL ? (
							<LivePlayer
								configUrl={`${play2ConfigBaseUrl}Accpy7KNHj1IUilFL5BMuNL0Q/configurations/live-manager`}
								onPlayerCreated={this.onPlayerCreated}
								isLive={true}
								entityId={mediaId}
								defaultAccountId={this.props.defaultAccountId}
								entityType="medias"
								widgetPrefix="channel-preview"
								container="liveContainer"
								width="inherit"
								hidePlayButton={false}
								data={this._getChannelPreviewLink()}
								play={isVisible}
								keepPreviousStateAfterSeeking={true}
								liveManagerControls={true}
								isStreamPublished={isStreamPublished}
								moderatorMode={moderatorMode}
								muted
							/>
						) : (
							<LivePlayer
								configUrl={`${play2ConfigBaseUrl}Accpy7KNHj1IUilFL5BMuNL0Q/configurations/live-manager`}
								onPlayerCreated={this.onPlayerCreated}
								isLive={true}
								entityId={mediaId}
								defaultAccountId={this.props.defaultAccountId}
								entityType={mediaNames.medias}
								widgetPrefix="live"
								container="liveContainer"
								width="inherit"
								hidePlayButton={false}
								data={null}
								play={isVisible}
								keepPreviousStateAfterSeeking={true}
								liveManagerControls={true}
								moderatorMode={moderatorMode}
								muted
							/>
						))}
				</div>
			</>
		);
	};

	render() {
		return <>{this.renderPlayer()}</>;
	}
}

const mapStateToProps = ({ session, snapshotSettings, liveManager, channel }) => ({
	defaultAccountId: session.defaultAccountId,
	snapshotPlayStarted: snapshotSettings.snapshotPlayStarted,
	defaultDate: liveManager.defaultDate,
	duration: liveManager.duration,
	liveStreamPositionTime: liveManager.liveStreamPositionTime,
	liveEventStatus: liveManager.liveEventStatus,
	timelineMarkerPosition: liveManager.timelineMarkerPosition,
	channels: channel.channels,
	timelineItems: liveManager.items,
	isPlayerAtLivePosition: liveManager.isPlayerAtLivePosition,
	selectedItem: liveManager.selectedItem,
});

const mapDispatchToProps = () => ({
	// resetSnapShotSettings: () => dispatch(resetSnapShotSettings())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(LivePlayerContainer);
