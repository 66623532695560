import * as actions from '../actions/inspectScreenActions';

const initialState = {
	deletedMediaId: '',
	mediaPropertiesToggled: false,
	updatedMedia: {},
	selectedLeftMenuKey: '',
	mediaOriginalDetails: {},
	disableCaptureFromVideo: true,
	playerConfigId: '',
	resizeScreenAfterCal: '',
	currentThumbnail: {},
	interactButtomImageUploaded: false,
	videoPlayerMuted: false,
	previewLink: '',
	downloadLink: '',
};

export const inspectScreenReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.UPDATE_PREVIEW_LINK:
			return {
				...state,
				previewLink: action.previewLink,
			};
		case actions.UPDATE_DOWNLOAD_LINK:
			return {
				...state,
				downloadLink: action.downloadLink,
			};
		case actions.SEND_MEDIA_DELETED:
			return {
				...state,
				deletedMediaId: action.mediaId,
			};
		case actions.TOGGLE_MEDIA_PROPERTIES:
			return {
				...state,
				mediaPropertiesToggled: action.toggle,
			};
		case actions.UPDATE_MEDIA_PROPERTIES:
			return {
				...state,
				updatedMedia: action.updatedMedia,
			};
		case actions.SET_CURRENT_THUMBNAIL:
			return {
				...state,
				currentThumbnail: action.currentThumbnail,
			};
		case actions.CLEAR_INSPECT_SCREEN_STORAGE:
			return {
				...initialState,
			};
		case actions.SET_INTERACT_BUTTON_IMAGE_UPLOADED:
			return {
				...state,
				interactButtomImageUploaded: action.interactButtomImageUploaded,
			};
		case actions.UPDATE_MENU_KEYS:
			return {
				...state,
				selectedLeftMenuKey: action.key,
			};
		case actions.ADD_MEDIA_DETAILS: {
			return {
				...state,
				mediaOriginalDetails: action.details,
			};
		}
		case actions.DISABLE_CAPTURE_FROM_VIDEO: {
			return {
				...state,
				disableCaptureFromVideo: action.value,
			};
		}
		case actions.SET_VIDEO_PLAYER_MUTED: {
			return {
				...state,
				videoPlayerMuted: action.value,
			};
		}
		case actions.SET_SIDE_PLAYER_CONFIG_ID: {
			return {
				...state,
				playerConfigId: action.value,
			};
		}
		case actions.RESIZE_SCREEN_AFTER_CALCULATION: {
			return {
				...state,
				resizeScreenAfterCal: Math.random(),
			};
		}

		default:
			return state;
	}
};
