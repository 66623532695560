import { mediaTypes, mediaNames, sortTypes, mediaViews } from './../constants/mediaConstants';
import trashcan_black from '../assets/images/trashcan_black.svg';
import inspect_black from '../assets/images/eye_black.svg';
import copy_black from '../assets/images/copy_black.svg';
import add_to_catalog_black from '../assets/images/add_to_catalog_black.svg';
import add_to_playlist_black from '../assets/images/add_to_playlist_black.svg';
import { getMonth, getYear, getMonthName } from './timeStampService';

export const isRequestFail = (component, data) => {
	const { showNotification } = component.props;
	let requestFail = false;
	if (data && data.message) {
		requestFail = true;
		showNotification(data.message);
	}

	if (data && data.Message) {
		requestFail = true;
		showNotification(data.Message);
	}
	if (!data) {
		requestFail = true;
	}
	return requestFail;
};

export const updateDimensions = (_) => {
	let height = '';
	let header = document.getElementById('mainHeader');
	let filterOptions = document.getElementById('mediaFilterOptions');
	let paginationSection = document.getElementById('paginationSection');
	let root = document.getElementById('root');
	let gridContainer = document.getElementById('mediaInternalGridContainer');
	let listBodyContainer = document.getElementById('listBodyContainer');
	let breadcrumb = document.getElementById('breadCrumbMobileView');

	const advancedSearchCollapseSection = document.getElementById('advancedSearchCollapseSection');
	const advancedSearchOptions = document.getElementById('advancedSearchOptions');
	const advancedSearchCollapseSectionHeight = advancedSearchCollapseSection
		? advancedSearchCollapseSection.offsetHeight
		: 0;
	const advancedSearchOptionsHeight = advancedSearchOptions ? advancedSearchOptions.offsetHeight : 0;

	if (header && filterOptions && paginationSection) {
		height =
			root.offsetHeight -
			(header.offsetHeight + (filterOptions.offsetHeight || 50) + paginationSection.offsetHeight + 75); // 50 is taken as filter options height as the height is different for desktop and mobile view
		if (breadcrumb) {
			height = height - breadcrumb.offsetHeight;
		}
		let cssHeight = height;
		if (gridContainer) {
			gridContainer.style.maxHeight = cssHeight + 'px';
		}
		if (listBodyContainer) {
			cssHeight = cssHeight - 22 - advancedSearchCollapseSectionHeight - advancedSearchOptionsHeight;
			listBodyContainer.style.maxHeight = cssHeight + 'px';
		}
	}
};

export const getMenuListForSingleMediaDropDown = (type, t) => {
	let menuList = [];
	if (type === mediaTypes.catalog || type === mediaNames.catalogs) {
		menuList = [
			{
				menuAction: 'inspect_catalog',
				menuTitle: t('LABEL_INSPECT_CATALOG'),
				img: inspect_black,
			},
			{
				menuAction: 'add_to_catalog',
				menuTitle: t('ADD_TO_CATALOG'),
				img: add_to_catalog_black,
			},
			{
				menuAction: 'delete',
				menuTitle: t('DELETE_CATALOG'),
				img: trashcan_black,
			},
		];
	} else if (type === mediaTypes.playlists) {
		menuList = [
			{
				menuAction: 'delete',
				menuTitle: t('DELETE_PLAYLISTS'),
				img: trashcan_black,
			},
		];
	} else if (type === mediaTypes.scenario) {
		menuList = [
			{
				menuAction: 'copy_media',
				menuTitle: t('COPY_MEDIA'),
				img: copy_black,
			},
			{
				menuAction: 'delete',
				menuTitle: t('DELETE_PLAYLISTS'),
				img: trashcan_black,
			},
		];
	} else {
		menuList = [
			{
				menuAction: 'add_to_playlist',
				menuTitle: t('LABEL_ADD_TO_PLAYLIST'),
				img: add_to_playlist_black,
			},
			{
				menuAction: 'add_to_catalog',
				menuTitle: t('ADD_TO_CATALOG'),
				img: add_to_catalog_black,
			},
			{
				menuAction: 'copy_media',
				menuTitle: t('COPY_MEDIA'),
				img: copy_black,
			},
			{
				menuAction: 'delete',
				menuTitle: t('DELETE_MEDIA'),
				img: trashcan_black,
			},
		];
	}
	return menuList;
};

export const getGridViewSingleMediaDropDown = (type, t) => {
	let menuTitle = t('COMMON_DELETED');
	if (type === mediaTypes.catalog || type === mediaNames.catalogs) {
		menuTitle = t('DELETE_CATALOG');
	} else if (type === mediaTypes.playlists) {
		menuTitle = t('DELETE_PLAYLISTS');
	} else {
		menuTitle = t('DELETE_MEDIA');
	}
	let menuList = [{ menuAction: 'delete', menuTitle: menuTitle, img: trashcan_black }];
	return menuList;
};

export const scrollViewUp = () => {
	let view = document.getElementById('mediaInternalGridContainer') || document.getElementById('listBodyContainer');
	if (view) {
		view.scrollTop = 0;
	}
};

const getGridFirstVisibleElement = (view) => {
	let tableBody =
		document.getElementById('mediaInternalGridContainer') || document.getElementById('listBodyContainer');
	let cutoff = tableBody ? tableBody.scrollTop : 0;
	let gridblock = [];
	if (view === mediaViews.grid) {
		gridblock = Array(document.getElementsByClassName('singleMediaBlock'))[0];
	} else {
		gridblock = Array(document.getElementsByClassName('listRow'))[0];
	}
	for (let block in gridblock) {
		let ele = gridblock[block];
		if (ele.offsetTop > cutoff) {
			return ele;
		}
	}
};

export const updateMonthDisplayOnScroll = (sortType, view, t) => {
	let ele = getGridFirstVisibleElement(view);
	if (ele) {
		if (ele.lastElementChild.className === 'viewUnixTimeStamp') {
			if (sortType === sortTypes.newest || sortType === sortTypes.oldest) {
				let timeStamp = ele.lastElementChild.getAttribute('created');
				if (timeStamp) {
					document.getElementById('monthLabel').textContent =
						getMonthName(getMonth(timeStamp), t) + ' ' + getYear(timeStamp);
				}
			} else if (sortType === sortTypes.name_ascending || sortType === sortTypes.name_descending) {
				let title = ele.lastElementChild.getAttribute('title');
				if (title) {
					document.getElementById('monthLabel').textContent = title.charAt(0).toLocaleUpperCase();
				}
			} else if (sortType === sortTypes.type) {
				let type = ele.getAttribute('attr');
				if (type === mediaTypes.catalog) {
					document.getElementById('monthLabel').textContent = 'CATALOGS';
				} else if (type === mediaTypes.playlists) {
					document.getElementById('monthLabel').textContent = 'PLAYLISTS';
				} else if (type === mediaTypes.scenario) {
					document.getElementById('monthLabel').textContent = 'Interactive videos';
				} else {
					document.getElementById('monthLabel').textContent = 'MEDIA';
				}
			} else if (sortType === sortTypes.relevance) {
				document.getElementById('monthLabel').textContent = 'Search results (By relevance)';
			} else {
				let views = ele.lastElementChild.getAttribute('views');
				if (views) {
					document.getElementById('monthLabel').textContent = views;
				}
			}
		}
	}
};

export const formatFileSize = (bytes, decimalPoint) => {
	if (bytes == 0) {
		return '0 Bytes';
	}
	const k = 1000;
	const dm = decimalPoint || decimalPoint === 0 ? decimalPoint : 2;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};
