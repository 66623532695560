import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Layout from './components/Layout';
import FilterByDates from './components/FilterByDates';
import SectionLayout from './components/SectionLayout';
import { _optionsPIE } from './../../utils/commonUtil';
import { useSelector } from 'react-redux';
import ReuseTable from './components/ReuseTable';
import { locationColumns } from './../../constants/analyticsConstant';
import { getLocationsStatsByDimension } from './../../services/analyticsServices';

const AnalyticsRegionsScreen = () => {
	const accountId = useSelector((state) => state.session.defaultAccountId);
	const [endDate, setEndDate] = useState(moment().valueOf());
	const [startDate, setStartDate] = useState(moment().subtract(7, 'days').valueOf());
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const getFilterData = (sDate, eDate) => {
		setStartDate(sDate);
		setEndDate(eDate);
	};

	useEffect(async () => {
		setLoading(true);
		const responce = await getLocationsStatsByDimension(accountId, startDate, endDate, 'regionlabel');
		setData(responce);
		setLoading(false);
	}, [startDate, endDate, accountId]);

	locationColumns[0]['label'] = 'Region';

	return (
		<Layout title="ANALYTICS_TITLE_CDN_ANALYTICS">
			<FilterByDates getFilterData={getFilterData} />
			<SectionLayout title="Regions" addClass="volumePerformance" loading={loading}>
				<ReuseTable columns={locationColumns} data={data} />
			</SectionLayout>
		</Layout>
	);
};

export default AnalyticsRegionsScreen;
