export const capitalizeFirstLetter = (text) => {
	if (text) {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}
	return '';
};

export const generateId = (_) => {
	let d = new Date().getTime();
	let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function replaceCharacter(c) {
		let r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
};

export const generateChannelId = (_) => {
	let d = new Date().getTime();
	let uuid = 'xxxx-4xxx-yxxx'.replace(/[xy]/g, (c) => {
		let r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16).toLowerCase();
	});
	return uuid;
};

export const generateRestrictionId = (_) => {
	let id = Math.floor(Math.pow(10, 13 - 1) + Math.random() * 9 * Math.pow(10, 13 - 1)).toString();
	return id;
};

export const isValidJsonString = (input) => {
	try {
		JSON.parse(input);
	} catch (e) {
		return false;
	}
	return true;
};

export const isValidTokenResponse = (data) => {
	try {
		if (data && data.access_token && data.expires_in && data.id_token && data.token_type) {
			return true;
		}
	} catch (e) {
		return false;
	}
	return false;
};

export const randomString = (maxLength) => {
	let text = '';
	let textArray = [];
	let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let str;
	let len = maxLength ? maxLength : 8;
	for (let j = 0; j < len; j++) {
		for (let i = 0; i < 2; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		textArray.push(text);
		text = '';
	}
	str = textArray.join('');
	return str;
};

export const randomNumberString = (maxLength) => {
	let text = '';
	let textArray = [];
	let possible = '0123456789';
	let str;
	let len = maxLength ? maxLength : 8;
	for (let j = 0; j < len; j++) {
		for (let i = 0; i < 2; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		textArray.push(text);
		text = '';
	}
	str = textArray.join('');
	return str;
};

export const getNumberWithoutDecimal = (number) => {
	if (number) {
		if (Math.trunc) {
			return Math.trunc(number);
		} else {
			return Math.floor(number);
		}
	}
	return 0;
};

export const getRandomShortString = () => {
	return Math.random().toString(36).substring(7);
};
