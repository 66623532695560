import React, { Component } from 'react';
import { resizePositions } from '../../../../constants/interactConstant';

class InteractResizable extends Component {
	constructor(props) {
		super(props);
		this.element = React.createRef();
		this.state = {
			isDragging: false,
		};

		this.dragResize = this.dragResize.bind(this);
	}

	/*initiateDragResize = (evt) => {
    evt.target.style["stroke-width"] = "20000px";
    this.setState({isDragging:true});
  }
  endDragResize = (evt) => {
    this.setState({isDragging:false});
    evt.target.style["stroke-width"] = "0px";
  }
  dragResize = (evt) => {
    let state = this.state;
    if(!state.isDragging)
      return;
    if(evt.movementX || evt.movementY){
      var c = evt.target;
      var ctm = c.getScreenCTM();
      var inverse = ctm.inverse();
      this.props.onDragged(inverse.a * evt.movementX, inverse.d * evt.movementY);
    }
  }*/

	initiateResize = (evt) => {
		let svgElem = evt.target.parentElement;
		while (svgElem.localName !== 'svg') {
			svgElem = svgElem.parentElement;
		}

		svgElem.addEventListener('mousemove', this.dragResize);
		svgElem.addEventListener('mouseup', this.endResize);
	};
	endResize = (evt) => {
		let svgElem = evt.target;
		while (svgElem.localName !== 'svg') {
			svgElem = svgElem.parentElement;
		}

		svgElem.removeEventListener('mousemove', this.dragResize);
		svgElem.removeEventListener('mouseup', this.endResize);
	};

	dragResize = (evt) => {
		const { svgPointClassName, position } = this.props;
		if (evt.movementX || evt.movementY) {
			let svgPoint = window.document.getElementsByClassName(svgPointClassName)[0].createSVGPoint();
			svgPoint.x = evt.clientX;
			svgPoint.y = evt.clientY;
			svgPoint = svgPoint.matrixTransform(evt.target.getScreenCTM().inverse());
			switch (position) {
				case resizePositions.topleft:
					this.props.onDragged(this.props.cx - svgPoint.x, this.props.cy - svgPoint.y);
					break;
				case resizePositions.topright:
					this.props.onDragged(svgPoint.x - this.props.cx, this.props.cy - svgPoint.y);
					break;
				case resizePositions.bottomleft:
					this.props.onDragged(this.props.cx - svgPoint.x, svgPoint.y - this.props.cy);
					break;
				case resizePositions.bottomright:
					this.props.onDragged(svgPoint.x - this.props.cx, svgPoint.y - this.props.cy);
					break;
				default:
					break;
			}
		}
	};

	render = () => {
		return (
			/* <rect className="resize-element hide" onMouseDown={this.initiateResize} x={this.props.cx-7} y={this.props.cy-7} width="14" height="14" />*/
			<circle
				className="resize-element"
				attr={this.props.position}
				onMouseDown={this.initiateResize}
				cx={this.props.cx}
				cy={this.props.cy}
				r="10"
			/>
		);
		/*<circle className="resize-element hide" onMouseDown={this.initiateResize} cx={this.props.cx} cy={this.props.cy} r="10" /> */
	};
}

/*
<circle className="resize-element hide" onMouseLeave={this.dragResize} onMouseDown={this.initiateDragResize} onMouseMove={this.dragResize} onMouseUp={this.endDragResize} cx={this.props.cx} cy={this.props.cy} r="10" />
*/

export default InteractResizable;
