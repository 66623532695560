import * as actions from '../actions/channelActions';

const initialState = {
	resources: [],
	channels: [],
};
export const channelReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SEND_CHANNELS_GET_SUCCESS:
			return {
				...state,
				channels: action.channels,
			};
		default:
			return state;
	}
};
