import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import './TabBar.scss';

const baseClassName = 'scenario-chart-tab-bar';

class TabBar extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { items, onItemClick } = this.props;
		if (!items || items.length === 0) {
			return null;
		}

		return (
			<ul
				className={baseClassName}
				style={this.props.style}
			>
				{items.map((item, index) => (
					<li
						className={[
							`${baseClassName}__item`,
							item.selected
								? `${baseClassName}__item--selected`
								: '',
						]
							.join(' ')
							.trim()}
						onClick={(e) => onItemClick?.(e, item)}
						key={index}
					>
						{item.label}
					</li>
				))}
			</ul>
		);
	}
}

const Item = PropTypes.shape({
	label: PropTypes.node,
	id: PropTypes.string,
	selected: PropTypes.bool,
});

TabBar.propTypes = {
	items: PropTypes.arrayOf(Item).isRequired,
	onItemClick: PropTypes.func,
};

const mapStateToProps = ({ session }) => ({
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = (_) => ({});

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation()
)(TabBar);
