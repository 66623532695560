import { appPlacementTypes } from '../constants/appPlacementTypes';

export const appsHelperService = {
	getApps(apps, placementType, startIndex) {
		let items = [];
		let index = startIndex;

		switch (placementType) {
			case appPlacementTypes.LEFTMENU:
				apps.forEach((app) =>
					app.appInitiator.filter((a) => {
						if (a.scope.toUpperCase().startsWith(appPlacementTypes.LEFTMENU) === true) {
							index += 1;
							let inactive_icon =
								a.appLoader.config && a.appLoader.config.inactive_icon
									? a.appLoader.config.inactive_icon
									: a.icon;
							items.push({
								id: index,
								order: a.order,
								//key: 'apps_' + app.id,
								//key: 'apps_' + app.id, //+ '_' + a.id,
								key: 'apps_' + app.id, //+ '_' + a.id,
								text: a.scope && a.scope.split('.').length > 2 ? a.title : a.title.toUpperCase(), // Upper case only main menu - LEFTMENU.EDITOR.Project
								icon: a.icon,
								icon_grey: inactive_icon,
								selected: false,
								children: [],
								param: { appId: app.id, scope: a.scope, url: a.url, title: a.title, configId: a.id },
							});
						}
					})
				);
				return items;
			case appPlacementTypes.TOPMENU:
				apps.forEach((app) =>
					app.appInitiator.filter((a) => {
						if (a.scope.toUpperCase().startsWith(appPlacementTypes.TOPMENU) === true) {
							index += 1;
							let inactive_icon =
								a.appLoader.config && a.appLoader.config.inactive_icon
									? a.appLoader.config.inactive_icon
									: a.icon;
							items.push({
								id: index,
								order: a.order,
								key: 'apps_' + app.id,
								text: a.title && a.title.toUpperCase(),
								icon: a.icon,
								icon_grey: inactive_icon,
								selected: false,
								children: [],
								param: { appId: app.id, scope: a.scope, url: a.url, title: a.title, configId: a.id },
							});
						}
					})
				);
				return items;
			case appPlacementTypes.LIBRARY_MEDIAS_MEDIA_DETAIL:
				apps &&
					apps.forEach((app) =>
						app.appInitiator.filter((a) => {
							if (a.scope.toUpperCase() == appPlacementTypes.LIBRARY_MEDIAS_MEDIA_DETAIL) {
								index += 1;
								let inactive_icon =
									a.appLoader.config && a.appLoader.config.inactive_icon
										? a.appLoader.config.inactive_icon
										: a.icon;
								items.push({
									id: index,
									key: 'apps_' + app.id,
									text: a.title,
									icon: a.icon,
									icon_grey: inactive_icon,
									selected: false,
									children: [],
									param: {
										appId: app.id,
										scope: a.scope,
										url: a.url,
										title: a.title,
										configId: a.id,
									},
								});
							}
						})
					);
				return items;
			case appPlacementTypes.LIBRARY_MEDIAS_LIVE_DETAIL:
				//TODO
				break;
			case appPlacementTypes.LIBRARY_CATALOGS_CATALOG_DETAIL:
				//TODO
				break;
			default:
				return items;
		}
	},

	parseLeftMenu(items) {
		let rootItems = items.filter((a) => a.param.scope.split('.').length == 1); //LEFTMENU
		let mainMenuItems = items.filter((a) => a.param.scope.split('.').length == 2); //LEFTMENU.EDITOR

		mainMenuItems.forEach((m) => {
			let child = items.filter((a) => a.param.scope.toUpperCase().startsWith(m.param.scope.toUpperCase())); // Get submenu items
			m.children = child;
		});
		rootItems = rootItems.concat(mainMenuItems);

		return rootItems;
	},
};
