import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './AppScreen.scss';
import AppViewer from './../../components/AppViewer/AppViewer';
import ScreenParentContainer from '../../reusable/ScreenParentContainer/ScreenParentContainer';

class AppScreen extends Component {
	render() {
		const { match } = this.props;
		let appId = match.params && match.params.appId;
		return (
			<>
				{' '}
				{
					<ScreenParentContainer>
						<AppViewer appId={appId} />
					</ScreenParentContainer>
				}
			</>
		);
	}
}

const mapStateToProps = ({ navReducer, apps }) => ({
	open: navReducer.open,
	appFullScreenMode: apps.appFullScreenMode,
});

export default compose(connect(mapStateToProps, null), withTranslation())(AppScreen);
