import _ from 'lodash';

export const sortArrayOfObjectInAscOrder = (items, key) => {
    let sortedArray = [];
    if (key == 'created') {
        sortedArray = _.orderBy(items, [media => _.get(media, 'created', '')], 'asc');
    } else if ((key == 'title')) {
        sortedArray = _.orderBy(items, [media => _.get(media, 'metadata.title', '').toString().toLowerCase()], 'asc');
    } else {
        sortedArray = items.sort((a, b) => (a[key] >= b[key]) ? 1 : -1);
    }
    return sortedArray;
};

export const sortArrayOfObjectInDescOrder = (items, key) => {
    let sortedArray = [];
    if (key === 'created') {
        sortedArray = _.orderBy(items, [media => _.get(media, 'created', '')], 'desc');
    } else if ((key == 'title')) {
        sortedArray = _.orderBy(items, [media => _.get(media, 'metadata.title', '').toString().toLowerCase()], 'desc');
    } else {
        sortedArray = items.sort((a, b) => (b[key] > a[key]) ? 1 : -1);
    }
    return sortedArray;
};