import * as actions from '../actions/liveManagerActions';
import { liveEventStatusList } from '../constants/liveEventStatus';
import { getTimelineStartDate } from './../services/timeStampService';
import { liveSettingsList } from '../constants/liveManagerConstants';

const initialState = {
	items: [],
	groups: [],
	selectedItem: '',
	selectedGroup: '',
	defaultDate: getTimelineStartDate(),
	duration: 43200,
	liveStreamPositionTime: getTimelineStartDate(),
	liveEventStatus: liveEventStatusList.INITIAL,
	liveEventStartTime: null,
	liveEventStopTime: null,
	timelineMarkerPosition: getTimelineStartDate(),
	playerPositionToSet: getTimelineStartDate(),
	lastSetPlayerPositionTime: Date.now(),
	startPlaying: false,
	isPlayerAtLivePosition: true, //making as true so that small player is not visible,
	liveSettingsId: liveSettingsList.STREAM,
	SaveAsOnDemand: true,
	liveLoading: false,
	liveChannel: null,
};
export const liveManagerReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.UPDATE_LIVE_CHANNEL:
			return {
				...state,
				liveChannel: { ...action.liveChannel },
			};
		case actions.CREATE_TIMELINE_ITEM: {
			let _items = state.items && state.items.concat(action.item);
			return {
				...state,
				items: _items,
			};
		}
		case actions.UPDATE_TIMELINE_ITEM:
			return {
				...state,
				items: state.items.map((item) =>
					item.id === action.item.id
						? {
								...item,
								className: action.item.className,
								start: action.item.start,
								end: action.item.end,
								index: action.item.index,
						  }
						: item
				),
			};
		case actions.REMOVE_TIMELINE_ITEM:
			return {
				...state,
				items: state.items.filter((item) => {
					return item.id !== action.item.id;
				}),
			};
		case actions.SET_SELECTED_TIMELINE_ITEM:
			return {
				...state,
				selectedItem: action.item,
			};
		case actions.CREATE_TIMELINE_GROUP: {
			let groups = state.groups && state.groups.concat(action.group);
			return {
				...state,
				groups: groups,
			};
		}
		case actions.UPDATE_TIMELINE_GROUP:
			return {
				...state,
				groups: state.groups.map((group) =>
					group.id === action.group.id
						? {
								...group,
								content: action.group.content,
						  }
						: group
				),
			};
		case actions.REMOVE_TIMELINE_GROUP:
			return {
				...state,
				groups: state.groups.filter((group) => {
					return group.id !== action.group.id;
				}),
			};
		case actions.SET_SELECTED_TIMELINE_GROUP:
			return {
				...state,
				selectedGroup: action.group,
			};
		case actions.SET_TIMELINE_MARKER_POSITION:
			return {
				...state,
				timelineMarkerPosition: action.markerPosition,
			};
		case actions.CLEAR_LIVE_MANAGER_STORE:
			return {
				...state,
				...initialState,
				//selectedGroup: ''
			};
		case actions.SET_DURATION_OF_LIVE_MEDIA:
			return {
				...state,
				duration: action.duration,
			};
		case actions.SET_LIVESTREAM_POSITION: {
			return {
				...state,
				liveStreamPositionTime: action.datetime,
			};
		}
		case actions.SET_LIVEPLAYER_POSITION: {
			return {
				...state,
				playerPositionToSet: action.datetime,
				startPlaying: action.startPlaying,
				lastSetPlayerPositionTime: Date.now(),
			};
		}
		case actions.SET_LIVE_EVENT_STATUS: {
			return {
				...state,
				liveEventStatus: action.status,
			};
		}
		case actions.SET_LIVE_EVENT_START_TIME: {
			return {
				...state,
				liveEventStartTime: action.datetime,
			};
		}
		case actions.SET_LIVE_EVENT_STOP_TIME: {
			return {
				...state,
				liveEventStopTime: action.datetime,
			};
		}
		case actions.SET_PLAYER_STREAM_AS_LIVE: {
			return {
				...state,
				isPlayerAtLivePosition: true,
			};
		}
		case actions.SET_PLAYER_STREAM_AS_NOT_LIVE: {
			return {
				...state,
				isPlayerAtLivePosition: false,
			};
		}
		case actions.SET_LIVE_SETTINGS_ID: {
			return {
				...state,
				liveSettingsId: action.id,
			};
		}
		case actions.SET_SAVE_ONDEMAND_STATUS: {
			return {
				...state,
				SaveAsOnDemand: action.boolStatus,
			};
		}
		case actions.SHOW_LIVE_LOADING: {
			return {
				...state,
				liveLoading: true,
			};
		}
		case actions.HIDE_LIVE_LOADING: {
			return {
				...state,
				liveLoading: false,
			};
		}
		default:
			return state;
	}
};
