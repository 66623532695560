import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Dropzone from 'react-dropzone';

import CardData from '../../../../../../../utils/CardData';

type ImageProps = {
	widgetTemplate: { settings: { bgImgUrl?: string } };
	isAtHoverSetting: boolean;
	uploadedFile: {};
	setHoverSetting: (_value: boolean) => {};
	uploadWidgetContent: () => {};
	onUploadFileClick: () => {};
};

const Image = ({
	widgetTemplate,
	isAtHoverSetting,
	setHoverSetting,
	uploadWidgetContent,
	onUploadFileClick,
}: ImageProps) => {
	const {
		settings: { bgImgUrl },
	} = widgetTemplate;

	const { t: translator } = useTranslation();
	return (
		<CardData title={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT')}>
			<div className="scene-widget__control">
				<ButtonGroup className="scene-widget__buttonGroup">
					<Button
						variant="outlined"
						color={isAtHoverSetting ? undefined : 'primary'}
						onClick={() => setHoverSetting(false)}
					>
						{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_NORMAL')}
					</Button>
					<Button
						variant="outlined"
						color={isAtHoverSetting ? 'primary' : undefined}
						onClick={() => setHoverSetting(true)}
					>
						{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_HOVER')}
					</Button>
				</ButtonGroup>
			</div>
			<div className={'scene-widget__control'}>
				<Dropzone multiple={false} accept={'.jpg, .jpeg, .gif, .png, .bmp'} onDrop={uploadWidgetContent}>
					{({ getRootProps, getInputProps }) => (
						<div className="scene-widget__fileUpload--placeholder" onClick={onUploadFileClick}>
							<div {...getRootProps({ className: 'dropzone' })}>
								<input {...getInputProps()} />
							</div>
							<img
								style={{
									height: '100%',
									width: '100%',
								}}
								id="widget-thumbnail"
							/>
						</div>
					)}
				</Dropzone>

				<Button variant="contained" color="primary" onClick={onUploadFileClick}>
					{isAtHoverSetting && !bgImgUrl
						? translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_UPLOAD_IMAGE')
						: translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_CONTENT_SETTING_REPLACE_IMAGE')}
				</Button>
			</div>
		</CardData>
	);
};

export default Image;
