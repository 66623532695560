import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { updateInteractTimelineItem } from '../../../../actions/interactTimelineActions';
import { getModifiedSVGTemplate } from '../../../../services/interactScreenHelperService';
import _ from 'underscore';
import './InteractEditorSVGOverlay.scss';
import ButtonCoordinates from './ButtonCoordinates.js';
import { showSelectedSubPropertyKey, hideSelectedSubPropertyKey } from '../../../../actions/interactElementsActions';

class InteractEditorSVGOverlay extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedItem: '',
			showCoordinates: false,
			overlayWidth: '',
		};
	}

	UNSAFE_componentWillMount = () => {
		window.addEventListener('click', this._handleClickEvent, true);
	};

	componentWillUnmount = () => {
		window.removeEventListener('click', this._handleClickEvent, true);
		window.removeEventListener('resize', this.updateDimensions);
	};

	componentDidMount = () => {
		// this.setState({ overlayWidth: document.getElementById("interactContainer").clientHeight * 1.777777 })
		setTimeout(() => {
			this.updateDimensions();
		}, 30);
		window.addEventListener('resize', this.updateDimensions);
	};

	componentDidUpdate(prevProps) {
		if (this.props.mosaicTileChange !== prevProps.mosaicTileChange) {
			this.updateDimensions();
		}
	}

	updateDimensions = () => {
		const interactPlayerContainer = document.getElementById('interactPlayerContainer');
		let width = interactPlayerContainer ? interactPlayerContainer.offsetWidth : window.innerWidth / 3;
		let screenSize = 100;
		let widthToSet = (screenSize / 100) * width;
		let heightToSet = (widthToSet * 9) / 16;
		let $mediaTags = document.getElementById('interactPlayer-od');

		if ($mediaTags) {
			$mediaTags.style.width = widthToSet + 'px';
			$mediaTags.style.height = heightToSet + 'px';
		}
	};

	getCurrentlyEditedItem = () => {
		const { interactItems, selectedInteractItems } = this.props;
		if (selectedInteractItems && selectedInteractItems.length > 0) {
			let itemId = selectedInteractItems[0];
			let item = _.find(interactItems, (item) => {
				return item.id === itemId;
			});
			if (item) {
				return item;
			}
		}
		return {};
	};

	transform = () => {};

	_handleClickEvent = (e) => {
		if (
			(e.target.nodeName === 'rect' ||
				e.target.classList.contains('draggable-element') ||
				e.target.classList.contains('resize-element') ||
				e.target.classList.contains('btnContainer')) &&
			!e.target.classList.contains('variantCombinationsImageButton')
		) {
			let gid = e.target.getAttribute('gid');
			if (e.target.nodeName === 'rect' && gid) {
				this.setState(
					{
						showCoordinates: true,
					},
					() => {
						this.props.showSelectedSubPropertyKey(gid);
					}
				);
			} else if (
				e.target.classList.contains('draggable-element') ||
				e.target.classList.contains('resize-element') ||
				e.target.classList.contains('btnContainer')
			) {
				this.setState({
					showCoordinates: true,
				});
			} else if (e.target.nodeName === 'rect' && !gid) {
				this.setState({
					showCoordinates: false,
				});
			}
		} else {
			this.setState({
				showCoordinates: false,
			});
		}
	};

	onInteractivElementClick = (e) => {
		e.stopPropagation();
		this.setState({
			showCoordinates: true,
		});
	};

	renderRectBox = () => {
		const { showCoordinates } = this.state;
		if (showCoordinates) {
			return (
				<>
					<ButtonCoordinates />
				</>
			);
		} else {
			return null;
		}
	};

	renderSelectedSVGElement = () => {
		const { interactPreview } = this.props;
		let item = this.getCurrentlyEditedItem();
		if (item && item.settings && !interactPreview) {
			//!interactpreview is required because for click event. When document.query selector is called, this svg is displayed. Ideally player svg should be returned
			let template = getModifiedSVGTemplate(item);

			return (
				<>
					<div //onClick={(e) => this.onInteractivElementClick(e)}
						id="interactEditorSVGOverlayElement"
						//style={{ width: this.state.overlayWidth + "px" }}
					>
						<div
							id="svgElement" //style={{ width: this.state.overlayWidth + "px" }}
							dangerouslySetInnerHTML={{ __html: template }}
						></div>
						{this.renderRectBox()}
					</div>
				</>
			);
		} else {
			return null;
		}
	};

	render() {
		return (
			<>
				<div id="interactEditorSVGOverlay">{this.renderSelectedSVGElement()}</div>
			</>
		);
	}
}

const mapStateToProps = ({ session, interactTimelineReducer, interactElementsReducer }) => ({
	defaultAccountId: session.defaultAccountId,
	interactItems: interactTimelineReducer.items,
	interactGroups: interactTimelineReducer.groups,
	selectedInteractItems: interactTimelineReducer.selectedInteractItems,
	interactPreview: interactElementsReducer.interactPreview,
	mosaicTileChange: interactElementsReducer.mosaicTileChange,
});

const mapDispatchToProps = (dispatch) => ({
	updateInteractTimelineItem: (item) => dispatch(updateInteractTimelineItem(item)),
	showSelectedSubPropertyKey: (key) => dispatch(showSelectedSubPropertyKey(key)),
	hideSelectedSubPropertyKey: (_) => dispatch(hideSelectedSubPropertyKey()),
	// setSelectedApp: (app) => dispatch(setSelectedApp(app))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(InteractEditorSVGOverlay);
