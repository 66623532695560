import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

const SectionLayout = (props) => {
	const { t } = useTranslation();
	const { children, title, subTitle, addClass, loading } = props;
	return (
		<div className={'sec ' + addClass}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h1 className="secTitle">{t(title)}</h1>
					{subTitle && <p className="secSubTitle">{subTitle}</p>}
				</Grid>
				{loading ? (
					<div className="inprogress">
						<CircularProgress />
					</div>
				) : (
					children
				)}
			</Grid>
		</div>
	);
};

export default SectionLayout;
