import React from 'react';
import Technology from './components/Technology';

const AnalyticsBrowsersTypeScreen = () => {
	return (
		<Technology
			dimension="browsertype"
			titleSec1="ANALYTICS_TITLE_CDN_BROWSER_TYPE"
			titleSec2="ANALYTICS_TITLE_CDN_VOLUME_PER_BROWSERS_TYPE"
			column="Browser Type"
		/>
	);
};

export default AnalyticsBrowsersTypeScreen;
