import React from 'react';
import { WIDGETS_TYPE_LIST } from '../../../../constants/scenarioConstant';
import EditorModal from '../../utils/EditorModal';
import { capitalizeFirstLetter } from '../../../../services/stringHelperService';

interface AddWidgetModalProps {
	open: boolean;
	title: string;
	actionText: string;
	actionAlign: string;
	baseClassName: string;
	selectedWidgetType: string;
	onClose: () => {};
	onAccept: () => {};
	onSelectWidgetType: (_type: string) => {};
}

const AddWidgetModal = ({
	open,
	onClose,
	onAccept,
	title,
	actionText,
	actionAlign,
	baseClassName,
	selectedWidgetType,
	onSelectWidgetType,
}: AddWidgetModalProps) => {
	return (
		<EditorModal
			open={open}
			onClose={onClose}
			onAccept={onAccept}
			title={title}
			actionText={actionText}
			actionAlign={actionAlign}
		>
			<div className={`${baseClassName}__tab-content`}>
				<div className={`${baseClassName}__tab-content ${baseClassName}__tab-widgets`}>
					{WIDGETS_TYPE_LIST.map(({ type, icon }) => (
						<div
							key={type}
							className={`${baseClassName}__widget ${baseClassName}__widget-dnd ${
								selectedWidgetType === type ? `${baseClassName}__widget--selected` : ''
							}`}
							onClick={() => {
								onSelectWidgetType(type);
							}}
						>
							{icon}
							<span className="widget-name">{capitalizeFirstLetter(type)}</span>
						</div>
					))}
				</div>
			</div>
		</EditorModal>
	);
};

export default AddWidgetModal;
