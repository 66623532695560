import { getMediaChatSessionDetails } from '../../../actions/liveChatActions';
import { setOpenInformationDialog } from '../../../actions/viewEngagementActions';

export const checkChatSessionDetails = (mediaId: string, dispatch: any) => {
	if (!getMediaChatSessionDetails(mediaId)) {
		dispatch(setOpenInformationDialog(true));
		return;
	}
};

export const isChatProfileEmpty = (chatSettings: any) => {
	let res = true;
	Object.keys(chatSettings).forEach((key) => {
		if (chatSettings[key] !== undefined && chatSettings[key] !== 'string') {
			res = false;
		}
	});

	return res;
};
