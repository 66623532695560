import {
	_startLiveMedia,
	_stopLiveMedia,
	_createSnapshot,
	_createLiveMediaFromChannel,
	_createIPOnlyChannel,
	_deleteIPOnlyChannel,
	_sendEmailNotification,
	_getPublishedStreams,
	_createLegacyLiveMedia,
} from './../services/workflowService';
import { LocalStorageService } from './../services/localStorageService';

export const SEND_REQUEST_CREATE_LIVE_MEDIA_FROM_CHANNEL = 'SEND_REQUEST_CREATE_LIVE_MEDIA_FROM_CHANNEL';
export const SEND_REQUEST_CREATE_LIVE_MEDIA_FROM_CHANNEL_SUCCESS =
	'SEND_REQUEST_CREATE_LIVE_MEDIA_FROM_CHANNEL_SUCCESS';
export const SEND_REQUEST_CREATE_LIVE_MEDIA_FROM_CHANNEL_FAILURE =
	'SEND_REQUEST_CREATE_LIVE_MEDIA_FROM_CHANNEL_FAILURE';

export const SEND_REQUEST_START_LIVE = 'SEND_REQUEST_START_LIVE';
export const SEND_REQUEST_START_LIVE_SUCCESS = 'SEND_REQUEST_START_LIVE_SUCCESS';
export const SEND_REQUEST_START_LIVE_FAILURE = 'SEND_REQUEST_START_LIVE_FAILURE';

export const SEND_REQUEST_STOP_LIVE = 'SEND_REQUEST_STOP_LIVE';
export const SEND_REQUEST_STOP_LIVE_SUCCESS = 'SEND_REQUEST_STOP_LIVE_SUCCESS';
export const SEND_REQUEST_STOP_LIVE_FAILURE = 'SEND_REQUEST_STOP_LIVE_FAILURE';

export const SEND_REQUEST_CREATE_SNAPSHOT = 'SEND_REQUEST_CREATE_SNAPSHOT';
export const SEND_REQUEST_CREATE_SNAPSHOT_SUCCESS = 'SEND_REQUEST_CREATE_SNAPSHOT_SUCCESS';
export const SEND_REQUEST_CREATE_SNAPSHOT_FAILURE = 'SEND_REQUEST_CREATE_SNAPSHOT_FAILURE';

export const SEND_REQUEST_DELETE_SNAPSHOT = 'SEND_REQUEST_DELETE_SNAPSHOT';
export const SEND_REQUEST_DELETE_SNAPSHOT_SUCCESS = 'SEND_REQUEST_DELETE_SNAPSHOT_SUCCESS';
export const SEND_REQUEST_DELETE_SNAPSHOT_FAILURE = 'SEND_REQUEST_DELETE_SNAPSHOT_FAILURE';

export const SEND_REQUEST_CREATE_IPONLY_CHANNEL = 'SEND_REQUEST_CREATE_IPONLY_CHANNEL';
export const SEND_REQUEST_CREATE_IPONLY_CHANNEL_SUCCESS = 'SEND_REQUEST_CREATE_IPONLY_CHANNEL_SUCCESS';
export const SEND_REQUEST_CREATE_IPONLY_CHANNEL_FAILURE = 'SEND_REQUEST_CREATE_IPONLY_CHANNEL_FAILURE';

export const SEND_REQUEST_DELETE_IPONLY_CHANNEL = 'SEND_REQUEST_DELETE_IPONLY_CHANNEL';
export const SEND_REQUEST_DELETE_IPONLY_CHANNEL_SUCCESS = 'SEND_REQUEST_DELETE_IPONLY_CHANNEL_SUCCESS';
export const SEND_REQUEST_DELETE_IPONLY_CHANNEL_FAILURE = 'SEND_REQUEST_DELETE_IPONLY_CHANNEL_FAILURE';

export const SEND_REQUEST_EMAIL_NOTIFICATION = 'SEND_REQUEST_EMAIL_NOTIFICATION';
export const SEND_REQUEST_EMAIL_NOTIFICATION_SUCCESS = 'SEND_REQUEST_EMAIL_NOTIFICATION_SUCCESS';
export const SEND_REQUEST_EMAIL_NOTIFICATION_FAILURE = 'SEND_REQUEST_EMAIL_NOTIFICATION_FAILURE';

const sendCreateLiveMediaFromChannel = (_) => ({
	type: SEND_REQUEST_CREATE_LIVE_MEDIA_FROM_CHANNEL,
});

const sendCreateLiveMediaFromChannelSuccess = (_) => ({
	type: SEND_REQUEST_CREATE_LIVE_MEDIA_FROM_CHANNEL_SUCCESS,
});

const sendCreateLiveMediaFromChannelFailure = (_) => ({
	type: SEND_REQUEST_CREATE_LIVE_MEDIA_FROM_CHANNEL_FAILURE,
});

const sendStartLive = (_) => ({
	type: SEND_REQUEST_START_LIVE,
});

const sendStartLiveSuccess = (_) => ({
	type: SEND_REQUEST_START_LIVE_SUCCESS,
});

const sendStartLiveFailure = (_) => ({
	type: SEND_REQUEST_START_LIVE_FAILURE,
});

const sendStopLive = (_) => ({
	type: SEND_REQUEST_STOP_LIVE,
});

const sendStopLiveSuccess = (_) => ({
	type: SEND_REQUEST_STOP_LIVE_SUCCESS,
});

const sendStopLiveFailure = (_) => ({
	type: SEND_REQUEST_STOP_LIVE_FAILURE,
});

const sendCreateSnapshot = (_) => ({
	type: SEND_REQUEST_CREATE_SNAPSHOT,
});

const sendCreateSnapshotSuccess = (_) => ({
	type: SEND_REQUEST_CREATE_SNAPSHOT_SUCCESS,
});

const sendCreateSnapshotFailure = (_) => ({
	type: SEND_REQUEST_CREATE_SNAPSHOT_FAILURE,
});

// eslint-disable-next-line no-unused-vars
const sendDeleteSnapshot = (_) => ({
	type: SEND_REQUEST_DELETE_SNAPSHOT_SUCCESS,
});

// eslint-disable-next-line no-unused-vars
const sendDeleteSnapshotFailure = (_) => ({
	type: SEND_REQUEST_DELETE_SNAPSHOT_FAILURE,
});

const sendCreateIponlyChannel = (_) => ({
	type: SEND_REQUEST_CREATE_IPONLY_CHANNEL,
});

const sendCreateIponlyChannelSuccess = (_) => ({
	type: SEND_REQUEST_CREATE_IPONLY_CHANNEL_SUCCESS,
});

const sendCreateIponlyChannelFailure = (_) => ({
	type: SEND_REQUEST_CREATE_IPONLY_CHANNEL_FAILURE,
});

const sendDeleteIponlyChannel = (_) => ({
	type: SEND_REQUEST_DELETE_IPONLY_CHANNEL,
});

const sendDeleteIponlyChannelSuccess = (_) => ({
	type: SEND_REQUEST_DELETE_IPONLY_CHANNEL_SUCCESS,
});

const sendDeleteIponlyChannelFailure = (_) => ({
	type: SEND_REQUEST_DELETE_IPONLY_CHANNEL_FAILURE,
});

const sendEmailNotificationRequest = (_) => ({
	type: SEND_REQUEST_EMAIL_NOTIFICATION,
});

const sendEmailNotificationSuccess = (_) => ({
	type: SEND_REQUEST_EMAIL_NOTIFICATION_SUCCESS,
});

const sendEmailNotificationFailure = (_) => ({
	type: SEND_REQUEST_EMAIL_NOTIFICATION_FAILURE,
});

export const getAccountId = (getState) => {
	if (getState) {
		return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

export const getPublishedStreams = (accountId, applicationId) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	return _getPublishedStreams(defaultAccountId, applicationId);
};

export const startLiveMedia = (accountId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendStartLive());
	return _startLiveMedia(defaultAccountId, body)
		.then((data) => {
			dispatch(sendStartLiveSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendStartLiveFailure());
			return error;
		});
};

export const stopLiveMedia = (accountId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendStopLive());
	return _stopLiveMedia(defaultAccountId, body)
		.then((data) => {
			dispatch(sendStopLiveSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendStopLiveFailure());
			return error;
		});
};

export const createLiveMediaFromChannel = (accountId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendCreateLiveMediaFromChannel());
	return _createLiveMediaFromChannel(defaultAccountId, body)
		.then((data) => {
			dispatch(sendCreateLiveMediaFromChannelSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendCreateLiveMediaFromChannelFailure());
			return error;
		});
};

export const createLegacyLiveMedia = (accountId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendCreateLiveMediaFromChannel());
	return _createLegacyLiveMedia(defaultAccountId, body)
		.then((data) => {
			dispatch(sendCreateLiveMediaFromChannelSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendCreateLiveMediaFromChannelFailure());
			return error;
		});
};

export const createSnapshot = (accountId, extractVod, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendCreateSnapshot());
	return _createSnapshot(defaultAccountId, extractVod, body)
		.then((data) => {
			dispatch(sendCreateSnapshotSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendCreateSnapshotFailure());
			return error;
		});
};

export const createIPOnlyChannel = (accountId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendCreateIponlyChannel());
	return _createIPOnlyChannel(defaultAccountId, body)
		.then((data) => {
			dispatch(sendCreateIponlyChannelSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendCreateIponlyChannelFailure());
			return error;
		});
};

export const deleteIPOnlyChannel = (accountId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendDeleteIponlyChannel());
	return _deleteIPOnlyChannel(defaultAccountId, body)
		.then((data) => {
			dispatch(sendDeleteIponlyChannelSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendDeleteIponlyChannelFailure());
			return error;
		});
};

export const sendEmailNotification = (accountId, body) => (dispatch, getState) => {
	const defaultAccountId = accountId || getAccountId(getState);
	dispatch(sendEmailNotificationRequest());
	return _sendEmailNotification(defaultAccountId, body)
		.then((data) => {
			dispatch(sendEmailNotificationSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendEmailNotificationFailure());
			return error;
		});
};
