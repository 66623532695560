import BackendClient from './../services/backendClient';
import { writeApiEndpointV1, readApiEndpointV1 } from "../utils/config";

export const getChannelPreview = (accountId, channelId) => `${readApiEndpointV1}accounts/${accountId}/channels/${channelId}/preview`;

export const _getChannels = accountId => BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/channels`);

export const _getChannel = (accountId, channelId) => BackendClient.get(`${readApiEndpointV1}accounts/${accountId}/channels/${channelId}`);

export const _updateChannel = (accountId, channelId, body) => BackendClient.put(`${writeApiEndpointV1}accounts/${accountId}/channels/${channelId}`, body, false, false);
