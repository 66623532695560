/**
 * Get element's parent which is satisfied the condition
 *
 * @param {*} element
 * @param {*} condition
 * @returns element's parent
 */
export const getElementParent = (element, condition) => {
    if (!element || !condition) {
        return element;
    }

    if (condition?.(element)) {
        return element;
    }

    const parentElement = element.parentElement;
    if (!parentElement) {
        return null;
    }

    if (condition?.(parentElement)) {
        return parentElement;
    }

    return getElementParent(parentElement, condition);
}

/**
 * Generate an UI id
 */
export const generateUIId =  () => {
    let d = new Date().getTime();
    let uuid = "xxxxxxxxxxxx".replace(/[xy]/g,
      function replaceCharacter(c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
      });
    return uuid;
};

export const joinClassNames = (...args) => {
    if (!args) {
        return "";
    }

    let classNames = "";
    if (args.length === 1 && typeof args[0] === "string") {
        const classes = args[0].split(" ").filter(Boolean);
        classNames = classes.length > 0 ? classes.join(" ").trim() : "";
    } else {
        for (const arg of args) {
            if (!arg) {
                classNames = classNames;
            } else if (typeof arg === "string") {
                classNames += " " + arg.trim();
            } else if (typeof arg === "object" && arg.length) {
                for (const a of arg) {
                    classNames += " " + joinClassNames(a);
                }
            }
        }
    }

    return classNames.trim();
}
