import { makeStyles } from '@material-ui/core';

const getListCustomStyle = makeStyles((theme) => ({
	allCatalogItem: {
		height: '30px',
		marginBottom: '5px',
		paddingLeft: '12px',
		paddingRight: '12px',
	},
	customItem: {
		paddingLeft: '12px',
		paddingRight: '4px',
		height: '30px',
		fontSize: '1rem',
		lineHeight: 1.5,
		letterSpacing: '0.00938em',
		margin: '2px 0px',
		borderRadius: '4px',
		width: 'auto',
	},
	activeItem: {
		background: 'rgba(0, 0, 0, 0.04)',
	},
	selected: {
		backgroundColor: '#DBE9FF80 !important',
		fontWeight: 600,
	},
	ellipsis: {
		width: 'calc(100% - 20px)',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	nested1: {
		marginLeft: theme.spacing(4),
	},
	nested2: {
		marginLeft: theme.spacing(6),
	},
	nested3: {
		marginLeft: theme.spacing(8),
	},
	nested4: {
		marginLeft: theme.spacing(10),
	},
	nested5: {
		marginLeft: theme.spacing(12),
	},
	indicator: {
		left: theme.spacing(3.5),
	},
	firstLevelItem: {
		marginBottom: theme.spacing(2),
	},
	noHover: {
		background: 'none',
	},
}));

export const useListCustomStyle = () => getListCustomStyle();
