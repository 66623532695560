import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import './ListingMenuDropDown.scss';

export const ListingMenuDropDown = ({ menuList, menuAnchor, objectParams, handleMenuClose, attr }) => (
	<Menu
		id="singleMediaDropDownList"
		keepMounted
		anchorEl={menuAnchor}
		onClose={handleMenuClose}
		open={Boolean(menuAnchor)}
		getContentAnchorEl={null}
		anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
		attr={attr}
	>
		{menuList.filter(Boolean).map((eachMenu, index) => (
			<MenuItem
				className="listingMenuDropDown"
				key={index}
				onClick={() => handleMenuClose(eachMenu.menuAction, objectParams)}
			>
				{eachMenu.img ? <img src={eachMenu.img} /> : <></>}
				{eachMenu.menuTitle}
			</MenuItem>
		))}
	</Menu>
);
