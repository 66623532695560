import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { withRouter } from 'react-router';
import { updateScreenName, clearBreadCrumbLocationArray } from '../../actions/breadCrumbActions';
import { toggleAppFullScreenMode, loadApps, setSelectedApp } from '../../actions/appsActions';
import './AppViewer.scss';
import { LocalStorageService } from './../../services/localStorageService';

class AppViewer extends PureComponent {
	constructor(props) {
		super(props);
	}

	UNSAFE_componentWillMount() {
		if (this.props.appId && this.props.apps && this.props.apps.length == 0 && this.props.defaultAccountId) {
			this.props.loadApps(this.props.defaultAccountId);
		}
	}

	componentDidMount = () => {
		this.initialize();
		window.addEventListener('message', this._handleMessage, { capture: true });
	};

	componentWillUnmount = () => {
		window.removeEventListener('message', this._handleMessage);
		this.props.clearBreadCrumbLocationArray();
	};

	initialize = () => {
		let app = this._getAppInfo();
		//this.props.updateScreenName(app && app.name)
		if (app) {
			this.props.updateScreenName(app.name);
		}
	};

	componentDidUpdate(prevProps) {
		if (this.props.appId != prevProps.appId) {
			this.initialize();
		}
		if (this.props.apps && prevProps.apps && this.props.apps.length != prevProps.apps.length) {
			this.initialize();
		}
	}

	_handleFullScreen = (e) => {
		if (e.type === 'toggleQbrickAppScreen' && e.detail) {
			this.props.toggleAppFullScreenMode(e.detail.fullScreenMode);
		}
	};

	_handleMessage = (e) => {
		if (e.data && e.data.type === 'toggleQbrickAppScreen') {
			this.props.toggleAppFullScreenMode(e.data.fullScreenMode);
		}
	};

	_getAppInfo = () => {
		const { appId } = this.props;
		let app = _.find(this.props.apps, (app) => {
			return app.id === appId;
		});
		return app;
	};

	_generateSourceUrl = () => {
		const { defaultAccountId, mediaId, playlistId, catalogId, selectedApp, match } = this.props;

		if (selectedApp === null) {
			let app = this._getAppInfo();
			let config = app && app.appInitiator.find((a) => a.id === match.params.configId || match.params.subMenuKey);
			if (!config) {
				return null;
			}
			let data = { url: config.url, appId: app.id, scope: config.scope };
			this.props.setSelectedApp(data);
		}

		let url = selectedApp && selectedApp.url;
		let id = selectedApp && selectedApp.appId;

		url += `?id=${id}&accountId=${defaultAccountId}&token=${LocalStorageService.getAccessToken()}`;
		url += mediaId ? `&mediaId=${mediaId}` : ``;
		url += playlistId ? `&playlistId=${playlistId}` : ``;
		url += catalogId ? `&catalogId=${catalogId}` : ``;

		return url;
	};

	render() {
		const { appFullScreenMode } = this.props;
		let url = this._generateSourceUrl();

		return (
			<div className="AppViewerWrapper">
				{url && (
					<iframe
						src={url}
						className={appFullScreenMode ? 'fullScreenMode' : 'normaScreenMode'}
						allow="camera; microphone; display-capture; clipboard-read; clipboard-write"
						name={`'{"source":"qbrick"}'`}
					/>
				)}
			</div>
		);
	}
}

AppViewer.propTypes = {
	appId: PropTypes.string.isRequired,
	//params: PropTypes.object.isRequired, // {id, url, scope}
	mediaId: PropTypes.string,
	catalogId: PropTypes.string,
	playlistId: PropTypes.string,
};

const mapStateToProps = ({ session, apps }) => ({
	defaultAccountId: session.defaultAccountId,
	apps: apps.apps,
	appFullScreenMode: apps.appFullScreenMode,
	selectedApp: apps.selectedApp,
});

const mapDispatchToProps = (dispatch) => ({
	updateScreenName: (name) => dispatch(updateScreenName(name)),
	toggleAppFullScreenMode: (toggle) => dispatch(toggleAppFullScreenMode(toggle)),
	clearBreadCrumbLocationArray: (_) => dispatch(clearBreadCrumbLocationArray()),
	setSelectedApp: (app) => dispatch(setSelectedApp(app)),
	loadApps: (accountId) => dispatch(loadApps(accountId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppViewer));
