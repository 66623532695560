import * as actions from '../actions/publishActions';
import { sortTypes, typeOptions, sortingOptionsDropDownItems, customScreenType } from '../constants/mediaConstants';

const initialState = {
	catalogs: [],
	playlists: [],
	loading: false,
	selectedFiles: [],
	showCatalogs: true,
	showMedias: true,
	showPlaylists: true,
	showScenarios: true,
	sortType: sortTypes.type,
	sortTypeText: sortingOptionsDropDownItems[0].value,
	showType: typeOptions.allMedia,
	uploadingMedia: [],
	uploadPercentage: [],
	routeAfterSave: '',
	selectAllItems: false,
	itemsToActionsList: [],
	didFileUploadHappened: false,
	removedItemInEveryAction: '',
	isInspectScreenModified: false,
	isPreparingForUpload: [],
	// only use this property to track if there is on going upload to prevent user from refresh or exit chrome
	isUploading: false,
	isEncoding: [],
	isAudioUploadFinished: false,
	uploadingProcessActive: '',
	mediaUploadStatus: false,
	catelogCountForMedia: {},
	emails: [],
	refreshCatalogs: undefined,
	refreshMedias: '',
	refreshPlaylists: '',
	lastLibraryView: '',
	predefinedFilters: [],
	selectedCatalogId: undefined,
	ccEverywhere: null,
	// this property keep the qbrick standard value when UploadMediaDialog fetched. The value need te re-used in other place
	qbrickStandardFlows: [],
	replaceMediaContent: undefined,
	screenType: customScreenType.medium,
	accountRestrictions: [],
	refreshMediasIds: [],
	permissionInformationDialog: false,
};

export const publishReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.SET_SHOW_PERMISSION_INFORMATION_DIALOG:
			return {
				...state,
				permissionInformationDialog: action.permissionInformationDialog,
			};
		case actions.SET_ACCOUNT_RESTRICTIONS:
			return {
				...state,
				accountRestrictions: action.accountRestrictions,
			};
		case actions.SET_CC_EVERYWHERE:
			return {
				...state,
				ccEverywhere: action.ccEverywhere,
			};
		case actions.SET_SHOW_UPLOAD_MEDIA_DIALOG:
			return {
				...state,
				showUploadMediaDialogBoxStatus: action.showUploadMediaDialogBoxStatus,
			};
		case actions.SET_PREDEFINED_FILTER:
			return {
				...state,
				predefinedFilters: action.predefinedFilters || [],
			};
		case actions.CLEAR_PREDEFINED_FILTER:
			return {
				...state,
				predefinedFilters: [],
			};
		case actions.SET_LAST_ROUTE_OF_LIBRARY_VIEW:
			return {
				...state,
				lastLibraryView: action.location,
			};
		case actions.REFRESH_CATALOGS:
			return {
				...state,
				refreshCatalogs: Math.random(),
			};
		case actions.REFRESH_MEDIAS:
			return {
				...state,
				refreshMedias: Math.random(),
			};
		case actions.REFRESH_MEDIAS_IDS:
			return {
				...state,
				refreshMediasIds: action.refreshMediasIds,
			};
		case actions.REFRESH_PLAYLISTS:
			return {
				...state,
				refreshPlaylists: Math.random(),
			};
		case actions.SET_SELECTED_MEDIA_FILTER:
			return {
				...state,
				showMedias: action.change,
			};
		case actions.SET_SELECTED_PLAYLISTS_FILTER:
			return {
				...state,
				showPlaylists: action.change,
			};
		case actions.SET_SELECTED_SCENARIOS_FILTER:
			return {
				...state,
				showScenarios: action.change,
			};
		case actions.SET_UPLOADING_PROCESS_ACTIVE:
			return {
				...state,
				uploadingProcessActive: action.uploadingProcessActive,
			};
		case actions.SET_IS_ENCODING_TRUE:
			return {
				...state,
				isEnconding: true,
			};
		case actions.SET_IS_ENCODING_FALSE:
			return {
				...state,
				isEnconding: false,
			};
		case actions.SET_IS_UPLOADING:
			return {
				...state,
				isUploading: action.payload,
			};
		case actions.ADD_TO_IS_PREPARING_FOR_UPLOAD: {
			let nameExistsToAddInArray = state.isPreparingForUpload.some((item) => item === action.fileName);
			if (nameExistsToAddInArray) {
				let tempArray = state.isPreparingForUpload.push(
					action.fileName + '(' + state.isPreparingForUpload.length + ')'
				);
				if (!tempArray.map) {
					tempArray = [];
				}
				return {
					...state,
					isPreparingForUpload: [...tempArray],
				};
			} else {
				let tempArray = state.isPreparingForUpload;
				tempArray.push(action.fileName);
				return {
					...state,
					isPreparingForUpload: [...tempArray],
				};
			}
		}
		case actions.REMOVE_FROM_IS_PREPARING_FOR_UPLOAD: {
			let removedArray = state.isPreparingForUpload;
			const index = removedArray.indexOf(action.fileName);
			if (index > -1) {
				removedArray.splice(index, 1);
			}
			return {
				...state,
				isPreparingForUpload: removedArray,
			};
		}
		case actions.EMPTY_PREPARING_FOR_UPLOAD:
			return {
				...state,
				isPreparingForUpload: [],
			};
		case actions.ADD_TO_IS_ENCODING: {
			let nameExistsToAddInArrayEnc = state.isEncoding.some((item) => item === action.fileName);
			if (nameExistsToAddInArrayEnc) {
				let tempArrayEnc = state.isEncoding;
				tempArrayEnc.push(action.fileName + '(' + state.isEncoding.length + ')');
				return {
					...state,
					mediaUploadStatus: true,
					isEncoding: tempArrayEnc,
				};
			} else {
				let tempArrayEnc = state.isEncoding;
				tempArrayEnc.push(action.fileName);
				return {
					...state,
					mediaUploadStatus: true,
					isEncoding: tempArrayEnc,
				};
			}
		}
		case actions.REMOVE_FROM_IS_ENCODING: {
			let encodingArray = state.isEncoding;
			const indexEnc = encodingArray.indexOf(action.fileName);
			if (indexEnc > -1) {
				encodingArray.splice(indexEnc, 1);
			}
			return {
				...state,
				isEncoding: encodingArray,
			};
		}
		case actions.REPLACE_IS_ENCODING: {
			let tempArrayENCO = action.encodingArray;
			return {
				...state,
				isEncoding: tempArrayENCO,
			};
		}
		case actions.ADD_EMAIL: {
			let _emailToAdd = state.emails;
			_emailToAdd.push(action.email);
			return {
				...state,
				emails: _emailToAdd,
			};
		}
		case actions.REMOVE_EMAIL: {
			let _emailToRemove = state.emails.filter((e) => {
				return e != action.email;
			});
			return {
				...state,
				emails: _emailToRemove,
			};
		}
		case actions.SEND_LOAD_MEDIA_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_LOAD_MEDIA_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_LOAD_MEDIA_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_MEDIA_ITEM_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_MEDIA_ITEM_DELETE_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_MEDIA_ITEM_DELETE_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_CATALOG_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SAVE_CATALOG_ITEMS:
			return {
				...state,
				catalogs: action.payload,
			};
		case actions.SET_SHOW_TYPE:
			return {
				...state,
				showType: action.showType,
			};

		case actions.SET_SORT_TYPE:
			return {
				...state,
				sortType: action.sortType,
			};
		case actions.SET_SORT_TYPE_TEXT:
			return {
				...state,
				sortTypeText: action.sortTypeText,
			};
		case actions.SEND_ENCODING_PROFILE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_ENCODING_PROFILES_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_ENCODING_PROFILES_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_MEDIA_ITEM_UPLOAD_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_MEDIA_ITEM_UPLOAD_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_MEDIA_ITEM_UPLOAD_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.DISPLAY_CATALOGS_MEDIA:
			return {
				...state,
				showCatalogs: true,
			};
		case actions.HIDE_CATALOGS_MEDIA:
			return {
				...state,
				showCatalogs: false,
			};
		case actions.MEDIA_UPLOAD_STARTS:
			return {
				...state,
				selectedFiles: action.selectedFiles,
				uploadingMedia: action.selectedFiles,
			};
		case actions.MEDIA_UPLOAD_CHECK_STATUS:
			return {
				...state,
				selectedFiles: action.selectedFiles,
				mediaUploadStatus: false,
			};
		case actions.MEDIA_UPLOAD_STOPS:
			return {
				...state,
				selectedFiles: [],
				uploadingMedia: [],
			};
		case actions.SEND_REQUEST_LOAD_PLAYLISTS:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_REQUEST_LOAD_PLAYLISTS_SUCCESS:
			return {
				...state,
				loading: false,
				playlists: action.playlists,
			};
		case actions.SEND_REQUEST_LOAD_PLAYLISTS_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.PUBLISH_FORM_UPDATE:
			return {
				...state,
				[action.field]: action.value,
			};

		case actions.SEND_REQUEST_CREATE_NEW_PLAYLIST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_REQUEST_CREATE_NEW_CATALOG_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_REQUEST_CREATE_NEW_PLAYLIST_SUCCESS:
			return {
				...state,
				loading: false,
			};

		case actions.SEND_REQUEST_CREATE_NEW_CATALOG_FAILURE:
		case actions.SEND_REQUEST_CREATE_NEW_PLAYLIST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_PLAYLIST_ITEM_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_PLAYLIST_ITEM_DELETE_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_PLAYLIST_ITEM_DELETE_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.START_SUBTITLE_UPLOAD_PROCESS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.START_SUBTITLE_UPLOAD_PROCESS_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.START_SUBTITLE_UPLOAD_PROCESS_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.REMOVE_SUBTITLE_REQUEST:
			return {
				...state,
			};
		case actions.REMOVE_SUBTITLE_REQUEST_SUCCESS:
			return {
				...state,
			};
		case actions.REMOVE_SUBTITLE_REQUEST_FAILURE:
			return {
				...state,
			};
		case actions.START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.START_THUMB_IMAGE_UPLOAD_PROCESS_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.REMOVE_THUMBNAIL_IMAGE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.REMOVE_THUMBNAIL_IMAGE_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.REMOVE_THUMBNAIL_IMAGE_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SET_THUMBNAIL_IMAGE_REQUEST:
		case actions.SEND_UPDATE_RESOURCE_OF_MEDIA_REQUEST:
		case actions.SEND_UPDATE_MEDIA_COVER_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SET_THUMBNAIL_IMAGE_REQUEST_SUCCESS:
		case actions.SEND_UPDATE_RESOURCE_OF_MEDIA_SUCCESS:
		case actions.SEND_UPDATE_MEDIA_COVER_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SET_THUMBNAIL_IMAGE_REQUEST_FAILURE:
		case actions.SEND_UPDATE_RESOURCE_OF_MEDIA_FAILURE:
		case actions.SEND_UPDATE_MEDIA_COVER_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST:
		case actions.SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST:
		case actions.SEND_DELETE_MEDIA_TO_CATALOG_REQUEST:
		case actions.SEND_ADD_NEW_MEDIA_REQUEST:
			// case actions.SEND_COUNT_MEDIA_REQUEST: //removed to avoid showing twice loading
			return {
				...state,
				loading: true,
			};
		case actions.SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST_SUCCESS:
		case actions.SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST_SUCCESS:
		case actions.SEND_COUNT_MEDIA_REQUEST_SUCCESS:
		case actions.SEND_DELETE_MEDIA_TO_CATALOG_REQUEST_SUCCESS:
		case actions.SEND_ADD_NEW_MEDIA_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_ASSIGN_MEDIA_ITEM_TO_PLAYLIST_REQUEST_FAILURE:
		case actions.SEND_ASSIGN_MEDIA_ITEM_TO_CATALOG_REQUEST_FAILURE:
		case actions.SEND_COUNT_MEDIA_REQUEST_FAILURE:
		case actions.SEND_DELETE_MEDIA_TO_CATALOG_REQUEST_FAILURE:
		case actions.SEND_ADD_NEW_MEDIA_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_RELATED_MEDIAS_FETCH_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_RELATED_MEDIAS_FETCH_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_RELATED_MEDIAS_FETCH_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_REMOVE_SELECTED_MEDIA_ITEM_FROM_THE_PLAYLIST_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_CHANGE_THE_ORDER_OF_THE_SELECTED_MEDIA_ITEM_IN_PLAYLIST_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_MEDIA_SCREEN_CHANGED:
			return {
				...state,
				isInspectScreenModified: true,
			};
		case actions.SEND_MEDIA_SCREEN_UNCHANGED:
			return {
				...state,
				isInspectScreenModified: false,
				routeAfterSave: '',
			};
		case actions.CHANGE_ROUTE_AFTER_INSPECT_SCREEN:
			return {
				...state,
				routeAfterSave: action.route,
			};
		case actions.REMOVED_ITEM_STORE_TO_PROPS:
			return {
				...state,
				removedItemInEveryAction: action.removedItemInEveryAction,
			};
		case actions.SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_SHARE_ON_SOCIAL_MEDIA_PLATFORM_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_POST_SHARED_DELETE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_POST_SHARED_DELETE_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_POST_SHARED_DELETE_REQUEST_FAILED:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.SEND_SOCIAL_MEDIA_AUTHENTICATION_REQUEST_FAILED:
			return {
				...state,
				loading: false,
			};
		case actions.GET_MEDIA_QUERY_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
			};
		case actions.GET_MEDIA_QUERY_DETAILS_REQUEST_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case actions.GET_MEDIA_QUERY_DETAILS_REQUEST_FAILURE:
			return {
				...state,
				loading: false,
			};
		case actions.DID_FILE_UPLOAD_ACTION_HAPPENED_STATUS:
			return {
				...state,
				didFileUploadHappened: action.filesUploaded,
			};
		case actions.UPDATE_ITEMS_LIST_FOR_ACTIONS:
			return {
				...state,
				itemsToActionsList: [...state.itemsToActionsList, action.newMedia],
			};
		case actions.UPDATE_ALL_ITEMS_LIST_FOR_ACTIONS:
			return {
				...state,
				itemsToActionsList: action.itemsList,
			};
		case actions.UPDATE_SELECT_ALL_PROPS:
			return {
				...state,
				selectAllItems: action.selectAllItemsStatus,
			};
		case actions.SET_IS_AUDIO_UPLOAD_FINISHED:
			return {
				...state,
				isAudioUploadFinished: action.value,
			};
		case actions.SET_UPLOAD_PERCENTAGE: {
			let nameExists = state.uploadPercentage.some((item) => item.name === action.fileName);
			let tempObj = {
				name: action.fileName,
				percentage: action.uploadPercentage,
				type: action.uploadType,
			};
			let tempArray = state.uploadPercentage;
			if (nameExists) {
				let arr = [tempObj];
				let resultArray = tempArray.map((obj) => arr.find((item) => item.name === obj.name) || obj);
				return {
					...state,
					mediaUploadStatus: false,
					uploadPercentage: resultArray,
				};
			} else {
				tempArray.push(tempObj);
				return {
					...state,
					mediaUploadStatus: false,
					uploadPercentage: tempArray,
				};
			}
		}
		case actions.REMOVE_FROM_UPLOAD_PERCENTAGE: {
			let nameExistsInUploadPercentage = state.uploadPercentage.some((item) => item.name === action.fileName);

			if (nameExistsInUploadPercentage) {
				const tempArray = state.uploadPercentage.filter((item) => item.name !== action.fileName);
				return {
					...state,
					uploadPercentage: tempArray,
				};
			} else {
				return {
					...state,
				};
			}
		}
		case actions.RESET_UPLOAD_PERCENTAGE:
			return {
				...state,
				uploadPercentage: [],
			};
		case actions.SEND_LIVE_ASSET_REQUEST:
		case actions.SEND_LIVE_ASSET_REQUEST_SUCCESS:
		case actions.SEND_LIVE_ASSET_REQUEST_FAILURE:
			return {
				...state,
				// TODO
			};
		case actions.SEND_MEDIA_ITEM_UPLOAD_OLD_REQUEST:
			return {
				...state,
				mediaUploadStatus: false,
			};
		case actions.SEND_MEDIA_ITEM_UPLOAD_OLD_REQUEST_SUCCESS:
			return {
				...state,
				mediaUploadStatus: true,
			};
		case actions.SEND_LOAD_MEDIA_TOTAL_COUNT_SUCCESS:
			return {
				...state,
				catelogCountForMedia: action.data,
			};
		case actions.SET_SELECTED_CATALOGS:
			return {
				...state,
				selectedCatalogId: action.selectedCatalogId,
			};
		case actions.SET_SCREEN_TYPE:
			return {
				...state,
				screenType: action.screenType,
			};
		case actions.SET_QBRICK_STANDARD_FLOWS:
			return {
				...state,
				qbrickStandardFlows: action.payload,
			};
		case actions.SET_REPLACE_MEDIA_CONTENT:
			return {
				...state,
				replaceMediaContent: action.payload,
			};

		default:
			return state;
	}
};
