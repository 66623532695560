import * as actions from "../actions/searchActions"

const initialState = {
    tags: [],
    loading: false,
    searchKeywords: "",
    resultTotalCount: 0,
    searchScreenLoaded: false,
    searchActionPerformed: false
};

export const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SEARCH_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.SEARCH_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case actions.SEARCH_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            };
        case actions.SEND_SEARCH_SCREEN_LOAD_REQUEST:
            return {
                ...state,
                searchScreenLoaded: true
            };
        case actions.SEND_SEARCH_SCREEN_UNMOUNT_REQUEST:
            return {
                ...state,
                searchScreenLoaded: false
            };
        case actions.SEND_SEARCH_KEYWORD_UPDATE_REQUEST:
            return {
                ...state,
                searchKeywords: [...action.searchKeywords]
            };
        case actions.SEND_CLEAR_SEARCH_KEYWORD_UPDATE_REQUEST:
            return {
                ...state,
                searchKeywords: ""
            };
        case actions.SEND_SEARCH_RESULT_TOTAL_COUNT_UPDATE_REQUEST:
            return {
                ...state,
                resultTotalCount: [parseInt(state.resultTotalCount) + parseInt(action.resultTotalCount)]
            };
        case actions.SEND_SEARCH_RESULT_TOTAL_COUNT_EMPTY_REQUEST:
            return {
                ...state,
                resultTotalCount: 0
            };
        case actions.SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case actions.SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            };
        case actions.SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case actions.SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            };
        case actions.SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case actions.SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            };
        case actions.SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case actions.SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            };
        case actions.SEND_SEARCH_ACTION_PERFORMED_REQUEST:
            return {
                ...state,
                searchActionPerformed: true
            };
        case actions.SEND_SEARCH_ACTION_PERFORMED_CLEARED:
            return {
                ...state,
                searchActionPerformed: false
            };
        case actions.UPDATE_SEARCH_TAGS:
            return {
                ...state,
                tags: [...state.tags, action.tag]
            };
        case actions.CLEAR_SEARCH_TAGS:
            return {
                ...state,
                tags: []
            };
        case actions.SET_SEARCH_TAGS:
            return {
                ...state,
                tags: action.tags
            };
        default:
            return state
    }
};
