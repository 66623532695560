import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import {
	FormControl,
	FormHelperText,
	InputAdornment,
	OutlinedInput,
	FormControlLabel,
	TextField,
	IconButton,
} from '@material-ui/core';

import { joinClassNames } from '../../../../../../../../services/elementHelperService';
import {
	WIDGET_TYPE_BUTTON,
	WIDGET_TYPE_VIDEO,
	BorderRadiusKeys,
} from '../../../../../../../../constants/scenarioConstant';
import { defaultRadius, getValidWidgetPosition } from '../../../../../../utils/helper';
import CardData from '../../../../../../utils/CardData';

import Position from './generalSettingsChild/Position';
import Duration from './generalSettingsChild/Duration';
import Events from './generalSettingsChild/events/Events';

import './GeneralSettings.scss';

interface Map {
	[key: string]: string | undefined;
}

type GeneralSettingsProps = {
	widgetTemplate: {
		name: string;
		type: string;
		events: Array<{
			actions: Array<{ type: string; metadata: { url: string; openNewTab: boolean; sceneId: string } }>;
			type: string;
		}>;
		style: {
			zIndex: number;
			coordinate: { x: number; y: number };
			dimension: { w: number; h: number };
			rotation: number;
			transformSettings?: Map;
		};
	};
	editingScene: { id: string };
	scenes: Array<{ id: string; name: string }>;
	gotoUrlRef: React.Ref<HTMLInputElement> | undefined;
	openNewTabRef: React.Ref<HTMLInputElement> | undefined;
	widgetStartTime: string;
	widgetEndTime: string;
	widgetOverlayRef: any;
	handleWidgetNameChange: () => {};
	handleDestinationSceneChange: () => {};
	handleGotoUrlWidgetChange: () => {};
	handleGotoUrlOpenNewTabChange: () => {};
	handleTimeAttributeChange: (_value: string, _attr: string) => {};
	handleSubmitTimeAttribute: (_value: string, _attr: string) => {};
	handleLocationAttributeChange: (_value: number, _obj: string, _attr?: string) => {};
	handleDeleteWidgetEvent: (_eventType: string) => {};
	handleAddNewEventToWidget: (_eventType: string) => {};
	handleAddActionToEvent: (_actionType: string, _eventType: string) => {};
	handleStylingAttributeChange: (_objKey: string | null, _attrKey: string, _value: any) => {};
	handleJumpToTimeMetaDataChange: (_objKey: string) => {};
};

const GeneralSettings = ({
	widgetTemplate,
	scenes,
	editingScene,
	gotoUrlRef,
	openNewTabRef,
	widgetStartTime,
	widgetEndTime,
	widgetOverlayRef,
	handleWidgetNameChange,
	handleDestinationSceneChange,
	handleGotoUrlWidgetChange,
	handleGotoUrlOpenNewTabChange,
	handleTimeAttributeChange,
	handleSubmitTimeAttribute,
	handleLocationAttributeChange,
	handleDeleteWidgetEvent,
	handleAddNewEventToWidget,
	handleAddActionToEvent,
	handleStylingAttributeChange,
	handleJumpToTimeMetaDataChange,
}: GeneralSettingsProps) => {
	const {
		name,
		type,
		events = [],
		style: {
			coordinate: { x, y } = { x: 0, y: 0 },
			dimension: { w, h } = { w: 0, h: 0 },
			rotation = 0,
			transformSettings = {
				borderRadiusBottom: defaultRadius,
				borderRadiusLeft: defaultRadius,
				borderRadiusRight: defaultRadius,
				borderRadiusTop: defaultRadius,
			},
		},
	} = widgetTemplate;

	//ref to the selected input
	const selectedInputRef = useRef<null | HTMLDivElement>(null);
	//property from child component x,y,w,h,roation...
	const selectedPropertyRef = useRef<null | string>(null);
	//old mouse position to check the direction of mouse
	const oldMouseYPos = useRef<number | undefined>();
	// callback to send updated value to redux
	const updateCallbackRef = useRef<null | Function>(null);
	// initMousedownPos to check if user click or drag
	const initMousedownPos = useRef<number>(0);
	// state of currentMouse position
	const [currentMousePos, setCurrentMousePos] = useState<number>(0);
	const [isSameAllBorderRadius, setChangeAllBorderRadius] = useState(true);

	const { t: translator } = useTranslation();

	const handleMouseActionSelectInput = (
		selectedInput: HTMLDivElement,
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		styleProperty: string,
		updateCallback: (_updateValue: number) => void
	) => {
		// set selected input
		selectedInputRef.current = selectedInput;
		// init old position of mouse
		oldMouseYPos.current = event.pageX;
		// init initMousedownPos to check if user click or drag
		initMousedownPos.current = event.pageX;
		// callback to send updated value to redux
		updateCallbackRef.current = updateCallback;
		//property from child component, since closure prevent update value inside child
		selectedPropertyRef.current = styleProperty;
		//hidden bouding box in widget
		widgetOverlayRef.current.hiddenHighlightBox(false);
		setCurrentMousePos(event.pageX);
	};

	const handleMouseMoveEvent = (event: any) => {
		if (!selectedInputRef || !selectedInputRef.current || !oldMouseYPos.current) {
			return;
		}
		oldMouseYPos.current = currentMousePos;
		setCurrentMousePos(event.pageX);
	};

	const handleSetMouseUp = () => {
		if (!oldMouseYPos.current || !initMousedownPos.current) {
			return;
		}

		if (Math.abs(oldMouseYPos.current - initMousedownPos.current) === 0) {
			selectedInputRef.current?.click();
		}
		widgetOverlayRef.current.hiddenHighlightBox(true);
		selectedInputRef.current = null;
	};

	const handleBorderRadiusChange = (key: string, value: string) => {
		if (!isSameAllBorderRadius) {
			handleStylingAttributeChange(`transformSettings`, `${key}`, value);
			return;
		}

		handleStylingAttributeChange(`transformSettings`, 'borderRadiusTop', value);
		handleStylingAttributeChange(`transformSettings`, 'borderRadiusRight', value);
		handleStylingAttributeChange(`transformSettings`, 'borderRadiusLeft', value);
		handleStylingAttributeChange(`transformSettings`, 'borderRadiusBottom', value);
	};

	useEffect(() => {
		if (!oldMouseYPos.current || !updateCallbackRef.current || !selectedPropertyRef.current) {
			return;
		}

		if (Math.abs(oldMouseYPos.current - currentMousePos) < 0.1) {
			return;
		}
		let updatedValue;
		updatedValue = currentMousePos >= oldMouseYPos.current ? 10 : -10;

		switch (selectedPropertyRef.current) {
			case 'x':
				updatedValue = getValidWidgetPosition(updatedValue + x, 0);
				break;
			case 'y':
				updatedValue = getValidWidgetPosition(updatedValue + y, 0);
				break;
			case 'w':
				updatedValue = getValidWidgetPosition(updatedValue + w, 0);
				break;
			case 'h':
				updatedValue = getValidWidgetPosition(updatedValue + h, 0);
				break;
			case 'rotation':
				updatedValue = getValidWidgetPosition(updatedValue + rotation, 0, 360);
				break;
			default:
				updatedValue = 0;
		}
		updateCallbackRef.current(updatedValue);
	}, [currentMousePos]);

	return (
		<CardData title={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_GENERAL_SETTINGS')}>
			<FormControlLabel
				className={'scenario-chart__control'}
				control={<TextField variant="standard" value={name} />}
				label={translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_WIDGETS_NAME')}
				onChange={handleWidgetNameChange}
				labelPlacement="start"
			/>

			{type !== WIDGET_TYPE_VIDEO && (
				<Events
					scenes={scenes}
					editingScene={editingScene}
					events={events}
					gotoUrlRef={gotoUrlRef}
					openNewTabRef={openNewTabRef}
					handleDestinationSceneChange={handleDestinationSceneChange}
					handleGotoUrlWidgetChange={handleGotoUrlWidgetChange}
					handleGotoUrlOpenNewTabChange={handleGotoUrlOpenNewTabChange}
					handleDeleteWidgetEvent={handleDeleteWidgetEvent}
					handleAddNewEventToWidget={handleAddNewEventToWidget}
					handleAddActionToEvent={handleAddActionToEvent}
					handleJumpToTimeMetaDataChange={handleJumpToTimeMetaDataChange}
				/>
			)}

			<Duration
				disabled={type === WIDGET_TYPE_VIDEO}
				widgetStartTime={widgetStartTime}
				widgetEndTime={widgetEndTime}
				handleTimeAttributeChange={handleTimeAttributeChange}
				handleSubmitTimeAttribute={handleSubmitTimeAttribute}
			/>

			{type !== WIDGET_TYPE_VIDEO && (
				<Position
					x={x}
					y={y}
					w={w}
					h={h}
					rotation={rotation}
					handleLocationAttributeChange={handleLocationAttributeChange}
					handleMouseActionSelectInput={handleMouseActionSelectInput}
					handleOnMouseMove={handleMouseMoveEvent}
					handleOnMouseUp={handleSetMouseUp}
				/>
			)}

			{type === WIDGET_TYPE_BUTTON && (
				<div className="scene-widget__control-column scene-widget__general-settings">
					<div className="scene-widget__control-label">
						{translator('SCENARIO_SCENE_EDITOR_WIDGET_SETTING_TEXT_WIDGET_BACKGROUND_BORDER_RADIUS')}
					</div>
					<div className="scene-widget__control-column--item">
						<div className="scene-widget__control-container">
							{Object.entries(BorderRadiusKeys).map(([key, displayVal], index) => {
								return (
									<FormControl
										disabled={isSameAllBorderRadius && index !== 0}
										key={displayVal}
										className="scene-widgets__transform-input"
										onChange={({ target }) =>
											handleBorderRadiusChange(key, (target as HTMLInputElement).value)
										}
										variant="outlined"
									>
										<OutlinedInput
											type="number"
											endAdornment={<InputAdornment position="end">px</InputAdornment>}
											value={transformSettings[key]}
										/>
										<FormHelperText>{displayVal}</FormHelperText>
									</FormControl>
								);
							})}
						</div>
						<IconButton
							onClick={() => setChangeAllBorderRadius(!isSameAllBorderRadius)}
							className={joinClassNames(
								'scene-widget__lock-btn',
								isSameAllBorderRadius ? 'scene-widget__lock-btn--active' : ''
							)}
						>
							{isSameAllBorderRadius ? <LockIcon /> : <LockOpenIcon />}
						</IconButton>
					</div>
				</div>
			)}
		</CardData>
	);
};

export default GeneralSettings;
