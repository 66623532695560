

export const SHOW_STACK_MESSAGE = "SHOW_STACK_MESSAGE";
export const SHOW_ALERT = "SHOW_ALERT";

export const showMessage = (message, msg_type) => ({
    type: SHOW_STACK_MESSAGE,
    message,
    msg_type
});

export const showAlert = (message, msg_type) => ({
    type: SHOW_ALERT,
    message,
    msg_type
});