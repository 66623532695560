import * as actions from "../actions/tracksActions"

const initialState = {
    loading: false
};

export const tracksReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CREATE_TRACK_REQUEST:
        case actions.CREATE_TRACK_REQUEST_SUCCESS:
        case actions.CREATE_TRACK_REQUEST_FAILURE:
            return {
                ...state
            };
        case actions.UPDATE_SEQUENCE_REQUEST:
        case actions.DELETE_SEQUENCE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actions.UPDATE_SEQUENCE_REQUEST_SUCCESS:
        case actions.DELETE_SEQUENCE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case actions.UPDATE_SEQUENCE_REQUEST_FAILURE:
        case actions.DELETE_SEQUENCE_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            };

        default:
            return state
    }
};
