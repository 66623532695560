import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import './TagsControlLibrary.scss';
import clear_icon from '../../assets/images/clear.svg';
import clear_hover_icon from '../../assets/images/clear-hover.svg';
import { setSearchTags } from '../../actions/searchActions';
import { retrieveTags } from '../../actions/publishActions';

const ENTER_KEY = 13;
const COMMA_KEY = 188;
const BACKSPACE_KEY = 8;

class TagsControlLibrary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: '',
			showSuggestion: false,
			matchingSuggestions: [],
			tags: this.props.displayTags || [],
			suggestions: this.props.suggestions || [],
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleKeyUp = this.handleKeyUp.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.inputRef = null;
	}

	componentDidMount() {
		if (this.props.onTagsLoaded) {
			this.props.onTagsLoaded(this);
		}
		this.props.retrieveTags(this.props.defaultAccountId).then((data) => this.setState({ suggestions: data }));
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.displayTags !== this.props.displayTags) {
			this.state = {
				tags: nextProps.displayTags,
				value: '',
			};
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.tags !== this.props.tags) {
			if (this.props.tags.length === 0) {
				this.setState({ tags: [] });
			}
		}
	}

	updatStateFromParent = (tags) => this.setState({ tags: tags });

	handleChange = (e) => {
		let value = e.target.value;
		this.setState({ value: value });
		if (!value) {
			this.hideSuggestion();
		} else {
			this.fillMatchingSuggestions(value);
			this.showSuggestion();
		}
	};

	handleKeyUp = (e) => {
		const key = e.keyCode;
		if (key === ENTER_KEY || key === COMMA_KEY) {
			this.addTag();
		}
	};

	handleKeyDown = (e) => {
		const key = e.keyCode;
		if (key === BACKSPACE_KEY) {
			//this.editPrevTag()
		} else {
			/*let dropdwn = document.getElementById('suggestionList');
            if (dropdwn) {
                var items = dropdwn.getElementsByTagName("li");
                items[0].classList.remove("highlight");
                for (var i = 0; i < items.length; ++i) {
                    var aKey = items[i].dataset.key;
                    if (String.fromCharCode(e.keyCode) === aKey) {
                        for (var j = 0; j < items.length; ++j) {
                            items[j].classList.remove("highlight");
                        }
                        items[i].classList.add("highlight");
                    }
                }
            }*/
			// to focus on key down
		}
	};

	addTag = (_) => {
		const { tags, value } = this.state;
		let tag = value.trim();
		tag = tag.replace(/,/g, '');
		if (!tag) {
			return;
		}
		this.setState({ tags: [...tags, tag], value: '' }, () => {
			this.props.setSearchTags(this.state.tags);
		});
		if (this.props.onDetailsChange) {
			this.props.onDetailsChange(tag);
		}
		this.hideSuggestion();
	};

	editPrevTag = (_) => {
		const { tags } = this.state;
		const tag = tags.pop();
		this.setState({ tags, value: tag });
		if (this.props.onDetailsChange) {
			this.props.onDetailsChange(tag);
		}
	};

	removeSelectedTag = (tag) => {
		const { tags } = this.state;
		const index = tags.indexOf(tag);
		if (index > -1) {
			tags.splice(index, 1);
		}
		this.setState({ tags: tags }, () => {
			this.props.setSearchTags(this.state.tags);
		});
		if (this.props.onDetailsChange) {
			this.props.onDetailsChange(tag);
		}
	};

	showSuggestion = (_) => this.setState({ showSuggestion: true });

	hideSuggestion = (_) =>
		this.setState({
			showSuggestion: false,
		});

	setTag = (name) => {
		this.setState({ value: name }, () => {
			this.addTag();
		});
		this.hideSuggestion();
	};

	fillMatchingSuggestions = (value) => {
		let matchingText = [];
		const { suggestions, showSuggestion } = this.state;
		if (suggestions && suggestions.length > 0 && showSuggestion) {
			suggestions.map((item) => {
				if (item.name && value && item.name.toLocaleLowerCase().match(value.toLocaleLowerCase())) {
					matchingText.push(item);
				}
			});
			this.setState({ matchingSuggestions: matchingText });
		}
	};

	renderSuggestionList = (_) => {
		const { matchingSuggestions, showSuggestion } = this.state;
		if (matchingSuggestions && matchingSuggestions.length > 0 && showSuggestion) {
			return (
				<ul
					className="suggestionListLibrary"
					id="suggestionList"
					style={{ width: this.inputRef?.getBoundingClientRect().width }}
				>
					{matchingSuggestions.map((item, index) => (
						<li key={index} onClick={() => this.setTag(item.name)}>
							{' '}
							{item.name + '(' + item.count + ')'}
						</li>
					))}
				</ul>
			);
		}
	};

	renderTagList = (_) => {
		const { tags } = this.state;
		return tags.map((tag, i) => (
			<li key={tag + i} className="tagLibrary">
				{tag}
				<img
					src={clear_icon}
					onMouseOver={(e) => (e.currentTarget.src = clear_hover_icon)}
					onMouseOut={(e) => (e.currentTarget.src = clear_icon)}
					onClick={() => this.removeSelectedTag(tag)}
				/>
			</li>
		));
	};

	render() {
		const { t } = this.props;
		const { value, tags } = this.state;
		return (
			<div className="form" id="TagsSectionLibrary">
				<span className="input-icon">
					<svg xmlns="http://www.w3.org/2000/svg" width="15.586" height="14.586" viewBox="0 0 15.586 14.586">
						<defs>
							<style>{'.aTag{fill - rule:evenodd;}'}</style>
						</defs>
						<g transform="translate(0 -1)">
							<path
								className="aTag"
								d="M3,2V6.586l7,7L14.586,9l-7-7ZM2,2A1,1,0,0,1,3,1H7.586a1,1,0,0,1,.707.293l7,7a1,1,0,0,1,0,1.414l-4.586,4.586a1,1,0,0,1-1.414,0l-7-7A1,1,0,0,1,2,6.586Z"
							/>
							<path
								className="aTag"
								d="M5.5,5A.5.5,0,1,0,5,4.5.5.5,0,0,0,5.5,5Zm0,1A1.5,1.5,0,1,0,4,4.5,1.5,1.5,0,0,0,5.5,6Z"
							/>
							<path d="M1,7.086a1,1,0,0,0,.293.707L8.75,15.25l-.043.043a1,1,0,0,1-1.414,0l-7-7A1,1,0,0,1,0,7.586V3A1,1,0,0,1,1,2Z" />
						</g>
					</svg>
				</span>
				{this.renderSuggestionList()}
				<input
					id="tagInput"
					ref={(ref) => (this.inputRef = ref)}
					type="text"
					value={value}
					placeholder={t('ADD_TAG')}
					onKeyUp={this.handleKeyUp}
					onChange={this.handleChange}
					onKeyDown={this.handleKeyDown}
				/>
				{/*}
                     <TextField
                        rows="1"
                        multiline
                        fullWidth
                        id="tagInput"
                        value={value}
                        margin="normal"
                        variant="outlined"
                        className='tag-input'
                        label= {t('ADD_TAG')}
                        onKeyUp={this.handleKeyUp}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                />*/}
				{/*<label className='tagInputInfo'>{t('PRESS_ENTER')}</label>*/}
				{tags && tags.length > 0 && <ul className="tagsListSection">{this.renderTagList()}</ul>}
			</div>
		);
	}
}

const mapStateToProps = ({ search, session }) => ({
	tags: search.tags,
	defaultAccountId: session.defaultAccountId,
});

const mapDispatchToProps = (dispatch) => ({
	setSearchTags: (tags) => dispatch(setSearchTags(tags)),
	retrieveTags: (accountId) => dispatch(retrieveTags(accountId)),
});
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation())(TagsControlLibrary);
