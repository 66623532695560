import QueryString from 'query-string';

import { writeApiEndpointV1 } from '../utils/config';
import BackendClient from './backendClient';
import { LocalStorageService } from './localStorageService';

export const twitterOAuthService = {
	async openPopUp(url) {
		window.open(
			url,
			'_blank',
			'resizable=yes,width=600,height=400,toolbar=no,titlebar=no,menubar=no,scrollbars=yes'
		);
		window.addEventListener('message', this.eventHandler, false);
	},

	eventHandler(event) {
		if (event.origin === window.location.origin) {
			const params = QueryString.parse(event.data.searchParam);
			if (params.error) {
				// Handle error
			} else if (params.oauth_token && params.oauth_verifier) {
				const twitterAuthenicationBody = {
					oauth_token: params.oauth_token,
					oauth_verifier: params.oauth_verifier,
				};
				BackendClient.post(
					`${writeApiEndpointV1}sharing/accounts/${LocalStorageService.getDefaultAccountId()}/platforms/Twitter01/authorizations/twitter`,
					twitterAuthenicationBody
				);
			}
		}
	},
};
