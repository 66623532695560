import React from 'react';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import FastClick from 'react-fastclick-alt';
import 'url-search-params-polyfill';
import 'date-input-polyfill';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { rootReducer } from './reducers/rootReducer';
import { createStore, applyMiddleware, compose } from 'redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { SnackbarProvider } from 'notistack';
import './i18n';

import './global.scss';
import App from './layout/App';

const preloadedState = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const middleware = process.env.NODE_ENV === 'development' ? [thunkMiddleware] : [thunkMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, preloadedState, composeEnhancers(applyMiddleware(...middleware)));

i18next.init({
	interpolation: { escapeValue: false },
});

ReactDOM.render(
	<Provider store={store}>
		<I18nextProvider i18n={i18next}>
			<SnackbarProvider maxSnack={3} hideIconVariant>
				<FastClick>
					<App />
				</FastClick>
			</SnackbarProvider>
		</I18nextProvider>
	</Provider>,
	document.getElementById('root')
);
