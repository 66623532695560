import { useContext } from 'react';
import { EdgeContext } from '../../../../screens/Scenario/utils/EdgeContext';


export const useEdgeInfo = ({source, target}) => {
    const edgeMap = useContext(EdgeContext);
    const key = `${source}-${target}`;
    const reverseKey = `${target}-${source}`;

    return edgeMap[key] || edgeMap[reverseKey] || [];
}