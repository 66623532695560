

export const getIndexOfResouceWithTagIncluded = (resource, tag) => {
    let index = resource && resource.rel ? resource.rel.indexOf(tag) : -1;
    return index;
};

export const removeTagFromRelOfResource = (resource, tag) => {
    let index = getIndexOfResouceWithTagIncluded(resource, tag);
    if (index > -1 && resource.rel) {
        resource.rel.splice(index, 1); //splicing tag from rel array in old resource
    }
    return resource;
};

export const addTagToRelOfResource = (resource, tag) => {
    let index = getIndexOfResouceWithTagIncluded(resource, tag);
    if (index === -1) {
        if (!resource.rel) { resource.rel = []; }
        resource.rel.push(tag); //adding tag to rel array with new resource
    }
    return resource;
};

export const getResourceOfMediaById = (media, id) => {
    let resourceWithId = media.asset.resources.filter(eachResource => (eachResource.id && eachResource.id == id));
    if (resourceWithId && resourceWithId.length > 0) {
        return resourceWithId[0];
    } else {
        return '';
    }
};

export const getResourcesWithRelTag = (media) => {
    let resources = media.asset.resources.filter(eachImageResource => (eachImageResource && eachImageResource.type === 'image' && eachImageResource.renditions));
    let resourcesWithRelTag = resources.filter(eachImageResource => (eachImageResource && eachImageResource.rel && eachImageResource.rel.length > 0));
    return resourcesWithRelTag;
};

export const getResourceOfMediaWithTag = (media, tag) => {
    let resourcesWithRelTag = getResourcesWithRelTag(media);
    let resourcesWithTag = [];
    if (resourcesWithRelTag.length > 0) {
        resourcesWithRelTag.map(resource => {
            if (resource.rel.indexOf(tag) != -1) {
                resourcesWithTag.push(resource);
            }
        });
    }
    if (resourcesWithTag.length > 0) {
        return resourcesWithTag[0];
    }
};

export const getResourceIdOfMediaWithTag = (media, tag) => {
    let resource = getResourceOfMediaWithTag(media, tag);
    if (resource) {
        return resource.id;
    }
};