import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getPollResult, getPolls, setActivePoll as setActivePollAction } from '../../../../actions/pollActions';
import { showMessage } from '../../../../actions/globalActions';
import { messageTypes } from '../../../../constants/mediaConstants';
import { PollsContainerTemplate } from './PollsContainerTemplate';
import { setViewEngagementLoading } from '../../../../actions/viewEngagementActions';
import { useTranslation } from 'react-i18next';

interface PollsDataProps {
	defaultAccountId: string;
	mediaId: string;
	updatePolls?: boolean;
	callback?: () => void;
}

interface PollsReturnDataProps {
	upcomingPolls: PollsContainerTemplate.PollData[];
	previousPolls: PollsContainerTemplate.PollData[];
	activePolls: PollsContainerTemplate.PollData[];
}

export const useFetchPolls = ({
	defaultAccountId,
	mediaId,
	updatePolls,
	callback,
}: PollsDataProps): PollsReturnDataProps => {
	const dispatch = useDispatch() as any;
	const { t } = useTranslation();

	const [activePolls, setActivePolls] = useState<PollsContainerTemplate.PollData[]>([]);
	const [previousPolls, setPreviousPolls] = useState<PollsContainerTemplate.PollData[]>([]);
	const [upcomingPolls, setUpcomingPolls] = useState<PollsContainerTemplate.PollData[]>([]);

	useEffect(() => {
		if (!updatePolls) {
			return;
		}

		dispatch(setViewEngagementLoading(true));

		dispatch(getPolls(defaultAccountId, mediaId))
			.then((response: any) => {
				if (!response) {
					dispatch(showMessage(`${t('VIEWER_ENGAGEMENT_CANT_RETRIEVE_DATA')}`, messageTypes.error));
					dispatch(setViewEngagementLoading(false));
					callback?.();
					return;
				}

				const newActivePolls = (response ?? []).filter((p: PollsContainerTemplate.PollData) => p.published);
				setActivePolls(newActivePolls);
				setPreviousPolls(
					(response ?? []).filter((p: PollsContainerTemplate.PollData) => p.closed && !p.published)
				);
				setUpcomingPolls(
					(response ?? []).filter((p: PollsContainerTemplate.PollData) => !p.closed && !p.published)
				);

				callback?.();

				dispatch(setViewEngagementLoading(false));
				dispatch(setActivePollAction(newActivePolls[0]));
			})
			.catch((error: any) => {
				dispatch(showMessage(`${t('VIEWER_ENGAGEMENT_CANT_RETRIEVE_DATA')}. ${error}`, messageTypes.error));
				dispatch(setViewEngagementLoading(false));
				callback?.();
			});
	}, [defaultAccountId, dispatch, mediaId, t, updatePolls]);

	return { activePolls, previousPolls, upcomingPolls };
};

interface PollResultDataProps {
	defaultAccountId: string;
	mediaId: string;
	pollId: string;
}
interface PollResultReturnDataProps {
	pollResult?: {};
	error?: any;
}
export const useFetchPollResult = ({
	defaultAccountId,
	mediaId,
	pollId,
}: PollResultDataProps): PollResultReturnDataProps => {
	const dispatch = useDispatch() as any;

	useEffect(() => {
		dispatch(getPollResult(defaultAccountId, mediaId, pollId))
			.then((response: any) => {
				return { pollResult: response };
			})
			.catch((error: any) => {
				dispatch(showMessage(`Can't get poll's result ${error}`, messageTypes.error));
				return { error: error };
			});
	}, [defaultAccountId, dispatch, mediaId, pollId]);

	return {};
};
