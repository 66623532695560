import BackendClient from './backendClient';
import { readApiEndpointV1, writeApiEndpointV1 } from '../utils/config';

export const _loadPlayerConfiguration = (accountId) =>
	BackendClient.get(`${readApiEndpointV1}player/accounts/${accountId}/settings`);

export const _loadPlayerConfigurationById = (accountId, playerConfigId) =>
	BackendClient.get(`${readApiEndpointV1}player/accounts/${accountId}/settings/${playerConfigId}`);

export const _createNewPlayerConfiguration = (accountId, body) =>
	BackendClient.post(`${writeApiEndpointV1}player/accounts/${accountId}/settings`, body, false);

export const _updatePlayerConfiguration = (accountId, playerConfigId, body) =>
	BackendClient.put(
		`${writeApiEndpointV1}player/accounts/${accountId}/settings/${playerConfigId}`,
		body,
		false,
		false
	);

export const _deletePlayerConfiguration = (accountId, playerConfigId) =>
	BackendClient.delete(`${writeApiEndpointV1}player/accounts/${accountId}/settings/${playerConfigId}`);
