import { ipOnlyEndpointV2 } from '../utils/config';
import BackendClient from './backendClient';

export const _getIPonlyResources = (accountId) =>
	BackendClient.get(`${ipOnlyEndpointV2}accounts/${accountId}/resources`);
export const _getIPonlyChannels = (accountId, resourceId) =>
	BackendClient.get(`${ipOnlyEndpointV2}live-origin/accounts/${accountId}/resources/${resourceId}/channels`);
export const _getIPonlyChannel = (accountId, resourceId, channelName) =>
	BackendClient.get(`${ipOnlyEndpointV2}accounts/${accountId}/resources/${resourceId}/channels/${channelName}`);
export const _createIPonlyChannel = (accountId, resourceId, channelName, data) =>
	BackendClient.put(
		`${ipOnlyEndpointV2}accounts/${accountId}/resources/${resourceId}/channels/${channelName}`,
		data,
		false,
		false
	);
export const _updateIPonlyChannel = (accountId, resourceId, channelName, data) =>
	BackendClient.put(
		`${ipOnlyEndpointV2}accounts/${accountId}/resources/${resourceId}/channels/${channelName}`,
		data,
		false,
		false
	);
export const _deleteIPonlyChannel = (accountId, resourceId, channelName) =>
	BackendClient.delete(`${ipOnlyEndpointV2}accounts/${accountId}/resources/${resourceId}/channels/${channelName}`);
