import React, { Component } from 'react'

export default class PopoverContent extends Component {

    componentDidMount() {
        this.popoverContentRef && this.popoverContentRef.focus();
        window.document.addEventListener('click', this.handleClickOutSide.bind(this), false)
        if (this.popoverContentRef) {
            const left = window.innerWidth - this.popoverContentRef.getBoundingClientRect().left - this.popoverContentRef.getBoundingClientRect().width - 12; // 12 for margin
            this.popoverContentRef.style.left = left + "px";
        }
    }

    componentWillUnmount() {
        this.popoverContentRef && this.popoverContentRef.blur();
        window.document.removeEventListener('click', this.handleClickOutSide.bind(this), false)
    }

    handleClickOutSide = e => {
        const { onClose, closeOnClick, onClickOutSide } = this.props;
        if (this.popoverContentRef) {
            if (closeOnClick) {
                onClose()
            } else {
                if (!this.popoverContentRef.contains(e.target)) {
                    onClickOutSide()
                }
            }
        }
    };

    onKeyDown = e => {
        if (e.keyCode === 27) {
            e.stopPropagation();
            this.props.onClose()
        }
    };

    render() {
        const {
            style,
            children,
            className,
            placement,
            showArrow
        } = this.props;
        return (
            <div
                tabIndex="-1"
                style={style}
                ref={r => this.popoverContentRef = r}
                onKeyDown={this.onKeyDown.bind(this)}
                className={[className ? `popover-content${className}` : "popover-content", showArrow ? "-arrow": "", placement ? placement : ""].join(" ").trim()}
            >
                {children && <div className="popover-inner">
                    {children}
                </div>}
            </div>
        )
    }
}