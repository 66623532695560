import React, { Component } from 'react';

class InteractDraggable extends Component {
	constructor(props) {
		super(props);
		this.element = React.createRef();
		this.drag = this.drag.bind(this);
		this.initiateDrag = this.initiateDrag.bind(this);
		this.endDrag = this.endDrag.bind(this);
		this.initialDragPosition = {
			x: 0,
			y: 0,
		};
		this.target = null;
	}

	initiateDrag = (evt) => {
		const { svgPointClassName } = this.props;
		this.target = evt.target;
		let svgElem = evt.target.parentElement;
		let svgPoint = window.document.getElementsByClassName(svgPointClassName)[0].createSVGPoint();
		svgPoint.x = evt.clientX;
		svgPoint.y = evt.clientY;
		this.initialDragPosition = svgPoint.matrixTransform(evt.target.getScreenCTM().inverse());
		this.initialDragPosition.x = this.initialDragPosition.x - this.props.x;
		this.initialDragPosition.y = this.initialDragPosition.y - this.props.y;

		while (svgElem.localName !== 'svg') {
			svgElem = svgElem.parentElement;
		}

		svgElem.addEventListener('mousemove', this.drag);
		svgElem.addEventListener('mouseup', this.endDrag);
		this.props.onInitiate(evt);
	};
	endDrag = (evt) => {
		let svgElem = evt.target;
		while (svgElem.localName !== 'svg') {
			svgElem = svgElem.parentElement;
		}

		svgElem.removeEventListener('mousemove', this.drag);
		svgElem.removeEventListener('mouseup', this.endDrag);
		if (this.props['onDragEnd']) {
			this.props.onDragEnd();
		}
	};
	drag = (evt) => {
		if (evt.movementX || evt.movementY) {
			let svgPoint = window.document.getElementsByTagName('svg')[0].createSVGPoint();
			svgPoint.x = evt.clientX;
			svgPoint.y = evt.clientY;
			svgPoint = svgPoint.matrixTransform(this.target.getScreenCTM().inverse());
			this.props.onDragged(
				svgPoint.x - this.initialDragPosition.x - this.props.x,
				svgPoint.y - this.initialDragPosition.y - this.props.y
			);
		}
	};

	render = () => {
		return (
			<rect
				className={this.props.selected ? 'draggable-element selected-element hide' : 'draggable-element'}
				gid={this.props.gid}
				onMouseDown={this.initiateDrag}
				x={this.props.x}
				y={this.props.y}
				width={this.props.width}
				height={this.props.height}
			/>
		);
	};
}

export default InteractDraggable;
