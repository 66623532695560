export const TimelineEvents = [
	'currentTimeTick',
	'click',
	'contextmenu',
	'doubleClick',
	'drop',
	'mouseOver',
	'mouseDown',
	'mouseUp',
	'mouseMove',
	'groupDragged',
	'changed',
	'rangechange',
	'rangechanged',
	'select',
	'itemover',
	'itemout',
	'timechange',
	'timechanged',
];
