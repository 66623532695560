import {
	processSearchQuery,
	saveNewFilterForSearchQuery,
	saveNewFilterForLibrarySearchQuery,
	deleteSearchFilter,
	loadTheSelectedMediaSearchFilterQuery,
	postQueriesForSearchResult,
	postPlayListsQuery,
	updateSearchFilter,
	updateSearchFilterQuery,
	newAssets,
} from '../services/mediaService';
import { LocalStorageService } from '../services/localStorageService';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SET_SEARCH_TAGS = 'SET_SEARCH_TAGS';
export const CLEAR_SEARCH_TAGS = 'CLEAR_SEARCH_TAGS';
export const UPDATE_SEARCH_TAGS = 'UPDATE_SEARCH_TAGS';
export const SEARCH_REQUEST_SUCCESS = 'SEARCH_REQUEST_SUCCESS';
export const SEARCH_REQUEST_FAILURE = 'SEARCH_REQUEST_FAILURE';
export const DELETE_SEARCH_FILTER_REQUEST = 'DELETE_SEARCH_FILTER_REQUEST';
export const DELETE_SEARCH_FILTER_REQUEST_SUCCESS = 'DELETE_SEARCH_FILTER_REQUEST_SUCCESS';
export const DELETE_SEARCH_FILTER_REQUEST_FAILURE = 'DELETE_SEARCH_FILTER_REQUEST_FAILURE';
export const SEND_SEARCH_SCREEN_LOAD_REQUEST = 'SEND_SEARCH_SCREEN_LOAD_REQUEST';
export const SEND_SEARCH_KEYWORD_UPDATE_REQUEST = 'SEND_SEARCH_KEYWORD_UPDATE_REQUEST';
export const SEND_SEARCH_SCREEN_UNMOUNT_REQUEST = 'SEND_SEARCH_SCREEN_UNMOUNT_REQUEST';
export const SEND_SEARCH_ACTION_PERFORMED_REQUEST = 'SEND_SEARCH_ACTION_PERFORMED_REQUEST';
export const SEND_SEARCH_ACTION_PERFORMED_CLEARED = 'SEND_SEARCH_ACTION_PERFORMED_CLEARED';
export const SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST = 'SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST';
export const SEND_CLEAR_SEARCH_KEYWORD_UPDATE_REQUEST = 'SEND_CLEAR_SEARCH_KEYWORD_UPDATE_REQUEST';
export const SEND_SEARCH_RESULT_TOTAL_COUNT_EMPTY_REQUEST = 'SEND_SEARCH_RESULT_TOTAL_COUNT_EMPTY_REQUEST';
export const SEND_SEARCH_RESULT_TOTAL_COUNT_UPDATE_REQUEST = 'SEND_SEARCH_RESULT_TOTAL_COUNT_UPDATE_REQUEST';
export const SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST = 'SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST';
export const SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST_SUCCESS = 'SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST_SUCCESS';
export const SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST_FAILURE = 'SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST_FAILURE';
export const SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST = 'SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST';
export const SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST_SUCCESS =
	'SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST_SUCCESS';
export const SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST_FAILURE =
	'SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST_FAILURE';
export const SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST =
	'SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST';
export const SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST_FAILURE =
	'SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST_FAILURE';
export const SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST_SUCCESS =
	'SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST_SUCCESS';
export const SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST_SUCCESS =
	'SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST_SUCCESS';
export const SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST_FAILURE =
	'SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST_FAILURE';

const sendSearchRequest = (_) => ({
	type: SEARCH_REQUEST,
});

const sendSearchRequestSuccess = (_) => ({
	type: SEARCH_REQUEST_SUCCESS,
});

const sendSearchRequestFailure = (_) => ({
	type: SEARCH_REQUEST_FAILURE,
});

const sendSaveNewFilterForSearchQueryRequest = (_) => ({
	type: SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST,
});

const sendSaveNewFilterForSearchQueryRequestSuccess = (_) => ({
	type: SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST_SUCCESS,
});

const sendSaveNewFilterForSearchQueryRequestFailure = (_) => ({
	type: SEND_SAVE_NEW_FILTER_FOR_SEARCH_QUERY_REQUEST_FAILURE,
});

const deleteSearchFilterRequest = (_) => ({
	type: DELETE_SEARCH_FILTER_REQUEST,
});

const deleteSearchFilterRequestSuccess = (_) => ({
	type: DELETE_SEARCH_FILTER_REQUEST_SUCCESS,
});

// eslint-disable-next-line no-unused-vars
const deleteSearchFilterRequestFailure = (_) => ({
	type: DELETE_SEARCH_FILTER_REQUEST_FAILURE,
});

const sendLoadTheSelectedMediaSearchFilterQueryRequest = (_) => ({
	type: SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST,
});

const sendLoadTheSelectedMediaSearchFilterQueryRequestSuccess = (_) => ({
	type: SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST_SUCCESS,
});

const sendLoadTheSelectedMediaSearchFilterQueryRequestFailure = (_) => ({
	type: SEND_LOAD_THE_SELECTED_MEDIA_SEARCH_FILTER_QUERY_REQUEST_FAILURE,
});

const sendHandlePostQueriesForSearchResultRequest = (_) => ({
	type: SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST,
});

const sendHandlePostQueriesForSearchResultRequestSuccess = (_) => ({
	type: SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST_SUCCESS,
});

const sendHandlePostQueriesForSearchResultRequestFailure = (_) => ({
	type: SEND_HANDLE_POST_QUERIES_FOR_SEARCH_RESULT_REQUEST_FAILURE,
});

export const sendSearchScreenLoadRequest = (_) => ({
	type: SEND_SEARCH_SCREEN_LOAD_REQUEST,
});

export const sendSearchScreenUnmountRequest = (_) => ({
	type: SEND_SEARCH_SCREEN_UNMOUNT_REQUEST,
});

export const sendSearchResultTotalCountUpdateRequest = (resultTotalCount) => ({
	type: SEND_SEARCH_RESULT_TOTAL_COUNT_UPDATE_REQUEST,
	resultTotalCount,
});

export const sendSearchResultTotalCountEmptyRequest = (_) => ({
	type: SEND_SEARCH_RESULT_TOTAL_COUNT_EMPTY_REQUEST,
});

export const sendSearchKeywordUpdateRequest = (searchKeywords) => ({
	type: SEND_SEARCH_KEYWORD_UPDATE_REQUEST,
	searchKeywords,
});

export const sendClearSearchKeywordUpdateRequest = (_) => ({
	type: SEND_CLEAR_SEARCH_KEYWORD_UPDATE_REQUEST,
});

export const sendHandleUpdateSearchFilterRequest = (_) => ({
	type: SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST,
});

export const sendHandleUpdateSearchFilterRequestSuccess = (_) => ({
	type: SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST_SUCCESS,
});

export const sendHandleUpdateSearchFilterRequestFailure = (_) => ({
	type: SEND_HANDLE_UPDATE_SEARCH_FILTER_REQUEST_FAILURE,
});

export const sendSearchActionPerformedRequest = (_) => ({
	type: SEND_SEARCH_ACTION_PERFORMED_REQUEST,
});

export const sendSearchActionPerformedCleared = (_) => ({
	type: SEND_SEARCH_ACTION_PERFORMED_CLEARED,
});

export const updateSearchTags = (tag) => ({
	type: UPDATE_SEARCH_TAGS,
	tag,
});

export const setSearchTags = (tags) => ({
	type: SET_SEARCH_TAGS,
	tags,
});

export const clearSearchTags = (_) => ({
	type: CLEAR_SEARCH_TAGS,
});

export const getAccountId = (getState) => {
	if (getState) {
		return getState().session.defaultAccountId || LocalStorageService.getDefaultAccountId();
	} else {
		return LocalStorageService.getDefaultAccountId();
	}
};

export const performSearchQueryAction = (accountId, mediaName, searchQuery) => (dispatch, getState) => {
	dispatch(sendSearchRequest());
	const defaultAccountId = accountId || getAccountId(getState);
	return processSearchQuery(defaultAccountId, mediaName, searchQuery)
		.then((data) => {
			dispatch(sendSearchRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendSearchRequestFailure());
			return error;
		});
};

export const saveNewFilterForSearchQueryAction =
	(accountId, filterName, guid, searchQuery, sortOrder, limit) => (dispatch, getState) => {
		dispatch(sendSaveNewFilterForSearchQueryRequest());
		const defaultAccountId = accountId || getAccountId(getState);

		return saveNewFilterForSearchQuery(defaultAccountId, filterName, guid, searchQuery, sortOrder, limit)
			.then((data) => {
				dispatch(sendSaveNewFilterForSearchQueryRequestSuccess());
				return data;
			})
			.catch((error) => {
				dispatch(sendSaveNewFilterForSearchQueryRequestFailure());
				return error;
			});
	};

export const deleteSearchFilterQueryAction = (accountId, filterId) => (dispatch, getState) => {
	dispatch(deleteSearchFilterRequest());
	const defaultAccountId = accountId || getAccountId(getState);

	return deleteSearchFilter(defaultAccountId, filterId)
		.then((data) => {
			dispatch(deleteSearchFilterRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(deleteSearchFilterRequestSuccess());
			return error;
		});
};

export const saveNewFilterForLibrarySearchQueryAction =
	(accountId, filterName, guid, searchQuery, sortOrder, limit) => (dispatch, getState) => {
		dispatch(sendSaveNewFilterForSearchQueryRequest());
		const defaultAccountId = accountId || getAccountId(getState);
		return saveNewFilterForLibrarySearchQuery(defaultAccountId, filterName, guid, searchQuery, sortOrder, limit)
			.then(() => {
				dispatch(sendSaveNewFilterForSearchQueryRequestSuccess());
				return guid;
			})
			.catch((error) => {
				dispatch(sendSaveNewFilterForSearchQueryRequestFailure());
				return error;
			});
	};

export const fetchTheSelectedMediaSearchFilterQuery = (accountId, filterId) => (dispatch, getState) => {
	dispatch(sendLoadTheSelectedMediaSearchFilterQueryRequest());
	const defaultAccountId = accountId || getAccountId(getState);
	return loadTheSelectedMediaSearchFilterQuery(defaultAccountId, filterId)
		.then((data) => {
			dispatch(sendLoadTheSelectedMediaSearchFilterQueryRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendLoadTheSelectedMediaSearchFilterQueryRequestFailure());
			return error;
		});
};

export const handlePostQueriesForSearchResult =
	(
		accountId,
		guidForQueries,
		guidForAssests,
		guidForPlaylist,
		newPLaylistName,
		searchQuery,
		sortOrder,
		searchLimit
	) =>
	(dispatch, getState) => {
		let cleanSearchQuery = searchQuery;

		if (cleanSearchQuery && cleanSearchQuery.startsWith('q=')) {
			cleanSearchQuery = cleanSearchQuery.substring(2);
		}

		dispatch(sendHandlePostQueriesForSearchResultRequest());
		const defaultAccountId = accountId || getAccountId(getState);
		return postQueriesForSearchResult(
			defaultAccountId,
			guidForQueries,
			newPLaylistName,
			cleanSearchQuery,
			sortOrder,
			searchLimit
		)
			.then((_) => newAssets(defaultAccountId, guidForAssests).then((newAsset) => newAsset))
			.then((_) =>
				postPlayListsQuery(
					defaultAccountId,
					guidForPlaylist,
					guidForQueries,
					newPLaylistName,
					guidForAssests
				).then((newPlaylist) => newPlaylist)
			)
			.then((data) => {
				dispatch(sendHandlePostQueriesForSearchResultRequestSuccess());
				return data;
			})
			.catch((error) => {
				dispatch(sendHandlePostQueriesForSearchResultRequestFailure());
				return error;
			});
	};

export const handleUpdateSearchFilter = (selectedFilter, filterId, accountId) => (dispatch, getState) => {
	dispatch(sendHandleUpdateSearchFilterRequest());
	const defaultAccountId = accountId || getAccountId(getState);
	return updateSearchFilter(selectedFilter, filterId, defaultAccountId)
		.then((data) => {
			dispatch(sendHandleUpdateSearchFilterRequestSuccess());
			return data;
		})
		.catch((error) => {
			dispatch(sendHandleUpdateSearchFilterRequestFailure());
			return error;
		});
};

export const handleUpdateSmartFilterQuery =
	(accountId, filterName, filterId, searchQuery, sortOrder, limit) => (dispatch, getState) => {
		dispatch(sendHandleUpdateSearchFilterRequest());
		const defaultAccountId = accountId || getAccountId(getState);
		return updateSearchFilterQuery(defaultAccountId, filterName, filterId, searchQuery, sortOrder, limit)
			.then((data) => {
				dispatch(sendHandleUpdateSearchFilterRequestSuccess());
				return data;
			})
			.catch((error) => {
				dispatch(sendHandleUpdateSearchFilterRequestFailure());
				return error;
			});
	};
