import React from 'react';
import AllLayersSettings from './allLayersSetting/AllLayersSettings';
import WidgetSettings from './widgetSettings/WidgetSettings';

const ALL_BREADCRUMB_LEVELS = {
	ALL_LAYERS: 'alllayers',
	WIDGET: 'widget',
};

type EditTabProps = {
	baseClassName: string;
	breadcrumbLevel: string;
	editingScene: { widgetTemplates: Array<{ id: string; name: string }> };
	editingWidget: { widgetTemplateId: string };
	activeLayout: string;
	editingBox: {};
	scenarioContainerRef: any;
	widgetOverlayRef: any;
	addHoverImageRef: {};
	widgetSettingRef: any;
	setBreadcrumbLevel: (_event: {}, _level: string) => {};
	onUploadMediaClick: () => {};
	onAddWidgetClick: () => {};
	onWidgetClick: () => {};
	duplicateWidget: () => {};
	removeWidgetInBox: () => {};
	movePositionHandler: () => {};
	onShowIconLibraryPickerModal: () => {};
	handleReturnedMedia: () => {};
};

const EditTab = ({
	baseClassName,
	breadcrumbLevel,
	editingScene,
	editingWidget,
	activeLayout,
	editingBox,
	scenarioContainerRef,
	widgetOverlayRef,
	addHoverImageRef,
	widgetSettingRef,
	setBreadcrumbLevel,
	onAddWidgetClick,
	onWidgetClick,
	duplicateWidget,
	removeWidgetInBox,
	onUploadMediaClick,
	movePositionHandler,
	onShowIconLibraryPickerModal,
	handleReturnedMedia,
}: EditTabProps) => {
	return (
		<div className={`${baseClassName}__tab-content ${baseClassName}__tab-edit`}>
			<div className={`${baseClassName}__tab-edit ${baseClassName}__tab-edit--breadcrumb`}>
				<a
					className={[
						'tab-edit__breadcrum-item',
						breadcrumbLevel === ALL_BREADCRUMB_LEVELS.ALL_LAYERS ? 'tab-edit__breadcrum-item--current' : '',
					]
						.join(' ')
						.trim()}
					onClick={(event) => setBreadcrumbLevel(event, 'alllayers')}
				>
					Edit
				</a>
				{breadcrumbLevel === ALL_BREADCRUMB_LEVELS.WIDGET && (
					<>
						<span className="tab-edit__breadcrum-item">/</span>
						<a
							className="tab-edit__breadcrum-item tab-edit__breadcrum-item--current"
							onClick={(event) => setBreadcrumbLevel(event, 'widget')}
						>
							{editingScene?.widgetTemplates.find((w) => w.id === editingWidget?.widgetTemplateId)
								?.name ?? 'Widget'}
						</a>
					</>
				)}
			</div>
			{breadcrumbLevel === ALL_BREADCRUMB_LEVELS.ALL_LAYERS && (
				<AllLayersSettings
					activeLayout={activeLayout}
					scenarioContainerRef={scenarioContainerRef}
					onWidgetClick={onWidgetClick}
					onAddWidgetClick={onAddWidgetClick}
					onDuplicateWidget={duplicateWidget}
					onRemoveWidget={removeWidgetInBox}
					editingWidget={editingWidget}
				/>
			)}
			{breadcrumbLevel === ALL_BREADCRUMB_LEVELS.WIDGET && (
				<WidgetSettings
					ref={widgetSettingRef}
					// @ts-ignore: Ts throw issue for some wierd reason. Ignore next line
					activeLayout={activeLayout}
					editingWidget={editingWidget}
					editingBox={editingBox}
					scenarioContainerRef={scenarioContainerRef}
					widgetOverlayRef={widgetOverlayRef}
					onUploadMediaClick={onUploadMediaClick}
					movePlayerToPosition={movePositionHandler}
					addHoverImageRef={addHoverImageRef}
					onShowIconLibraryPickerModal={onShowIconLibraryPickerModal}
					handleReturnedMedia={handleReturnedMedia}
				/>
			)}
		</div>
	);
};

export default EditTab;
